import { ChangeEvent, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  TopAmountWrapper,
  BillingInputsCtn,
  InputField,
  InputWrapper,
  Title,
  MultiInputsWrapper,
} from "./styles";
import ReusableButton from "../../component/Reusable/ReusableButton";
import { updateDonationForm } from "../../redux/features/donationFormSlice";
import { updateDonationStep } from "../../redux/features/donationStepSlice";
import { Notify } from "notiflix";
import { emailValidation } from "../../utils/email-validation";

const Step2 = () => {
  const dispatch = useAppDispatch();
  const [details, setDetails] = useState<{ [key: string]: string }>({
    firstname: "",
    lastname: "",
    email: "",
    addressLineOne: "",
    addressLineTwo: "",
    city: "",
    postcode: "",
  });
  const [show, setShow] = useState({
    firstname: false,
    lastname: false,
    email: false,
    addressLineOne: false,
    addressLineTwo: false,
    city: false,
    postcode: false,
  });
  const form = useAppSelector((state) => state.donationForm);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setDetails((prev) => ({ ...prev, [name]: value }));
    if (details[name].length > 0) {
      setShow((prev) => ({ ...prev, [name]: true }));
    }
  };

  const handleFocus = (name: string) => {
    setShow((prev) => ({ ...prev, [name]: true }));
  };

  const handleBlur = (name: string) => {
    if (details[name].length === 0) {
      setShow((prev) => ({ ...prev, [name]: false }));
    } else {
      setShow((prev) => ({ ...prev, [name]: true }));
    }
  };

  const handleContinue = async () => {
    if (!details.firstname) {
      return Notify.failure("Please provide your first name.");
    }
    if (!details.lastname) {
      return Notify.failure("Please provide your surname.");
    }
    if (!details.email) {
      return Notify.failure("Please provide your email.");
    }
    const emailRes = await emailValidation(details.email);
    if (!emailRes) {
      return Notify.failure("Please provide a valid email address.");
    }
    if (!details.addressLineOne) {
      return Notify.failure("Please provide your address");
    }
    if (!details.city) {
      return Notify.failure("Please provide your town/city");
    }
    if (!details.postcode) {
      return Notify.failure("Please provide your postcode");
    }

    sessionStorage.setItem("billingInfo", JSON.stringify(details));
    dispatch(updateDonationForm(details));
    dispatch(updateDonationStep(3));
  };

  return (
    <>
      <TopAmountWrapper>
        <div>
          <p>Your Donation</p>
          <p>
            <span>£{form.amount}</span>
          </p>
        </div>
      </TopAmountWrapper>
      <Title>Billing Information</Title>
      <BillingInputsCtn>
        <MultiInputsWrapper>
          <InputWrapper $show={show.firstname}>
            {details.firstname.length > 0 || show.firstname ? (
              <p>First name</p>
            ) : null}
            <InputField
              name="firstname"
              value={details.firstname}
              placeholder="First name"
              onFocus={() => handleFocus("firstname")}
              onBlur={() => handleBlur("firstname")}
              onChange={handleChange}
              $show={details.firstname.length > 0}
            />
          </InputWrapper>
          <InputWrapper $show={show.lastname}>
            {details.lastname.length > 0 || show.lastname ? (
              <p>Surname</p>
            ) : null}
            <InputField
              name="lastname"
              value={details.lastName}
              placeholder="Surname"
              onFocus={() => handleFocus("lastname")}
              onBlur={() => handleBlur("lastname")}
              onChange={handleChange}
              $show={details.lastname.length > 0}
            />
          </InputWrapper>
        </MultiInputsWrapper>
        <InputWrapper $show={show.email}>
          {details.email.length > 0 || show.email ? <p>Email address</p> : null}
          <InputField
            name="email"
            value={details.email}
            placeholder="Email address"
            onFocus={() => handleFocus("email")}
            onBlur={() => handleBlur("email")}
            onChange={handleChange}
            $show={details.email.length > 0}
          />
        </InputWrapper>
        <InputWrapper $show={show.addressLineOne}>
          {details.addressLineOne.length > 0 || show.addressLineOne ? (
            <p>Address Line 1</p>
          ) : null}
          <InputField
            name="addressLineOne"
            value={details.addressLineOne}
            placeholder="Address line 1"
            onFocus={() => handleFocus("addressLineOne")}
            onBlur={() => handleBlur("addressLineOne")}
            onChange={handleChange}
            $show={details.addressLineOne.length > 0}
          />
        </InputWrapper>
        <InputWrapper $show={show.addressLineTwo}>
          {details.addressLineTwo.length > 0 || show.addressLineTwo ? (
            <p>Address Line 2</p>
          ) : null}
          <InputField
            name="addressLineTwo"
            value={details.addressLineTwo}
            placeholder="Address line 2"
            onFocus={() => handleFocus("addressLineTwo")}
            onBlur={() => handleBlur("addressLineTwo")}
            onChange={handleChange}
            $show={details.addressLineTwo.length > 0}
          />
        </InputWrapper>
        <MultiInputsWrapper>
          <InputWrapper $show={show.city}>
            {details.city.length > 0 || show.city ? <p>Town / City</p> : null}
            <InputField
              name="city"
              value={details.city}
              placeholder="Town / City"
              onFocus={() => handleFocus("city")}
              onBlur={() => handleBlur("city")}
              onChange={handleChange}
              $show={details.city.length > 0}
            />
          </InputWrapper>
          <InputWrapper $show={show.postcode}>
            {details.postcode.length > 0 || show.postcode ? (
              <p>Postcode</p>
            ) : null}
            <InputField
              name="postcode"
              value={details.postcode}
              placeholder="Postcode"
              onFocus={() => handleFocus("postcode")}
              onBlur={() => handleBlur("postcode")}
              onChange={handleChange}
              $show={details.postcode.length > 0}
            />
          </InputWrapper>
        </MultiInputsWrapper>
      </BillingInputsCtn>
      <ReusableButton
        name="Continue"
        onClick={handleContinue}
      />
    </>
  );
};

export default Step2;
