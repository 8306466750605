import { useNavigate } from "react-router-dom";
import ReusableButton from "../ReusableButton";
import { Container, Paragraph, Section } from "./styles";

const Help = () => {
  const navigate = useNavigate();

  return (
    <Section>
      {/* <Container>
        <Paragraph>
          <h1>Help The Cause!</h1>
          <p>
            By pledging your support to the Commercial Tenants Association, you
            will be helping us provide essential training and advice to hundreds
            of thousands of businesses just like yours. We firmly believe that a
            united industry and collection of businesses is the strongest way
            for change to be implemented, but this cannot come to fruition
            without your help!
          </p>
          <ReusableButton
            $primary={true}
            name="Make A Donation"
            onClick={() => navigate("/donation")}
          />
        </Paragraph>
      </Container> */}
    </Section>
  );
};

export default Help;
