import bgs from "../../../assets/bgs";
import size from "../../../theme/devices/sizes";
import useWindowSize, { Size } from "../../../utils/useWindowSize";
import {
  BackgroundBlocks,
  Container,
  Paragraph,
  ParagraphsWrapper,
  Section,
  Title,
} from "./styles";

const Change = () => {
  const windowSize: Size = useWindowSize();
  const mobileL = +size.mobileL.slice(0, -2);

  return (
    <Section>
      <Container>
        <Title>
          <h1>Where We Feel We Can Lobby For Greater Change </h1>
        </Title>
        <ParagraphsWrapper>
          <Paragraph>
            <h3>Abolishing Upward-Only Rent Reviews</h3>
            <p>
              The CTA aims to influence all major political parties to include
              the abolishment of upward-only rent reviews in their manifestos
              for the upcoming 2024 election, aligning the UK with European
              norms.
            </p>
          </Paragraph>
          <Paragraph>
            <h3>Encouraging Landlord Accountability</h3>
            <p>
              The CTA seeks to level the playing field, ensuring that landlords
              bear responsibility for rates payable. By doing so, the
              association aims to create a fairer environment where rates align
              more closely with turnover and affordability, promoting equitable
              conditions for businesses.
            </p>
          </Paragraph>
          <Paragraph>
            <h3>Review & Amend The Landlord Tenant Act</h3>
            <p>
              The CTA will strive for a thorough evaluation and revision of the
              Landlord Tenant Act to ensure longevity and security for tenants,
              safeguarding their rights and interests
            </p>
          </Paragraph>
          <Paragraph>
            <h3>Prompt A Level Playing Field</h3>
            <p>
              Over many decades, landlords and managing agents have exploited
              tenants through unfair service charges, particularly in areas such
              as insurance and energy. The CTA will advocate for more
              transparency, accountability, and fairness in service charge
              practices.
            </p>
          </Paragraph>
        </ParagraphsWrapper>
        {windowSize.width! > mobileL ? (
          <BackgroundBlocks
            src={bgs.changeBlock}
            alt="bg-block"
          />
        ) : (
          <BackgroundBlocks
            src={bgs.changeBlockMobile}
            alt="bg-block"
          />
        )}
      </Container>
    </Section>
  );
};

export default Change;
