import axios from "axios";

const submitDonation = async (data: any) => {
  try {
    const newData = { ...data };
    newData.card_number = newData.card_number.replaceAll("-", "");
    newData.card_exp_year = parseInt(newData.card_exp_year);

    const res = await axios.post(
      process.env.REACT_APP_API + "/donation",
      newData,
    );
    console.log("Submit Donation Response: ", res);
    return res.data;
  } catch (err: any) {
    console.log("Submit Donation Form err: ", err);
    return err.response.data.message;
  }
};

export default submitDonation;
