import React, { useState } from "react";
import {
    Section,
    FooterUpper,
    FooterUpperLeft,
    FooterUpperRight,
    FooterMiddle,
    FooterBottom,
    DevelopedBy,
    TermsAndPrivacy,
    Copyright,
    GetInTouch,
    QuickLinks,
    CtaLogoContainer,
    SocialsIconsContainer,
} from "./styles";
import { useNavigate } from "react-router-dom";

import mailIcon from "../../assets/icons/mail.svg";
import ctaLogo from "../../assets/icons/ctaLogo.svg";
import opopLogo from "../../assets/icons/opopLogo.svg";
import facebookIcon from "../../assets/icons/facebookIcon.svg";
import twitterIcon from "../../assets/icons/twitterIcon.svg";
import linkedInIcon from "../../assets/icons/linkedInIcon.svg";
import instagramIcon from "../../assets/icons/instagramIcon.svg";
import emailIcon from "../../assets/icons/emailIcon.svg";
import opopFooterLogo from "../../assets/icons/opopFooterLogo.svg";
import emailGreen from "../../assets/icons/EmailGreen.svg";
import emailRed from "../../assets/icons/EmailRed.svg";
import { emailValidation } from "../../utils/email-validation";
import subscribe from "../../utils/subscribe";

const terms = require("../../assets/documents/termsAndConditions.pdf");
const privacy = require("../../assets/documents/privacyPolicy.pdf");

const Footer: React.FC = () => {
    const [emailAddress, setEmailAddress] = useState("");
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [emailInvalid, setEmailInvalid] = useState(false);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmailAddress(event.target.value);
    };

    const handleSubmit = async (email: string) => {
        try {
            const valid = await emailValidation(email);

            if (!valid) {
                setEmailInvalid(true);
                setIsSubmitted(false);
            } else {
                setEmailInvalid(false);
                const res = await subscribe("subscribe", { email: email });
                if (res.success) {
                    setIsSubmitted(true);
                    setEmailAddress("");
                }
            }
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <Section>
            <FooterUpper>
                <FooterUpperLeft>
                    <h2>Subscribe To The CTA</h2>
                    <p>
                        Keep up-to-date with the latest events and industry
                        news, courtesy of the Commercial Tenants Association.
                    </p>
                    <div
                        className={`subscribe-form ${
                            isSubmitted ? "submitted" : ""
                        }`}
                    >
                        <div className="input-wrapper">
                            {emailAddress.length > 0 && (
                                <p
                                    className={`${
                                        emailInvalid ? "invalid" : ""
                                    }`}
                                >
                                    Email address
                                </p>
                            )}
                            <img
                                src={
                                    isSubmitted
                                        ? emailGreen
                                        : emailInvalid
                                        ? emailRed
                                        : mailIcon
                                }
                                alt="Mail Logo"
                                className={`mail-logo ${
                                    isSubmitted ? "submitted" : ""
                                } ${emailInvalid ? "invalid" : ""}`}
                            />
                            <input
                                type="email"
                                id="email-input"
                                placeholder="Email Address"
                                value={emailAddress}
                                onChange={handleInputChange}
                                style={{
                                    border: emailInvalid
                                        ? "1px solid #EB3C3C"
                                        : isSubmitted
                                        ? "1px solid #7FFF00"
                                        : emailAddress.length > 0
                                        ? "1px solid #1CCEDC"
                                        : "1px solid #20566b",
                                }}
                            />
                            <button
                                type="submit"
                                onClick={() => handleSubmit(emailAddress)}
                                disabled={emailAddress.length < 1}
                                style={{
                                    backgroundColor:
                                        emailAddress.length > 0
                                            ? "#1CCEDC"
                                            : "",
                                }}
                                className={`${isSubmitted ? "submitted" : ""} ${
                                    emailInvalid ? "invalid" : ""
                                }`}
                            >
                                Subscribe
                            </button>
                            {isSubmitted ? (
                                <p
                                    className="message"
                                    style={{
                                        color: "#7FFF00",
                                        marginLeft: "auto",
                                        alignItems: "flex-end",
                                    }}
                                >
                                    Thank you, you are now subscribed!
                                </p>
                            ) : emailInvalid ? (
                                <p
                                    className="message"
                                    style={{
                                        color: "#EB3C3C",
                                        marginLeft: "auto",
                                        alignItems: "flex-end",
                                    }}
                                >
                                    *Please enter a valid email address
                                </p>
                            ) : null}
                        </div>
                    </div>
                </FooterUpperLeft>
                <FooterUpperRight>
                    <CtaLogoContainer>
                        <img
                            src={ctaLogo}
                            alt="CTA Logo"
                            className="cta-logo"
                        />
                    </CtaLogoContainer>
                    <SocialsIconsContainer>
                        <a
                            href="https://www.facebook.com/TheCTAuk"
                            target="_blank"
                        >
                            <img
                                src={facebookIcon}
                                alt="Facebook Icon"
                                className="facebook-icon"
                            />
                        </a>
                        <a href="https://twitter.com/TheCTAuk" target="_blank">
                            <img
                                src={twitterIcon}
                                alt="Twitter Icon"
                                className="twitter-icon"
                            />
                        </a>
                        <a
                            href="https://www.instagram.com/thectauk/?hl=en"
                            target="_blank"
                        >
                            <img
                                src={instagramIcon}
                                alt="Instagram Icon"
                                className="instagram-icon"
                            />
                        </a>
                        <a
                            href="https://www.linkedin.com/company/the-cta-uk/?originalSubdomain=uk"
                            target="_blank"
                        >
                            <img
                                src={linkedInIcon}
                                alt="LinkedIn Icon"
                                className="linkedIn-icon"
                            />
                        </a>
                        <a href="mailto:customerservice@thecta.co.uk">
                            <img
                                src={emailIcon}
                                alt="Facebook Icon"
                                className="email-icon"
                            />
                        </a>
                    </SocialsIconsContainer>
                </FooterUpperRight>
            </FooterUpper>
            <FooterMiddle>
                <QuickLinks>
                    <ul>
                        <li>
                            <h3>Quick Links</h3>
                        </li>
                        <li>
                            <a href="/about">About</a>
                        </li>
                        {/* <li>
              <a href="/news">News</a>
            </li> */}
                        <li>
                            <a href="/lobbying">Lobbying</a>
                        </li>
                        <li>
                            <a href="/community">Community</a>
                        </li>
                        {/* <li>
              <a href="/donation">Donate</a>
            </li> */}
                        <li>
                            <a href="/enquiry">Enquire</a>
                        </li>
                    </ul>
                </QuickLinks>
                <ul>
                    <li>
                        <h3>Advice</h3>
                    </li>
                    <li>
                        <a href="/advice-support">Our Advice & Process</a>
                    </li>
                    <li>
                        <a href="/specific-advice">
                            Specialist Advice & Representation
                        </a>
                    </li>
                </ul>
                <ul>
                    <li>
                        <h3>Services</h3>
                    </li>
                    <li>
                        <a href="/property/services">Property</a>
                    </li>
                    <li>
                        <a href="/corporate/services">Corporate</a>
                    </li>
                    <li>
                        <a href="/non-corporate/services">Non-Corporate</a>
                    </li>
                </ul>
                <GetInTouch>
                    <ul>
                        <li>
                            <h3 id="getInTouch-header">Get In Touch</h3>
                        </li>
                        <li>
                            <h4>General Support</h4>
                        </li>
                        <li>
                            <a href="mailto:customerservice@thecta.co.uk">
                                customerservice@thecta.co.uk
                            </a>
                        </li>
                        <li>
                            <h4>Let's Talk</h4>
                        </li>
                        <li>
                            <a href="tel:020 7494 6333">020 7494 6333</a>
                        </li>
                    </ul>
                </GetInTouch>
            </FooterMiddle>
            <FooterBottom>
                <TermsAndPrivacy>
                    <a href={terms} target="_blank">
                        Terms & Conditions
                    </a>
                    <a href={privacy} target="_blank">
                        Privacy Policy
                    </a>
                </TermsAndPrivacy>
                <Copyright>
                    © Copyright 2023 Commercial Tenants Association
                </Copyright>
                <DevelopedBy>
                    <a href="https://opopmedia.co.uk/" target="_blank">
                        <img src={opopFooterLogo} alt="Opop Logo" />
                    </a>
                </DevelopedBy>
            </FooterBottom>
        </Section>
    );
};
export default Footer;
