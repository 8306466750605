import ReusableButton from "../../Reusable/ReusableButton";
import size from "../../../theme/devices/sizes";
import useWindowSize, { Size } from "../../../utils/useWindowSize";
import { Main, Container, Section, Top } from "./styles";
import ReusableNews from "../../Reusable/ReusableNews";
import { articlesData } from "./articlesData";
import { useNavigate } from "react-router-dom";

const Articles: React.FC = () => {
  const windowSize: Size = useWindowSize();
  const tabletL = +size.tabletL.slice(0, -2);

  return (
    <Section>
      <Container>
        {/* <Top>
          <h1>Read Our Latest Articles</h1>
          {windowSize.width! > tabletL && (
            <a href="/news">
              <ReusableButton
                name="View All Articles"
                onClick={() => {}}
              />
            </a>
          )}
        </Top>
        <Main>
          <ReusableNews
            news={articlesData}
            $flip={false}
          />
        </Main>
        {windowSize.width! <= tabletL && (
          <a href="/news">
            <ReusableButton
              name="View All Articles"
              onClick={() => {}}
            />
          </a>
        )} */}
      </Container>
    </Section>
  );
};

export default Articles;
