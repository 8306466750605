import { propertyImgs } from "../../../assets/imgs/property";
import Downloadable from "../../../component/Reusable/Downloadable";
import EnquiryForm from "../../../component/Reusable/EnquiryForm";
import Offer from "../../../component/Reusable/Offer";
import NeedAdvice from "../../../component/Reusable/NeedAdvice";
import ReusableIntro from "../../../component/Reusable/ReusableIntro";
import ReusableHero from "../../../component/Reusable/ReusableHero";
import { offerData } from "../offerData";
import bgs from "../../../assets/bgs";

const StrategicLeaseManagement = () => {
  const heroContents = {
    type: 2,
    title: <h1>Strategic Lease Management</h1>,
    text: (
      <p>
        For commercial tenants with multiple leaseholds, they can avoid the
        complexities and logistical difficulties that come with us by employing
        better strategic lease management.
      </p>
    ),
    img: bgs.strategicLeaseManagementHeroBg,
    mobileimg: bgs.strategicLeaseManagementHeroBgMobile,
    button: true,
  };
  const introContents = {
    title: "What Are The Advantages?",
    text: (
      <p>
        Commercial property rents and the hidden costs surrounding are often the
        largest expenses for commercial tenants. 
        <br />
        <br />
        With better strategic lease management, you can identify ways to improve
        your commercial tenancy status by finding out which of your current
        leaseholds are either poorly structured or not as financially viable as
        you originally thought, meaning you can plan further ahead and save
        money come the end of your commercial tenancy.
      </p>
    ),
    img: propertyImgs.strategicLeaseManagementIntro,
    link: "/enquiry",
  };

  const offerContents = [offerData.rates, offerData.ownYourProperty];

  return (
    <>
      <ReusableHero contents={heroContents} />
      <ReusableIntro contents={introContents} />
      <NeedAdvice />
      <Downloadable full={false} />
      <Offer
        contents={offerContents}
        title="Similar Services"
      />
      <EnquiryForm />
    </>
  );
};

export default StrategicLeaseManagement;
