export const navContents = {
  PropertyServices: [
    { name: "Lease Restructuring", href: "/property/lease-restructuring" },
    { name: "Lease Regearing", href: "/property/lease-regearing" },
    { name: "Lease Renewals", href: "/property/lease-renewals" },
    { name: "Surrenders", href: "/property/surrenders" },
    { name: "Assignments", href: "/property/assignments" },
    { name: "Service charge", href: "/property/service-charge" },
    { name: "Rates", href: "/property/rates" },
    {
      name: "Strategic Lease Management",
      href: "/property/strategic-lease-management",
    },
    {
      name: "Own Your Own Property",
      href: "/property/own-your-property",
    },
    { name: "Acquisitions", href: "/property/acquisitions" },
    { name: "Arrears", href: "/property/arrears" },
    { name: "Dilapidations", href: "/property/dilapidations" },
    { name: "Capex support", href: "/property/capex-support" },
  ],
  corporateServices: [
    { name: "Insolvency Advice", href: "/corporate/insolvency-advice" },
    { name: "Insurance", href: "/corporate/insurance" },
    { name: "Litigation", href: "/corporate/litigation" },
    { name: "Finance & Investments", href: "/corporate/finance-investments" },
    {
      name: "Mergers & Acquisitions",
      href: "/corporate/mergers-acquisition",
    },
    { name: "Accountants", href: "/corporate/accountants" },
    { name: "Legal", href: "/corporate/legal" },
    { name: "Mediation", href: "/corporate/mediation" },
  ],

  nonCorporateServices: [
    { name: "HR Consultants", href: "/non-corporate/hr-consultants" },
    { name: "Contractors", href: "/non-corporate/contractors" },
    { name: "Shopfitters", href: "/non-corporate/shopfitters" },
    { name: "Utilities", href: "/non-corporate/utilities" },
  ],
};
