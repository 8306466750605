import { useEffect, useState } from "react";
import ReusableButton from "../Reusable/ReusableButton";
import {
    Center,
    Container,
    DonateBtn,
    AdviceDropdownTitle,
    DropdownWrapper,
    NavItem,
    Right,
    Section,
    DropdownInner,
    DropdownItem,
    DropdownList,
    ServicesDropdownTitle,
    MobileHeader,
    BurgerWrapper,
    BurgerOne,
    BurgerTwo,
    BurgerThree,
    Logo,
    BurgerMenuWrapper,
    BurgerMenuItem,
    BurgerMenuTop,
    BurgerMenuBtns,
    LoginBtnWrapper,
    DropDownCtn,
} from "./styles";
import { ReactComponent as RightArrowSvg } from "../../assets/icons/navArrowRight.svg";
import useWindowSize, { Size } from "../../utils/useWindowSize";
import size from "../../theme/devices/sizes";
import { useLocation, useNavigate } from "react-router-dom";
import { navContents } from "./data";
import icons from "../../assets/icons";
import { ReactComponent as UserIconSvg } from "../../assets/icons/userIcon.svg";
import profile from "../../utils/profile";
import { Notify } from "notiflix";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { updateLogin } from "../../redux/features/loginSlice";

const NavBar = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const windowSize: Size = useWindowSize();
    const tabletL = +size.tabletL.slice(0, -2);
    const location = useLocation();
    const [scrollY, setScrollY] = useState<number>(window.scrollY);
    const [show, setShow] = useState({
        showNav: false,
        mobileBg: false,
        animation: false,
        burgerMenu: false,
        section: false,
        advice: false,
        services: false,
    });
    const loginState = useAppSelector((state) => state.login);

    useEffect(() => {
        console.log("Login state:", loginState);
    }, [loginState]);

    const handleScroll = () => {
        setScrollY(window.scrollY);
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    useEffect(() => {
        if (location.pathname === "/") {
            setShow((prev) => ({ ...prev, showNav: false }));
            if (window.innerWidth > tabletL) {
                setShow((prev) => ({ ...prev, showNav: scrollY > 1 }));
            } else {
                setShow((prev) => ({ ...prev, showNav: true }));
            }
        } else {
            setShow((prev) => ({ ...prev, showNav: true }));
        }
    }, [scrollY, location]);

    useEffect(() => {
        setShow((prev) => ({
            ...prev,
            mobileBg:
                scrollY > 1 || location.pathname.split("/")[1] === "insights",
        }));
    }, [scrollY]);

    const handleOpenBurger = () => {
        setShow((prev: any) => ({
            ...prev,
            animation: !show.burgerMenu,
            burgerMenu: !show.burgerMenu,
        }));
    };

    const handleLogin = () => {
        navigate("/login");
    };

    const handleLogout = () => {
        localStorage.removeItem("token");
        dispatch(updateLogin(false));
        Notify.success("You have logged out.");
    };

    // prevent scrolling when burger menu is open
    useEffect(() => {
        document.documentElement.style.overflow = show.burgerMenu
            ? "hidden"
            : "auto";
        return () => {
            document.documentElement.style.overflow = "auto";
        };
    }, [show.burgerMenu]);

    return (
        <>
            <Section
                $burger={show.burgerMenu}
                $showBg={show.mobileBg}
                $hide={show.showNav}
            >
                <DropDownCtn
                    $hover={show.advice || show.services}
                ></DropDownCtn>
                <Container>
                    {windowSize.width! > tabletL ? (
                        <>
                            <a href="/">
                                <Logo src={icons.logo} alt="logo" />
                            </a>
                            <Center>
                                <a href="/about">
                                    <NavItem>
                                        <p>About CTA</p>
                                    </NavItem>
                                </a>
                                <NavItem
                                    onMouseEnter={() =>
                                        setShow((prev: any) => ({
                                            ...prev,
                                            advice: true,
                                        }))
                                    }
                                    onMouseLeave={() =>
                                        setShow((prev: any) => ({
                                            ...prev,
                                            advice: false,
                                        }))
                                    }
                                >
                                    <p>Advice</p>
                                    <img src={icons.navArrow} alt="arrow" />
                                </NavItem>

                                <DropdownWrapper
                                    $hover={show.advice}
                                    onMouseEnter={() =>
                                        setShow((prev: any) => ({
                                            ...prev,
                                            advice: true,
                                        }))
                                    }
                                    onMouseLeave={() =>
                                        setShow((prev: any) => ({
                                            ...prev,
                                            advice: false,
                                        }))
                                    }
                                >
                                    <DropdownInner>
                                        <a href="/advice-support">
                                            <DropdownItem>
                                                <AdviceDropdownTitle>
                                                    <h4>
                                                        Our Advice & Process
                                                    </h4>
                                                    <RightArrowSvg />
                                                </AdviceDropdownTitle>
                                                <p>
                                                    This section includes guides
                                                    to lease-related
                                                    negotiations and legal
                                                    compliance. It also provides
                                                    useful advice for business
                                                    tenants who want to know
                                                    more about occupying
                                                    commercial real estate.
                                                </p>
                                            </DropdownItem>
                                        </a>
                                        <a href="/specific-advice">
                                            <DropdownItem>
                                                <AdviceDropdownTitle>
                                                    <h4>
                                                        Specialist Advice &
                                                        Representation
                                                    </h4>
                                                    <RightArrowSvg />
                                                </AdviceDropdownTitle>
                                                <p>
                                                    Speak to a member of our
                                                    specialist team, and see how
                                                    we can help advise and
                                                    represent you depending on
                                                    your business circumstances.
                                                </p>
                                            </DropdownItem>
                                        </a>
                                    </DropdownInner>
                                </DropdownWrapper>

                                <NavItem
                                    onMouseEnter={() =>
                                        setShow((prev: any) => ({
                                            ...prev,
                                            services: true,
                                        }))
                                    }
                                    onMouseLeave={() =>
                                        setShow((prev: any) => ({
                                            ...prev,
                                            services: false,
                                        }))
                                    }
                                >
                                    <p>Services</p>
                                    <img src={icons.navArrow} alt="arrow" />
                                </NavItem>
                                <DropdownWrapper
                                    $hover={show.services}
                                    onMouseEnter={() =>
                                        setShow((prev: any) => ({
                                            ...prev,
                                            services: true,
                                        }))
                                    }
                                    onMouseLeave={() =>
                                        setShow((prev: any) => ({
                                            ...prev,
                                            services: false,
                                        }))
                                    }
                                >
                                    <DropdownInner>
                                        <DropdownItem>
                                            <a href="/property/services">
                                                <ServicesDropdownTitle>
                                                    <h4>Property Services</h4>
                                                    <RightArrowSvg />
                                                </ServicesDropdownTitle>
                                            </a>
                                            <DropdownList>
                                                {navContents.PropertyServices.map(
                                                    (item, i) => {
                                                        return (
                                                            <li key={i}>
                                                                <a
                                                                    href={
                                                                        item.href
                                                                    }
                                                                >
                                                                    {item.name}
                                                                </a>
                                                            </li>
                                                        );
                                                    }
                                                )}
                                            </DropdownList>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <a href="/corporate/services">
                                                <ServicesDropdownTitle>
                                                    <h4>Corporate Services</h4>
                                                    <RightArrowSvg />
                                                </ServicesDropdownTitle>
                                            </a>
                                            <DropdownList>
                                                {navContents.corporateServices.map(
                                                    (item, i) => {
                                                        return (
                                                            <li key={i}>
                                                                <a
                                                                    href={
                                                                        item.href
                                                                    }
                                                                >
                                                                    {item.name}
                                                                </a>
                                                            </li>
                                                        );
                                                    }
                                                )}
                                            </DropdownList>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <a href="/non-corporate/services">
                                                <ServicesDropdownTitle>
                                                    <h4>
                                                        Non-Corporate Services
                                                    </h4>
                                                    <RightArrowSvg />
                                                </ServicesDropdownTitle>
                                            </a>
                                            <DropdownList>
                                                {navContents.nonCorporateServices.map(
                                                    (item, i) => {
                                                        return (
                                                            <li key={i}>
                                                                <a
                                                                    href={
                                                                        item.href
                                                                    }
                                                                >
                                                                    {item.name}
                                                                </a>
                                                            </li>
                                                        );
                                                    }
                                                )}
                                            </DropdownList>
                                        </DropdownItem>
                                    </DropdownInner>
                                </DropdownWrapper>
                                <a href="/lobbying">
                                    <NavItem>
                                        <p>Lobbying</p>
                                    </NavItem>
                                </a>
                                <a href="/community">
                                    <NavItem>
                                        <p>Community</p>
                                    </NavItem>
                                </a>
                                <a href="/news">
                                    <NavItem>
                                        <p>News</p>
                                    </NavItem>
                                </a>
                                <a href="/enquiry">
                                    <NavItem className="enquiry-item">
                                        <p>
                                            <strong className="enquiry">
                                                Enquire
                                            </strong>
                                        </p>
                                    </NavItem>
                                </a>
                            </Center>
                        </>
                    ) : (
                        <>
                            <MobileHeader>
                                <a href="/">
                                    <Logo src={icons.logo} alt="logo" />
                                </a>
                                {location.pathname === "/" && (
                                    <p> Commercial Tenants Association</p>
                                )}
                            </MobileHeader>
                        </>
                    )}

                    <Right>
                        <a href="/login">
                            <LoginBtnWrapper>
                                <UserIconSvg />
                            </LoginBtnWrapper>
                        </a>

                        {windowSize.width! > tabletL ? (
                            <>
                                {/* <a href="/donation">
                  <DonateBtn>Donate</DonateBtn>
                </a> */}
                                {!loginState && (
                                    <a href="/sign-up">
                                        <ReusableButton
                                            $primary={true}
                                            name="Join For Free"
                                            width="117px"
                                            height="35px"
                                            fontSize="14px"
                                            onClick={() => {}}
                                        />
                                    </a>
                                )}
                            </>
                        ) : (
                            <BurgerWrapper
                                $open={show.burgerMenu}
                                onClick={handleOpenBurger}
                            >
                                <BurgerOne
                                    $open={show.animation}
                                    src={icons.hamburger}
                                    alt="burger-menu"
                                />
                                <BurgerTwo
                                    $open={show.animation}
                                    src={icons.hamburger}
                                    alt="burger-menu"
                                />
                                <BurgerThree
                                    $open={show.animation}
                                    src={icons.hamburger}
                                    alt="burger-menu"
                                />
                            </BurgerWrapper>
                        )}
                    </Right>
                </Container>
            </Section>
            <BurgerMenuWrapper $open={show.animation} $top={scrollY}>
                <BurgerMenuTop>
                    <a href="/about">
                        <h4>About CTA</h4>
                    </a>
                    <BurgerMenuItem>
                        <h4>Advice</h4>
                        <p>
                            <a href="/advice-support">Our Advice & Process</a>
                        </p>
                        <p>
                            <a href="/specific-advice">
                                Specialist Advice & Representation
                            </a>
                        </p>
                    </BurgerMenuItem>
                    <BurgerMenuItem className="middle">
                        <h4>Services</h4>
                        <p>
                            <a href="/property/services">Property Services</a>
                        </p>
                        <p>
                            <a href="/corporate/services">Corporate Services</a>
                        </p>
                        <p>
                            <a href="/non-corporate/services">
                                Non-Corporate Services
                            </a>
                        </p>
                    </BurgerMenuItem>
                    <BurgerMenuItem>
                        <a href="/lobbying">
                            <h4>Lobbying</h4>
                        </a>
                        <a href="/community">
                            <h4>Community</h4>
                        </a>
                        <a href="/news">
                            <h4>News</h4>
                        </a>
                        <a href="/enquiry">
                            <h4>Enquire</h4>
                        </a>
                    </BurgerMenuItem>
                </BurgerMenuTop>
                <BurgerMenuBtns>
                    {/* <a href="/donation">
            <DonateBtn>Donate</DonateBtn>
          </a> */}
                    {!loginState && (
                        <a href="/sign-up">
                            <ReusableButton
                                $primary={true}
                                name="Join For Free"
                                width="155.5px"
                                height="47px"
                                fontSize="14px"
                                $mwidth="100%"
                                onClick={() => navigate("/login")}
                            />
                        </a>
                    )}
                </BurgerMenuBtns>
            </BurgerMenuWrapper>
        </>
    );
};

export default NavBar;
