import axios from "axios";

const resetPassword = async (data: any) => {
  try {
    const res = await axios.post(
      process.env.REACT_APP_API + "/reset_pass",
      data,
    );

    return res.data;
  } catch (err: any) {
    console.log("Reset Password Error: ", err);
    return err.response.data.errors[0].message;
  }
};

export default resetPassword;
