import { useNavigate } from "react-router-dom";
import { colors } from "../../../theme/colors";
import ReusableButton from "../ReusableButton";
import { Container, Section, Titles } from "./styles";

interface ReusableHeroProps {
  contents: {
    type: number;
    title: React.ReactNode;
    text: React.ReactNode;
    img: string;
    mobileimg?: string;
    button: boolean;
    page?: string;
  };
}

const ReusableHero: React.FC<ReusableHeroProps> = ({ contents }) => {
  const navigate = useNavigate();

  return (
    <Section>
      <Container
        $img={contents.img}
        $mobileimg={contents.mobileimg}
        type={contents.type}
        page={contents.page}
      >
        <Titles type={contents.type}>
          {contents.title}
          {contents.text}
          {contents.button && (
            <ReusableButton
              name="Enquire Now"
              color={colors.white}
              onClick={() => navigate("/enquiry")}
            />
          )}
        </Titles>
        {contents.type === 1 && (
          <img
            src={contents.img}
            alt="bg"
          />
        )}
      </Container>
    </Section>
  );
};

export default ReusableHero;
