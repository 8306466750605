import styled from "styled-components";
import { colors } from "../../../theme/colors";
import device from "../../../theme/devices";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: 559px;

  > h1 {
    font-size: 40px;
    line-height: 56px;
  }

  > p {
    font-size: 16px;
    line-height: 28px;

    > span {
      color: ${colors.primary};
      font-weight: 700;
      text-decoration: underline;
    }
  }

  @media ${device.laptop} {
    max-width: 100%;
    > button {
      display: none;
    }
  }

  @media ${device.mobileL} {
    > h1 {
      font-size: 32px;
      line-height: 48px;
    }

    > p {
      font-size: 14px;
      line-height: 26px;
    }
  }
`;
