import { useEffect, useState } from "react";
import icons from "../../../../assets/icons";
import ReusableButton from "../../../../component/Reusable/ReusableButton";
import { Container, InputsCtn, InputWrapper, Input } from "./styles";
import { Notify } from "notiflix";
import resetPassword from "../../../../utils/resetPassword";
import { useNavigate } from "react-router-dom";

const ResetPassword = () => {
  const navigate = useNavigate();
  const [passwords, setPasswords] = useState<{ [key: string]: string }>({
    create: "",
    confirm: "",
  });
  const [showP, setShowP] = useState<{ [key: string]: boolean }>({
    create: false,
    confirm: false,
  });
  const [showPassword, setShowPassword] = useState<{ [key: string]: boolean }>({
    create: false,
    confirm: false,
  });
  const [reset, setReset] = useState(false);
  const [token, setToken] = useState("");

  useEffect(() => {
    const currentURL = window.location.href;
    const urlObject = new URL(currentURL);
    const tokenValue = urlObject.searchParams.get("token");

    if (!tokenValue) {
      navigate("/");
    } else {
      setToken(tokenValue);
    }
  }, []);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setPasswords((prev) => ({ ...prev, [name]: value }));
    if (passwords[name].length > 0) {
      setShowP((prev) => ({ ...prev, [name]: true }));
    }
  };

  const handleFocus = (name: string) => {
    setShowP((prev) => ({ ...prev, [name]: true }));
  };

  const handleBlur = (name: string) => {
    if (passwords[name].length === 0) {
      setShowP((prev) => ({ ...prev, [name]: false }));
    }
  };

  const handleReset = async () => {
    try {
      if (!passwords.create) {
        return Notify.failure("Please enter your new password.");
      }
      if (!passwords.confirm) {
        return Notify.failure("Please enter your new password.");
      }
      if (passwords.create !== passwords.confirm) {
        return Notify.failure(
          "The confirm password does not match the new password.",
        );
      }

      const res = await resetPassword({
        token: token,
        new_password: passwords.create,
        confirm_password: passwords.confirm,
      });

      if (res.success) {
        setReset(true);
      } else {
        Notify.failure(res.message);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Container>
      {reset ? (
        <>
          <img
            src={icons.circleTick}
            alt="tick"
          />
          <h1>Password Reset</h1>
          <p>Your password has been reset.</p>
          <a href="/login">
            <strong>Back To Login</strong>
          </a>
        </>
      ) : (
        <>
          <h1>Reset Password</h1>
          <InputsCtn>
            <InputWrapper $show={showP.create}>
              <p>Create new password</p>
              <img
                src={icons.lockBlue}
                alt="lock"
              />
              <Input
                name="create"
                value={passwords.create}
                onFocus={() => handleFocus("create")}
                onBlur={() => handleBlur("create")}
                type={showPassword.create ? "text" : "password"}
                $show={showP.create}
                placeholder="Create new password"
                onChange={handleChange}
              />
              <img
                id="visible"
                src={icons.visible}
                alt="visible"
                onClick={() =>
                  setShowPassword((prev) => ({ ...prev, create: true }))
                }
              />
            </InputWrapper>
            <InputWrapper $show={showP.confirm}>
              <p>Confirm new password</p>
              <img
                src={icons.lockBlue}
                alt="lock"
              />
              <Input
                name="confirm"
                value={passwords.confirm}
                onFocus={() => handleFocus("confirm")}
                onBlur={() => handleBlur("confirm")}
                type={showPassword.confirm ? "text" : "password"}
                $show={showP.confirm}
                placeholder="Confirm new password"
                onChange={handleChange}
              />
              <img
                id="visible"
                src={icons.visible}
                alt="visible"
                onClick={() =>
                  setShowPassword((prev) => ({ ...prev, confirm: true }))
                }
              />
            </InputWrapper>
          </InputsCtn>
          <ReusableButton
            $primary={true}
            name="Reset Password"
            onClick={handleReset}
            width="204px"
            height="50px"
          />
        </>
      )}
    </Container>
  );
};

export default ResetPassword;
