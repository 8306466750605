import bgs from "../../../assets/bgs";
import Downloadable from "../../../component/Reusable/Downloadable";
import EnquiryForm from "../../../component/Reusable/EnquiryForm";
import Offer from "../../../component/Reusable/Offer";
import DilapidationsIntro from "../../../component/PropertyPage/DilapidationsIntro";
import ReusableHero from "../../../component/Reusable/ReusableHero";
import { offerData } from "../offerData";
import NeedAdvice from "../../../component/Reusable/NeedAdvice";

const Dilapidations = () => {
  const heroContents = {
    type: 2,
    title: <h1>Dilapidations</h1>,
    text: (
      <p>
        Put simply, dilapidations are a tenant’s liability to repair the
        premises they occupy under the terms of a lease. A lease will contain
        specific clauses which dictate the extent of this obligation.
      </p>
    ),
    img: bgs.dilapidationsHeroBg,
    mobileimg: bgs.dilapidationsHeroBgMobile,
    button: true,
  };

  const offerContents = [offerData.arrears, offerData.capexSupport];

  return (
    <>
      <ReusableHero contents={heroContents} />
      <DilapidationsIntro />
      <Downloadable full={false} />
      <NeedAdvice />
      <Offer
        contents={offerContents}
        title="Similar Services"
      />
      <EnquiryForm />
    </>
  );
};

export default Dilapidations;
