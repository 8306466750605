import { ReactElement } from "react";
import Services from "./pages/Services";
import { Main } from "./styles";
import LeaseRestructuring from "./pages/LeaseRestructuring";
import LeaseRegearing from "./pages/LeaseRegearing";
import LeaseRenewals from "./pages/LeaseRenewals";
import Surrenders from "./pages/Surrenders";
import Assignments from "./pages/Assignments";
import ServiceCharge from "./pages/ServiceCharge";
import Rates from "./pages/Rates";
import StrategicLeaseManagement from "./pages/StrategicLeaseManagement";
import OwnYourProperty from "./pages/OwnYourProperty";
import Acquisitions from "./pages/Acquisitions";
import Arrears from "./pages/Arrears";
import CapExSupport from "./pages/CapExSupport";
import Dilapidations from "./pages/Dilapidations";

const PropertyPage: React.FC = () => {
  const pathname = window.location.pathname.split("/");
  const pageName: string = pathname[pathname.length - 1];

  const pages: { [key: string]: ReactElement } = {
    services: <Services />,
    "lease-restructuring": <LeaseRestructuring />,
    "lease-regearing": <LeaseRegearing />,
    "lease-renewals": <LeaseRenewals />,
    surrenders: <Surrenders />,
    assignments: <Assignments />,
    "service-charge": <ServiceCharge />,
    rates: <Rates />,
    "strategic-lease-management": <StrategicLeaseManagement />,
    "own-your-property": <OwnYourProperty />,
    acquisitions: <Acquisitions />,
    arrears: <Arrears />,
    "capex-support": <CapExSupport />,
    dilapidations: <Dilapidations />,
  };

  return <Main>{pages[pageName]}</Main>;
};

export default PropertyPage;
