import styled from "styled-components";
import size from "../../../theme/devices/sizes";
import { colors } from "../../../theme/colors";
import device from "../../../theme/devices";

export const Section = styled.section`
  position: relative;
  max-width: ${size.laptopL};
  margin: 0 auto;

  > img {
    position: absolute;
    width: 477px;
    height: 477px;
    right: 0;
    top: 18%;
    z-index: -1;
  }

  @media ${device.mobileL} {
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 56px;
  padding: 0px 120px 120px;

  @media ${device.tabletL} {
    padding: 88px 56px;
  }

  @media ${device.mobileL} {
    padding: 88px 24px;
  }
`;

export const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 20px;
`;

export const Titles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  > h1 {
    font-size: 40px;
    line-height: 56px;
  }

  > p {
    font-size: 16px;
    line-height: 28px;
  }

  @media ${device.mobileL} {
    gap: 16px;

    > h1 {
      font-size: 32px;
      line-height: 48px;
    }

    > p {
      font-size: 14px;
      line-height: 26px;
    }
  }
`;

export const ItemsCtn = styled.div`
  display: flex;
  gap: 40px;

  @media ${device.laptop} {
    flex-direction: column;
  }
`;

export const Item = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 8px;
  padding: 40px;
  background: ${colors.white};
  box-shadow: 0px 10px 30px 0px rgba(7, 53, 71, 0.1);

  > h2 {
    font-size: 24px;
    line-height: 32px;
  }

  > p {
    font-size: 16px;
    line-height: 28px;

    > span {
      color: ${colors.primary};
    }
  }

  @media ${device.mobileL} {
    padding: 24px 16px;
    > h1 {
      font-size: 20px;
      line-height: 48px;
    }

    > p {
      font-size: 12px;
      line-height: 22px;
    }
  }
`;
