import { ReactElement } from "react";
import ReusableButton from "../ReusableButton";
import { colors } from "../../../theme/colors";
import size from "../../../theme/devices/sizes";
import useWindowSize, { Size } from "../../../utils/useWindowSize";
import { Container, Item, Left, Right, Section } from "./styles";
import imgs from "../../../assets/imgs";

interface MissionProp {
  page: string;
}

const Mission: React.FC<MissionProp> = ({ page }) => {
  const windowSize: Size = useWindowSize();
  const mobileL: number = +size.mobileL.slice(0, -2);
  let contents: {
    title: string;
    text: React.ReactNode;
    items: React.ReactNode[];
    button: ReactElement;
    background: string;
    backgroundBlock: boolean;
  };

  if (page === "community") {
    contents = {
      title: "When It Comes To The Work Of The CTA, Our Mission Is Clear.",
      text: (
        <p>
          But all of the brilliant work we do can’t be accomplished without the
          input of you and your business! <br />
          <br />
          That’s why we’re offering you this amazing opportunity to both join
          and contribute to the work of the CTA for free!. All you need to do is
          click the button below to discover what else we can offer you.
        </p>
      ),
      items: [
        <h2>
          Instil <span>greater change</span> in the commercial tenants sector
        </h2>,
        <h2>
          Firmly <span>oppose rising rent</span> costs and increased tenancy
          rates
        </h2>,
        <h2>
          Be <span>the leading voice</span> for commercial tenants and business
          owners in the UK
        </h2>,
      ],
      button: (
        <a href="/enquiry">
          <ReusableButton
            $primary={true}
            name="Enquire Now"
            onClick={() => {}}
          />
        </a>
      ),
      background: colors.greenLight2,
      backgroundBlock: false,
    };
  } else {
    contents = {
      title: "The Impact Of Re-Gearing Your Lease",
      text: (
        <p>
          Our team are specialists in identifying good terms for re-gearing, and
          ensuring both tenants and landlords can come to an effective
          compromise during these negotiations. In your current situation,
          however positive or negative it may be, it’s always worth looking at
          the terms of your lease and whether it’s best suited to you. 
          <br />
          <br />
          To re-gear your lease is to negotiate the terms of your lease whilst
          your contract is still ongoing: this can include:
        </p>
      ),
      items: [
        <h2>
          Hoping to alter your <span>payment instalments.</span>
        </h2>,
        <h2>
          Removing a <span>break clause</span> from your contract.
        </h2>,
        <h2>
          Getting the <span>property refurbished</span> in exchange for a longer
          tenancy.
        </h2>,
      ],
      button: (
        <a href="/enquiry">
          <ReusableButton
            name="Enquire Now"
            onClick={() => {}}
          />
        </a>
      ),
      background: colors.white,
      backgroundBlock: true,
    };
  }

  return (
    <Section>
      {contents.backgroundBlock && (
        <img
          src={imgs.charterBlock}
          alt="block"
        />
      )}
      <Container>
        <Left>
          {windowSize.width! > mobileL && <h1>{contents.title}</h1>}
          {contents.text}
          {contents.button}
        </Left>
        <Right>
          {windowSize.width! <= mobileL && <h1>{contents.title}</h1>}
          {contents.items.map((el: React.ReactNode, i: number) => {
            return (
              <Item
                key={i}
                $background={contents.background}
              >
                {el}
              </Item>
            );
          })}
        </Right>
      </Container>
    </Section>
  );
};

export default Mission;
