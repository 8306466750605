import { propertyImgs } from "../../../assets/imgs/property";
import EnquiryForm from "../../../component/Reusable/EnquiryForm";
import Help from "../../../component/Reusable/Help";
import Offer from "../../../component/Reusable/Offer";
import ThreePoints from "../../../component/Reusable/ThreePoints";
import ReusableHero from "../../../component/Reusable/ReusableHero";
import { offerData } from "../offerData";
import bgs from "../../../assets/bgs";

const Services = () => {
  const heroContents = {
    type: 1,
    title: <h1>Property Services</h1>,
    text: (
      <p>
        In this section, our Members will find advice, support and guidance to
        help leverage their negotiating position. The information is tailored to
        assist those occupying commercial premises, whether they are taking a
        new lease, reviewing their rent, renewing their existing arrangements or
        facing a dilapidations claim.
      </p>
    ),
    img: propertyImgs.propertyMainHero,
    mobileimg: bgs.propertyServicesHeroBgMobile,
    button: true,
  };

  const offerContents = Object.values(offerData).filter(
    (el) => el.title !== "Lease Negotiations",
  );

  return (
    <>
      <ReusableHero contents={heroContents} />
      <ThreePoints />
      <Offer
        contents={offerContents}
        title="Similar Services"
      />
      <Help />
      <EnquiryForm />
    </>
  );
};

export default Services;
