import icons from "../../assets/icons";
import Help from "../../component/Reusable/Help";
import ReusableNews from "../../component/Reusable/ReusableNews";
import {
  ActiveBorder,
  ArticleSection,
  BorderWrapper,
  Header,
  LeftArrow,
  Main,
  PageNumber,
  PagesWrapper,
  RightArrow,
  SearchInput,
  SearchWrapper,
  TopBar,
  Topic,
  Topics,
  TopicsWrapper,
} from "./styles";

import { useEffect, useState } from "react";
import getArticles from "../../utils/getArticles";
import { Loading } from "notiflix";
import { useSearchParams } from "react-router-dom";

const NewsPage = () => {
  const [articles, setArticles] = useState([]);
  const [pagesArr, setPagesArr] = useState<string[]>([]);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchParams, setSearchParams] = useSearchParams({
    page: "",
    category: "",
    keyword: "",
  });

  const currPage = searchParams.get("page");
  const category = searchParams.get("category");
  const keyword = searchParams.get("keyword");

  const topics = ["News", "Hot Topics", "Insights"];

  useEffect(() => {
    const initialKeyword = searchParams.get("keyword");
    if (initialKeyword) {
      setSearchQuery(initialKeyword);
    }
  }, [searchParams]);

  useEffect(() => {
    window.scroll(0, 0);
    const fetchArticles = async () => {
      Loading.standard();

      if (!searchParams.get("page")) {
        searchParams.set("page", "1");
      }
      if (!searchParams.get("category")) {
        searchParams.set("category", "News");
      }

      try {
        const res = await getArticles(
          category ?? "News",
          currPage ?? "1",
          keyword ?? "",
        );

        Loading.remove();
        if (res.success && res.payload.articles.length > 0) {
          setTotalPages(res?.payload?.totalPage);
          const updatedPagesArr = Array.from(
            { length: res?.payload?.totalPage },
            (_, index) => (index + 1).toString(),
          );

          const startIndex =
            +currPage! < 3
              ? 0
              : +currPage! > updatedPagesArr.length - 2
              ? updatedPagesArr.length - 5
              : +currPage! - 3;
          const endIndex =
            +currPage! === 1
              ? +currPage! + 4
              : +currPage! === 2
              ? +currPage! + 3
              : +currPage! + 2;

          setPagesArr(updatedPagesArr.slice(startIndex, endIndex));
          setArticles(res.payload.articles);
        } else {
          const categories = ["News", "Hot Topics", "Insights"];

          for (const categoryToTry of categories) {
            try {
              const res = await getArticles(
                categoryToTry,
                currPage ?? "1",
                keyword ?? "",
              );

              Loading.remove();
              if (res.success && res.payload.articles.length > 0) {
                setSearchParams((prev) => {
                  prev.set("category", categoryToTry);
                  return prev;
                });
                setTotalPages(res?.payload?.totalPage);
                const updatedPagesArr = Array.from(
                  { length: res?.payload?.totalPage },
                  (_, index) => (index + 1).toString(),
                );

                const startIndex =
                  +currPage! < 3
                    ? 0
                    : +currPage! > updatedPagesArr.length - 2
                    ? updatedPagesArr.length - 5
                    : +currPage! - 3;
                const endIndex =
                  +currPage! === 1
                    ? +currPage! + 4
                    : +currPage! === 2
                    ? +currPage! + 3
                    : +currPage! + 2;

                setPagesArr(updatedPagesArr.slice(startIndex, endIndex));
                setArticles(res.payload.articles);
                break;
              } else {
                setArticles([]);
                setPagesArr([]);
              }
            } catch (err) {
              Loading.remove();
              console.log(err);
            }
          }
        }
      } catch (error) {
        Loading.remove();
        console.log(error);
      }
    };

    fetchArticles();
  }, [category, currPage, keyword]);

  // useEffect(() => {
  //   window.scroll(0, 0);
  //   const fetchArticles = async () => {
  //     Loading.standard();

  //     if (!searchParams.get("page")) {
  //       searchParams.set("page", "1");
  //     }
  //     if (!searchParams.get("category")) {
  //       searchParams.set("category", "News");
  //     }
  //     if (!searchParams.get("keyword")) {
  //       searchParams.set("keyword", "");
  //     }
  //     try {
  //       const res = await getArticles(
  //         category ?? "News",
  //         currPage ?? "1",
  //         keyword ?? "",
  //       );

  //       Loading.remove();
  //       if (res.success) {
  //         setTotalPages(res?.payload?.totalPage);
  //         const updatedPagesArr = Array.from(
  //           { length: res?.payload?.totalPage },
  //           (_, index) => (index + 1).toString(),
  //         );

  //         const startIndex =
  //           +currPage! < 3
  //             ? 0
  //             : +currPage! > updatedPagesArr.length - 2
  //             ? updatedPagesArr.length - 5
  //             : +currPage! - 3;
  //         const endIndex =
  //           +currPage! === 1
  //             ? +currPage! + 4
  //             : +currPage! === 2
  //             ? +currPage! + 3
  //             : +currPage! + 2;

  //         setPagesArr(updatedPagesArr.slice(startIndex, endIndex));

  //         setArticles(res.payload.articles);
  //       }
  //     } catch (err) {
  //       Loading.remove();
  //       console.log(err);
  //     }
  //   };

  //   fetchArticles();
  // }, [category, currPage, keyword]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSearchParams((prev) => {
        prev.set("keyword", searchQuery);
        if (searchQuery !== keyword) {
          prev.set("page", "1");
        }
        return prev;
      });
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [searchQuery, keyword]);

  const handleChangePage = (action: string) => {
    if (action === "previous" && currPage !== "1") {
      setSearchParams((prev) => {
        prev.set("page", (+currPage! - 1).toString());
        return prev;
      });
    } else if (action === "next" && +currPage! !== totalPages) {
      setSearchParams((prev) => {
        prev.set("page", (+currPage! + 1).toString());
        return prev;
      });
    } else {
      return;
    }
  };

  return (
    <>
      <Header>
        <h1>News</h1>
      </Header>
      <Main>
        <TopBar>
          <TopicsWrapper>
            <Topics>
              {topics.map((topic, i) => {
                return (
                  <Topic
                    key={i}
                    $select={category === topic}
                    onClick={() => {
                      setSearchParams((prev) => {
                        prev.set("category", topic);
                        prev.set("page", "1");
                        return prev;
                      });
                    }}
                  >
                    <h3>{topic}</h3>
                  </Topic>
                );
              })}
            </Topics>
            <BorderWrapper>
              <ActiveBorder
                $topic={
                  category === "News" ? 1 : category === "Hot Topics" ? 2 : 3
                }
              />
            </BorderWrapper>
          </TopicsWrapper>
          <SearchWrapper>
            <img
              src={icons.search}
              alt="search"
            />
            <SearchInput
              name="searchQuery"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Search article or keyword..."
            />
          </SearchWrapper>
        </TopBar>
        <ArticleSection>
          {articles.length > 0 && (
            <>
              {Array.from(
                { length: Math.ceil(articles.length / 5) },
                (_, index) => (
                  <ReusableNews
                    key={index}
                    news={articles.slice(index * 5, (index + 1) * 5)}
                    $flip={index % 2 !== 0}
                  />
                ),
              )}
            </>
          )}
        </ArticleSection>
        {pagesArr.length > 1 && (
          <PagesWrapper>
            <LeftArrow
              $disable={currPage === "1"}
              onClick={() => handleChangePage("previous")}
            />
            {pagesArr.map((page, i) => {
              return (
                <PageNumber
                  key={i}
                  $active={currPage === page}
                  onClick={() => {
                    setSearchParams((prev) => {
                      prev.set("page", page);
                      return prev;
                    });
                  }}
                >
                  {page}
                </PageNumber>
              );
            })}
            <RightArrow
              $disable={+currPage! === totalPages}
              onClick={() => handleChangePage("next")}
            />
          </PagesWrapper>
        )}

        <Help />
      </Main>
    </>
  );
};

export default NewsPage;
