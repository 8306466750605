import styled, { keyframes } from "styled-components";
import { colors } from "../../../theme/colors";
import device from "../../../theme/devices";
import bgs from "../../../assets/bgs";
import size from "../../../theme/devices/sizes";

const fadeIn = keyframes`
  0% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
`;

export const Section = styled.section`
  background: ${colors.greenDark};
`;

export const Container = styled.div`
  display: flex;
  max-width: ${size.laptopL};
  margin: 0 auto;
  padding: 120px;

  @media ${device.laptop} {
    flex-direction: column;
    padding: 56px;
  }

  @media ${device.tabletL} {
    padding: 0;
  }
`;

interface LeftProps {
  $submitted?: boolean;
}

export const Left = styled.div<LeftProps>`
  background-image: linear-gradient(
      to bottom,
      rgba(0, 38, 53, 0.75),
      rgba(0, 38, 53, 0.75)
    ),
    url(${bgs.enquireFormBg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center, -270px 0px;
  background: ${({ $submitted }) => $submitted && "none"};
  border-bottom: 8px solid ${colors.primary};
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 36%;
  padding: 80px 40px;

  color: ${colors.white};

  > h1 {
    font-size: 40px;
    line-height: 56px;
  }

  > p {
    font-size: 16px;
    line-height: 28px;
  }

  @media ${device.laptop} {
    width: 100%;
    min-width: 100%;
    background-position: center, center;
  }

  @media ${device.tabletL} {
    border-bottom: none;
  }

  @media ${device.mobileL} {
    padding: 112px 24px 40px;
    > p {
      font-size: 14px;
    }
  }
`;

export const StepWrapper = styled.div`
  position: relative;
  width: 64%;
  min-height: 572px;
  max-height: 572px;

  @media ${device.laptop} {
    width: 100%;
  }
`;

export const StepMain = styled.div<LeftProps>`
  position: relative;
  display: ${({ $submitted }) => ($submitted ? "none" : "flex")};
  flex-direction: column;
  align-items: center;
  gap: 48px;
  background: ${colors.white};
  border-bottom: 8px solid ${colors.primary};
  min-height: 572px;
  max-height: 572px;
  width: 100%;
  padding: 120px 48px;
  color: ${colors.greenLight};
  animation: 0.5s ${fadeIn} ease-in-out;

  p {
    font-size: 16px;
    font-weight: 700;
  }

  @media ${device.laptopL} {
    padding: 120px 40px;
  }

  @media ${device.tabletL} {
    padding: 40px 24px;
    flex-direction: column;
    max-width: 100%;
    min-width: 100%;
    min-height: 534px;
    max-height: 534px;

    p {
      font-size: 14px;
    }
  }
`;

export const Title = styled.h2`
  font-size: 32px;
  line-height: 48px;
  text-align: center;

  @media ${device.tabletL} {
    font-size: 24px;
    line-height: 32px;
  }
`;

export const OptionsCtn = styled.div`
  display: flex;
  gap: 16px;
  justify-content: space-between;
  margin-bottom: 16px;
  flex-wrap: wrap;

  @media ${device.laptopL} {
    justify-content: center;
    margin-bottom: 56px;
  }

  @media ${device.tabletL} {
    width: 100%;
  }
`;

interface OptionProps {
  $questionNum?: number;
  $selected?: boolean;
}

export const OptionWrapper = styled.div<OptionProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 16px;
  width: ${({ $questionNum }) => ($questionNum === 1 ? "206px" : "325px")};
  height: ${({ $questionNum }) => ($questionNum === 1 ? "118px" : "54px")};
  color: ${({ $selected }) => $selected && `${colors.primary}`};
  border: 2px solid
    ${({ $selected }) => ($selected ? `${colors.primary}` : `${colors.border}`)};
  cursor: pointer;

  > img {
    display: ${({ $selected }) => ($selected ? "block" : "none")};
  }

  > svg {
    path {
      fill: ${({ $selected }) => $selected && `${colors.primary}`};
    }
    rect {
      fill: ${({ $selected }) => $selected && `${colors.primary}`};
    }
  }

  @media ${device.tabletL} {
    width: ${({ $questionNum }) => ($questionNum ? "147.5px" : "100%")};
  }

  @media ${device.mobileL} {
    height: ${({ $questionNum }) => ($questionNum === 1 ? "107px" : "52px")};
  }

  &:hover {
    border: 2px solid ${colors.primary};

    > img {
      display: block !important;
    }

    > svg {
      path {
        fill: ${colors.primary};
      }
      rect {
        fill: ${colors.primary};
      }
    }

    > p {
      color: ${colors.primary};
    }
  }
`;

export const Circle = styled.img`
  position: absolute;
  display: none;
  bottom: -12px;
`;

export const TickCircle = styled.img`
  position: absolute;
  display: none;
  bottom: -12px;
  animation: 0.5s ${fadeIn} ease-out;
`;

export const Unsure = styled.div<OptionProps>`
  position: relative;
  width: 100%;
  border: 2px solid ${colors.border};
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: ${({ $selected }) => $selected && `${colors.primary}`};
  border: 2px solid
    ${({ $selected }) => ($selected ? `${colors.primary}` : `${colors.border}`)};

  > img {
    display: ${({ $selected }) => ($selected ? "block" : "none")};
  }

  &:hover {
    border: 2px solid ${colors.primary};

    > img {
      display: block !important;
    }

    > svg {
      path {
        fill: ${colors.primary};
      }
      rect {
        fill: ${colors.primary};
      }
    }

    > p {
      color: ${colors.primary};
    }
  }
`;

interface InputProps {
  $show?: boolean;
}

export const InputWrapper = styled.div<InputProps>`
  position: relative;
  width: 479px;
  height: 50px;

  > img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 14px;
  }

  > p {
    position: absolute;
    background: ${colors.white};
    top: -8px;
    left: 14px;
    z-index: 2;
    font-size: 12px;
    color: ${colors.primary};
    padding: 0px 5px;
  }

  @media ${device.mobileL} {
    width: 100%;

    > p {
      font-size: 12px;
    }
  }
`;

export const InputField = styled.input<InputProps>`
  width: 100%;
  height: 100%;
  padding: 14px 14px 14px 36px;
  font-size: 16px;
  background: ${colors.white};
  border: none;
  border: 2px solid
    ${({ $show }) => ($show ? `${colors.primary}` : `${colors.border}`)};

  &:focus {
    outline: none;
    border: 2px solid ${colors.primary};
    &::placeholder {
      opacity: 0;
    }
  }

  @media ${device.mobileL} {
    width: 100%;
    font-size: 14px;
  }
`;

export const TextareaWrapper = styled.div<InputProps>`
  position: relative;
  max-width: 100%;

  > p {
    position: absolute;
    background: ${colors.white};
    top: -8px;
    left: 14px;
    z-index: 2;
    font-size: 12px;
    color: ${colors.primary};
    padding: 0px 5px;
  }

  @media ${device.mobileL} {
    width: 100%;

    > p {
      font-size: 12px;
    }
  }
`;

export const Textarea = styled.textarea<InputProps>`
  width: 479px;
  height: 100px;
  padding: 14px;
  font-size: 16px;
  background: ${colors.white};
  resize: none;
  -ms-resize: none;
  -moz-resize: none;
  -webkit-resize: none;
  ${({ $show }) => $show && `border: 2px solid ${colors.primary};`}

  &:focus {
    outline: none;
    border: 2px solid ${colors.primary};
    &::placeholder {
      opacity: 0;
    }
  }

  @media ${device.mobileL} {
    width: 100%;
    font-size: 14px;
  }
`;

export const ContactWrapper = styled.div`
  display: flex;
  gap: 16px;

  div {
    width: 293px;

    > input {
      padding: 14px;
    }
  }

  @media ${device.mobileL} {
    flex-direction: column;
    width: 100%;
    div {
      width: 100%;
    }
    
`;

export const Remark = styled.div`
  position: absolute;
  bottom: 24px;
  display: flex;
  gap: 8px;
  align-items: center;

  > p {
    font-size: 14px;
    line-heght: 24px;
    color: #a1b2b7;
    font-weight: 400;
  }

  @media ${device.mobileL} {
    left: 24px;
  }
`;

interface CheckboxProps {
  checked: boolean;
}

export const CheckboxWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;

  p {
    font-size: 14px;
    line-height: 24px;
    color: ${colors.greenLight2};
    font-weight: 400;
  }

  @media ${device.tabletL} {
    gap: 16px;
  }
`;

export const Checkbox = styled.div<CheckboxProps>`
  position: relative;
  min-width: 16px;
  min-height: 16px;
  ${({ checked }) => !checked && `border: 1px solid ${colors.border};`}
  background: ${({ checked }) =>
    checked ? `${colors.primary}` : `${colors.white}`};

  > img {
    padding: 1px;
    display: ${({ checked }) => !checked && "none"};
    position: absolute;
    transform: scale(0.9);
  }
`;

const FadeIn = keyframes`
  0% {
    opacity: 0;
  } 
  100% {
    opacity: 1;
  }
`;

const ExpandAnimationMobile = keyframes`
  0% {
   height: 100%;
   min-height: 555px;
  }
  100% {
    height: 0%;
    min-height: 731px;
  }
`;

const ShrinkAnimation = keyframes`
  0% {
    flex: 1;
    width: 100%;
    min-width: 36%;
  }
  100% {
    flex: 0;
    width: 0%;
    min-width: 0%;
  }
`;

const ShrinkAnimationMobile = keyframes`
  0% {
    flex: 1;
    min-height: 534px;
  }
  100% {
    flex: 0;
    min-height: 0px;
  }
`;

const TickAnimation = keyframes`
  0% {
    opacity: 0;
    transform: scale(0);
  }
  70% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
  }
`;

const CircleAnimation = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

export const Thankyou = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  padding: 48px 40px;
  background: rgba(0, 97, 104, 0.9);
  color: ${colors.white};
  min-width: 36%;
  width: 100%;
  height: 572px;
  border-bottom: 8px solid ${colors.primary};
  backdrop-filter: blur(5px);
  transition: 0.5s ease-in-out;

  > h1 {
    font-size: 40px;
    line-height: 56px;
  }

  > .thankyou {
    max-width: 672px;
    margin: 0 auto;
    text-align: center;
    animation: ${FadeIn} 2.5s forwards;
  }

  > p {
    font-size: 16px;
    line-height: 32px;

    @media ${device.mobileL} {
      font-size: 14px;
      line-height: 26px;
    }
  }

  > a {
    margin-top: 24px;
    align-self: center;
    animation: ${FadeIn} 2s forwards;
  }

  @media ${device.tabletL} {
    background-image: url(${bgs.donationPageBgMobile});
    background-size: cover;
    background-repeat: no-repeat;
    min-width: unset;
    max-width: 100%;
    padding: 137px 24px 40px;
    height: 100%;
    border: none;
    height: 100%;
    min-height: 555px;
    justify-content: flex-start;
    animation: ${ExpandAnimationMobile} 0.5s forwards 1.2s;
  }
`;

export const CircleCtn = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 64%;
  background: ${colors.white};
  animation: ${ShrinkAnimation} 0.5s ease 1.2s forwards;

  @media ${device.tabletL} {
    width: 100%;
    height: 0%;
    min-height: 534px;
    animation: ${ShrinkAnimationMobile} 0.5s ease 1.2s forwards;
  }
`;

export const CircleWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  > .circle {
    position: absolute;
    width: 120px;
    animation: ${CircleAnimation} 0.1s ease 1s forwards;
  }

  > .tick {
    position: absolute;
    width: 120px;
    opacity: 0;
    transform: scale(0);
    animation: ${TickAnimation} 1s ease 0.5s forwards;
  }
`;
