import { corporateImgs } from "../../../assets/imgs/corporate";
import EnquiryForm from "../../../component/Reusable/EnquiryForm";
import Offer from "../../../component/Reusable/Offer";
import NeedAdvice from "../../../component/Reusable/NeedAdvice";
import ReusableIntro from "../../../component/Reusable/ReusableIntro";
import ReusableHero from "../../../component/Reusable/ReusableHero";
import { offerData } from "../offerData";
import bgs from "../../../assets/bgs";

const Mediation = () => {
  const heroContents = {
    type: 2,
    title: <h1>Mediation</h1>,
    text: (
      <p>
        Commercial mediation is a form of dispute resolution in which a neutral
        third party (the mediator) assists parties to agree to work towards a
        settlement of their dispute.
      </p>
    ),
    img: bgs.mediationHeroBg,
    mobileimg: bgs.mediationHeroBgMobile,
    button: true,
  };

  const introContents = {
    title: "What is Mediation?",
    text: (
      <p>
        It’s a flexible form of dispute resolution which is voluntary yet
        confidential, with the parties retaining control of the decision whether
        or not to settle on certain terms. 
        <br />
        <br />
        When compared to a judge or arbitrator, the mediator will not decide the
        case on merits, but will work to help towards an agreement between the
        parties.
      </p>
    ),
    img: corporateImgs.mediationIntro,
    link: "/enquiry",
  };

  const offerContents = [offerData.mergersAcquisitions, offerData.accountants];

  return (
    <>
      <ReusableHero contents={heroContents} />
      <ReusableIntro contents={introContents} />
      <NeedAdvice />
      <Offer
        contents={offerContents}
        title="Similar Services"
      />
      <EnquiryForm />
    </>
  );
};

export default Mediation;
