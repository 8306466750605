import styled from "styled-components";
import size from "../../../theme/devices/sizes";
import device from "../../../theme/devices";

export const Container = styled.div`
  max-width: ${size.laptopL};
  margin: 0 auto 120px;

  @media ${device.tabletL} {
    div {
      padding-bottom: 0px;
    }

    div:nth-child(1) {
      button {
        display: none;
      }
    }
  }
`;

export const ParagraphsWrapper = styled.div`
  display: flex;
  padding: 0px 120px;
  gap: 80px;

  @media ${device.laptop} {
    flex-direction: column;
    gap: 32px;
  }

  @media ${device.tabletL} {
    padding: 0px 56px;
  }

  @media ${device.mobileL} {
    padding: 0px 24px;
  }
`;

export const Paragraph = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  flex: 1;

  font-size: 16px;
  line-height: 28px;
`;
