import styled from "styled-components";
import { colors } from "../../theme/colors";
import device from "../../theme/devices";

export const Main = styled.main`
  color: ${colors.greenDark};

  @media ${device.tabletL} {
  }
`;
