import styled from "styled-components";
import { colors } from "../../../../theme/colors";
import device from "../../../../theme/devices";

export const Container = styled.div`
  flex: 1;
  background: ${colors.white};
  padding: 32px 48px 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
  max-width: 58%;
  height: 572px;

  > h1 {
    font-size: 32px;
    line-height: 48px;
  }

  p {
    color: ${colors.greenLight};
  }

  @media ${device.tabletL} {
    max-width: 100%;
    padding-top: 118px;
  }

  @media ${device.mobileL} {
    padding: 40px 24px 32px;
    gap: 48px;

    > h1 {
      font-size: 24px;
      line-height: 32px;
    }
  }
`;

export const InputsCtn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

export const MultiInputsWrapper = styled.div`
  display: flex;
  gap: 16px;

  @media ${device.mobileL} {
    flex-direction: column;
  }
`;

interface InputProps {
  $show: boolean;
}

export const InputWrapper = styled.div<InputProps>`
  position: relative;
  width: 100%;
  height: 50px;
  border: none;

  > img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 14px;
    cursor: pointer;
  }

  > p {
    ${({ $show }) => !$show && "display: none"};
    position: absolute;
    background: ${colors.white};
    top: -8px;
    left: 14px;
    z-index: 2;
    font-size: 12px;
    color: ${colors.primary};
    padding: 0px 5px;
  }

  @media ${device.mobileL} {
    width: 100%;

    > p {
      font-size: 12px;
    }
  }
`;

export const Input = styled.input<InputProps>`
  width: 100%;
  height: 100%;
  padding: 14px;
  font-size: 16px;
  background: ${colors.white};
  border: none;
  border: 2px solid
    ${({ $show }) => ($show ? `${colors.primary}` : `${colors.border}`)};

  &:focus {
    outline: none;
    border: 2px solid ${colors.primary};
    &::placeholder {
      opacity: 0;
    }
  }

  @media ${device.mobileL} {
    width: 100%;
    font-size: 14px;
  }
`;

export const Bottom = styled.div`
  display: flex;
  gap: 8px;
  font-size: 16px;

  @media ${device.mobileL} {
    font-size: 14px;
  }
`;
