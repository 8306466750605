import { useNavigate } from "react-router-dom";
import imgs from "../../../../assets/imgs";
import ReusableButton from "../../../Reusable/ReusableButton";
import { Container, Section, Paragraph } from "./styles";

const RatesIntro = () => {
  const navigate = useNavigate();

  const paragraphs = [
    {
      title: "Rateable Value",
      text: (
        <p>
          The Valuation Office Agency (VOA) works out the{" "}
          <span>Rateable Value of commercial properties</span> by estimating
          their rental value at a statutory valuation date. This means that
          rates are normally calculated according to historic rental values in
          the locality of a premises, which means occupiers may not be paying
          rates based on prevailing market values.
          <br />
          <br />
          There may be factors specific to certain businesses which can affect
          the valuation, so it is always worth obtaining professional advice as
          to whether the VOA’s estimate is correct. Business rates are also
          calculated differently depending on whether the property is located in
          England and Wales, Scotland or Northern Ireland.
        </p>
      ),
    },
    {
      title: "Rates Payable",
      text: (
        <p>
          The business rates that are actually payable are based upon the
          Rateable Value and a multiplier which is adjusted annually. <br />
          <br />
          There may also be further adjustments made to the rates payable
          depending on the size of the occupying business. If there has been a
          recent revaluation, the rates may be adjusted to lessen the impact of
          changes in Rateable Values.
          <br />
          <br /> It is the local authority for the location of a property that
          will issue the rate demand. Once tenants understand their Rateable
          Value, they can estimate the <span>business rates payable</span> by
          using the multipliers published on the government website.
          <br />
          <br /> Tenants should be wary of inclusive rents. If the landlord
          fails to pay business rates on behalf of the tenant, unfortunately it
          is the tenant who is liable to the local authority.
        </p>
      ),
    },
  ];

  return (
    <Section>
      <img
        src={imgs.charterBlock}
        alt="background"
      />
      <Container>
        {paragraphs.map((paragraph, i) => {
          return (
            <Paragraph key={i}>
              <h1>{paragraph.title}</h1>
              {paragraph.text}
              <ReusableButton
                name="Enquire Now"
                onClick={() => {}}
              />
            </Paragraph>
          );
        })}
      </Container>
    </Section>
  );
};

export default RatesIntro;
