import { useState } from "react";
import ReusableButton from "../../ReusableButton";
import { StepMain, Textarea, TextareaWrapper, Title } from "../styles";
import { useAppDispatch } from "../../../../redux/hooks";
import { updateEnquiryForm } from "../../../../redux/features/enquiryFormSlice";
import { updateEnquiryStep } from "../../../../redux/features/enquiryStepSlice";

const Step5 = () => {
  const dispatch = useAppDispatch();
  const [concerns, setConcerns] = useState<string>("");
  const [show, setShow] = useState<boolean>(false);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;
    setConcerns(value);
  };

  const handleClick = () => {
    // check if this is mandatory
    dispatch(updateEnquiryForm({ comments: concerns }));
    dispatch(updateEnquiryStep(6));
  };

  return (
    <StepMain>
      <Title>Please tell us more about your business concenrs:</Title>
      <TextareaWrapper>
        {concerns.length > 0 || show ? <p>How can we help you?</p> : null}
        <Textarea
          placeholder="How can we help you?"
          onChange={handleChange}
          onFocus={() => setShow(true)}
          onBlur={() => setShow(false)}
          $show={concerns.length > 0}
        />
      </TextareaWrapper>

      <ReusableButton
        $primary={true}
        name="Continue"
        onClick={handleClick}
      />
    </StepMain>
  );
};

export default Step5;
