import styled from "styled-components";
import size from "../../theme/devices/sizes";
import device from "../../theme/devices";

export const Section = styled.div`
  background: #002635;
  display: flex;
  width: 100vw;
  height: 654px;
  overflow: hidden;
  flex-direction: column;

  @media (min-width: 631px) and (max-width: 880px) {
    height: 740px;
  }

  li {
    list-style-type: none;
  }

  @media ${device.mobileL} {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    height: 1450px;
    width: 100%;
  }
`;

export const FooterUpper = styled.div`
  display: flex;
  width: 92%;
  height: 40%;
  margin: 0 auto;
  justify-content: space-between;
  border-bottom: 2px solid #20566b;

  @media ${device.mobileL} {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center;
    gap: 24px !important;
    height: 40%;
    border-bottom: 0px;
    margin-top: -10%;
  }
`;

export const FooterUpperLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 18px;
  padding-top: 60px;
  padding-left: 56px;

  .subscribe-form {
    position: relative;
    width: 100%;
    padding-right: 20px;
  }

  @media ${device.mobileL} {
    padding-bottom: 20px;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px;

    h2 {
      font-size: 24px !important;
    }

    button {
      display: flex;
      padding: 8px 16px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      position: absolute;
      top: 50% !important;
    }
    p {
      font-size: 12px !important;
    }
  }

  @media (min-width: 631px) and (max-width: 880px) {
    input[type="email"] {
      margin-bottom: 10px;
    }

    .subscribe-form p {
      margin-top: -10px;
    }

    .mail-logo {
      top: 40% !important;
    }

    .subscribe-form p:nth-child(1) {
      top: 2px !important;
    }
  }

  @media (max-width: 631px) {
    .subscribe-form {
      width: 100%;
    }
  }

  h2 {
    color: #f5f5f5;
    font-family: Owners;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 40px; /* 125% */
    white-space: nowrap;
    align-items: flex-start !important;
  }

  p {
    color: #d3dadd;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
  }

  .subscribe-form p {
    text-align: right;
  }

  .input-wrapper {
    position: relative;
    height: 43px;
    width: 100%;
    max-width: 479px;

    p:nth-child(1) {
      text-align: left;
      position: absolute;
      top: -8px;
      left: 10px;
      color: #1ccedc;
      background-color: #002635;
      padding: 0 5px;
      font-family: Manrope;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .subscribe-form p:nth-child(1).invalid {
      color: #eb3c3c;
    }

    .mail-logo {
      position: absolute;
      top: 50%;
      color: #1ccedc;
      left: 10px;
      transform: translateY(-50%);
      width: 18px;
      height: 14.5px;
      flex-shrink: 0;
      pointer-events: none;
    }

    input[type="email"] {
      padding: 10px 40px 10px 40px;
      color: #d3dadd;
      border: 1px solid #20566b;
      border-radius: 5px;
      width: 100%;
      height: 100%;
      align-items: center;
      gap: 8px;
      background: #002635;
      outline: none;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus {
      -webkit-text-fill-color: #d3dadd;
      -webkit-box-shadow: 0 0 0px 1000px #002635 inset;
    }

    input[type="email"].submitted {
      border: 1px solid green;
    }

    input {
      border: 1px solid transparent;
      outline: none;
    }

    button {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 6px;
      height: 35px;
      padding: 8px 16px;
      color: #073547;
      font-family: Manrope;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      background: #20566b;
      border: none;
      border-radius: 4px;
    }

    button:hover {
      cursor: pointer;
      background: #073547 !important;
      color: #1ccedc;
    }

    @media (max-width: 880px) {
      /* button {
        top: 40%;
      } */
    }

    input::placeholder {
      color: #d3dadd;
      font-family: Manrope;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
`;
export const FooterUpperRight = styled.div`
  display: flex;
  height: 139px;
  flex-direction: column;
  /* justify-content: space-between; */
  align-items: flex-end;
  padding-top: 60px;
  padding-right: 56px;

  @media ${device.mobileL} {
    display: inline-flex;
    padding-top: 0;
    flex-direction: column;
    padding-left: 24px;
    align-items: flex-start;
    gap: 32px;
    height: 90%;
    margin-bottom: -350px;
  }
`;

export const CtaLogoContainer = styled.div`
  width: 88px;
  height: 88px;
  padding-top: 3%;
`;

export const SocialsIconsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: 10%;

  img {
    width: 28px;
    height: 28px;
  }
`;

export const FooterMiddle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 2%;
  width: 92%;
  gap: 32px;
  flex-direction: row;
  margin: 0 auto;
  border-bottom: 2px solid #20566b;
  padding-bottom: 29px;
  padding-left: 56px;
  padding-right: 56px;

  @media ${device.mobileL} {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    margin: 0px;
    margin-left: -10px;
    border-bottom: 0px;

    h3 {
      font-size: 14px !important;
    }
  }

  h3 {
    color: #f5f5f5;
    font-family: Manrope;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    white-space: nowrap;
  }

  h4 {
    color: #f5f5f5;
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px; /* 183.333% */
  }

  a {
    color: #d3dadd;
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 183.333% */
    text-decoration: none;
  }

  li {
    padding-bottom: 8px;
  }
`;

export const QuickLinks = styled.div`
  @media ${device.mobileL} {
    border-top: 2px solid #20566b;
    width: 117%;
    padding-top: 32px;
  }
`;

export const GetInTouch = styled.div`
  @media ${device.mobileL} {
    border-top: 2px solid #20566b;
    padding-top: 32px;
    width: 117%;
    border-bottom: 2px solid #20566b;
    padding-bottom: 32px;
    width: 117%;

    #getInTouch-header {
      padding-bottom: 10px;
    }
  }
`;

export const FooterBottom = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 39px;
  padding-left: 56px;
  gap: 10px;
  padding-right: 56px;

  @media (min-width: 631px) and (max-width: 880px) {
    gap: 30px;
    flex-direction: column;
  }

  @media ${device.mobileL} {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    padding-top: 0px;
    margin-top: -28px;
    margin-bottom: 8.5%;
    white-space: nowrap;
    margin-left: -10px;
  }

  @media ${device.mobileM} {
    margin-left: -12px;
  }

  a {
    color: #20566b;
    text-decoration: none;
  }
`;
export const TermsAndPrivacy = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 32px;
  color: #20566b;
  font-family: Manrope;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  @media ${device.mobileL} {
    font-size: 11.1px !important;
  }
`;
export const Copyright = styled.p`
  color: #20566b;
  font-family: Manrope;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 216.667% */
  letter-spacing: 0.2px;

  @media ${device.mobileL} {
    font-size: 11.1px !important;
  }
`;

export const DevelopedBy = styled.div`
  display: flex;
  align-items: center;
  font-family: Proxima Nova;
  gap: 8px;
`;
