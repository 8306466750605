import { corporateImgs } from "../../../assets/imgs/corporate";
import EnquiryForm from "../../../component/Reusable/EnquiryForm";
import Offer from "../../../component/Reusable/Offer";
import NeedAdvice from "../../../component/Reusable/NeedAdvice";
import ReusableIntro from "../../../component/Reusable/ReusableIntro";
import ReusableHero from "../../../component/Reusable/ReusableHero";
import { offerData } from "../offerData";
import bgs from "../../../assets/bgs";

const Legal = () => {
  const heroContents = {
    type: 2,
    title: <h1>Legal</h1>,
    text: (
      <p>
        We can find you a range of legal services that are designed to support
        your business, including litigation, property-related matters and
        commercial issues.
      </p>
    ),
    img: bgs.legalHeroBg,
    mobileimg: bgs.legalHeroBgMobile,

    button: true,
  };

  const introContents = {
    title: "What does a commercial solicitor do?",
    text: (
      <p>
        Commercial lawyers will tend to have a specialist practice area that
        suits your business, e.g. Intellectual Property or industry focus such
        as technology or telecoms.
        <br />
        <br />
        As said, it all depends on the specialist area of the solicitor, but a
        lot of the transactional work focuses on protecting each client’s
        business interest by managing a wide range of tasks. It may be involving
        drawing up commercial agreements and disclaimers  or interpreting the
        law to protect a client against liable.
      </p>
    ),
    img: corporateImgs.legalIntro,
    link: "/enquiry",
  };

  const offerContents = [offerData.mergersAcquisitions, offerData.accountants];

  return (
    <>
      <ReusableHero contents={heroContents} />
      <ReusableIntro contents={introContents} />
      <NeedAdvice />
      <Offer
        contents={offerContents}
        title="Similar Services"
      />
      <EnquiryForm />
    </>
  );
};

export default Legal;
