import { propertyImgs } from "../../../assets/imgs/property";
import EnquiryForm from "../../../component/Reusable/EnquiryForm";
import Offer from "../../../component/Reusable/Offer";
import NeedAdvice from "../../../component/Reusable/NeedAdvice";
import ReusableIntro from "../../../component/Reusable/ReusableIntro";
import ReusableHero from "../../../component/Reusable/ReusableHero";
import { offerData } from "../offerData";
import bgs from "../../../assets/bgs";

const Acquisitions = () => {
  const heroContents = {
    type: 2,
    title: <h1>Acquisitions</h1>,
    text: (
      <p>
        Business owners in the UK may want to acquire the premises they’re
        currently leasing, to allow for a more secure and assured future.
      </p>
    ),
    img: bgs.acquisitionsHeroBg,
    mobileimg: bgs.acquisitionsHeroBgMobile,
    button: true,
  };

  const introContents = {
    title: "Why acquire a property?",
    text: (
      <p>
        We’ve supported commercial tenants for over 25 years, giving tenants the
        opportunity to own their premises. It’s about being 100% tenant-focused,
        meaning we can act for tenants to help guide them with either finance or
        the ability to acquire their property at the best price possible.
        <br />
        <br />
        We want the market to be as transparent as possible so that it’s fair to
        support real growth of small and medium-sized businesses, but also
        contributing to the economic development of local communities.
      </p>
    ),
    img: propertyImgs.acquisitionsIntro,
    link: "/enquiry",
  };

  const offerContents = [
    offerData.strategicLeaseManagement,
    offerData.ownYourProperty,
  ];

  return (
    <>
      <ReusableHero contents={heroContents} />
      <ReusableIntro contents={introContents} />
      <NeedAdvice />
      <Offer
        contents={offerContents}
        title="Similar Services"
      />
      <EnquiryForm />
    </>
  );
};

export default Acquisitions;
