import ReusableButton from "../ReusableButton";
import { colors } from "../../../theme/colors";
import {
  ButtonWrapper,
  Card,
  CardsCtn,
  Container,
  ImgWrapper,
  Section,
  Titles,
  Top,
} from "./styles";
import icons from "../../../assets/icons";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import profile from "../../../utils/profile";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { updateLogin } from "../../../redux/features/loginSlice";
import downloadable from "../../../assets/downloadable";

interface DownloadableProps {
  id?: string;
  full: boolean;
}

const Downloadable: React.FC<DownloadableProps> = ({ full, id }) => {
  const dispatch = useAppDispatch();
  const login = useAppSelector((state) => state.login);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");

    const checkToken = async () => {
      if (token) {
        const res: any = await profile(token);
        if (res.success) {
          dispatch(updateLogin(true));
        } else {
          dispatch(updateLogin(false));
          localStorage.removeItem("token");
        }
      }
    };

    if (token) {
      checkToken();
    }
  }, []);

  const fullCards = [
    {
      img: icons.downloadable1,
      name: "Disposing of Leasehold Property",
      file: downloadable.disposingOfLeaseholdProperty,
    },
    {
      img: icons.downloadable2,
      name: "Lease Negotiations Guide",
      file: downloadable.leaseNegotiationsGuide,
    },
    {
      img: icons.downloadable3,
      name: "Lease Renewal Timeline",
      file: downloadable.leaseRenewalTimeline,
    },
    {
      img: icons.downloadable4,
      name: "Lease Renewals",
      file: downloadable.leaseRenewals,
    },
    {
      img: icons.downloadable5,
      name: "New Lease Timeline",
      file: downloadable.newLeaseTimeline,
    },
    {
      img: icons.downloadable6,
      name: "New License Timeline",
      file: downloadable.newLicenseTimeline,
    },
    {
      img: icons.downloadable7,
      name: "Rent Review Disputes",
      file: downloadable.rentReviewDisputes,
    },
    {
      img: icons.downloadable8,
      name: "Service Charges",
      file: downloadable.serviceCharges,
    },
  ];

  const partialCards = [
    {
      img: icons.downloadable2,
      name: "Lease Negotiations Guide",
      file: downloadable.leaseNegotiationsGuide,
    },
    {
      img: icons.downloadable3,
      name: "Lease Renewal Timeline",
      file: downloadable.leaseRenewalTimeline,
    },
    {
      img: icons.downloadable4,
      name: "Lease Renewals",
      file: downloadable.leaseRenewals,
    },
    {
      img: icons.downloadable5,
      name: "New Lease Timeline",
      file: downloadable.newLeaseTimeline,
    },
  ];

  const cards = full ? fullCards : partialCards;

  const handleDownload = (file: string) => {
    window.open(file, "_blank");
  };

  return (
    <Section id={id}>
      <Container>
        <Top>
          <Titles>
            <h1>Downloadable Content</h1>
            <p>To get access to all our guides, join now for free!</p>
          </Titles>
          <a href={full ? "/enquiry" : "/advice-support"}>
            <ReusableButton
              name={full ? "Enquire Now" : "View All Guides"}
              onClick={() => {}}
              color={colors.white}
            />
          </a>
        </Top>
        <CardsCtn $full={full}>
          {cards.map((card, i) => {
            return (
              <Card key={i}>
                <ImgWrapper>
                  {!login && (
                    <img
                      id="lock"
                      src={icons.lock}
                      alt="lock"
                    />
                  )}
                  <img
                    src={card.img}
                    alt="lock"
                  />
                </ImgWrapper>
                {card.name}
                {login ? (
                  <ButtonWrapper>
                    <img
                      src={icons.lockBlue}
                      alt="lock"
                    />
                    <ReusableButton
                      name="Download"
                      color={colors.white}
                      onClick={() => handleDownload(card.file)}
                      width="100%"
                      hover={"#1CCEDC"}
                    />
                  </ButtonWrapper>
                ) : (
                  <ReusableButton
                    $primary={true}
                    name="Join To Unlock"
                    onClick={() => {
                      navigate("/login");
                    }}
                    width="100%"
                  />
                )}
              </Card>
            );
          })}
        </CardsCtn>
      </Container>
    </Section>
  );
};

export default Downloadable;
