import imgs from "../../../assets/imgs";
import Paragraph from "../../Reusable/Paragraph";
import { Container, Section } from "./styles";

const DilapidationsIntro = () => {
  const firstParagraph = {
    title: "Before Signing The Lease",
    text: "",
    img: "",
    button: true,
  };
  const secondParagraph = {
    title: "",
    text: (
      <p>
        Liability extends not only to ongoing repairs and decoration but also to
        handing the property back in no worse a condition as it was when the
        lease first commenced.
        <br />
        <br />
        When renting a car, note is taken of any defects so as not to be liable
        for dents and scratches which have nothing to do with the hirer. The
        same principle applies to taking a new lease of a property. It is worth
        the relatively small expense of instructing a building surveyor to
        inspect the premises prior to signing the lease as this could save an
        unwary tenant a fortune further down the line.
      </p>
    ),
    img: "",
  };
  return (
    <Section>
      <img
        src={imgs.charterBlock}
        alt="block"
      />
      <Container>
        <Paragraph contents={firstParagraph} />
        <Paragraph contents={secondParagraph} />
      </Container>
    </Section>
  );
};

export default DilapidationsIntro;
