import styled from "styled-components";
import size from "../../../../theme/devices/sizes";
import device from "../../../../theme/devices";
import { colors } from "../../../../theme/colors";

export const Section = styled.section`
  position: relative;
  max-width: ${size.laptopL};
  margin: 0 auto;

  > img {
    position: absolute;
    width: 477px;
    height: 477px;
    top: 0;
    left: 0;
    z-index: -1;
  }

  @media ${device.laptop} {
    > img {
      display: none;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 99px 120px 120px;
  gap: 80px;

  > div {
    flex: 1;
  }

  @media ${device.tabletL} {
    flex-direction: column;
    padding: 99px 56px 120px;
    gap: 88px;
  }

  @media ${device.mobileL} {
    padding: 48px 24px 88px;
  }
`;

export const Paragraph = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  > h1 {
    font-size: 40px;
    line-height: 56px;
  }

  > p {
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 24px;

    > span {
      color: ${colors.primary};
      font-weight: 700;
      text-decoration: underline;
    }
  }

  @media ${device.mobileL} {
    gap: 16px;

    > h1 {
      font-size: 32px;
      line-height: 48px;
    }

    > p {
      font-size: 14px;
      line-height: 26px;
      margin-bottom: 16px;
    }
  }
`;
