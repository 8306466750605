import { useEffect, useRef, useState } from "react";
import ReusableButton from "../ReusableButton";
import {
  ActiveBorder,
  BorderWrapper,
  Bottom,
  Container,
  ContentWrapper,
  ContentsCtn,
  Headers,
  LinkWrapper,
  Section,
  Texts,
  Top,
  Topic,
  Topics,
} from "./styles";
import imgs from "../../../assets/imgs";
import { ReactComponent as TriangleSvg } from "../../../assets/icons/triangleRight.svg";
import useWindowSize, { Size } from "../../../utils/useWindowSize";
import size from "../../../theme/devices/sizes";
import { useNavigate } from "react-router-dom";

const Service: React.FC = () => {
  const [show, setShow] = useState(1);
  const screenSize: Size = useWindowSize();
  const laptop = +size.laptop.slice(0, -2);
  const [topicWidth, setTopicWidth] = useState<{
    topic1: number | null;
    topic2: number | null;
    topic3: number | null;
  }>({
    topic1: null,
    topic2: null,
    topic3: null,
  });
  const containerRef = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();

  const topics = [
    { name: "Property", ref: useRef<HTMLDivElement | null>(null) },
    { name: "Corporate", ref: useRef<HTMLDivElement | null>(null) },
    { name: "Non-Corporate", ref: useRef<HTMLDivElement | null>(null) },
  ];

  const contents = [
    {
      order: "one",
      img: imgs.service1,
      paragraph:
        "In this section, our Members will find advice, support and guidance to help leverage their negotiating position. The information is tailored to assist those occupying commercial premises, whether they are taking a new lease, reviewing their rent, renewing their existing arrangements or facing a dilapidations claim.",
      linkName: "View Property Services",
      link: "/property/services",
    },
    {
      order: "two",
      img: imgs.service2,
      paragraph:
        "This section provides Members information and guidance in the critical area of compliance and examines the legal obligations involved in occupying commercial property.",
      linkName: "View Corporate Services",
      link: "/corporate/services",
    },
    {
      order: "three",
      img: imgs.service3,
      paragraph:
        "This section provides our Members advice, examines different ways of working and provides an introduction to service charges and discusses the various ways in which a business may occupy commercial property.",
      linkName: "View Non-Corporate Services",
      link: "/non-corporate/services",
    },
  ];

  useEffect(() => {
    const getTopicWidth = () => {
      return {
        topic1: +getComputedStyle(
          document.getElementById("topic1")!,
        ).width.slice(0, -2),
        topic2: +getComputedStyle(
          document.getElementById("topic2")!,
        ).width.slice(0, -2),
        topic3: +getComputedStyle(
          document.getElementById("topic3")!,
        ).width.slice(0, -2),
      };
    };

    const updateTopicWidth = () => {
      const widths = getTopicWidth();
      setTopicWidth(widths);
    };

    window.addEventListener("load", updateTopicWidth);
    window.addEventListener("resize", updateTopicWidth);

    return () => {
      window.removeEventListener("load", updateTopicWidth);
      window.removeEventListener("resize", updateTopicWidth);
    };
  }, []);

  const handleClick = (i: number) => {
    setShow(i);

    const topicElement = containerRef.current?.children[i - 1];
    const container = containerRef.current;

    if (topicElement && container) {
      const topic3Rect = topicElement.getBoundingClientRect().left;
      const scrollLeft = i === 1 ? 0 : i === 2 ? -50 : topic3Rect;

      container.scrollTo({
        left: scrollLeft,
        behavior: "smooth",
      });
    }
  };

  return (
    <Section>
      <Container>
        <Top>
          <Headers>
            <h1>Expert-Led Services, Perfect For Everyone</h1>
            <p>
              When it comes to helping commercial tenants, our work is truly
              second-to-none. Explore our vast array of specialist services
              tailored to help clients and commercial tenants across the UK.
            </p>
          </Headers>
          {screenSize.width! > laptop && (
            <ReusableButton
              name="Enquire Now"
              onClick={() => navigate("/enquiry")}
            />
          )}
        </Top>
        <Bottom>
          <Topics ref={containerRef}>
            {topics.map((topic, i) => {
              return (
                <Topic
                  id={`topic${i + 1}`}
                  key={i + 1}
                  $select={show === i + 1}
                  onClick={() => handleClick(i + 1)}
                  ref={topic.ref}
                >
                  {topic.name}
                </Topic>
              );
            })}
          </Topics>
          <BorderWrapper>
            <ActiveBorder
              $topic={show}
              $width={topicWidth}
            />
          </BorderWrapper>
          <ContentsCtn>
            {contents.map((content, i) => (
              <ContentWrapper
                $show={show === i + 1}
                key={content.order}
              >
                <img
                  src={content.img}
                  alt="people"
                />
                <Texts>
                  <p>{content.paragraph}</p>
                  <a href={content.link}>
                    <LinkWrapper>
                      {content.linkName}
                      <TriangleSvg />
                    </LinkWrapper>
                  </a>
                </Texts>
                {screenSize.width! <= laptop && (
                  <ReusableButton
                    name="Enquire Now"
                    $mwidth="165px"
                    onClick={() => {
                      navigate("/enquiry");
                    }}
                  />
                )}
              </ContentWrapper>
            ))}
          </ContentsCtn>
        </Bottom>
      </Container>
    </Section>
  );
};

export default Service;
