import axios from "axios";

export const phoneValidation = async (telephone: number) => {
  const key = process.env.REACT_APP_DATA8_KEY;

  const phoneData = {
    telephoneNumber: telephone,
    defaultCountry: "GB",
    options: {
      UseMobileValidation: true,
      UseLineValidation: true,
      RequiredCountry: "",
      AllowedPrefixes: "",
      BarredPrefixes: "",
      UseUnavailableStatus: true,
      UseAmbiguousStatus: true,
      TreatUnavailableMobileAsInvalid: false,
      ExcludeUnlikelyNumbers: false,
    },
  };

  let result: any = await axios({
    method: "post",
    url: `https://webservices.data-8.co.uk/PhoneValidation/IsValid.json?key=${key}`,
    data: phoneData,
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((res) => {
      return res.data?.Result?.ValidationResult === "Valid" ? true : false;
    })
    .catch((err) => {
      return err;
    });
  return result;
};
