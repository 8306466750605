export const colors = {
  primary: "#1CCEDC",
  greenDark: "#002635",
  greenLight: "#20566B",
  greenLight2: "#073547",
  border: "#D3DADD",
  body: "#202020",
  tertiary: "#FAFAFA",
  positive: "#5DD200",
  grey: "#D3D3D3",
  white: "#F5F5F5",
};
