import { ReactElement } from "react";
import { Main } from "./styles";
import Services from "./pages/Services";
import InsolvencyAdvice from "./pages/InsolvencyAdvice";
import Insurance from "./pages/Insurance";
import Litigation from "./pages/Litigation";
import MergersAcquisition from "./pages/MergersAcquisition";
import Accountants from "./pages/Accountants";
import Legal from "./pages/Legal";
import Mediation from "./pages/Mediation";
import FinanceInvestments from "./pages/FinanceInvestments";

const CorporatePage: React.FC = () => {
  const pathname = window.location.pathname.split("/");
  const pageName: string = pathname[pathname.length - 1];
  const pages: { [key: string]: ReactElement } = {
    services: <Services />,
    "insolvency-advice": <InsolvencyAdvice />,
    insurance: <Insurance />,
    litigation: <Litigation />,
    "finance-investments": <FinanceInvestments />,
    "mergers-acquisition": <MergersAcquisition />,
    accountants: <Accountants />,
    legal: <Legal />,
    mediation: <Mediation />,
  };

  return <Main>{pages[pageName]}</Main>;
};

export default CorporatePage;
