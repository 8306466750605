import styled from "styled-components";
import { colors } from "../../../theme/colors";
import size from "../../../theme/devices/sizes";
import device from "../../../theme/devices";

export const Section = styled.div`
  background: ${colors.greenLight2};
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  gap: 60px;
  color: ${colors.white};
  padding: 82px 160px 80px;
  max-width: ${size.laptopL};
  margin: 0 auto;

  @media ${device.laptopL} {
    flex-direction: column;
  }

  @media ${device.tabletL} {
    padding: 82px 56px 80px;
  }

  @media ${device.mobileL} {
    gap: 32px;
    padding: 64px 24px;
  }
`;

export const BackgroundBlocks = styled.img`
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 0;

  @media ${device.mobileL} {
    left: unset;
    right: 0;
  }
`;

export const Title = styled.div`
  max-width: 264px;
  z-index: 2;

  > h1 {
    font-size: 48px;
  }

  @media ${device.laptopL} {
    max-width: 100%;
  }

  @media ${device.mobileL} {
    > h1 {
      font-size: 32px;
      line-height: 48px;
    }
  }
`;

export const ParagraphsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 40px;

  @media ${device.mobileL} {
    gap: 24px;
  }
`;

export const Paragraph = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 373px;
  z-index: 2;

  > h3 {
    font-size: 24px;
    line-height: 32px;
  }

  > p {
    font-size: 16px;
    line-height: 28px;
  }

  @media ${device.laptopL} {
    max-width: 100%;
  }

  @media ${device.mobileL} {
    > h3 {
      font-size: 20px;
      line-heiht: 32px;
    }

    > p {
      font-size: 14px;
      line-height: 26px;
    }
  }
`;
