import { nonCorporateImgs } from "../../../assets/imgs/nonCorporate";
import EnquiryForm from "../../../component/Reusable/EnquiryForm";
import Offer from "../../../component/Reusable/Offer";
import NeedAdvice from "../../../component/Reusable/NeedAdvice";
import ReusableIntro from "../../../component/Reusable/ReusableIntro";
import ReusableHero from "../../../component/Reusable/ReusableHero";
import { offerData } from "../offerData";
import bgs from "../../../assets/bgs";

const HRConsultants = () => {
  const heroContents = {
    type: 2,
    title: <h1>HR Consultants</h1>,
    text: (
      <p>
        We can find you a range of HR Consultants services that are designed to
        support your business, including litigation, property-related matters
        and commercial issues.
      </p>
    ),
    img: bgs.hrConsultantsHeroBg,
    mobileimg: bgs.hrConsultantsHeroBgMobile,
    button: true,
  };

  const introContents = {
    title: "Why you need HR",
    text: (
      <p>
        Training, developing your staff, managing their performance and dealing
        with any HR issues, such as conflict, is something you’ll need to
        account for in any business that wants to be a success.
        <br />
        <br />
        You can manage your HR in house, or use expert HR consultants that we
        can help provide for you. No matter the size of your business, there are
        consultants on hand to free up your time so you can focus on the main
        aspects of your business, and also ensuring, legally, you’re compliant
        from all employment legislation.
      </p>
    ),
    img: nonCorporateImgs.hrConsultantsIntro,
    link: "/enquiry",
  };

  const offerContents = [offerData.contractors, offerData.shopfitters];

  return (
    <>
      <ReusableHero contents={heroContents} />
      <ReusableIntro contents={introContents} />
      <NeedAdvice />
      <Offer
        contents={offerContents}
        title="Similar Services"
      />
      <EnquiryForm />
    </>
  );
};

export default HRConsultants;
