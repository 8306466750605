import { useState } from "react";
import size from "../../../../theme/devices/sizes";
import useWindowSize, { Size } from "../../../../utils/useWindowSize";
import {
  Circle,
  OptionWrapper,
  OptionsCtn,
  StepMain,
  TickCircle,
  Title,
  Unsure,
} from "../styles";
import { useAppDispatch } from "../../../../redux/hooks";
import { updateEnquiryForm } from "../../../../redux/features/enquiryFormSlice";
import { updateEnquiryStep } from "../../../../redux/features/enquiryStepSlice";
import icons from "../../../../assets/icons";

const Step3 = () => {
  const windowSize: Size = useWindowSize();
  const laptopL = +size.laptopL.slice(0, -2);
  const dispatch = useAppDispatch();
  const [option, setOption] = useState("");
  const [showTick, setShowTick] = useState<{ [key: string]: boolean }>({
    Yes: false,
    No: false,
    Unsure: false,
  });

  const handleClick = (name: string) => {
    setOption(name);
    setShowTick((prev) => ({ ...prev, [name]: true }));
    setTimeout(() => {
      dispatch(
        updateEnquiryForm({
          isLeaseHeld: option === "Yes" ? 1 : option === "No" ? 0 : 2,
        }),
      );
      dispatch(updateEnquiryStep(4));
    }, 500);
  };

  return (
    <StepMain>
      <Title>Is your lease held in a Ltd company?</Title>
      <OptionsCtn>
        <OptionWrapper
          $selected={option === "Yes"}
          onClick={() => handleClick("Yes")}
        >
          <p>Yes</p>
          {showTick.Yes ? (
            <TickCircle
              src={icons.circleTick}
              alt="tick"
            />
          ) : (
            <Circle
              src={icons.circleBlue}
              alt="circle"
            />
          )}
        </OptionWrapper>
        <OptionWrapper
          $selected={option === "No"}
          onClick={() => handleClick("No")}
        >
          <p>No</p>
          {showTick.No ? (
            <TickCircle
              src={icons.circleTick}
              alt="tick"
            />
          ) : (
            <Circle
              src={icons.circleBlue}
              alt="circle"
            />
          )}
        </OptionWrapper>
        {windowSize.width! > laptopL ? (
          <Unsure
            $selected={option === "Unsure"}
            onClick={() => handleClick("Unsure")}
          >
            <p>Unsure</p>
            {showTick.Unsure ? (
              <TickCircle
                src={icons.circleTick}
                alt="tick"
              />
            ) : (
              <Circle
                src={icons.circleBlue}
                alt="circle"
              />
            )}
          </Unsure>
        ) : (
          <OptionWrapper
            $selected={option === "Unsure"}
            onClick={() => handleClick("Unsure")}
          >
            <p>Unsure</p>
            {showTick ? (
              <TickCircle
                src={icons.circleTick}
                alt="tick"
              />
            ) : (
              <Circle
                src={icons.circleBlue}
                alt="circle"
              />
            )}
          </OptionWrapper>
        )}
      </OptionsCtn>
    </StepMain>
  );
};

export default Step3;
