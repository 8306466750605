import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface FormState {
  type: string;
  annual_rent: string;
  isLeaseHeld: number | null;
  city: string;
  comments: string;
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
  isSubscribe: boolean;
}

const initialState: FormState = {
  type: "",
  annual_rent: "",
  isLeaseHeld: null,
  city: "",
  comments: "",
  firstname: "",
  lastname: "",
  email: "",
  phone: "",
  isSubscribe: false,
};

const enquiryFormSlice = createSlice({
  name: "form",
  initialState,
  reducers: {
    updateEnquiryForm: (state, action: PayloadAction<Partial<FormState>>) => {
      return (state = { ...state, ...action.payload });
    },
    resetEnquiryForm: () => {
      return initialState;
    },
  },
});

export const { updateEnquiryForm, resetEnquiryForm } = enquiryFormSlice.actions;
export default enquiryFormSlice.reducer;
