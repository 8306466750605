import size from "../../../../theme/devices/sizes";
import useWindowSize, { Size } from "../../../../utils/useWindowSize";
import {
  Circle,
  OptionWrapper,
  OptionsCtn,
  Title,
  Unsure,
  StepMain,
  TickCircle,
} from "../styles";
import { ReactComponent as Svg1 } from "../../../../assets/icons/enquireFrom1.svg";
import { ReactComponent as Svg2 } from "../../../../assets/icons/enquireFrom2.svg";
import { ReactComponent as Svg3 } from "../../../../assets/icons/enquireFrom3.svg";
import { updateEnquiryForm } from "../../../../redux/features/enquiryFormSlice";
import { updateEnquiryStep } from "../../../../redux/features/enquiryStepSlice";
import { useAppDispatch } from "../../../../redux/hooks";
import { useState } from "react";
import icons from "../../../../assets/icons";

const Step1 = () => {
  const windowSize: Size = useWindowSize();
  const laptopL = +size.laptopL.slice(0, -2);
  const dispatch = useAppDispatch();
  const [type, setType] = useState("");
  const [showTick, setShowTick] = useState<boolean>(false);

  const handleClick = (option: string) => {
    setType(option);
    setShowTick(true);
    dispatch(updateEnquiryForm({ type: option }));
    setTimeout(() => {
      dispatch(updateEnquiryStep(2));
    }, 500);
  };

  const options = [
    { img: <Svg1 />, name: "Property" },
    {
      img: <Svg2 />,
      name: "Corporate",
    },
    { img: <Svg3 />, name: "Non-Corporate" },
  ];

  return (
    <StepMain>
      <Title>Which service are you interested in?</Title>
      <OptionsCtn>
        {options.map((option, i) => {
          return (
            <OptionWrapper
              key={i}
              $questionNum={1}
              $selected={type === option.name}
              onClick={() => handleClick(option.name)}
            >
              {option.img}
              <p>{option.name}</p>
              {showTick ? (
                <TickCircle
                  src={icons.circleTick}
                  alt="tick"
                />
              ) : (
                <Circle
                  src={icons.circleBlue}
                  alt="circle"
                />
              )}
            </OptionWrapper>
          );
        })}
        {windowSize.width! <= laptopL ? (
          <OptionWrapper
            $questionNum={1}
            $selected={type === "Unsure"}
            onClick={() => handleClick("Unsure")}
          >
            <p>Unsure</p>
            {showTick ? (
              <TickCircle
                src={icons.circleTick}
                alt="tick"
              />
            ) : (
              <Circle
                src={icons.circleBlue}
                alt="circle"
              />
            )}
          </OptionWrapper>
        ) : (
          <Unsure
            $selected={type === "Unsure"}
            onClick={() => handleClick("Unsure")}
          >
            <p>Unsure</p>
            {showTick ? (
              <TickCircle
                src={icons.circleTick}
                alt="tick"
              />
            ) : (
              <Circle
                src={icons.circleBlue}
                alt="circle"
              />
            )}
          </Unsure>
        )}
      </OptionsCtn>
    </StepMain>
  );
};

export default Step1;
