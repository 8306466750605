import { nonCorporateImgs } from "../../../assets/imgs/nonCorporate";
import EnquiryForm from "../../../component/Reusable/EnquiryForm";
import Offer from "../../../component/Reusable/Offer";
import NeedAdvice from "../../../component/Reusable/NeedAdvice";
import ReusableIntro from "../../../component/Reusable/ReusableIntro";
import ReusableHero from "../../../component/Reusable/ReusableHero";
import { offerData } from "../offerData";
import bgs from "../../../assets/bgs";

const Shopfitters = () => {
  const heroContents = {
    type: 2,
    title: <h1>Shopfitters</h1>,
    text: (
      <p>
        A great commercial fit-out can give a new lease of life to your
        building, increase occupier satisfaction, attract great quality tenants,
        extend occupant retention, and boost the owner’s bottom line.
      </p>
    ),
    img: bgs.shopfittersHeroBg,
    mobileimg: bgs.shopfittersHeroBgMobile,
    button: true,
  };

  const introContents = {
    title: "Benefits of a fit-out",
    text: (
      <p>
        It’s also a lot less expensive when compared to major works, like a
        complete rebuild. A new fit-out is about coming into a building and
        providing a breath of fresh air. 
        <br />
        <br />
        Contractors must be adaptive to every client’s requirements, whether
        that’s ripping apart the internals, building or upgrading a new lobby,
        upgrading services to BSI standards, even putting in new handrails or
        compliant glass.
      </p>
    ),
    img: nonCorporateImgs.shopfittersIntro,
    link: "/enquiry",
  };

  const offerContents = [offerData.utilities, offerData.contractors];

  return (
    <>
      <ReusableHero contents={heroContents} />
      <ReusableIntro contents={introContents} />
      <NeedAdvice />
      <Offer
        contents={offerContents}
        title="Similar Services"
      />
      <EnquiryForm />
    </>
  );
};

export default Shopfitters;
