import { useState } from "react";
import {
  ContactWrapper,
  InputField,
  InputWrapper,
  Remark,
  StepMain,
  Title,
} from "../styles";
import ReusableButton from "../../ReusableButton";
import { useAppDispatch } from "../../../../redux/hooks";
import { updateEnquiryForm } from "../../../../redux/features/enquiryFormSlice";
import { updateEnquiryStep } from "../../../../redux/features/enquiryStepSlice";
import icons from "../../../../assets/icons";
import { Notify } from "notiflix";

const Step6 = () => {
  const dispatch = useAppDispatch();
  const [details, setDetails] = useState<{
    firstName: string;
    lastName: string;
  }>({
    firstName: "",
    lastName: "",
  });
  const [show, setShow] = useState<{
    firstName: boolean;
    lastName: boolean;
  }>({
    firstName: false,
    lastName: false,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setDetails((prev) => ({ ...prev, [name]: value }));
  };

  const handleClick = () => {
    if (details.firstName.length === 0) {
      return Notify.failure("Please provide your first name.");
    }
    if (details.lastName.length === 0) {
      return Notify.failure("Please provide your last name.");
    }

    dispatch(
      updateEnquiryForm({
        firstname: details.firstName,
        lastname: details.lastName,
      }),
    );
    dispatch(updateEnquiryStep(7));
  };

  return (
    <StepMain>
      <Title>That’s great! Now please tell us a little more about you...</Title>
      <ContactWrapper>
        <InputWrapper>
          {details.firstName.length > 0 || show.firstName ? (
            <p>First Name</p>
          ) : null}
          <InputField
            name="firstName"
            value={details.firstName}
            onChange={handleChange}
            onFocus={() => setShow((prev) => ({ ...prev, firstName: true }))}
            onBlur={() => setShow((prev) => ({ ...prev, firstName: false }))}
            $show={details.firstName.length > 0}
            placeholder="First Name"
          />
        </InputWrapper>
        <InputWrapper>
          {details.lastName.length > 0 || show.lastName ? (
            <p>Last Name</p>
          ) : null}
          <InputField
            name="lastName"
            value={details.lastName}
            onChange={handleChange}
            onFocus={() => setShow((prev) => ({ ...prev, lastName: true }))}
            onBlur={() => setShow((prev) => ({ ...prev, lastName: false }))}
            $show={details.lastName.length > 0}
            placeholder="Last Name"
          />
        </InputWrapper>
      </ContactWrapper>
      <ReusableButton
        $primary={true}
        name="Continue"
        onClick={handleClick}
      />

      <Remark>
        <img
          src={icons.info}
          alt="i-icon"
        />
        <p>
          A member of our team will use this information to contact you
          regarding your enquiry.
        </p>
      </Remark>
    </StepMain>
  );
};

export default Step6;
