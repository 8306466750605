import axios from "axios";

export const emailValidation = async (email: string) => {
  const key = process.env.REACT_APP_DATA8_KEY;

  const emailData = {
    email: email,
    level: "MX",
    options: {},
  };

  let result = await axios({
    method: "post",
    url: `https://webservices.data-8.co.uk/EmailValidation/IsValid.json?key=${key}`,
    data: emailData,
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((res) => {
      return res.data.Result === "Valid" ? true : false;
    })
    .catch((err) => {
      return err;
    });

  return result;
};
