import styled from "styled-components";
import { colors } from "../../../theme/colors";
import device from "../../../theme/devices";
import size from "../../../theme/devices/sizes";

export const Section = styled.section`
  background: ${colors.greenDark};
`;

interface HeroProps {
  type?: number;
  $img?: string;
  $mobileimg?: string;
  page?: string;
}

export const Container = styled.div<HeroProps>`
  display: flex;
  max-width: ${size.laptopL};
  margin: 0 auto;
  min-height: 571px;

  > img {
    max-width: 840px;

    @media ${device.laptopL} {
      display: none;
    }
  }

  @media ${device.mobileL} {
    min-height: ${({ page }) => (page === "community" ? "496px" : "625px")};
  }

  ${({ type, $img, $mobileimg }) =>
    type === 2 || type === 3
      ? `background-image: url(${$img});
      background-repeat: no-repeat;
      background-size: cover;
      @media ${device.mobileL}{
        background-image: url(${$mobileimg});
        background-position: top;
      }`
      : `@media ${device.laptopL} {
  background-image: url(${$mobileimg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
}`}
`;

export const Titles = styled.div<HeroProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 24px;
  margin: ${props => (props.type === 3 ? '0px 20% 0px' : '0px 56px 0px')};
  color: ${colors.white};

  > h1 {
    font-size: 56px;
    line-height: 72px;
    max-width: ${({ type }) => (type === 1 ? "600px" : "100%")};

    > span {
      font-family: "owners";
      color: ${colors.primary};
    }
  }

  > p {
    font-size: 16px;
    line-height: 32px;
    max-width: ${({ type }) => (type === 1 ? "600px" : "824px")};
  }

  @media ${device.laptopL} {
    padding: 125px 56px 183px;
    max-width: 100%;
  }

  @media ${device.mobileL} {
    padding: 125px 24px 67px;
    margin: 0;
    justify-content: flex-start;

    > h1 {
      font-size: 40px;
      line-height: 56px;
    }

    > p {
      font-size: 14px;
      line-height: 26px;
    }
  }
  
  @media (max-width: 1403px) {
      margin: unset;
    }
`;
