import { nonCorporateImgs } from "../../../assets/imgs/nonCorporate";
import EnquiryForm from "../../../component/Reusable/EnquiryForm";
import Offer from "../../../component/Reusable/Offer";
import NeedAdvice from "../../../component/Reusable/NeedAdvice";
import ReusableIntro from "../../../component/Reusable/ReusableIntro";
import ReusableHero from "../../../component/Reusable/ReusableHero";
import { offerData as corporateOfferData } from "../../CorporatePage/offerData";
import bgs from "../../../assets/bgs";

const Utilities = () => {
  const heroContents = {
    type: 2,
    title: <h1>Utilities</h1>,
    text: (
      <p>
        Commercial landlords do allow their tenants to provide their own utility
        providers, so getting the best deal is an important factor.
      </p>
    ),
    img: bgs.utilitiesHeroBg,
    mobileimg: bgs.utilitiesHeroBgMobile,
    button: true,
  };

  const introContents = {
    title: "Finding the best deal",
    text: (
      <p>
        You want to get the lowest prices for your business and try to avoid
        common pitfalls. By using a broker, you can save you a lot of time on
        hunting down individual companies as the brokers will know the markets
        back to front.
        <br />
        <br />
        Whether it’s getting the best deal for your energy or water, you’ll find
        that we can help facilitate it. Enquire now to speak to us about
        utilities for your commercial property.
      </p>
    ),
    img: nonCorporateImgs.utilitiesIntro,
    link: "/enquiry",
  };

  const offerContents = [
    corporateOfferData.mergersAcquisitions,
    corporateOfferData.accountants,
  ];

  return (
    <>
      <ReusableHero contents={heroContents} />
      <ReusableIntro contents={introContents} />
      <NeedAdvice />
      <Offer
        contents={offerContents}
        title="Similar Services"
      />
      <EnquiryForm />
    </>
  );
};

export default Utilities;
