import styled from "styled-components";
import device from "../../../../theme/devices";
import { colors } from "../../../../theme/colors";
import size from "../../../../theme/devices/sizes";

export const Section = styled.section`
  max-width: ${size.laptopL};
  margin: 0 auto;
`;

export const Container = styled.div`
  padding: 0px 120px 120px;
  display: flex;
  flex-direction: column;
  gap: 56px;

  @media ${device.laptop} {
    flex-direction: column;
  }

  @media ${device.tabletL} {
    padding: 120px 56px;
  }

  @media ${device.mobileL} {
    gap: 40px;
    padding: 0px 24px 88px;
  }
`;

export const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  max-width: 100%;
  gap: 16px;

  @media ${device.laptop} {
    flex-direction: column;
    align-items: flex-start;
    max-width: 100%;
  }
`;

export const Titles = styled.div`
  max-width: 824px;

  > h1 {
    font-size: 40px;
    line-height: 56px;
  }

  > p {
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 16px;
  }

  @media ${device.mobileL} {
    > h1 {
      font-size: 32px;
      line-height: 48px;
    }

    > p {
      font-size: 14px;
      line-height: 26px;
    }
  }
`;

export const Bottom = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 40px;

  @media ${device.laptopL} {
    flex-direction: column;
    gap: 24px;
  }
`;

interface QuestionProps {
  selected: boolean;
}

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const Wrapper = styled.div<QuestionProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 579px;
  padding-bottom: ${({ selected }) => selected && "24px"};
  border-bottom: ${({ selected }) => selected && "1px solid #e7ecee"};
  max-height: ${({ selected }) => (selected ? "900px" : "54px")};
  overflow: hidden;
  transition: 0.3s all linear;

  @media ${device.laptopL} {
    flex-direction: column;
    width: 100%;
  }
`;

export const Question = styled.div<QuestionProps>`
  display: flex;
  justify-content: space-between;
  background: ${({ selected }) =>
    selected ? `${colors.greenLight}` : "#e7ecee"};
  color: ${({ selected }) => selected && `${colors.white}`};
  padding: 16px 8px;
  cursor: pointer;

  > p {
    font-size: 16px;
    font-weight: 700;
  }

  @media ${device.mobileL} {
    > p {
      font-size: 14px;
      line-height: 28px;
    }
  }
`;

export const Answer = styled.div`
  > p {
    font-size: 16px;
    line-height: 28px;

    > span {
      font-weight: 700;
      color: ${colors.primary};
      text-decoraton: underline;
    }
  }

  @media ${device.mobileL} {
    font-size: 12px;
    line-height: 22px;
  }
`;
