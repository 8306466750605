import styled from "styled-components";
import size from "../../../theme/devices/sizes";
import device from "../../../theme/devices";

export const Section = styled.section`
  max-width: ${size.laptopL};
  margin: 0 auto;
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  padding: 0px 120px 120px;

  @media ${device.laptop} {
    flex-direction: column;

    > .first {
      a {
        display: none;
      }
    }

    > a {
      margin-top: 16px;
    }
  }

  @media ${device.tabletL} {
    padding: 88px 56px;
  }

  @media ${device.mobileL} {
    padding: 88px 24px;
  }
`;
