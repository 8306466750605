import ReusableButton from "../../Reusable/ReusableButton";
import size from "../../../theme/devices/sizes";
import useWindowSize, { Size } from "../../../utils/useWindowSize";
import { Section } from "../propertyReusable/ReusableExpanding/styles";
import { Container, Left, Right, Tip, TipTitle, Title } from "./styles";
import icons from "../../../assets/icons";

const Viewings = () => {
  const windowSize: Size = useWindowSize();
  const tabletL = +size.tabletL.slice(0, -2);

  return (
    <Section>
      <Container>
        <Left>
          <Title>
            <h1>Property Viewings & Making Offers</h1>
            {windowSize.width! > tabletL && (
              <a href="/enquiry">
                <ReusableButton
                  name="Enquire Now"
                  onClick={() => {}}
                />
              </a>
            )}
          </Title>
          {windowSize.width! > tabletL && (
            <Tip>
              <TipTitle>
                <img
                  src={icons.identifying5}
                  alt="light-bulb"
                />
                <h2>CTA Top Tip!</h2>
              </TipTitle>
              <p>
                Offers should be made for at least two properties to avoid any
                subsequent time pressures derived from unsuccessful
                negotiations.
              </p>
            </Tip>
          )}
        </Left>
        <Right>
          <p>
            Once clear search parameters have been established, prospective
            tenants will begin the process of viewing properties. This will
            generally consist of preliminary viewings, incorporating a wide
            variety of options, before narrowing down to a shortlist of 2-4
            properties.
            <br />
            <br />
            Check that the shortlisted properties can adequately accommodate any
            future fit-out, as poorly configured floorplates and columns can
            hinder the ideal workplace design.
            <br />
            <br />
            Additionally, it is crucial to appoint a consultant to undertake due
            diligence surveys to provide a full overview of the specifications
            and condition of expensive mechanical, electrical and plumbing
            services within the shortlisted properties.
            <br />
            <br />
            Once satisfied, formal offers can be drafted and agreed with the
            tenant representative. These will form what’s called the heads of
            terms for each property negotiation. These detail the key elements
            agreed between parties ‘subject to contract’, prior to the
            involvement of solicitors in drafting the lease.
          </p>
        </Right>
        {windowSize.width! <= tabletL && (
          <Tip>
            <TipTitle>
              <img
                src={icons.identifying5}
                alt="light-bulb"
              />
              <h2>CTA Top Tip!</h2>
            </TipTitle>
            <p>
              Offers should be made for at least two properties to avoid any
              subsequent time pressures derived from unsuccessful negotiations.
            </p>
          </Tip>
        )}
        {windowSize.width! <= tabletL && (
          <a href="/enquiry">
            <ReusableButton
              name="Enquire Now"
              onClick={() => {}}
            />
          </a>
        )}
      </Container>
    </Section>
  );
};

export default Viewings;
