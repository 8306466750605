import { useEffect, useState } from "react";
import icons from "../../../../assets/icons";
import ReusableButton from "../../../../component/Reusable/ReusableButton";
import {
  Container,
  InputsCtn,
  InputWrapper,
  Input,
  Bottom,
  Top,
  LoggedWrapper,
  LoginWrapper,
} from "./styles";
import { useNavigate } from "react-router-dom";
import { Notify } from "notiflix";
import login from "../../../../utils/login";
import { emailValidation } from "../../../../utils/email-validation";
import profile from "../../../../utils/profile";
import useWindowSize, { Size } from "../../../../utils/useWindowSize";
import size from "../../../../theme/devices/sizes";
import { useDispatch } from "react-redux";
import { updateLogin } from "../../../../redux/features/loginSlice";
import { useAppSelector } from "../../../../redux/hooks";

const Login = () => {
  const dispatch = useDispatch();
  const windowSize: Size = useWindowSize();
  const mobileL = +size.mobileL.slice(0, -2);
  const navigate = useNavigate();
  const [loginDetails, setLoginDetails] = useState<{ [key: string]: string }>({
    email: "",
    password: "",
  });
  const [show, setShow] = useState<{ [key: string]: boolean }>({
    email: false,
    password: false,
  });
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const loginState = useAppSelector((state) => state.login);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    setLoginDetails({ email: "", password: "" });
  }, [loginState]);

  useEffect(() => {
    const token = localStorage.getItem("token");

    const checkToken = async () => {
      const res = await profile(token);
      if (res.success) {
        dispatch(updateLogin(true));
      } else {
        localStorage.removeItem("token");
        dispatch(updateLogin(false));
      }
    };

    if (token) {
      checkToken();
    } else {
      dispatch(updateLogin(false));
    }
  }, [loginState]);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setLoginDetails((prev) => ({ ...prev, [name]: value }));
  };

  const handleFocus = (name: string) => {
    setShow((prev) => ({ ...prev, [name]: true }));
  };

  const handleBlur = (name: string) => {
    if (loginDetails[name].length === 0) {
      setShow((prev) => ({ ...prev, [name]: false }));
    }
  };

  const navigateWithScroll = (path: any, targetDivId: any) => {
    navigate(path);

    setTimeout(() => {
      const offset = windowSize.width! <= mobileL ? 96 : 0;
      scrollToDiv(targetDivId, -offset);
    }, 100);
  };

  const scrollToDiv = (targetDivId: any, yOffset: number = 0) => {
    const targetDiv = document.getElementById(targetDivId);

    if (targetDiv) {
      const rect = targetDiv.getBoundingClientRect();
      const topOffset = rect.top + window.scrollY;

      window.scrollTo({
        top: topOffset + yOffset,
        behavior: "smooth",
      });
    }
  };

  const handleForgotPassword = async () => {
    navigate("/forgot-password");
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    dispatch(updateLogin(false));
    Notify.success("You have logged out.");
  };

  const handleLogin = async () => {
    try {
      if (!loginDetails.email) {
        return Notify.failure("Please provide your email address.");
      }
      const emailRes = await emailValidation(loginDetails.email);
      if (!emailRes) {
        return Notify.failure("Please provide a valid email address.");
      }

      if (!loginDetails.email) {
        return Notify.failure("Please provide your password.");
      }

      const res = await login(loginDetails);

      if (res.success) {
        localStorage.setItem("token", res.token);
        dispatch(updateLogin(true));
      } else {
        Notify.failure(res.message);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };

  return (
    <Container>
      {loginState ? (
        <LoggedWrapper>
          <Top>
            <h1>Welcome Back!</h1>
            <p>You can now access all our guides.</p>
            <ReusableButton
              $primary={true}
              name="View Guides"
              onClick={() =>
                navigateWithScroll("/advice-support", "downloadable")
              }
            />
          </Top>
          <p onClick={handleLogout}>
            <strong>Logout</strong>
          </p>
        </LoggedWrapper>
      ) : (
        <LoginWrapper>
          <Top>
            <h1>Welcome Back!</h1>
            <InputsCtn>
              <InputWrapper $show={show.email}>
                <p>Email address</p>
                <img
                  src={icons.userIconBlue}
                  alt="user-icon"
                />
                <Input
                  name="email"
                  value={loginDetails.email}
                  onFocus={() => handleFocus("email")}
                  onBlur={() => handleBlur("email")}
                  $show={show.email}
                  placeholder="Email address"
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                />
              </InputWrapper>
              <InputWrapper $show={show.password}>
                <p>Password</p>
                <img
                  src={icons.lockBlue}
                  alt="lock"
                />
                <Input
                  name="password"
                  value={loginDetails.password}
                  onFocus={() => handleFocus("password")}
                  onBlur={() => handleBlur("password")}
                  type={showPassword ? "text" : "password"}
                  $show={show.password}
                  placeholder="Password"
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                />
                <img
                  id="visible"
                  src={icons.visible}
                  alt="visible"
                  onClick={() => setShowPassword(!showPassword)}
                />
              </InputWrapper>
            </InputsCtn>
            <ReusableButton
              $primary={true}
              name="Sign in"
              onClick={handleLogin}
              width="133px"
              height="50px"
            />
            <Bottom>
              <p onClick={handleForgotPassword}>
                <strong>Forgotten your password?</strong>
              </p>

              <div>
                <p>Don’t already have an account? </p>
                <a href="/sign-up">
                  <strong>Sign Up</strong>
                </a>
              </div>
            </Bottom>
          </Top>
        </LoginWrapper>
      )}
    </Container>
  );
};

export default Login;
