import styled from "styled-components";
import device from "../../theme/devices";

export const Main = styled.main`
  @media (min-width: 996px) {
    padding-top: 62px;
  }
`;

export const NewsWrapper = styled.div`
  padding: 120px;

  @media ${device.tabletL} {
    padding: 88px 56px;
  }

  @media ${device.mobileL} {
    padding: 88px 24px;
  }
`;
