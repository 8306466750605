import bgs from "../../../assets/bgs";
import Downloadable from "../../../component/Reusable/Downloadable";
import EnquiryForm from "../../../component/Reusable/EnquiryForm";
import Offer from "../../../component/Reusable/Offer";
import NeedAdvice from "../../../component/Reusable/NeedAdvice";
import ReusableHero from "../../../component/Reusable/ReusableHero";
import { offerData } from "../offerData";
import Impact from "../../../component/PropertyPage/Impact";

const LeaseRegearing = () => {
  const heroContents = {
    type: 2,
    title: <h1>Lease Re-Gearing</h1>,
    text: (
      <p>
        Re-gearing your lease can be a fantastic option for commercial tenants
        and landlords alike, as both parties can benefit from new lease terms in
        the middle of the contracted period.
      </p>
    ),
    img: bgs.leaseRegearingHeroBg,
    mobileimg: bgs.leaseRegearingHeroBgMobile,
    button: true,
  };

  const offerContents = [offerData.leaseNegotiations, offerData.leaseRenewals];

  return (
    <>
      <ReusableHero contents={heroContents} />
      <Impact />
      <Downloadable full={false} />
      <NeedAdvice />
      <Offer
        contents={offerContents}
        title="Similar Services"
      />
      <EnquiryForm />
    </>
  );
};

export default LeaseRegearing;
