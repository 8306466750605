import size from "../../../theme/devices/sizes";
import useWindowSize, { Size } from "../../../utils/useWindowSize";
import {
  Arrow,
  BoxWrapper,
  Container,
  Headers,
  IconDiv,
  Left,
  LeftTop,
  Logo,
  Right,
  Section,
} from "./styles";
import icons from "../../../assets/icons";
import { ReactComponent as Svg1 } from "../../../assets/icons/hands.svg";
import { ReactComponent as Svg2 } from "../../../assets/icons/shield.svg";
import { ReactComponent as Svg3 } from "../../../assets/icons/messages.svg";
import { ReactComponent as Svg4 } from "../../../assets/icons/peoples.svg";
import { ReactComponent as BackgroundSvg1 } from "../../../assets/icons/handsL.svg";
import { ReactComponent as BackgroundSvg2 } from "../../../assets/icons/shieldL.svg";
import { ReactComponent as BackgroundSvg4 } from "../../../assets/icons/peoplesL.svg";
import { useNavigate } from "react-router-dom";

interface LandingHeroProps {
  handleValuesScroll: () => void;
}

const LandingHero: React.FC<LandingHeroProps> = ({ handleValuesScroll }) => {
  const windowSize: Size = useWindowSize();
  const tabletL = +size.tabletL.slice(0, -2);
  const navigate = useNavigate();

  const boxContents = [
    {
      img: <Svg1 />,
      title: "Community",
      paragraph:
        "We represent the interests of over 900,000 commercial tenants in the UK, aiding in their commercial renting concerns.",
      background: <BackgroundSvg1 className="background" />,
      link: "/community",
    },
    {
      img: <Svg2 />,
      title: "Lobbying",
      paragraph:
        "Our expert team of advocates, specialists and industry experts are here to make sure your voice is heard.",
      background: <BackgroundSvg2 className="background" />,
      link: "/lobbying",
    },
    {
      img: <Svg3 />,
      title: "Need Help?",
      paragraph:
        "We’re here for you: see how we can help you with your commercial tenancy concerns.",
      background: "",
      link: "/enquiry",
    },
    {
      img: <Svg4 />,
      title: "Become a Member For Free",
      paragraph:
        "We’re spearheading change, but we can’t do it without you: be part of our revolution here!",
      background: <BackgroundSvg4 className="background" />,
      link: "/sign-up",
    },
  ];

  const handleClick = (link: string) => {
    navigate(link);
  };

  return (
    <Section>
      <Container>
        <Left>
          {windowSize.width! > tabletL && (
            <LeftTop>
              <Logo
                src={icons.logoNav}
                alt="logo"
              />
            </LeftTop>
          )}

          <Headers>
            <h1>
              The Leading Voice For <span>Commercial Tenants</span>
            </h1>
            <p>
              As part of the Commercial Tenants Association,{" "}
              <strong>
                you’ll receive quality representation and guaranteed support
              </strong>{" "}
              from the UK’s leading industry voice concerning commercial renting
              and leaseholds. Join our revolution for free today!
            </p>
          </Headers>
          {windowSize.width! > tabletL && (
            <Arrow
              src={icons.heroArrow}
              alt="logo"
              onClick={handleValuesScroll}
            />
          )}
        </Left>
        <Right>
          {boxContents.map((box, i) => {
            return (
              <BoxWrapper
                key={i}
                $num={i}
                onClick={() => handleClick(box.link)}
              >
                <IconDiv className="icon">{box.img}</IconDiv>

                <h1>{box.title}</h1>
                <p>{box.paragraph}</p>
                {box.background && box.background}
              </BoxWrapper>
            );
          })}
        </Right>
      </Container>
    </Section>
  );
};

export default LandingHero;
