import styled from "styled-components";
import size from "../../../theme/devices/sizes";
import device from "../../../theme/devices";

export const Section = styled.div`
  max-width: ${size.laptopL};
  margin: 0 auto;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 56px;
  padding: 0px 120px 120px;

  @media ${device.tabletL} {
    padding: 88px 56px;
  }

  @media ${device.tabletL} {
    padding: 88px 24px;
  }

  @media ${device.mobileL} {
    gap: 24px;
    padding: 48px 24px 88px;
  }
`;

export const Title = styled.h1`
  font-size: 40px;
  line-height: 56px;

  @media ${device.laptopL} {
    text-align: center;
  }

  @media ${device.mobileL} {
    font-size: 32px;
    line-height: 48px;
    text-align: left;
  }
`;

export const CardsCtn = styled.div`
  display: flex;
  gap: 56px;
  flex-wrap: wrap;
  justify-content: space-between;

  @media ${device.laptopL} {
    align-items: center;
    flex-direction: column;
  }

  @media ${device.mobileL} {
    gap: 40px;
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 559px;
  gap: 24px;

  > h2 {
    font-size: 24px;
    line-height: 32px;
  }

  > p {
    font-size: 16px;
    line-height: 28px;
  }

  @media ${device.mobileL} {
    width: 100%;
    gap: 8px;

    > h2 {
      font-size: 20px;
      line-height: 32px;
      margin-top: 8px;
    }

    > p {
      font-size: 14px;
      line-height: 36px;
      margin-bottom: 24px;
    }
  }
`;

export const Buttons = styled.div`
  font-size: 16px;
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 24px;
`;

export const EnquireBtn = styled.div`
  display: flex;
  gap: 16px;

  @media ${device.mobileL} {
    font-size: 14px;
  }
`;
