import bgs from "../../assets/bgs";
import imgs from "../../assets/imgs";
import Downloadable from "../../component/Reusable/Downloadable";
import EnquiryForm from "../../component/Reusable/EnquiryForm";
import FAQ from "../../component/AdvicePage/FAQ";
import Help from "../../component/Reusable/Help";
import Offer from "../../component/Reusable/Offer";
import Review from "../../component/Reusable/Review";
import ReusableHero from "../../component/Reusable/ReusableHero";
import { Main } from "./styles";
import ReusableIntro from "../../component/Reusable/ReusableIntro";

interface AdvicePageProps {
  adviceType: number;
}

const AdvicePage: React.FC<AdvicePageProps> = ({ adviceType }) => {
  const heroContents = {
    type: adviceType === 1 ? 1 : 2,
    title:
      adviceType === 1 ? (
        <h1>Advice & Support</h1>
      ) : (
        <h1>Specialist Advice & Support</h1>
      ),
    text:
      adviceType === 1 ? (
        <p>
          This section includes guides to lease-related negotiations and legal
          compliance. It also provides useful advice for business tenants who
          want to know more about occupying commercial real estate.
        </p>
      ) : (
        ""
      ),
    img: adviceType === 1 ? bgs.adviceHeroBg : bgs.specificAdviceHeroBg,
    mobileimg:
      adviceType === 1
        ? bgs.adviceHeroBgMobile
        : bgs.specificAdviceHeroBgMobile,
    button: true,
  };

  const offerContents = [
    {
      img: imgs.adviceOfferImg,
      title: "Specific Advice & Representation",
      text: "Speak to a member of our specialist team, and see how we can help advise and represent you depending on your business circumstances.",
      link: "/specific-advice",
    },
  ];

  const introContents = {
    title: "Do you need advice?",
    text: (
      <p>
        The CTA offers a free 30-minute consultation with a property
        professional to all of our members.
        <br />
        <br /> In many cases we can give you the advice you need to resolve your
        tenancy issue in an initial consultation.
        <br />
        <br /> Sometimes the issues are more complex, so the CTA may refer you
        to one of our Accredited Service Providers. However, if you would like
        the CTA to act on your behalf, we offer Members and Affiliates the
        following options:
      </p>
    ),
    img: imgs.needAdviceImg,
    link: "/enquiry",
  };

  return (
    <Main>
      <ReusableHero contents={heroContents} />
      {adviceType === 1 ? (
        <>
          <Offer
            title="What We Can Offer You"
            contents={offerContents}
          />
          <Downloadable
            id="downloadable"
            full={true}
          />
          <FAQ />
          <Review />
          <Help />
        </>
      ) : (
        <ReusableIntro contents={introContents} />
      )}

      <EnquiryForm />
    </Main>
  );
};

export default AdvicePage;
