import styled, { keyframes } from "styled-components";
import { colors } from "../../theme/colors";
import bgs from "../../assets/bgs";
import device from "../../theme/devices";
import size from "../../theme/devices/sizes";

const FadeIn = keyframes`
  0% {
    opacity: 0;
  } 
  100% {
    opacity: 1;
  }
`;

const ExpandAnimationMobile = keyframes`
  0% {
   height: 100%;
   min-height: 555px;
  }
  100% {
    height: 0%;
    min-height: 731px;
  }
`;

const ShrinkAnimation = keyframes`
  0% {
    flex: 1;
    width: 100%;
  }
  100% {
    flex: 0;
    width: 0%;
  }
`;

const ShrinkAnimationMobile = keyframes`
  0% {
    flex: 1;
    min-height: 534px;
  }
  100% {
    flex: 0;
    min-height: 0px;
  }
`;

const TickAnimation = keyframes`
  0% {
    opacity: 0;
    transform: scale(0);
  }
  70% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
  }
`;

const CircleAnimation = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

export const Main = styled.main`
  background-image: url(${bgs.donationPageBg});
  background-size: cover;
  background-repeat: no-repeat;

  @media ${device.tabletL} {
    background-image: unset;
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 119px 161px;
  max-width: ${size.laptopL};
  margin: 0 auto;

  @media ${device.laptopL} {
    padding: 119px 40px;
  }

  @media ${device.laptop} {
    padding: 119px 30px;
  }

  @media ${device.tabletL} {
    flex-direction: column;
    padding: 0;
    max-width: 100%;
  }
`;

export const LeftWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  transition: 0.5s ease-in-out;
  max-width: 550px;
`;

export const Left = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  flex: 1;
  padding: 48px 40px;
  background: rgba(0, 97, 104, 0.9);
  color: ${colors.white};
  height: 572px;
  width: 100%;
  max-width: 559px;
  border-bottom: 8px solid ${colors.primary};
  backdrop-filter: blur(5px);
  transition: 0.5s ease-in-out;

  > h1 {
    font-size: 40px;
    line-height: 56px;
  }

  > p {
    font-size: 16px;
    line-height: 32px;

    @media ${device.mobileL} {
      font-size: 14px;
      line-height: 26px;
    }
  }

  @media ${device.tabletL} {
    max-width: 100%;
    padding: 117px 24px 40px;
    height: 100%;
    border: none;
    background-image: url(${bgs.donationPageBgMobile});
  }
`;

export const Right = styled.div`
  flex: 1;
  background: ${colors.white};
  padding: 0px 40px;
  min-width: 559px;
  max-width: 559px;
  height: 572px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
  border-bottom: 8px solid ${colors.primary};

  @media ${device.tabletL} {
    min-width: unset;
    max-width: 100%;
    height: 100%;
    padding: 48px 24px 40px;
    min-height: 534px;
    justify-content: flex-start;
  }
`;

export const Title = styled.h1`
  font-size: 24px;
  line-height: 32px;
`;

export const OptionsCtn = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;

  @media ${device.tabletL} {
    justify-content: space-between;
    flex-wrap: wrap;
  }
`;

interface OptionProps {
  $selected: boolean;
}

export const OptionWrapper = styled.div<OptionProps>`
  position: relative;
  border: 2px solid
    ${({ $selected }) => ($selected ? `${colors.primary}` : `${colors.border}`)};
  border-radius: 4px;
  width: 149px;

  > img {
    display: ${({ $selected }) => ($selected ? "block" : "none")};
    cursor: pointer;
  }

  &:hover {
    border: 2px solid ${colors.primary};
    > img {
      display: block !important;
    }
  }

  @media ${device.tabletL} {
    width: 100%;
    flex: 1;
    min-width: 155px;
  }
`;

export const Option = styled.div`
  background: #f5f5f5;
  padding: 16px 0px;
  width: 100%;

  display: flex;
  justify-content: center;
  cursor: pointer;

  > p {
    font-size: 16px;
    font-weight: 700;
  }

  &:hover {
  }
`;

export const Circle = styled.img`
  position: absolute;
  display: none;
  bottom: -12px;
  left: 50%;
  transform: translateX(-50%);
`;

export const TickCircle = styled.img`
  position: absolute;
  display: none;
  bottom: -12px;
  left: 50%;
  transform: translateX(-50%);
  animation: 0.5s ${FadeIn} ease-out;
`;

interface AmountProps {
  $show: boolean;
}

export const AmountInputWrapper = styled.div<AmountProps>`
  position: relative;
  width: 100%;

  > p {
    ${({ $show }) => !$show && "display: none"};
    position: absolute;
    background: ${colors.white};
    top: -8px;
    left: 14px;
    z-index: 2;
    font-size: 12px;
    color: ${colors.primary};
    padding: 0px 5px;
  }

  > img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 14px;
  }

  > input {
    width: 100%;
    height: 50px;
    border: 2px solid
      ${({ $show }) => ($show ? `${colors.primary}` : `${colors.border}`)};
    padding-left: 36px;
    background: ${colors.white};
    outline: none;
  }
`;

export const TopAmountWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-bottom: 1px solid ${colors.border};
  padding-bottom: 8px;

  > div {
    display: flex;
    justify-content: space-between;
    width: 100%;

    > p {
      font-size: 16px;

      > span {
        color: ${colors.primary};
        font-weight: 700;
      }
    }
  }
`;

export const BillingInputsCtn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

export const MultiInputsWrapper = styled.div`
  display: flex;
  gap: 16px;

  @media ${device.mobileL} {
    flex-direction: column;
  }
`;

interface InputProps {
  $show: boolean;
}

export const InputWrapper = styled.div<InputProps>`
  position: relative;
  width: 100%;
  height: 50px;
  border: none;

  > img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 14px;
    cursor: pointer;
  }

  > p {
    position: absolute;
    background: ${colors.white};
    top: -8px;
    left: 14px;
    z-index: 2;
    font-size: 12px;
    color: ${colors.primary};
    padding: 0px 5px;
  }

  @media ${device.mobileL} {
    width: 100%;

    > p {
      font-size: 12px;
    }
  }
`;

export const InputField = styled.input<InputProps>`
  width: 100%;
  height: 100%;
  padding: 14px;
  font-size: 16px;
  background: ${colors.white};
  border: none;
  border: 2px solid
    ${({ $show }) => ($show ? `${colors.primary}` : `${colors.border}`)};

  &:focus {
    outline: none;
    border: 2px solid ${colors.primary};
    &::placeholder {
      opacity: 0;
    }
  }

  @media ${device.mobileL} {
    width: 100%;
    font-size: 14px;
  }
`;

export const Thankyou = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  padding: 48px 40px;
  background: rgba(0, 97, 104, 0.9);
  color: ${colors.white};
  min-width: 50%;
  width: 100%;
  height: 572px;
  border-bottom: 8px solid ${colors.primary};
  backdrop-filter: blur(5px);
  transition: 0.5s ease-in-out;

  > h1 {
    font-size: 40px;
    line-height: 56px;
  }

  > .thankyou {
    max-width: 672px;
    margin: 0 auto;
    text-align: center;
    animation: ${FadeIn} 2s forwards;
  }

  > p {
    font-size: 16px;
    line-height: 32px;

    @media ${device.mobileL} {
      font-size: 14px;
      line-height: 26px;
    }
  }

  > button {
    margin-top: 24px;
    align-self: center;
    animation: ${FadeIn} 2s forwards;
  }

  @media ${device.tabletL} {
    background-image: url(${bgs.donationPageBgMobile});
    background-size: cover;
    background-repeat: no-repeat;
    min-width: unset;
    max-width: 100%;
    padding: 137px 24px 40px;
    height: 100%;
    border: none;
    height: 100%;
    min-height: 555px;
    justify-content: flex-start;
    animation: ${ExpandAnimationMobile} 0.5s forwards 1.2s;
  }
`;

export const CircleCtn = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  background: ${colors.white};
  animation: ${ShrinkAnimation} 0.5s ease 1.2s forwards;

  @media ${device.tabletL} {
    width: 100%;
    height: 0%;
    min-height: 534px;
    animation: ${ShrinkAnimationMobile} 0.5s ease 1.2s forwards;
  }
`;

export const CircleWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  > .circle {
    position: absolute;
    width: 120px;
    animation: ${CircleAnimation} 0.1s ease 1s forwards;
  }

  > .tick {
    position: absolute;
    width: 120px;
    opacity: 0;
    transform: scale(0);
    animation: ${TickAnimation} 1s ease 0.5s forwards;
  }
`;

export const InputsCtn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

export const ErrorMsg = styled.p`
  color: #eb3c3c;
  font-size: 14px;
`;

export const PopPup = styled.div`
  position: absolute;
  top: -80%;
  right: 0;
  z-index: 4;
  min-width: 163px;
  max-width: 200px;
  border: 1px solid ${colors.primary};
  border-radius: 4px;
  background: #fff;
  padding: 8px;

  > p {
    font-size: 12px;
    color: ${colors.greenLight};
    text-align: right;
  }
`;
