import { configureStore } from "@reduxjs/toolkit";
import donationFormSlice from "./features/donationFormSlice";
import enquiryFormSlice from "./features/enquiryFormSlice";
import donationStepSlice from "./features/donationStepSlice";
import enquiryStepSlice from "./features/enquiryStepSlice";
import loginSlice from "./features/loginSlice";

const store = configureStore({
  reducer: {
    enquiryStep: enquiryStepSlice,
    donationStep: donationStepSlice,
    enquiryForm: enquiryFormSlice,
    donationForm: donationFormSlice,
    login: loginSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
