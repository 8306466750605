import bgs from "../../../assets/bgs";
import { propertyImgs } from "../../../assets/imgs/property";
import EnquiryForm from "../../../component/Reusable/EnquiryForm";
import Offer from "../../../component/Reusable/Offer";
import Challenging from "../../../component/PropertyPage/Challenging";
import ConsiderPoints from "../../../component/PropertyPage/ConsiderPoints";
import RatesIntro from "../../../component/PropertyPage/propertyReusable/RatesIntro";
import NeedAdvice from "../../../component/Reusable/NeedAdvice";
import ReusableHero from "../../../component/Reusable/ReusableHero";
import { offerData } from "../offerData";

const Rates = () => {
  const heroContents = {
    type: 2,
    title: <h1>Rates</h1>,
    text: (
      <p>
        Most non-domestic property will be liable for business rates, which
        essentially serve as an occupier’s tax. Consequently, if a business
        operates from land or buildings then they will have a liability to pay
        business rates for the area they <span>occupy.</span> Conversely, if a
        property is empty, then liability for rates will rest with whomever is
        entitled to possession.
      </p>
    ),
    img: bgs.ratesHeroBg,
    mobileimg: bgs.ratesHeroBgMobile,
    button: true,
  };
  const pointsContents = {
    page: "rate",
    title: "Rate Relief",
    subTitle:
      "In certain circumstances, the ratepayer is entitled to a discount on the business rates they pay:",
    points: [
      {
        title: "Empty Property",
        text: (
          <p>
            Business rates are not payable on buildings which are empty for a
            period of three months, however there are a number of scenarios when
            this period of empty rate relief can be extended. For example,
            charities may qualify for further exemption in certain
            circumstances.
          </p>
        ),
      },
      {
        title: "Enterprise Zones",
        text: (
          <p>
            Some business may be located in a designated Enterprise Zone, which
            may allow it to qualify for rates relief of up to 100%. The{" "}
            <span>Enterprise Zones</span> website will confirm whether the
            property is positioned within an area where this type of rates
            relief is possible.
          </p>
        ),
      },
      {
        title: "Charitable Organisations",
        text: (
          <p>
            Charitable businesses can apply for 80% rates relief. Not-for-profit
            organisations can also obtain rates relief, although this is
            discretionary in nature and decided locally by each council.
          </p>
        ),
      },
      {
        title: "Small Business Rate Relief",
        text: "Business rates are not payable on properties with a rateable value of less than £12,000 and discounts are available if the rateable value is listed at less than £15,000.",
      },
      <img
        src={propertyImgs.ratesPoints}
        alt="person"
      />,
      {
        title: "COVID-19",
        text: (
          <p>
            There are other forms of rates relief and grants which apply to
            certain businesses, particularly during the current pandemic.
            Retail, leisure and hospitality property, in particular, may fall
            into this category. It is recommended that tenants engage with a
            rating specialist at an early stage who will be able to advise them
            further.
          </p>
        ),
      },
    ],
  };

  const offerContents = [offerData.assignments, offerData.serviceCharge];

  return (
    <>
      <ReusableHero contents={heroContents} />
      <RatesIntro />
      <ConsiderPoints contents={pointsContents} />
      <Challenging />
      <NeedAdvice />
      <Offer
        contents={offerContents}
        title="Similar Services"
      />
      <EnquiryForm />
    </>
  );
};

export default Rates;
