import { ReactElement } from "react";
import { Main } from "./styles";
import Services from "./pages/Services";
import HRConsultants from "./pages/HRConsultants";
import Contractors from "./pages/Contractors";
import Shopfitters from "./pages/Shopfitters";
import Utilities from "./pages/Utilities";

const NonCorporatePage: React.FC = () => {
  const pathname = window.location.pathname.split("/");
  const pageName: string = pathname[pathname.length - 1];
  const pages: { [key: string]: ReactElement } = {
    services: <Services />,
    "hr-consultants": <HRConsultants />,
    contractors: <Contractors />,
    shopfitters: <Shopfitters />,
    utilities: <Utilities />,
  };

  return <Main>{pages[pageName]}</Main>;
};

export default NonCorporatePage;
