import { styled } from "styled-components";
import { colors } from "../../../theme/colors";
import device from "../../../theme/devices";

interface ButtonProps {
  $primary?: boolean;
  width?: string;
  height?: string;
  $mwidth?: string;
  color?: string;
  fontSize?: string;
  hover?: string;
}

export const Button = styled.button<ButtonProps>`
  width: ${({ width }) => width || "178px"};
  height: ${({ height }) => height || "50px"};
  font-size: ${({ fontSize }) => fontSize || "16px"};
  font-weight: 700;
  color: ${({ color }) => color || `${colors.greenDark}`};

  background: ${({ $primary }) =>
    $primary ? `${colors.primary}` : "rgba(35, 215, 229, 0.10);"};
  border-radius: 4px;
  border: ${({ $primary }) =>
    $primary ? "none" : `2px solid ${colors.primary}`};

  cursor: pointer;

  &:hover {
    background: ${({ hover }) => hover || `${colors.greenLight2}`};
    border: ${colors.greenLight2};
    color: ${colors.white};
  }

  @media ${device.mobileL} {
    width: ${({ $mwidth }) => $mwidth || "178px"};
    height: 47px;
    font-size: ${({ fontSize }) => fontSize || "14px"};
  }
`;
