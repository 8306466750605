import imgs from "../../assets/imgs";
import Articles from "../../component/Lobbying/Articles";
import Change from "../../component/Lobbying/Change";
import EnquiryForm from "../../component/Reusable/EnquiryForm";
import WhySection from "../../component/Lobbying/Why";
import ReusableHero from "../../component/Reusable/ReusableHero";
import { Main } from "./styles";
import bgs from "../../assets/bgs";

const LobbyingPage = () => {
  const heroContents = {
    type: 1,
    title: (
      <h1>
        Experts Lobbyists, Influencing Change With <span>You In Mind</span>
      </h1>
    ),
    text: (
      <p>
        The Commercial Tenants Association is proud to advocate and campaign for
        the commercial tenancy rights of over{" "}
        <strong>
          900,000 UK businesses.
          <br />
          <br />
        </strong>{" "}
        Our vision is to see{" "}
        <strong>positive legislative and nationwide change</strong> for
        commercial tenants, by directly addressing politicians and key decision
        makers alike. By doing this, we can express your concerns and see them
        implemented into law and <strong>future political action.</strong>
      </p>
    ),
    img: imgs.lobbyingHero,
    mobileimg: bgs.lobbyingHeroBgMobile,
    button: false,
  };
  return (
    <Main>
      <ReusableHero contents={heroContents} />
      <Change />
      <WhySection />
      {/* <Articles /> */}
      <EnquiryForm />
    </Main>
  );
};

export default LobbyingPage;
