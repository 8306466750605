import bgs from "../../assets/bgs";
import CommunityGroups from "../../component/CommunityPage/CommunityGroups";
import EnquiryForm from "../../component/Reusable/EnquiryForm";
import Help from "../../component/Reusable/Help";
import Mission from "../../component/Reusable/Mission";
import ReusableHero from "../../component/Reusable/ReusableHero";
import { Main } from "./styles";

const CommunityPage = () => {
  const contents = {
    type: 3,
    title: <h1>As A Revolution, We Are Stronger Together.</h1>,
    text: (
      <p>
        Meet like-minded individuals and fellow business owners through the
        Commercial Tenants Association: as a collective front, we believe
        greater change can be created for companies across the country.{" "}
      </p>
    ),
    img: bgs.communityHeroBg,
    mobileimg: bgs.communityHeroBgMobile,
    button: false,
    page: "community",
  };
  return (
    <Main>
      <ReusableHero contents={contents} />
      <CommunityGroups />
      <Mission page="community" />
      <Help />
      <EnquiryForm />
    </Main>
  );
};

export default CommunityPage;
