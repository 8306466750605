import styled from "styled-components";
import size from "../../../theme/devices/sizes";
import { colors } from "../../../theme/colors";
import device from "../../../theme/devices";
import bgs from "../../../assets/bgs";

export const Section = styled.section`
  max-width: ${size.laptopL};
  margin: 0 auto;
  padding: 0px 120px 120px;

  @media ${device.tabletL} {
    padding: 0px;
  }
`;

export const Container = styled.div`
  color: ${colors.white};
  background-image: url(${bgs.propertyNeedAdviceBg});
  background-size: cover;

  @media ${device.mobileL} {
    background-image: url(${bgs.propertyNeedAdviceBgMobile});
  }
`;

export const Paragraph = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 50%;
  padding: 173px 40px 145px;
  background: rgba(0, 38, 53, 0.8);
  backdrop-filter: blur(5px);

  > h1 {
    font-size: 40px;
    line-height: 56px;
  }

  > p {
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 24px;
  }

  @media ${device.tabletL} {
    width: 100%;
  }

  @media ${device.mobileL} {
    padding: 150px 24px;
    gap: 16px;

    > h1 {
      font-size: 32px;
      line-height: 48px;
    }

    > p {
      font-size: 14px;
      line-height: 26px;
      margin-bottom: 16px;
    }
  }
`;
