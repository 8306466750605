import { useEffect, useState } from "react";
import icons from "../../assets/icons";
import { newsImgs } from "../../assets/imgs/news";
import ReusableButton from "../../component/Reusable/ReusableButton";
import { Interweave } from "interweave";
import {
  Article,
  ArticleFooter,
  ArticleTop,
  Container,
  Header,
  Main,
  OtherArticle,
  OtherArticlesSection,
  OtherArticlesCtn,
  Right,
  Share,
  Title,
  TitleFooter,
  LoadingScreen,
} from "./styles";
import getArticle from "../../utils/getArticle";
import { useLocation, useNavigate } from "react-router-dom";
import { Loading } from "notiflix";
import formatDate from "../../utils/formatDate";
import { useParams } from "react-router-dom";
import getArticles from "../../utils/getArticles";
import DocumentMeta from "react-document-meta";

const NewsArticlePage = () => {
  const navigate = useNavigate();
  const [article, setArticle] = useState<any>({});
  const [otherArticles, setOtherArticles] = useState<any[]>([]);
  const [currentUrl, setCurrentUrl] = useState("");
  const [metaInfo, setMetaInfo] = useState({});

  useEffect(() => {
    setCurrentUrl(window.location.href);
  }, []);

  const params = useParams();

  useEffect(() => {
    const alias = params.id;
    Loading.standard();

    fetchArticle(alias!);
    fetchOtherArticles();
  }, [params]);

  const fetchArticle = async (alias: string) => {
    try {
      const res = await getArticle(alias);

      if (res.success) {
        const updatedArticle = res?.payload;
        const pattern = /<p>&nbsp;<\/p><br\s*\/><br\s*\/>/g;
        const updatedContent = updatedArticle.content[0].replace(pattern, "");
        updatedArticle.paragraph = updatedContent;
        setArticle(updatedArticle);

        setMetaInfo({
          title: updatedArticle.title,
          description: updatedArticle.caption,
          canonical: currentUrl,
          meta: {
            charset: "utf-8",
            name: {
              keywords: "cta, articles",
            },
            property: {
              "og:title": updatedArticle.title,
              "og:url": currentUrl,
              "og:image":
                process.env.REACT_APP_API + "/" + updatedArticle.Media.path,
              "og:description": updatedArticle.caption,

              "twitter:card": "summary_large_image",
              "twitter:title": updatedArticle.title,
              "twitter:description": updatedArticle.caption,
              "twitter:image":
                process.env.REACT_APP_API + "/" + updatedArticle.Media.path,
            },
          },
        });

        Loading.remove();
      }
    } catch (error) {
      Loading.remove();
      navigate("/404");
      console.error("Error fetching article", error);
    }
  };

  const fetchOtherArticles = async () => {
    try {
      const res = await getArticles("News", "", "");

      if (res.success) {
        if (res.payload.articles.length >= 2) {
          const randomIndices: any = [];

          while (randomIndices.length < 2) {
            const randomIndex = Math.floor(
              Math.random() * res.payload.articles.length,
            );
            if (!randomIndices.includes(randomIndex)) {
              randomIndices.push(randomIndex);
            }
          }

          const articlesArr = randomIndices.map(
            (index: number) => res.payload.articles[index],
          );

          articlesArr.forEach((article: any) => {
            const caption = article.caption;
            let truncatedCaption = caption;
            const maxLength = 400;
            if (caption && caption.length > maxLength) {
              truncatedCaption = `${caption.slice(0, maxLength)}...`;
            }
            article.caption = truncatedCaption;
          });
          setOtherArticles(articlesArr);
        }
      }
    } catch (error) {
      Loading.remove();
      console.error("Error fetching article", error);
    }
  };

  if (article.Media) {
    return (
      <Container>
        <DocumentMeta {...metaInfo} />
        <Header>
          <Title>
            <p>{article.Category.name}</p>
            <h1>{article.title}</h1>
          </Title>
        </Header>
        <Main>
          <Article>
            <TitleFooter>
              <p>
                {article.author
                  ? `Written by: ${(<strong>{article.author}</strong>)}`
                  : ""}
              </p>
              <p>{formatDate(article.publishedAt)}</p>
            </TitleFooter>
            <Share>
              <p>Share article:</p>

              <a
                href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                  currentUrl,
                )}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={icons.facebookColor}
                  alt="Facebook"
                />
              </a>
              <a
                href={`https://www.linkedin.com/shareArticle?url=${encodeURIComponent(
                  currentUrl,
                )}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={icons.linkedInColor}
                  alt="LinkedIn"
                />
              </a>
              <a
                href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(
                  currentUrl,
                )}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={icons.twitterColor}
                  alt="Twitter"
                />
              </a>
              <a
                href={`https://wa.me/?text=${encodeURIComponent(currentUrl)}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={icons.whatsappColor}
                  alt="WhatsApp"
                />
              </a>
              <a
                href={`mailto:?subject=${encodeURIComponent(
                  article.title,
                )}&body=${encodeURIComponent(currentUrl)}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={icons.emailColor}
                  alt="Email"
                />
              </a>
            </Share>
            <img
              src={`${process.env.REACT_APP_API}/${article.Media.path}`}
              alt="news"
            />
            {article && (
              <>
                <Interweave
                  className="caption"
                  content={article.caption}
                />
                <Interweave
                  className="content"
                  content={article.paragraph}
                />
              </>
            )}

            <TitleFooter>
              <p>
                {article.author
                  ? `Written by: ${(<strong>{article.author}</strong>)}`
                  : ""}
              </p>
              <p>{formatDate(article.publishedAt)}</p>
            </TitleFooter>
            <Share>
              <p>Share article:</p>
              <a
                href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                  currentUrl,
                )}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={icons.facebookColor}
                  alt="Facebook"
                />
              </a>
              <a
                href={`https://www.linkedin.com/shareArticle?url=${encodeURIComponent(
                  currentUrl,
                )}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={icons.linkedInColor}
                  alt="LinkedIn"
                />
              </a>
              <a
                href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(
                  currentUrl,
                )}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={icons.twitterColor}
                  alt="Twitter"
                />
              </a>
              <a
                href={`https://wa.me/?text=${encodeURIComponent(currentUrl)}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={icons.whatsappColor}
                  alt="WhatsApp"
                />
              </a>
              <a
                href={`mailto:?subject=${encodeURIComponent(
                  article.title,
                )}&body=${encodeURIComponent(currentUrl)}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={icons.emailColor}
                  alt="Email"
                />
              </a>
            </Share>
          </Article>
          {/* <Right>
            <h2>Help The Cause!</h2>
            <p>
              By pledging your support to the Commercial Tenants Association,
              you will be helping us provide essential training and advice to
              hundreds of thousands of businesses just like yours.
            </p>
            <ReusableButton
              $primary={true}
              name="Make A Donation"
              onClick={() => navigate(:/donate)}
            />
          </Right> */}
        </Main>
        <OtherArticlesSection>
          <h1>Other Articles</h1>
          <OtherArticlesCtn>
            {otherArticles.length > 0 &&
              otherArticles.map((article, i) => {
                return (
                  <OtherArticle
                    key={i}
                    onClick={() =>
                      window.open(`/insights/${article?.alias}`, "_blank")
                    }
                  >
                    <ArticleTop>
                      <img
                        src={`${process.env.REACT_APP_API}/${article.Media.path}`}
                        alt="news"
                      />
                      <h3>{article.title}</h3>
                      <Interweave content={article?.caption} />
                    </ArticleTop>
                    <ArticleFooter>
                      <p>{article.Category.name}</p>
                      <p>{formatDate(article.publishedAt)}</p>
                    </ArticleFooter>
                  </OtherArticle>
                );
              })}
          </OtherArticlesCtn>
        </OtherArticlesSection>
      </Container>
    );
  } else {
    return <LoadingScreen></LoadingScreen>;
  }
};

export default NewsArticlePage;
