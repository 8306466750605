import styled from "styled-components";
import device from "../../theme/devices";

export const Main = styled.main`
  @media (min-width: 996px) {
    padding-top: 62px;
  }

  > div:nth-child(2) {
    margin-top: 120px;

    @media ${device.tabletL} {
      margin-top: 0px;
    }
  }
`;
