import styled from "styled-components";
import size from "../../../theme/devices/sizes";
import { colors } from "../../../theme/colors";
import device from "../../../theme/devices";

export const Section = styled.section`
  position: relative;
  max-width: ${size.laptopL};
  margin: 0 auto;

  > img {
    position: absolute;
    z-index: -1;
    width: 477px;
    height: 477px;
  }

  @media ${device.tabletL} {
    > img {
      display: none;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  padding: 120px 120px;
  gap: 80px;

  @media ${device.laptop} {
    flex-direction: column;
  }

  @media ${device.tabletL} {
    padding: 88px 56px;
  }

  @media ${device.mobileL} {
    padding: 88px 24px;
    gap: 32px;
  }
`;

export const Left = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  > h1 {
    font-size: 40px;
    line-height: 56px;
  }

  p {
    font-size: 16px;
    font-weight: 300;
    line-height: 28px;
    margin-bottom: 16px;
  }
`;

export const Right = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;

  > a {
    margin-top: 12px;
  }

  @media ${device.mobileL} {
    > h1 {
      font-size: 32px;
      line-height: 40px;
    }
  }
`;

interface ItemProps {
  $background: string;
}

export const Item = styled.div<ItemProps>`
  background: ${({ $background }) => $background};
  ${({ $background }) =>
    $background === `${colors.greenLight2}`
      ? `border-bottom: 8px solid ${colors.primary}`
      : `box-shadow: 0px 10px 30px 0px rgba(7, 53, 71, 0.1);`};

  width: 559px;

  > h2 {
    color: ${({ $background }) =>
      $background === `${colors.greenLight2}`
        ? `${colors.white}`
        : `${colors.greenDark}`};
    font-size: 24px;
    line-height: 32px;
    padding: 40px;

    > span {
      color: ${colors.primary};
    }
  }

  @media ${device.laptop} {
    width: 100%;
  }

  @media ${device.mobileL} {
    > h2 {
      padding: 24px 16px;
    }
  }
`;
