import { propertyImgs } from "../../../assets/imgs/property";
import EnquiryForm from "../../../component/Reusable/EnquiryForm";
import Offer from "../../../component/Reusable/Offer";
import NeedAdvice from "../../../component/Reusable/NeedAdvice";
import ReusableIntro from "../../../component/Reusable/ReusableIntro";
import ReusableHero from "../../../component/Reusable/ReusableHero";
import { offerData } from "../offerData";
import bgs from "../../../assets/bgs";

const CapExSupport = () => {
  const heroContents = {
    type: 2,
    title: <h1>CapEx Support</h1>,
    text: (
      <p>
        Capex Support (capital expenditure support) is a type of funding used to
        maintain or upgrade an asset. This could be in the form of plants,
        buildings, technology or equipment within or surrounding a commercial
        property.
      </p>
    ),
    img: bgs.capExSupportHeroBg,
    mobileimg: bgs.capExSupportHeroBgMobile,
    button: true,
  };
  const introContents = {
    title: "How CapEx Works",
    text: (
      <p>
        CapEx is used often when undertaking new projects or investments by a
        business. Making capital expenditures on fixed assets includes things
        such as roofs, buying equipment or building a factory for one’s
        business. 
        <br />
        <br />
        Ultimately, CapEx is used to increase the scope of one’s business
        operations or add future economic benefits to the operation.
      </p>
    ),
    img: propertyImgs.capExSupportIntro,
    link: "/enquiry",
  };

  const offerContents = [offerData.arrears, offerData.dilapidation];

  return (
    <>
      <ReusableHero contents={heroContents} />
      <ReusableIntro contents={introContents} />
      <NeedAdvice />
      <Offer
        contents={offerContents}
        title="Similar Services"
      />
      <EnquiryForm />
    </>
  );
};

export default CapExSupport;
