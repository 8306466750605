import styled from "styled-components";
import { colors } from "../../theme/colors";
import size from "../../theme/devices/sizes";
import device from "../../theme/devices";
import { ReactComponent as ArrowLeft } from "../../assets/icons/arrowLeft.svg";
import { ReactComponent as ArrowRight } from "../../assets/icons/arrowRight.svg";

export const Header = styled.header`
  background: ${colors.greenDark};
  padding: 119px 0px 29px;
  color: ${colors.white};

  > h1 {
    text-align: center;
    font-size: 56px;
    line-height: 72px;
  }

  @media ${device.tabletL} {
    padding: 125px 24px 39px;
  }

  @media ${device.mobileL} {
    > h1 {
      font-size: 40px;
    }
  }
`;

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  gap: 80px;
  max-width: ${size.laptopL};
  margin: 0 auto;
  padding: 60px 120px;

  @media ${device.laptopL} {
    padding: 60px 56px;
  }

  @media ${device.mobileL} {
    padding: 60px 24px;
    gap: 32px;
  }
`;

export const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${colors.border};

  @media ${device.tabletL} {
    flex-direction: column;
    gap: 40px;
  }
`;

export const SearchWrapper = styled.div`
  position: relative;

  > img {
    position: absolute;
    left: 14px;
    top: 40%;
    transform: translateY(-50%);
  }

  @media ${device.tabletL} {
    order: 1;

    > img {
      top: 50%;
    }
  }
`;

export const SearchInput = styled.input`
  width: 379px;
  height: 50px;
  padding: 14px 14px 14px 36px;
  font-size: 16px;

  &::placeholder {
    color: ${colors.greenLight};
  }

  @media ${device.tabletL} {
    font-size: 14px;
    width: 100%;
    height: 47px;
  }

  @media ${device.mobileL} {
  }
`;

export const ArticleSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

interface PagesProps {
  $active: boolean;
}

export const PagesWrapper = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
  justify-content: center;

  > svg {
    cursor: pointer;
  }
`;

export const PageNumber = styled.p<PagesProps>`
  font-size: 24px;
  font-weight: 700;
  cursor: pointer;
  color: ${({ $active }) => $active && `${colors.border}`};

  @media ${device.mobileL} {
    font-size: 16px;
  }
`;

interface ArrowProps {
  $disable: boolean;
}

export const LeftArrow = styled(ArrowLeft)<ArrowProps>`
  path {
    fill: ${({ $disable }) => $disable && "#D3DADD"};
  }
`;

export const RightArrow = styled(ArrowRight)<ArrowProps>`
  path {
    fill: ${({ $disable }) => $disable && "#D3DADD"};
  }
`;

export const TopicsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media ${device.tabletL} {
    order: 2;
  }
`;

export const Topics = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  gap: 40px;

  @media (max-width: 700px) {
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-x: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  @media ${device.laptop} {
    gap: 0px;
  }

  @media ${device.tabletL} {
    justify-content: space-evenly;
  }
`;

interface TopicProps {
  $select: boolean;
}

export const Topic = styled.div<TopicProps>`
  display: flex;
  justify-content: center;
  width: 140px;

  white-space: nowrap;
  cursor: pointer;
  position: inline;
  color: ${({ $select }) => ($select ? `${colors.primary}` : "")};

  > h3 {
    font-size: 24px;
    line-height: 32px;
    padding: 16px 0px;
  }

  @media ${device.mobileL} {
    > h3 {
      font-size: 20px;
      padding: 8px;
    }
  }
`;

export const BorderWrapper = styled.div`
  position: relative;
  height: 1px;
  background: ${colors.border};
  width: 100%;
  max-width: 100%;
`;

interface BorderProps {
  $topic: number;
}

export const ActiveBorder = styled.div<BorderProps>`
  position: absolute;
  bottom: -1px;
  z-index: 2;
  max-width: 100%;
  height: 3px;
  width: 140px;
  background: ${colors.primary};
  transition: 0.2s all ease-in;

  ${({ $topic }) =>
    $topic === 2
      ? `left: calc(33.3% + 10px);`
      : $topic === 3
      ? `left: calc(100% - 140px);`
      : "left: 0;"}

  @media ${device.tabletL} {
    width: 33.3%;

    ${({ $topic }) =>
      $topic === 2
        ? `left: 50%; transform: translateX(-50%);`
        : $topic === 3
        ? `left: calc(100% - 33.3%);`
        : "left: 0;"}
  }
`;
