import { useEffect, useRef, useState } from "react";
import {
  CircleCtn,
  CircleWrapper,
  Container,
  Left,
  Main,
  Right,
  Thankyou,
} from "./styles";
import { useAppSelector } from "../../redux/hooks";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import icons from "../../assets/icons";
import ReusableButton from "../../component/Reusable/ReusableButton";
import { colors } from "../../theme/colors";
import { useNavigate } from "react-router-dom";
import useWindowSize, { Size } from "../../utils/useWindowSize";
import { useDispatch } from "react-redux";
import { updateDonationForm } from "../../redux/features/donationFormSlice";
import { updateDonationStep } from "../../redux/features/donationStepSlice";

const EnquiryForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const windowSize: Size = useWindowSize();
  const { step } = useAppSelector((state) => state.donationStep);
  const { pathname } = window.location;
  const [thankyou, setThankyou] = useState(false);
  const [changeTexts, setChangeTexts] = useState(false);
  const [failPayment, setFailPayment] = useState(false);

  const Steps = [<Step1 />, <Step2 />, <Step3 fail={failPayment} />];

  useEffect(() => {
    if (pathname.split("/")[2] === "failure") {
      setFailPayment(true);
      const amount = sessionStorage.getItem("amount");
      const billingInfo = sessionStorage.getItem("billingInfo");

      if (billingInfo && amount) {
        dispatch(updateDonationForm({ amount: JSON.parse(amount) }));
        dispatch(updateDonationForm(JSON.parse(billingInfo)));
        dispatch(updateDonationStep(3));
      } else {
        navigate("/");
      }
    } else {
      setFailPayment(false);
    }
  }, []);

  useEffect(() => {
    if (pathname.split("/")[2] === "thankyou") {
      setThankyou(true);
      sessionStorage.removeItem("amount");
      sessionStorage.removeItem("billingInfo");
    } else {
      setThankyou(false);
    }
  }, [pathname]);

  useEffect(() => {
    if (thankyou && windowSize.height! < 850 && windowSize.width! < 995) {
      window.scroll({
        top: 280,
        left: 0,
        behavior: "smooth",
      });
    } else if (pathname.split("/")[2] === "failure") {
      window.scroll({
        top: 575,
        left: 0,
        behavior: "smooth",
      });
    } else {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  }, [thankyou, pathname]);

  useEffect(() => {
    if (thankyou) {
      setTimeout(() => {
        setChangeTexts(true);
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }, 1200);
    }
  }, [thankyou]);

  return (
    <Main>
      <Container>
        {thankyou ? (
          <>
            <Thankyou>
              {changeTexts ? (
                <h1 className="thankyou">
                  Thank you so much for your donation!
                </h1>
              ) : (
                <h1>Donate Today & Support The CTA</h1>
              )}
              {changeTexts ? (
                <p className="thankyou">
                  Thank you for your donation in support of the CTA! Without
                  you, we wouldn’t be able to do accomplish any of the fantastic
                  work that we do.
                  <br />
                  <br />
                  This payment will appear as ‘Commercial Tenants Association’
                  on your next bank statement and transaction history.
                </p>
              ) : (
                <p>
                  In order to continue providing the fantastic services we offer
                  to thousands of businesses across the UK, we need your
                  support!
                  <br />
                  <br />
                  By pledging a donation, your funds will be used by our team to
                  launch legal battles, conduct investigations, as well as
                  provide essential training and support to businesses as part
                  of our mission to revolutionise the commercial lease and
                  renting situation in the UK.
                </p>
              )}
              {changeTexts && (
                <ReusableButton
                  $primary={false}
                  name="Return To Home"
                  color={colors.white}
                  onClick={() => navigate("/")}
                />
              )}
            </Thankyou>
            <CircleCtn>
              <CircleWrapper>
                <img
                  src={icons.circleBlue}
                  alt="circle"
                  className="circle"
                />
                <img
                  src={icons.circleTick}
                  alt="circle"
                  className="tick"
                />
              </CircleWrapper>
            </CircleCtn>
          </>
        ) : (
          <>
            <Left>
              <h1>Donate Today & Support The CTA</h1>
              <p>
                In order to continue providing the fantastic services we offer
                to thousands of businesses across the UK, we need your support!
                <br />
                <br />
                By pledging a donation, your funds will be used by our team to
                launch legal battles, conduct investigations, as well as provide
                essential training and support to businesses as part of our
                mission to revolutionise the commercial lease and renting
                situation in the UK.
              </p>
            </Left>
            <Right>{Steps[step - 1]}</Right>
          </>
        )}
      </Container>
    </Main>
  );
};

export default EnquiryForm;
