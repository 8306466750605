import styled from "styled-components";
import size from "../../../theme/devices/sizes";
import device from "../../../theme/devices";
import { colors } from "../../../theme/colors";

export const Section = styled.section`
  max-width: ${size.laptopL};
  margin: 0 auto;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 0px 120px 120px;

  @media ${device.laptopL} {
    padding: 0px 56px 120px;
  }

  @media ${device.mobileL} {
    padding: 0px 24px 88px;
  }
`;

export const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 20px;

  @media ${device.tabletL} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const Headers = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 824px;
  width: 100%;

  h1 {
    font-size: 40px;
    line-height: 56px;
  }

  p {
    font-size: 16px;
    line-height: 28px;
  }

  @media ${device.mobileL} {
    > h1 {
      font-size: 32px;
      line-height: 48px;
    }

    > p {
      font-size: 14px;
      line-height: 26px;
    }
  }
`;

export const Bottom = styled.div`
  display: flex;
  gap: 40px;
  max-width: 100%;
  overflow: hidden;

  @media ${device.laptop} {
    flex-direction: column;
  }
`;

interface CardProps {
  selected?: boolean;
}

export const CardWrapper = styled.div<CardProps>`
  display: flex;
  max-height: 366px;
  background: #e7ecee;
  transition: 0.3s all linear;

  > img {
    width: ${({ selected }) => (selected ? "265px" : "160px")};
    object-fit: cover;
    height: 366px;
    transition: 0.3s all linear;
    cursor: pointer;
  }

  @media ${device.laptop} {
    > img {
      width: ${({ selected }) => (selected ? "265px" : "265px")};
    }
  }

  @media ${device.mobileL} {
    flex-direction: column;
    max-width: 100%;
    max-height: ${({ selected }) => (selected ? "448px" : "96px")};
    background: #e7ecee;
    transition: 0.3s all linear;

    > img {
      width: 100%;
      height: ${({ selected }) => (selected ? "172px" : "96px")};
    }
  }
`;

export const TextWrapper = styled.div<CardProps>`
  max-width: ${({ selected }) => (selected ? "533px" : "0px")};
  overflow: hidden;
  transition: 0.5s all ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${device.laptop} {
    max-width: 100%;
  }

  @media ${device.mobileL} {
    max-width: ${({ selected }) => (selected ? "533px" : "0px")};
    ${({ selected }) =>
      selected
        ? `border-bottom: 8px solid ${colors.primary};
          transition: 0.1s border linear 0.2s;
    `
        : `border-bottom: 0px solid ${colors.primary};
          transition: 0.3s border linear 0.1s;
    `}
  }
`;

export const Texts = styled.div<CardProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  padding: 64px;
  opacity: ${({ selected }) => (selected ? "1" : "0")};
  transition: 0.5s linear;

  width: 100%;
  overflow: hidden;

  > img {
    transition: 0.2s opacity linear 0.1s;
    width: 49px;
  }

  > p {
    transition: 0.2s opacity linear;
    font-size: 16px;
    line-height: 32px;
    color: ${colors.greenLight};
    min-width: 405px;
    max-width: 405px;
  }

  @media ${device.laptopL} {
    padding: 42px;
  }

  @media ${device.laptop} {
    opacity: 1;

    > p {
      font-size: 14px;
      line-height: 26px;
      min-width: 100%;
      max-width: 100%;
    }
  }

  @media ${device.tabletL} {
    padding: 36px 16px;

    > img {
      width: 39px;
    }
  }
`;
