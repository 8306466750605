import {
  Article,
  ArticleFooter,
  ArticleTop,
  Container,
  ImgBackground,
  ImgWrapper,
  LargeNewsCtn,
  SmallNewsCtn,
} from "./styles";
import formatDate from "../../../utils/formatDate";
import { useNavigate } from "react-router-dom";
import { Interweave } from "interweave";

interface newsDataProps {
  news: any;
  $flip: boolean;
}

const ReusableNews: React.FC<newsDataProps> = ({ news, $flip }) => {
  const navigate = useNavigate();
  const largeNewsIndex = $flip ? news.length - 1 : 0;
  const maxLength = 400;

  const caption = news[largeNewsIndex]?.caption;
  let truncatedCaption;
  if (caption) {
    truncatedCaption = caption;
    if (caption.length > maxLength) {
      truncatedCaption = `${caption.slice(0, maxLength)}...`;
    }
  }

  return (
    <Container>
      <LargeNewsCtn $flip={$flip}>
        <Article
          size="large"
          onClick={() =>
            window.open(`/insights/${news[largeNewsIndex]?.alias}`, "_blank")
          }
        >
          <ArticleTop
            size="large"
            style={{ paddingBottom: "16px" }}
          >
            <ImgBackground>
              <img
                src={`${process.env.REACT_APP_API}/${news[largeNewsIndex]?.Media.path}`}
                alt="news"
              />
              <ImgWrapper>
                <img
                  src={`${process.env.REACT_APP_API}/${news[largeNewsIndex]?.Media.path}`}
                  alt="news"
                />
              </ImgWrapper>
            </ImgBackground>
            <h3>{news[largeNewsIndex]?.title}</h3>
            <Interweave content={truncatedCaption} />
          </ArticleTop>
          <ArticleFooter>
            <p>{news[largeNewsIndex]?.Category.name}</p>
            <p>{formatDate(news[largeNewsIndex]?.publishedAt)}</p>
          </ArticleFooter>
        </Article>
      </LargeNewsCtn>
      <SmallNewsCtn $flip={$flip}>
        {news.map((article: any, i: number) => {
          return (
            i !== largeNewsIndex && (
              <Article
                size="small"
                key={i}
                onClick={() =>
                  window.open(`/insights/${article.alias}`, "_blank")
                }
              >
                <ArticleTop size="small">
                  <ImgBackground size="small">
                    <img
                      src={`${process.env.REACT_APP_API}/${article.Media.path}`}
                      alt="news"
                    />
                    <ImgWrapper>
                      <img
                        src={`${process.env.REACT_APP_API}/${article.Media.path}`}
                        alt="news"
                      />
                    </ImgWrapper>
                  </ImgBackground>
                  <h3>{article.title}</h3>
                  <p>{article.text}</p>
                </ArticleTop>
                <ArticleFooter>
                  <p>{article.Category.name}</p>
                  <p>{formatDate(article.publishedAt)}</p>
                </ArticleFooter>
              </Article>
            )
          );
        })}
      </SmallNewsCtn>
    </Container>
  );
};

export default ReusableNews;
