import ReusableButton from "../../component/Reusable/ReusableButton";
import { Main } from "./styles";

const NotFoundPage = () => {
  return (
    <Main>
      <h1>404 Not Found</h1>
      <a href="/">
        <ReusableButton
          $primary={true}
          name="Back to Home"
          onClick={() => {}}
        />
      </a>
    </Main>
  );
};

export default NotFoundPage;
