import { Container, Item, ItemsCtn, Section } from "./styles";
import { ReactComponent as SVGIcon1 } from "../../../assets/icons/value1.svg";
import { ReactComponent as SVGIcon2 } from "../../../assets/icons/value2.svg";
import { ReactComponent as SVGIcon3 } from "../../../assets/icons/value3.svg";
import { ReactComponent as SVGIcon4 } from "../../../assets/icons/value4.svg";
import { ReactComponent as SVGIcon5 } from "../../../assets/icons/value5.svg";
import { useInView } from "react-intersection-observer";

interface ValuesProps {
  aboutPage?: boolean;
  scrollRef?: React.MutableRefObject<HTMLDivElement | null>;
}

const Values: React.FC<ValuesProps> = ({ aboutPage, scrollRef }) => {
  const { ref, inView } = useInView();
  const contents = [
    {
      img: <SVGIcon1 />,
      title: "Community",
      paragraph:
        "We believe in fostering a supportive community where commercial tenants can come together, share experiences, and find strength in unity.",
    },
    {
      img: <SVGIcon2 />,
      title: "Making A Difference",
      paragraph:
        "We are dedicated to making a positive impact in the lives of commercial tenants, striving to address the imbalances and challenges they face and working towards a fairer and more equitable business environment.",
    },
    {
      img: <SVGIcon3 />,
      title: "Integrity",
      paragraph:
        "We maintain rigorous standards in all our actions and interactions, ensuring that our advocacy and support for commercial tenants are grounded in integrity.",
    },
    {
      img: <SVGIcon4 />,
      title: "Fairness",
      paragraph:
        "We are committed to promoting fairness and justice for commercial tenants, fighting against practices that disadvantage them and working towards a system that respects their rights and interests.",
    },
    {
      img: <SVGIcon5 />,
      title: "Bold Thinking ",
      paragraph:
        "We embrace innovative and forward-thinking approaches to tackle complex issues, exploring creative solutions and challenging the status quo to drive meaningful change for commercial tenants.",
    },
  ];

  return (
    <Section ref={scrollRef}>
      <Container $enter={inView}>
        {!aboutPage && <h1>Our Values</h1>}
        <ItemsCtn ref={ref}>
          {contents.map((item, i) => {
            return (
              <Item
                key={i}
                $enter={inView}
              >
                {item.img}
                <h2>{item.title}</h2>
                <p>{item.paragraph}</p>
              </Item>
            );
          })}
        </ItemsCtn>
        {aboutPage && (
          <h2>
            Proudly supporting the interests of over{" "}
            <span>900,000 commercial tenants</span> every step of the way.
          </h2>
        )}
      </Container>
    </Section>
  );
};

export default Values;
