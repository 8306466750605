import axios from "axios";

const forgotPassword = async (data: any) => {
  try {
    const res = await axios.post(
      process.env.REACT_APP_API + "/forgot_pass",
      data,
    );

    return res.data;
  } catch (err: any) {
    console.log("Forgot Password Error: ", err);
    return err.response.data.message;
  }
};

export default forgotPassword;
