const formatDate = (dateString: string) => {
  const date = new Date(dateString);

  const getOrdinalSuffix = (day: any) => {
    if (day % 10 === 1 && day !== 11) return `${day}st`;
    if (day % 10 === 2 && day !== 12) return `${day}nd`;
    if (day % 10 === 3 && day !== 13) return `${day}rd`;
    return `${day}th`;
  };

  const formattedDate = `${getOrdinalSuffix(
    date.getDate()
  )} ${date.toLocaleString("default", {month: "long"})} ${date.getFullYear()}`;

  return formattedDate;
};

export default formatDate;
