import styled from "styled-components";
import { colors } from "../../../../theme/colors";
import device from "../../../../theme/devices";

export const Container = styled.div`
  flex: 1;
  background: ${colors.white};
  padding: 32px 48px 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 32px;
  max-width: 58%;

  > h1 {
    font-size: 32px;
    line-height: 48px;
  }

  p {
    color: ${colors.greenLight};
  }

  > img {
    width: 40px;
    height: 40px;
  }

  @media ${device.tabletL} {
    max-width: 100%;
  }

  @media ${device.mobileL} {
    padding: 40px 24px 32px;
    gap: 48px;

    > h1 {
      font-size: 24px;
      line-height: 32px;
    }

    > p {
      font-size: 14px;
    }

    > a {
      font-size: 12px;
    }
  }
`;

export const LoginWrapper = styled.div`
  padding-top: 80px;

  @media ${device.mobileL} {
    padding-top: 0px;
    width: 100%;
  }
`;

export const LoggedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding-top: 151px;

  > p {
    cursor: pointer;
  }

  @media ${device.tabletL} {
    padding-top: 10px;
    gap: 48px;
  }

  @media ${device.mobileL} {
    > p {
      font-size: 14px;
    }
  }
`;

export const Top = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;

  @media ${device.tabletL} {
    gap: 48px;
  }

  @media ${device.mobileL} {
    width: 100%;
  }
`;

export const InputsCtn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 479px;

  @media ${device.mobileL} {
    width: 100%;
  }
`;

interface InputProps {
  $show: boolean;
  $error?: boolean;
}

export const InputWrapper = styled.div<InputProps>`
  position: relative;
  display: flex;
  flex-direction: column;

  > img {
    position: absolute;
    top: 50%;
    left: 14px;
    transform: translateY(-50%);
  }

  > #visible {
    left: unset;
    right: 14px;
    cursor: pointer;
  }

  > p {
    ${({ $show }) => !$show && "display: none"};
    position: absolute;
    background: ${colors.white};
    top: -8px;
    left: 14px;
    z-index: 2;
    font-size: 12px;
    color: ${colors.primary};
    padding: 0px 5px;
  }

  > span {
    font-size: 14px;
    align-self: flex-end;
    color: #eb3c3c;
  }

  @media ${device.mobileL} {
    width: 100%;

    > span {
      font-size: 12px;
    }
  }
`;

export const Input = styled.input<InputProps>`
  width: 100%;
  height: 50px;
  border: none;
  border: 2px solid
    ${({ $show, $error }) =>
      $show ? `${colors.primary}` : $error ? "#EB3C3C" : `${colors.border}`};
  padding: 14px 14px 14px 40px;
  border-radius: 4px;
  font-size: 16px;
  background: ${colors.white};

  &:focus {
    outline: none;
    border: 2px solid ${colors.primary};
    &::placeholder {
      opacity: 0;
    }
  }

  &:-webkit-autofill {
    background-color: transparent !important;
    -webkit-box-shadow: 0 0 0 50px ${colors.white} inset;
  }

  @media ${device.mobileL} {
    font-size: 14px;
  }
`;

export const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 33px;

  > p {
    font-size: 16px;
    cursor: pointer;
  }

  > div {
    display: flex;
    gap: 8px;
    font-size: 16px;

    @media ${device.mobileL} {
      font-size: 14px;
    }
  }

  @media ${device.mobileL} {
    > p {
      font-size: 14px;
    }
  }
`;
