import { propertyImgs } from "../../../assets/imgs/property";
import Downloadable from "../../../component/Reusable/Downloadable";
import EnquiryForm from "../../../component/Reusable/EnquiryForm";
import Offer from "../../../component/Reusable/Offer";
import Act1954 from "../../../component/PropertyPage/Act1954";
import ConsiderPoints from "../../../component/PropertyPage/ConsiderPoints";
import CostConsiderations from "../../../component/PropertyPage/CostConsiderations";
import NeedAdvice from "../../../component/Reusable/NeedAdvice";
import ReusableIntro from "../../../component/Reusable/ReusableIntro";
import ReusableHero from "../../../component/Reusable/ReusableHero";
import { offerData } from "../offerData";
import bgs from "../../../assets/bgs";

const LeaseRenewals = () => {
  const heroContents = {
    type: 2,
    title: <h1>Lease Renewals</h1>,
    text: (
      <p>
        With the current trend towards shorter leases, it is likely that
        occupiers of commercial premises will need to consider renewing their
        lease at some point during the lifecycle of their business.
        <br />
        <br />
        Whilst changes in space and personnel requirements may mean that it is
        time to look for alternative premises at lease expiry, what happens when
        a tenant wants to stay and renew the lease?
        <br />
        <br />
        This introduction to the lease renewal process will look at some of the
        issues.
      </p>
    ),
    img: bgs.leaseRenewalsHeroBg,
    mobileimg: bgs.leaseRenewalsHeroBgMobile,
    button: true,
  };

  const introContents = {
    title: "Timing",
    text: (
      <p>
        It is imperative to check all dates in the lease and be aware of their
        significance, this includes the lease expiry date.
        <br />
        <br />
        It is not unusual to begin considering options 18-24 months prior to
        lease expiry, it will allow plenty of time to consider all the options.
        Consideration should be given as to whether the property is still
        suitable and as well as looking at alternative options for comparison.
        <br />
        <br />
        If this process begins too late and the business does need to relocate,
        there must be an overlap of the old and new leases in order to avoid
        disruption. The overlap is required to allow for fit-out works to
        complete in the new premises and{" "}
        <span>dilapidations/reinstatement</span> works to complete at the old.
      </p>
    ),
    img: propertyImgs.timing,
    link: "/enquiry",
  };

  const considerPointsContents = {
    page: "renewals",
    title: "Points To Consider",
    subTitle: "",
    points: [
      {
        title: "Compare",
        text: (
          <p>
            Is there a better deal elsewhere? Even if the alternatives are
            prohibitive because of the upfront costs required, being aware of
            the market is integral to understanding what can be achieved in
            lease renewal negotiations.
            <br />
            <br />
            It also may be advantageous if the current landlord believes that
            the tenant is serious about vacating the premises at the end of the
            lease and increase your negotiating strength.
          </p>
        ),
      },
      {
        title: "Negotiations",
        text: (
          <p>
            In appointing a tenant representative early, it is possible to
            navigate the renewal process without putting too much pressure on
            other business activities and hopefully achieve some preferential
            terms and flexibility in the process.
            <br />
            <br />
            Importantly, the quality and variety of landlord incentives are
            generally poorer when renewing a lease as compared to taking a new
            lease at a different property:
          </p>
        ),
      },
      <img
        src={propertyImgs.propertyPointsImg}
        alt="people"
      />,
      {
        title: "",
        text: (
          <ul>
            <li>
              <span>Negotiations for a new lease</span> are often marked by
              longer rent-free periods and greater fit-out contributions, both
              of which are designed to financially aid the tenant during the
              fit-out period
            </li>
            <br />
            <br />
            <li>
              Renewing a lease does not, in the landlord’s mind, tie-in with
              renewing the fit-out. Therefore, negotiations for a lease renewal
              tend to revolve a far reduced, or even non-existent, rent-free
              period or fit-out contribution.
            </li>
          </ul>
        ),
      },
    ],
  };

  const offerContents = [
    offerData.leaseRestructuring,
    offerData.leaseRegearing,
  ];

  return (
    <>
      <ReusableHero contents={heroContents} />
      <ReusableIntro contents={introContents} />
      <Act1954 />
      <ConsiderPoints contents={considerPointsContents} />
      <CostConsiderations />
      <Downloadable full={false} />
      <NeedAdvice />
      <Offer
        contents={offerContents}
        title="Similar Services"
      />
      <EnquiryForm />
    </>
  );
};

export default LeaseRenewals;
