import styled from "styled-components";
import device from "../../../theme/devices";
import { colors } from "../../../theme/colors";
import size from "../../../theme/devices/sizes";

export const Section = styled.div`
  position: relative;
  max-width: ${size.laptopL};
  margin: 0 auto;
`;

export const BackgroundBlock = styled.img`
  position: absolute;
  width: 477px;
  z-index: -1;

  @media ${device.laptop} {
    display: none;
  }
`;

export const Container = styled.div`
  display: flex;
  gap: 80px;
  padding: 120px 120px 0px;

  > img {
    max-width: 559px;
    max-height: 482px;
    margin: 0 auto;
  }

  @media ${device.laptop} {
    flex-direction: column;

    > img {
      width: 100%;
    }
  }

  @media ${device.tabletL} {
    padding: 88px 56px 0px;
  }

  @media ${device.mobileL} {
    padding: 88px 24px 0px;
    gap: 32px;

    > img {
      max-width: 327px;
    }
  }
`;

export const Paragraph = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 559px;
  margin: 0 auto;
  color: ${colors.greenDark};

  > h1 {
    font-size: 40px;
    line-height: 56px;
  }

  > p {
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 24px;
  }

  @media ${device.mobileL} {
    max-width: 100%;

    > h1 {
      font-size: 32px;
      line-height: 48px;
    }

    p {
      font-size: 14px;
      line-height: 26px;
      font-weight: 300;
    }
  }
`;
