import ReusableButton from "../../Reusable/ReusableButton";
import size from "../../../theme/devices/sizes";
import useWindowSize, { Size } from "../../../utils/useWindowSize";
import {
  Bottom,
  Container,
  Headers,
  ItemWrapper,
  Section,
  Top,
} from "./styles";
import icons from "../../../assets/icons";
import { useNavigate } from "react-router-dom";

const Benefits = () => {
  const windowSize: Size = useWindowSize();
  const tabletL = +size.tabletL.slice(0, -2);
  const mobileL = +size.mobileL.slice(0, -2);
  const navigate = useNavigate();

  return (
    <Section>
      <Container>
        <Top>
          <Headers>
            <h1>Benefits Of Joining The CTA</h1>
            {windowSize.width! > mobileL ? (
              <p>
                The CTA's vision is to make positive impact in three key areas:
              </p>
            ) : (
              <p>
                Over 900,000 lease and license holders across the UK entrust the
                CTA as their champion and leading voice for all of their
                commercial and renting-related concerns. See how the CTA can
                help you by joining us for free today!
              </p>
            )}
          </Headers>
          {windowSize.width! > tabletL && (
            <ReusableButton
              $primary={true}
              name="Join For Free"
              onClick={() => navigate("/sign-up")}
            />
          )}
        </Top>
        <Bottom>
          <ItemWrapper $num={1}>
            <img
              src={icons.benefits1}
              alt="icon"
            />
            <h1>Community</h1>
            <p>
              Connectivity is now <strong>more critical than ever</strong> : the
              CTA recognises the necessity to{" "}
              <strong>foster greater, healthier connections</strong> between
              businesses of all sizes. We will facilitate dialogue among
              regional and local businesses, enabling them to{" "}
              <strong>
                support one another on crucial tenant-related issues.
              </strong>
            </p>
          </ItemWrapper>
          <ItemWrapper $num={2}>
            <img
              src={icons.benefits2}
              alt="icon"
            />
            <h1>Lobbying</h1>
            <p>
              From <strong>abolishing upward-only rent reviews,</strong> to
              advocating for a <strong>comprehensive review</strong> of the
              Landlord Tenant Act, the CTA aims to influence all major political
              parties to review their manifesto and future political action in
              order to <strong>greater benefit</strong> our members and
              Commercial Tenants alike.
            </p>
          </ItemWrapper>
          <ItemWrapper $num={3}>
            <img
              src={icons.benefits3}
              alt="icon"
            />
            <h1>Support</h1>
            <p>
              Commercial tenants can turn to the CTA for{" "}
              <strong>guidance on critical matters</strong> such as rent
              reviews, dilapidations, evictions and disputes. We will establish
              a triage service to <strong>ensure immediate attention</strong> to
              your concerns. We have enlisted corporate sponsors to not only
              fund this support, but also{" "}
              <strong>dedicate their time and expertise</strong> to those in
              need.
            </p>
          </ItemWrapper>
        </Bottom>
        {windowSize.width! <= tabletL && (
          <ReusableButton
            $primary={true}
            name="Join For Free"
            onClick={() => navigate("/sign-up")}
          />
        )}
      </Container>
    </Section>
  );
};

export default Benefits;
