import { corporateImgs } from "../../../assets/imgs/corporate";
import EnquiryForm from "../../../component/Reusable/EnquiryForm";
import Help from "../../../component/Reusable/Help";
import Offer from "../../../component/Reusable/Offer";
import ThreePoints from "../../../component/Reusable/ThreePoints";
import ReusableHero from "../../../component/Reusable/ReusableHero";
import { offerData } from "../offerData";
import bgs from "../../../assets/bgs";

const Services = () => {
  const heroContents = {
    type: 1,
    title: <h1>Corporate Services</h1>,
    text: (
      <p>
        This section provides Members information and guidance in the critical
        area of compliance and examines the legal obligations involved in
        occupying commercial property.
      </p>
    ),
    img: corporateImgs.commercialServicesHero,
    mobileimg: bgs.corporateServicesHeroBgMobile,
    button: true,
  };

  const offerContents = Object.values(offerData).filter(
    (el) =>
      el.title !== "Business Rates" &&
      el.title !== "Dispute Resolution" &&
      el.title !== "Tenant Compliance",
  );

  return (
    <>
      <ReusableHero contents={heroContents} />
      <ThreePoints />
      <Offer
        title="Similar Services"
        contents={offerContents}
      />
      <Help />
      <EnquiryForm />
    </>
  );
};

export default Services;
