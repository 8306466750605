import servicesHero from "./servicesHero.png";
import contractorsIntro from "./contractorsIntro.png";
import hrConsultantsIntro from "./hrConsultantsIntro.png";
import nonCorporateOffer1 from "./nonCorporateOffer1.png";
import nonCorporateOffer2 from "./nonCorporateOffer2.png";
import nonCorporateOffer3 from "./nonCorporateOffer3.png";
import nonCorporateOffer4 from "./nonCorporateOffer4.png";
import shopfittersIntro from "./shopfittersIntro.png";
import utilitiesIntro from "./utilitiesIntro.png";
import nonCorporateOffer5 from "./nonCorporateOffer5.png";
import nonCorporateOffer6 from "./nonCorporateOffer6.png";

export const nonCorporateImgs = {
  servicesHero,
  contractorsIntro,
  hrConsultantsIntro,
  nonCorporateOffer1,
  nonCorporateOffer2,
  nonCorporateOffer3,
  nonCorporateOffer4,
  shopfittersIntro,
  utilitiesIntro,
  nonCorporateOffer5,
  nonCorporateOffer6,
};
