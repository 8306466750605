import styled from "styled-components";
import device from "../../../theme/devices";
import size from "../../../theme/devices/sizes";
import bgs from "../../../assets/bgs";

export const Section = styled.section`
  max-width: ${size.laptopL};
  margin: 0 auto;
  background-image: url(${bgs.benefitsBg});
  background-size: 947px 568px;
  background-repeat: no-repeat;
  background-position: 100%;

  @media ${device.mobileL} {
    background-image: url(${bgs.benefitsBgMobile});
    background-size: contain;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 72px 120px;

  @media ${device.tabletL} {
    padding: 0px 48px 88px;
  }

  @media ${device.mobileL} {
    padding: 0px 24px 88px;
  }
`;

export const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 20px;
`;

export const Headers = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 824px;
  width: 100%;

  h1 {
    font-size: 40px;
    line-height: 56px;
  }

  p {
    font-size: 16px;
    line-height: 28px;
  }

  @media ${device.mobileL} {
    h1 {
      font-size: 32px;
      line-height: 48px;
    }

    p {
      font-size: 14px;
      line-height: 26px;
    }
  }
`;

export const Bottom = styled.div`
  display: flex;
  gap: 39.5px;

  @media ${device.tabletL} {
    flex-direction: column;
  }
`;

interface IconsProps {
  $num: number;
}

export const ItemWrapper = styled.div<IconsProps>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 373px;

  > img {
    width: ${({ $num }) =>
      $num === 1 ? "56px" : $num === 2 ? "32px" : "40px"};
    height: ${({ $num }) =>
      $num === 1 ? "56px" : $num === 2 ? "40px" : "40px"};
  }

  > h1 {
    font-size: 24px;
    line-height: 32px;
  }

  p {
    font-size: 16px;
    line-height: 28px;
  }

  @media ${device.tabletL} {
    max-width: 100%;

    > img {
      width: ${({ $num }) =>
        $num === 1 ? "48px" : $num === 2 ? "26px" : "32px"};
      height: ${({ $num }) =>
        $num === 1 ? "48px" : $num === 2 ? "32px" : "32px"};
    }

    > h1 {
      font-size: 20px;
    }

    p {
      font-size: 14px;
      line-height: 26px;
    }
  }
`;
