import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface FormState {
  amount: number | null;
  firstname: string;
  lastname: string;
  email: string;
  addressLineOne: string;
  addressLineTwo: string;
  city: string;
  postcode: string;
  card_number: string;
  card_exp_month: string;
  card_exp_year: number | null;
  card_cvc: string;
}

const initialState: FormState = {
  amount: null,
  firstname: "",
  lastname: "",
  email: "",
  addressLineOne: "",
  addressLineTwo: "",
  city: "",
  postcode: "",
  card_number: "",
  card_exp_month: "",
  card_exp_year: null,
  card_cvc: "",
};

const donationFormSlice = createSlice({
  name: "donationForm",
  initialState,
  reducers: {
    updateDonationForm: (state, action: PayloadAction<Partial<FormState>>) => {
      return (state = { ...state, ...action.payload });
    },
    resetDonationForm: () => {
      return initialState;
    },
  },
});

export const { updateDonationForm, resetDonationForm } =
  donationFormSlice.actions;
export default donationFormSlice.reducer;
