import { useNavigate } from "react-router-dom";
import ReusableButton from "../ReusableButton";
import { Container, Paragraph, Section } from "./styles";

interface ReusableIntroProps {
  contents: {
    title: string;
    text: React.ReactNode;
    img: string;
    link: string;
    leftTitle?: string;
    bgBlock?: string;
    $flipOrder?: boolean;
  };
}

const ReusableIntro: React.FC<ReusableIntroProps> = ({ contents }) => {
  const navigate = useNavigate();

  return (
    <Section>
      {contents.bgBlock && (
        <img
          src={contents.bgBlock}
          alt="block"
        />
      )}
      <Container $flipOrder={contents.$flipOrder ? true : false}>
        {contents.img.length > 0 ? (
          <img
            src={contents.img}
            alt="working"
          />
        ) : (
          <h1>{contents.leftTitle}</h1>
        )}
        <Paragraph $flipOrder={contents.$flipOrder ? true : false}>
          <h1>{contents.title.length > 0 && contents.title}</h1>
          {contents.text}
          <a href={contents.link}>
            <ReusableButton
              name="Enquire Now"
              onClick={() => {}}
            />
          </a>
        </Paragraph>
      </Container>
    </Section>
  );
};

export default ReusableIntro;
