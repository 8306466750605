import ReusableButton from "../ReusableButton";
import { colors } from "../../../theme/colors";
import { Container, Paragraph, Section } from "./styles";

const NeedAdvice = () => {
  return (
    <Section>
      <Container>
        <Paragraph>
          <h1>Need specific advice?</h1>
          <p>
            If you require specific advice on your outstanding rent debt, or any
            other issue relating to your commercial tenancy, the CTA offer an
            expert advisory service.
          </p>
          <a href="/specific-advice">
            <ReusableButton
              name="Learn More"
              onClick={() => {}}
              color={colors.white}
            />
          </a>
        </Paragraph>
      </Container>
    </Section>
  );
};

export default NeedAdvice;
