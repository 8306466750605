import { useState } from "react";
import ReusableButton from "../ReusableButton";
import {
  Bottom,
  CardWrapper,
  Container,
  Headers,
  Section,
  Texts,
  Top,
  TextWrapper,
} from "./styles";
import imgs from "../../../assets/imgs";
import useWindowSize, { Size } from "../../../utils/useWindowSize";
import size from "../../../theme/devices/sizes";
import icons from "../../../assets/icons";
import { useNavigate } from "react-router-dom";

const Review = () => {
  const windowSize: Size = useWindowSize();
  const mobileL = +size.mobileL.slice(0, -2);
  const navigate = useNavigate();
  const [show, setShow] = useState({
    one: true,
    two: false,
    three: false,
  });

  const handleClick = (card: string) => {
    setShow({
      one: false,
      two: false,
      three: false,
    });
    setShow((prev) => ({ ...prev, [card]: true }));
  };

  return (
    <Section>
      <Container>
        <Top>
          <Headers>
            <h1>Don’t Just Take Our Word For It...</h1>
            <p>
              Read about how the CTA has helped business owners and commercial
              tenants spanning a wide range of industries in helping them
              challenge, negotiate, and acquire a fairer commercial tenancy
              option for their business.
            </p>
          </Headers>
          <ReusableButton
            name="Enquire Now"
            onClick={() => navigate("/enquiry")}
          />
        </Top>
        <Bottom>
          <CardWrapper
            selected={show.one}
            onClick={() => handleClick("one")}
          >
            {windowSize.width! > mobileL ? (
              <img
                src={imgs.review1}
                alt="person"
              />
            ) : (
              <img
                src={imgs.reviewMobile1}
                alt="person"
              />
            )}
            <TextWrapper selected={show.one}>
              <Texts selected={show.one}>
                <img
                  src={icons.apostrophes}
                  alt="person"
                />
                <p>
                  “Kieran and Fabian in particular have been perfect advisers to
                  us, and the advice they have relayed to us regarding our
                  current leasing situation has been very helpful in our future
                  business decisions and strategy.”
                </p>
                <p>
                  <strong>Proudly Representing You</strong>
                </p>
              </Texts>
            </TextWrapper>
          </CardWrapper>
          <CardWrapper
            selected={show.two}
            onClick={() => handleClick("two")}
          >
            {windowSize.width! > mobileL ? (
              <img
                src={imgs.review2}
                alt="person"
              />
            ) : (
              <img
                src={imgs.reviewMobile2}
                alt="person"
              />
            )}
            <TextWrapper selected={show.two}>
              <Texts selected={show.two}>
                <img
                  src={icons.apostrophes}
                  alt="person"
                />
                <p>
                  “The opportunities and support that our team have received as
                  a member of the Commercial Tenants Association have been truly
                  second-to-none. We’re immensely grateful for all that they’ve
                  {/* done so far.” Incognito Industries */}
                </p>
                <p>
                  {/* <strong>Incognito Industries</strong> */}
                </p>
              </Texts>
            </TextWrapper>
          </CardWrapper>
          <CardWrapper
            selected={show.three}
            onClick={() => handleClick("three")}
          >
            {windowSize.width! > mobileL ? (
              <img
                src={imgs.review3}
                alt="person"
              />
            ) : (
              <img
                src={imgs.reviewMobile3}
                alt="person"
              />
            )}
            <TextWrapper selected={show.three}>
              <Texts selected={show.three}>
                <img
                  src={icons.apostrophes}
                  alt="person"
                />
                <p>
                  “Kieran and Fabian in particular have been perfect advisers to
                  us, and the advice they have relayed to us regarding our
                  current leasing situation has been very helpful in our future
                  business decisions and strategy.”
                </p>
                <p>
                  <strong>Proudly Representing You</strong>
                </p>
              </Texts>
            </TextWrapper>
          </CardWrapper>
        </Bottom>
      </Container>
    </Section>
  );
};

export default Review;
