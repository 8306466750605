import ReusableButton from "../ReusableButton";
import { Container } from "./styles";

interface ParagraphProps {
  contents: any;
}

const Paragraph: React.FC<ParagraphProps> = ({ contents }) => {
  return (
    <Container>
      {contents.title.length > 0 && <h1>{contents.title}</h1>}
      {contents.text}
      {contents.button && (
        <a href="/enquiry">
          <ReusableButton
            name="Enquire Now"
            onClick={() => {}}
          />
        </a>
      )}
    </Container>
  );
};

export default Paragraph;
