import styled from "styled-components";
import size from "../../theme/devices/sizes";
import { colors } from "../../theme/colors";
import device from "../../theme/devices";

interface SectionProps {
    $burger: boolean;
    $hide: boolean;
    $showBg: boolean;
}

export const Section = styled.div<SectionProps>`
    ${({ $hide }) => !$hide && "display: none;"}
    background: ${colors.greenDark};
    position: fixed;
    top: 0;
    width: 100%;
    // z-index: ${({ $burger }) => ($burger ? "21" : "4")};
    z-index: 13;

    @media ${device.tabletL} {
        position: fixed;
        background: ${({ $showBg }) =>
            $showBg ? `${colors.greenDark}` : "transparent"};
    }
`;

export const Container = styled.div`
    max-width: ${size.laptopL};
    margin: 0 auto;
    padding: 0px 56px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: ${colors.white};
    position: relative;

    a:link,
    a:visited,
    a:hover,
    a:active {
        text-decoration: none;
    }

    @media ${device.tabletL} {
        max-width: 100%;
        width: 100%;
        padding: 40px 24px 16px;
    }
`;

export const Logo = styled.img`
    width: 40px;
    height: 40px;
`;

export const MobileHeader = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;

    > a {
        width: 40px;
        height: 40px;
    }

    > p {
        border-left: 1px solid ${colors.greenLight};
        padding-left: 8px;
        font-size: 12px;
    }
`;

export const Center = styled.div`
    display: flex;
`;

export const NavItem = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 20px;
    white-space: nowrap;
    cursor: pointer;

    img {
        width: 8px;
        height: 4px;
    }

    &:hover {
        background: ${colors.greenLight2};

        p {
            color: ${colors.primary};
        }

        &.enquiry-item {
            background: transparent;
        }
    }

    @media ${device.laptop} {
        padding: 20px 8px;
    }

    .enquiry {
        border-radius: 4px;
        background: ${colors.greenLight2};
        padding: 8px 16px;
        border: 1px solid transparent;

        &:hover {
            border-color: ${colors.primary};
            background: ${colors.greenLight2};
        }
    }
`;

export const Right = styled.div`
    position: relative;
    display: flex;
    gap: 16px;
    justify-content: flex-end;
    align-items: center;
    width: fit-content;
    overflow: hidden;

    > img {
        width: 14px;
        height: 14px;
    }

    @media ${device.tabletL} {
        width: unset;
    }
`;

export const DonateBtn = styled.button`
    background: ${colors.greenLight2};
    color: ${colors.white};
    border: none;
    width: 82px;
    height: 35px;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
        border: 1px solid ${colors.primary};
        color: ${colors.primary};
    }

    @media ${device.tabletL} {
        width: 155px;
        height: 47px;
    }
`;

interface DropdownProps {
    $hover: boolean;
}

export const DropDownCtn = styled.div<DropdownProps>`
    display: ${({ $hover }) => ($hover ? "block" : "none")};
    position: absolute;
    width: 100vw;
    height: 100vh;
    z-index: -1;
    background: rgba(0, 0, 0, 0.5);
`;

export const DropdownWrapper = styled.div<DropdownProps>`
    display: ${({ $hover }) => ($hover ? "flex" : "none")};
    position: absolute;
    justify-content: space-between;
    background: ${colors.white};
    color: ${colors.greenDark};
    top: 100%;
    z-index: 20;

    @media ${device.laptop} {
        left: 40px;
    }
`;

export const DropdownInner = styled.div`
    display: flex;
    gap: 40px;
    padding: 32px;

    @media ${device.laptop} {
        gap: 20px;
    }
`;

export const DropdownItem = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 286px;

    > h4 {
        font-size: 18px;
    }

    > p {
        font-size: 12px;
        line0-height: 22px;
        color: #657a85;
    }
`;

export const AdviceDropdownTitle = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    > svg {
        width: 8px;
    }

    h4 {
        cursor: pointer;
    }

    &:hover {
        color: ${colors.primary};

        > svg {
            path {
                fill: ${colors.primary};
            }
        }
    }
`;

export const ServicesDropdownTitle = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid ${colors.border};
    padding-bottom: 16px;
    margin-bottom: 8px;

    > svg {
        width: 8px;
    }

    h4 {
        cursor: pointer;
    }

    &:hover {
        color: ${colors.primary};

        > svg {
            path {
                fill: ${colors.primary};
            }
        }
    }
`;

export const DropdownList = styled.ul`
    list-style: none;

    > li {
        font-size: 14px;
        padding-bottom: 8px;
        cursor: pointer;

        &:hover {
            color: ${colors.primary};
        }
    }
`;

interface MenuProps {
    $open: boolean;
    $top?: number;
}

export const BurgerWrapper = styled.div<MenuProps>`
    position: relative;
    width: 16px;
    height: 16px;
    cursor: pointer;
`;

export const BurgerOne = styled.img<MenuProps>`
    position: absolute;
    top: 0;
    opacity: ${({ $open }) => ($open ? "0" : "1")};
    transition: opacity 0.3s ease;
`;
export const BurgerTwo = styled.img<MenuProps>`
    position: absolute;
    top: 50%;
    transform: ${({ $open }) =>
        $open
            ? "rotate(42deg) translateX(0.2px) translateY(0.5px) "
            : "translateY(-50%)"};
    transition: transform 0.3s ease;
`;
export const BurgerThree = styled.img<MenuProps>`
    position: absolute;
    bottom: 0;
    transform: ${({ $open }) =>
        $open ? "rotate(-45deg) translateX(3.7px) translateY(-4px) " : ""};
    transition: transform 0.3s ease;
`;

// export const BurgerMenuWrapper = styled.div<MenuProps>`
//   ${({ $open }) =>
//     $open
//       ? css`
//           left: 0;
//           transition: all 0.3s linear;
//         `
//       : css`
//           left: 100%;
//           transition: all 0.3s linear;
//         `}
//   display: flex;
//   flex-direction: column;
//   position: absolute;
//   top: ${({ $top }) => `${$top}px` || 0};
//   color: ${colors.white};
//   z-index: 10;
//   padding: 24px;
//   padding-top: 115px;
//   background: ${colors.greenDark};
//   height: 100vh;
//   width: 100vw;
//   gap: 40px;
// `;

export const BurgerMenuWrapper = styled.div.attrs<MenuProps>(
    ({ $top, $open }) => ({
        style: {
            left: $open ? "0" : "100%",
            top: $top ? $top + `px` : "0",
        },
    })
)`
    display: flex;
    flex-direction: column;
    position: absolute;
    background: ${colors.greenDark};
    color: ${colors.white};
    z-index: 12;
    padding: 24px;
    padding-top: 115px;
    height: 100vh;
    width: 100vw;
    gap: 40px;
    transition: all 0.3s linear;
`;

export const BurgerMenuItem = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;

    > p {
        padding: 8px;
        font-size: 16px;
    }

    &.middle {
        border-top: 1px solid ${colors.greenLight};
        border-bottom: 1px solid ${colors.greenLight};
        margin: 8px 0px;
        padding: 8px 0px;
    }
`;

export const BurgerMenuTop = styled.div`
    width: 100%;
    flex-direction: column;
    gap: 16px;
    overflow: scroll;
    &::-webkit-scrollbar {
        display: none;
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    h4 {
        padding: 8px;
        font-size: 24px;
    }
`;

export const BurgerMenuBtns = styled.div`
    display: flex;
    gap: 16px;
    max-width: 327px;
    width: 100%;
    padding-bottom: 65px;

    > a {
        width: 100%;
    }
`;

export const LoginBtnWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    gap: 5px;
    width: 35px;
    height: 35px;
    transition: 0.2s linear;
    border-radius: 4px;
    cursor: pointer;

    > svg {
        min-width: 35px;
        min-height: 35px;
    }

    > p {
        font-size: 14px;
        font-weight: 700;
        color: ${colors.primary};
        overflow: hidden;
    }

    // @media (min-width: 1236px) {
    //   &:hover {
    //     width: 94px;
    //     border: 1px solid ${colors.primary};

    //     background: #073547;

    //     > svg {
    //       path {
    //         fill: ${colors.primary};
    //       }
    //     }
    //   }
    // }
`;
