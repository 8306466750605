import styled from "styled-components";
import { colors } from "../../../theme/colors";
import device from "../../../theme/devices";
import size from "../../../theme/devices/sizes";

export const Section = styled.section`
  max-width: ${size.laptopL};
  margin: 0 auto;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 120px;

  @media ${device.tabletL} {
    padding: 88px 48px;
  }

  @media ${device.mobileL} {
    padding: 88px 24px;
  }
`;

export const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 20px;
`;

export const Headers = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 824px;
  width: 100%;

  h1 {
    font-size: 40px;
    line-height: 56px;
  }

  p {
    font-size: 16px;
    line-height: 28px;
  }
`;

export const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  min-height: 100%;
`;

export const Topics = styled.div`
  position: relative;
  z-index: 1;
  display: flex;

  @media (max-width: 700px) {
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-x: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

interface TopicProps {
  $select: boolean;
}

export const Topic = styled.div<TopicProps>`
  padding: 16px;
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
  width: 100%;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  position: inline;
  color: ${({ $select }) => ($select ? `${colors.primary}` : "")};

  @media ${device.mobileL} {
    font-size: 20px;
    padding: 8px;
  }
`;

export const BorderWrapper = styled.div`
  position: relative;
  height: 3px;
  background: ${colors.border};
  margin-bottom: 48px;
  width: 100%;
  max-width: 100%;
`;

interface BorderProps {
  $topic: number;
  $width: any;
}

export const ActiveBorder = styled.div<BorderProps>`
  position: absolute;
  bottom: 0px;
  z-index: 2;
  width: 100%;
  max-width: 100%;
  height: 3px;
  width: ${({ $topic, $width }) =>
    $topic === 1
      ? $width.topic1 + "px"
      : $topic === 2
      ? $width.topic2 + "px"
      : $width.topic3 + "px"};
  ${({ $topic, $width }) =>
    $topic === 2
      ? `left: ${$width.topic1}px;`
      : $topic === 3
      ? `left: calc(100% - ${$width.topic3}px);`
      : "left: 0;"}

  background: ${colors.primary};
  transition: 0.2s all ease-in;
`;

interface ContentProps {
  $show?: boolean;
}

export const ContentsCtn = styled.div`
  position: relative;
  display: grid;

  @media ${device.laptop} {
  }

  @media ${device.mobileL} {
    max-height: 100%;
  }
`;

export const ContentWrapper = styled.div<ContentProps>`
  display: flex;
  grid-column: 1;
  grid-row: 1;
  width: 100%;
  height: 100%;
  gap: 80px;
  opacity: ${({ $show }) => ($show ? "1" : "0")};
  pointer-events: ${({ $show }) => !$show && "none"};
  transition: 0.3s opacity ease-in-out;

  @media ${device.laptop} {
    flex-direction: column;
    gap: 20px;

    > img {
      width: clamp(150px, 100%, 100%);
    }
  }

  @media ${device.mobileL} {
    > img {
      width: 327px;
    }
  }
`;

export const Texts = styled.div`
  > p {
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 24px;
  }

  > a {
    decoration: none;
  }

  @media ${device.laptop} {
    margin-bottom: 48px;
  }
`;

export const LinkWrapper = styled.div`
  display: flex;
  gap: 16px;
  cursor: pointer;
  align-items: center;
  font-weight: 700;

  &:hover {
    color: ${colors.primary};

    > svg {
      > path {
        fill: ${colors.primary};
      }
    }
  }
`;
