import styled from "styled-components";
import { colors } from "../../../theme/colors";
import size from "../../../theme/devices/sizes";
import device from "../../../theme/devices";
import bgs from "../../../assets/bgs";

export const Section = styled.section``;

export const Container = styled.div`
  display: flex;

  background: url(${bgs.helpBg});
  background-repeat: no-repeat;
  background-size: cover;

  max-width: ${size.laptopL};
  margin: 0 auto;

  @media ${device.tabletL} {
    border-bottom: 5px solid ${colors.primary};
  }

  @media ${device.mobileL} {
    background: url(${bgs.helpBgMobile});
    background-repeat: no-repeat;
    background-size: cover;

    flex-direction: column;
  }
`;

export const Paragraph = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 126px 80px;
  width: 50%;
  margin-left: 50%;
  color: ${colors.white};
  background-color: rgba(0, 97, 104, 0.85);

  > h1 {
    font-size: 40px;
    line-height: 56px;
  }

  > p {
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 16px;
  }

  @media ${device.tabletL} {
    padding: 48px 24px;
  }

  @media ${device.mobileL} {
    width: 100%;
    margin: 68% 0px 0px 0px;

    > h1 {
      font-size: 32px;
      line-height: 48px;
    }

    > p {
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 16px;
    }
  }
`;
