import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import EnquiryFormPage from "./pages/EnquiryFormPage";
import AboutPage from "./pages/AboutPage";
import LobbyingPage from "./pages/LobbyingPage";
import CommunityPage from "./pages/CommunityPage";
import AdvicePage from "./pages/AdvicePage";
import PropertyPage from "./pages/PropertyPage";
import CorporatePage from "./pages/CorporatePage";
import NonCorporatePage from "./pages/NonCorporatePage";
import NewsPage from "./pages/NewsPage";
import NewsArticlePage from "./pages/NewsArticlePage";
import NavBar from "./component/NavBar";
import Footer from "./component/Footer";
import DonationPage from "./pages/DonationPage";
import AccountPage from "./pages/AccountPage";
import NotFoundPage from "./pages/NotFoundPage";

const AppRouter: React.FC = () => {
  return (
    <Router>
      <NavBar />
      <Routes>
        <Route
          path="/"
          element={<LandingPage />}
        />
        <Route
          path="/enquiry"
          element={<EnquiryFormPage />}
        />
        <Route
          path="/enquiry/thankyou"
          element={<EnquiryFormPage />}
        />
        <Route
          path="/donation"
          element={<DonationPage />}
        />
        <Route
          path="/donation/thankyou"
          element={<DonationPage />}
        />
        <Route
          path="/donation/failure"
          element={<DonationPage />}
        />
        <Route
          path="/about"
          element={<AboutPage />}
        />
        <Route
          path="/lobbying"
          element={<LobbyingPage />}
        />
        <Route
          path="/community"
          element={<CommunityPage />}
        />
        <Route
          path="/news"
          element={<NewsPage />}
        />
        <Route
          path="/insights/:id"
          element={<NewsArticlePage />}
        />
        <Route
          path="/advice-support"
          element={<AdvicePage adviceType={1} />}
        />
        <Route
          path="/specific-advice"
          element={<AdvicePage adviceType={2} />}
        />
        <Route
          path="/property/:page"
          element={<PropertyPage />}
        />
        <Route
          path="/corporate/:page"
          element={<CorporatePage />}
        />
        <Route
          path="/non-corporate/:page"
          element={<NonCorporatePage />}
        />

        <Route
          path="/enquiry/thankyou"
          element={<EnquiryFormPage />}
        />
        <Route
          path="/login"
          element={<AccountPage />}
        />
        <Route
          path="/sign-up"
          element={<AccountPage />}
        />
        <Route
          path="/forgot-password"
          element={<AccountPage />}
        />
        <Route
          path="/reset-password"
          element={<AccountPage />}
        />
        <Route
          path="/sorry"
          element={<></>}
        />
        <Route
          path="/404"
          element={<NotFoundPage />}
        />
        <Route
          path="/*"
          element={<NotFoundPage />}
        />
      </Routes>
      <Footer />
    </Router>
  );
};

export default AppRouter;
