import icons from "../../../../assets/icons";
import imgs from "../../../../assets/imgs";
import {
    Bottom,
    Card,
    CardsCtn,
    Container,
    GradientOverlay,
    Top,
} from "./styles";

const Left = () => {
    const firstRow = [
        <img id="content" src={icons.downloadable1} alt="content" />,
        <img id="content" src={icons.downloadable2} alt="content" />,

        <img id="content" src={icons.downloadable3} alt="content" />,

        <img id="content" src={icons.downloadable4} alt="content" />,
    ];

    const secondRow = [
        <img id="content" src={icons.downloadable5} alt="content" />,

        <img id="content" src={icons.downloadable6} alt="content" />,

        <img id="content" src={icons.downloadable7} alt="content" />,

        <img id="content" src={icons.downloadable8} alt="content" />,
    ];

    return (
        <Container img={imgs.bg}>
            {/* <Top> */}
            {/* <h1>Be Part Of Our Revolution!</h1>
            <p>
                As part of the Commercial Tenants Association, you’ll receive
                quality representation and guaranteed support: Join our
                revolution for free today!
            </p> */}
            {/* </Top> */}
            {/* <Bottom>
                <GradientOverlay />
                <CardsCtn>
                    {firstRow.map((card, i) => {
                        return (
                            <Card key={i}>
                                {card}
                                <img id="lock" src={icons.lock} alt="lock" />
                            </Card>
                        );
                    })}
                </CardsCtn>
                <CardsCtn>
                    {secondRow.map((card, i) => {
                        return (
                            <Card key={i}>
                                {card}
                                <img id="lock" src={icons.lock} alt="lock" />
                            </Card>
                        );
                    })}
                </CardsCtn>
            </Bottom> */}
        </Container>
    );
};

export default Left;
