import { propertyImgs } from "../../../assets/imgs/property";
import Identifying from "../../../component/PropertyPage/Identifying";
import ReusableIntro from "../../../component/Reusable/ReusableIntro";
import Viewings from "../../../component/PropertyPage/ViewingsSection";
import ReusableHero from "../../../component/Reusable/ReusableHero";
import ReusableExpanding from "../../../component/PropertyPage/propertyReusable/ReusableExpanding";
import Downloadable from "../../../component/Reusable/Downloadable";
import NeedAdvice from "../../../component/Reusable/NeedAdvice";
import EnquiryForm from "../../../component/Reusable/EnquiryForm";
import Offer from "../../../component/Reusable/Offer";
import ConsiderPoints from "../../../component/PropertyPage/ConsiderPoints";
import { offerData } from "../offerData";
import bgs from "../../../assets/bgs";

const LeaseRestructuring = () => {
  const heroContents = {
    type: 2,
    title: <h1>Lease Restructuring</h1>,
    text: (
      <p>
        There are a few things to consider when negotiating a new lease of a
        commercial property. This page provides detailed advice on how
        businesses should approach the acquisition of their new premises, from
        appointing an agent to undertaking the search to picking up the keys.
      </p>
    ),
    img: bgs.leaseRestructuringHeroBg,
    mobileimg: bgs.leaseRestructuringHeroBgMobile,
    button: true,
  };

  const introContents = {
    title: "What is a lease?",
    text: (
      <p>
        A lease is a contract between the owner of a property (the landlord), or
        superior leaseholder, and a tenant. The lease creates a legal interest
        in the property and permits exclusive occupation for an agreed period of
        time in exchange for rent.
        <br />
        <br />
        The lease will also include numerous promises, known as covenants, which
        must be observed during the lease term. These clauses are either implied
        or expressly written in every lease.
        <br />
        <br />
        Careful consideration should be given to each clause agreed with the
        landlord, as the implications can impact the tenant both practically and
        financially.
      </p>
    ),
    img: propertyImgs.propertyLeaseRestructuringIntro,
    link: "/enquiry",
  };

  const considerPointsContents = {
    page: "restructuring",
    title: "Points To Consider",
    subTitle: "",
    points: [
      {
        title: "Instructing Solicitors",
        text: (
          <p>
            Once the heads of terms have been agreed, both the tenant and
            landlord will instruct solicitors to begin drafting the lease.
            However, this also triggers another period of negotiations between
            parties.
            <br />
            <br />
            This may simply be highlighting areas of the lease which are not
            formally discussed when negotiating heads of terms. Often, however,
            solicitors will highlight specific clauses or areas of unfavourable
            wording that require challenging before they are formally written
            into the lease. For example, the wording around the{" "}
            <span>reinstatement/dilapidations</span> liability should remove any
            ambiguity as to what is expected of the tenant at the end of the
            lease.
          </p>
        ),
      },
      {
        title: "Picking Up The Keys",
        text: (
          <p>
            Once the lease has been agreed between parties, it is then signed,
            exchanged and the transaction is completed. At this point, if the
            parties have agreed for the lease to commence upon completion, the
            tenant will have the right to occupy the premises from this moment
            until the lease expires.
            <br />
            <br />
            It is also from this point that the tenant can mobilise their
            fit-out contractors and can commence the required fit-out works for
            their space.
          </p>
        ),
      },
      <img
        src={propertyImgs.propertyPointsImg}
        alt="people"
      />,
      {
        title: "Timing",
        text: (
          <p>
            It is vital to allow sufficient time and internal resources to plan
            how the whole process will be managed.
            <br />
            <br />
            The timeline for negotiating a new lease will vary between different
            tenants, but it is important for tenants to overestimate how long
            the process may take, as an insufficient overlap between the leases
            of the old and new premises may result in serious disruption to
            business operations.
          </p>
        ),
      },
    ],
  };

  const appointingExpandingContents = {
    title: "Appointing a Tenant Representative",
    subTitle:
      "Tenants should appoint an agent or tenant representative; an industry professional who will work in their best interests to find the ideal space. Here are some tips to help you find the right tenant representative:",
    qa: [
      {
        question: "How tenant-focused are they?",
        answer: (
          <p>
            The majority of commercial property agency businesses will represent
            both landlords and tenants. It is unusual for an agent to only
            represent tenants but such representatives do exist.
            <br />
            <br /> Checking that the agent has experience in your particular
            market is well worthwhile (see requirements and local knowledge).
          </p>
        ),
      },
      {
        question: "Can they provide any additional services?",
        answer: (
          <p>
            A huge amount of work goes into finding, designing and fitting-out a
            space to suit a business. Some tenant representatives will be able
            to assist with some or all of a tenant’s requirements and will work
            with an in-house design team or have recommendations of contractors,
            consultants and furniture suppliers.
          </p>
        ),
      },
      {
        question: "Requirements and local knowledge",
        answer: (
          <p>
            Make sure you give specific details for what you require from your
            leasehold premises.
            <br />
            <br />
            Similarly, check that the tenant representative has knowledge of the
            appropriate sector (offices, industrial, distribution, retail,
            hospitality etc.) and experience helping similar businesses to yours
            find space.
          </p>
        ),
      },
      {
        question: "Large agency vs small firm",
        answer: (
          <p>
            Large agencies may have more resources and data, but a smaller firm
            with a specific sector or area focus may have more specialist local
            knowledge.
          </p>
        ),
      },
      {
        question:
          "How do they charge for finding space and negotiating a lease?",
        answer: (
          <p>
            Tenant representatives’ fees are generally a percentage of the value
            of the transaction, the extent of success achieved during
            negotiations or a combination of the two. However, for small spaces,
            a fixed fee may be agreed. <br />
            <br />A fee of 10% of the average first five years’ rent is not
            unusual in the current market.
          </p>
        ),
      },
    ],
  };

  const negotiationExpandingContents = {
    title: "Key Area Of Negotiation",
    subtitle:
      "As negotiations progress, the landlord’s agent will be looking to agree the draft heads of terms. The following represent some of the key terms to be negotiated:",
    qa: [
      {
        question: "Renewal rights",
        answer: (
          <p>
            <span>Statutory rights of renewal</span> under the{" "}
            <span>Landlord and Tenant Act 1954</span> mean a tenant has a right
            to renew the lease when it expires, though a landlord may decide to
            exclude the lease from these protections. A tenant should be
            notified of this decision at the outset.
            <br />
            <br />
            It is important not to give up this right without negotiating
            something in return, as it can be very valuable to the landlord.
            <br />
            <br /> For further information, please see the Lease Renewals advice
            page.
          </p>
        ),
      },
      {
        question: "Length of term and break rights",
        answer: (
          <p>
            Although it may be possible to agree a shorter lease length on
            smaller units, landlords will usually attempt to seek a minimum term
            length that is as long as possible. Longer commitments to a leased
            property will strengthen the tenant’s negotiating position around
            rent level and other incentives.
            <br />
            <br />
            That being said, in longer lease arrangements, landlords may agree
            to a tenant-only break clause that permits tenants to terminate the
            lease at a specific point in the term, subject to certain lease
            obligations being met and sufficient notice being provided.
            <br />
            <br />
            The position of the break clause will usually be negotiated for a
            specific point in the lease term. This could be at the conclusion of
            the fifth year of a ten-year term or on the fifth and tenth years of
            a fifteen-year term.
          </p>
        ),
      },
      {
        question: "Rent and rent review",
        answer: (
          <p>
            The initial rent and the frequency of payment should all be
            negotiated. Any inflexibility from the landlord’s quoting rent may
            be countered in negotiations with the demand for rent-free
            incentives or landlord contributions.
            <br />
            <br />
            In longer leases, where the landlord proposes that the rent is to be
            subject to review, the tenant needs to agree the proposed frequency
            and the method of review at the outset, in order to obtain the best
            advice for these points in the term.
            <br />
            <br />
            For further information, please see the <span>
              Rent Reviews
            </span>{" "}
            advice page.
          </p>
        ),
      },
      {
        question: "Rent deposits",
        answer: (
          <p>
            Rent deposits can vary in size, but often equate to a specific
            number of months’ rent. During negotiations, it is important to
            highlight the covenant strength of the tenant, which ultimately
            informs the landlord that they are reliable and will pay their rent
            on time. If the covenant strength is great enough, the tenant may
            not be required to provide a deposit at all.
            <br />
            <br />
            Moreover, rent deposit agreements should prescribe that landlords
            hold rent deposits in specific rent deposit bank accounts for the
            duration of the term.
          </p>
        ),
      },
      {
        question: "Services charges",
        answer: (
          <p>
            The landlord should highlight the main services included within the
            property and provide proper estimates of service charge and
            insurance payments. If in doubt, the tenant is within their rights
            to request the service charge budgets and actual expenditure from
            previous years to see a breakdown of the charges.
            <br />
            <br />
            These costs will usually rise in line with inflation. However, for
            protection, the tenant can request a service charge cap to prevent
            this figure rising past an unreasonable point during their lease
            term. Success in achieving a cap on costs will always depend on the
            tenant’s negotiating strength.
            <br />
            <br />
            Where the landlord insures the property, leases should provide that
            the policy will be on normal market terms and that details of the
            insurance can be made available to the tenant on reasonable request.
          </p>
        ),
      },
      {
        question: "Alienation",
        answer: (
          <p>
            Leases should always allow tenants to assign the whole of the
            premises with the landlord’s consent. Landlords may set out
            circumstances in which consent can be refused, such as where there
            are arrears of rents, that are not the subject of a legitimate
            dispute.
            <br />
            <br />
            Moreover, if the landlord reasonably requires it, leases may also
            demand that the assigning tenant utilise an authorised guarantee
            agreement (AGA) with the incoming tenant.
            <br />
            <br />
            Leases should also allow tenants to sublet the whole of the premises
            and may allow subleases of parts of the space if appropriate. These
            may be granted on different terms to the headlease where
            appropriate.
            <br />
            <br />
            If the landlord shows any inflexibility in terms of alienation, then
            the tenant should counter this with a request for a reduction in
            rent or some other quid pro quo. <br />
            <br />
            For further information, please see the Disposing of Leasehold
            Property advice page.
          </p>
        ),
      },
      {
        question: "Reinstatement/Dilapidations",
        answer: (
          <p>
            If the tenant’s obligations are to be limited to returning the
            premises to its initial received condition, it is incredibly
            important to ensure that a schedule of condition is appended to the
            lease document. This will detail the condition of the property at
            the start of the lease and will normally need to be agreed by both
            parties before serving as reference for reinstatement at lease
            expiry.
            <br />
            <br /> This can often be a contentious area between landlord and
            tenant, where the condition of a property may be perceived
            differently by each party. A tenant will need to appoint a building
            surveyor and should budget for this accordingly.
            <br />
            <br /> For further information, please see the Dilapidations
            Negotiations advice page.
          </p>
        ),
      },
      {
        question: "Alterations",
        answer: (
          <p>
            The tenant should be notified at the outset if the landlord requires
            a licence for alterations before they can take place. Landlords will
            always require written details of the works and for the tenant to
            carry out such works properly and without causing damage or
            nuisance.
            <br />
            <br />
            It is not unusual for a landlord to require the premises to be
            reinstated at the end of the lease, placing an obligation on the
            tenant to remove any alterations.
            <br />
            <br />
            The heads of terms should also set out any capital contributions
            provided by the landlord to benefit the fit-out. This could be
            negotiated as a single sum, as a figure required per fit-out element
            (e.g. for floorboxes) or as a figure contributed per sq ft or sqm
            (e.g. for carpet).
          </p>
        ),
      },
    ],
  };

  const offerContents = [offerData.leaseRegearing, offerData.leaseRenewals];

  return (
    <>
      <ReusableHero contents={heroContents} />
      <ReusableIntro contents={introContents} />
      <ReusableExpanding contents={appointingExpandingContents} />
      <Identifying />
      <Viewings />
      <ReusableExpanding contents={negotiationExpandingContents} />
      <ConsiderPoints contents={considerPointsContents} />
      <Downloadable full={false} />
      <NeedAdvice />
      <Offer
        contents={offerContents}
        title="Similar Services"
      />
      <EnquiryForm />
    </>
  );
};

export default LeaseRestructuring;
