import styled from "styled-components";
import { colors } from "../../../theme/colors";
import device from "../../../theme/devices";
import size from "../../../theme/devices/sizes";
import bgs from "../../../assets/bgs";

export const Section = styled.section`
  background: ${colors.greenLight2};
`;

interface ValuesProps {
  $enter: boolean;
}

export const Container = styled.div<ValuesProps>`
  background-image: url(${bgs.valueBg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right bottom;
  max-width: ${size.laptopL};
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 82px 120px;

  color: ${colors.white};
  opacity: ${({ $enter }) => ($enter ? "1" : "0.2")};
  transition: opacity 2s;

  h1 {
    font-size: 48px;
  }

  h2 {
    font-size: 40px;
    line-height: 56px;
    max-width: 883px;

    > span {
      font-family: "owners";
      color: ${colors.primary};
    }
  }

  @media ${device.tabletL} {
    padding: 64px 48px;
    align-items: center;
  }

  @media ${device.mobileL} {
    padding: 64px 24px;
    gap: 40px;
    align-items: flex-start;
    background-image: url(${bgs.valuesBgMobile});
    background-size: contain;

    > h1 {
      font-size: 32px;
      line-height: 48px;
    }

    > h2 {
      font-size: 32px;
      line-height: 48px;
    }
  }
`;

export const ItemsCtn = styled.div`
  display: flex;
  gap: 39.5px;
  flex-wrap: wrap;

  @media ${device.tabletL} {
    flex-direction: column;
    align-items: center;
  }

  @media ${device.mobileL} {
    gap: 24px;
  }
`;

export const Item = styled.div<ValuesProps>`
  display: flex;
  flex-direction: column;
  max-width: 373px;
  gap: 16px;

  > h2 {
    font-size: 24px;
    line-height: 32px;
  }

  > p {
    font-size: 16px;
    line-height: 28px;
  }

  svg {
    path {
      fill: ${({ $enter }) => $enter && `${colors.primary}`};
    }
  }

  @media ${device.tabletL} {
    max-width: 100%;
  }

  @media ${device.mobileL} {
    > img {
      width: 32px;
    }

    > h2 {
      font-size: 20px;
    }

    > p {
      font-size: 14px;
      line-height: 26px;
    }
  }
`;
