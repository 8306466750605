import React from "react";
import ReusableButton from "../../Reusable/ReusableButton";
import size from "../../../theme/devices/sizes";
import useWindowSize, { Size } from "../../../utils/useWindowSize";
import { Container, Point, PointsCtn, Section, Titles, Top } from "./styles";

interface ConsiderPointsProps {
  contents: {
    page: string;
    title: string;
    subTitle: string;
    points: any[];
  };
}

const ConsiderPoints: React.FC<ConsiderPointsProps> = ({ contents }) => {
  const windowSize: Size = useWindowSize();
  const mobileL = +size.mobileL.slice(0, -2);

  return (
    <Section>
      <Container>
        <Top>
          <Titles>
            <h1>{contents.title}</h1>
            {contents.subTitle && <p>{contents.subTitle}</p>}
          </Titles>
          {windowSize.width! > mobileL && (
            <a href="/enquiry">
              <ReusableButton
                name="Enquire Now"
                onClick={() => {}}
              />
            </a>
          )}
        </Top>
        <PointsCtn>
          {contents.points.map((content: any, i: number) => (
            <React.Fragment key={i}>
              {React.isValidElement(content) ? (
                content
              ) : (
                <Point>
                  {content.title && <h2>{content.title}</h2>}
                  {content.text}
                </Point>
              )}
            </React.Fragment>
          ))}
        </PointsCtn>
        {windowSize.width! <= mobileL && (
          <a href="/enquiry">
            <ReusableButton
              name="Enquire Now"
              onClick={() => {}}
            />
          </a>
        )}
      </Container>
    </Section>
  );
};

export default ConsiderPoints;

// {contents.points.map((content: any, i: number) => {
//   return contents.page === "renewals" ? (
//     <React.Fragment key={i}>
//       {React.isValidElement(content) ? (
//         content
//       ) : (
//         <Point>
//           <h2>{content.title}</h2>
//           {content.text}
//         </Point>
//       )}
//     </React.Fragment>
//   ) : (
//     <React.Fragment key={i}>
//       {content.text && (
//         <Point>
//           <h2>{content.title}</h2>
//           {content.text}
//         </Point>
//       )}
//     </React.Fragment>
//   );
// })}
