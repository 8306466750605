import { propertyImgs } from "../../../assets/imgs/property";
import EnquiryForm from "../../../component/Reusable/EnquiryForm";
import Offer from "../../../component/Reusable/Offer";
import NeedAdvice from "../../../component/Reusable/NeedAdvice";
import ReusableIntro from "../../../component/Reusable/ReusableIntro";
import ReusableHero from "../../../component/Reusable/ReusableHero";
import { offerData } from "../offerData";
import bgs from "../../../assets/bgs";

const Arrears = () => {
  const heroContents = {
    type: 2,
    title: <h1>Arrears</h1>,
    text: (
      <p>
        If you’re struggling to make rental payments, or owe money to your
        landlord, this is considered as being in ‘arrears’. It’s vitally
        important that if this is ever the case, you should do all you can to
        pay off these arrears as soon as possible.
      </p>
    ),
    img: bgs.arrearsHeroBg,
    mobileimg: bgs.arrearsHeroBgMobile,
    button: true,
  };
  const introContents = {
    title: "What You Need To Know",
    text: (
      <p>
        By signing a rental contract and tenancy agreement, it’s your legal
        obligation to pay the rent owed to your landlord according to the terms
        of your contract.
        <br />
        <br />
        Failing to do so properly, including by missing payment dates and paying
        insufficient amounts, can land you and your business in ‘arrears’ to the
        landlord. The landlord can then take you to court in hopes of recouping
        this money back from you and your business.
      </p>
    ),
    img: propertyImgs.acquisitionsIntro,
    link: "/enquiry",
  };

  const offerContents = [
    offerData.strategicLeaseManagement,
    offerData.ownYourProperty,
  ];

  return (
    <>
      <ReusableHero contents={heroContents} />
      <ReusableIntro contents={introContents} />
      <NeedAdvice />
      <Offer
        contents={offerContents}
        title="Similar Services"
      />
      <EnquiryForm />
    </>
  );
};

export default Arrears;
