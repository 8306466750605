import styled from "styled-components";
import size from "../../../theme/devices/sizes";
import device from "../../../theme/devices";
import { colors } from "../../../theme/colors";

export const Section = styled.section`
  position: relative;
  max-width: ${size.laptopL};
  margin: 0 auto;

  > img {
    position: absolute;
    z-index: -1;
  }

  @media ${device.laptop} {
    > img {
      display: none;
    }
  }
`;

interface ContainerProps {
  $flipOrder: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  justify-content: space-between;
  padding: 96px 120px 120px;
  gap: 40px;

  > img {
    width: 559px;
    height: 482px;
    order: ${({ $flipOrder }) => ($flipOrder ? "2" : "1")};
  }

  > h1 {
    font-size: 40px;
    line-height: 56px;
  }

  @media ${device.laptop} {
    flex-direction: column;

    > img {
      order: 1;
    }
  }

  @media ${device.tabletL} {
    padding: 96px 56px 120px;
  }

  @media ${device.mobileL} {
    padding: 24px 24px 88px;
    gap: 16px;

    > h1 {
      font-size: 32px;
      line-height: 48px;
    }

    > img {
      width: 100%;
      height: 100%;
    }
  }
`;

export const Paragraph = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 559px;
  order: ${({ $flipOrder }) => ($flipOrder ? "1" : "2")};

  > h1 {
    font-size: 40px;
    line-height: 56px;
  }

  > p {
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 24px;

    > span {
      color: ${colors.primary};
      font-weight: 700;
      text-decoration: underline;
    }
  }

  @media ${device.laptop} {
    max-width: 100%;
  }

  @media ${device.mobileL} {
    gap: 16px;

    > h1 {
      font-size: 32px;
      line-height: 48px;
    }

    > p {
      font-size: 14px;
      line-height: 26px;
      margin-bottom: 16px;
    }
  }
`;
