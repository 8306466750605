import imgs from "../../../assets/imgs";
import { propertyImgs } from "../../../assets/imgs/property";
import Downloadable from "../../../component/Reusable/Downloadable";
import EnquiryForm from "../../../component/Reusable/EnquiryForm";
import Offer from "../../../component/Reusable/Offer";
import NeedAdvice from "../../../component/Reusable/NeedAdvice";
import ReusableExpanding from "../../../component/PropertyPage/propertyReusable/ReusableExpanding";
import ReusableIntro from "../../../component/Reusable/ReusableIntro";
import ReusableHero from "../../../component/Reusable/ReusableHero";
import { offerData } from "../offerData";
import bgs from "../../../assets/bgs";
import useWindowSize, { Size } from "../../../utils/useWindowSize";
import size from "../../../theme/devices/sizes";

const ServiceCharge = () => {
  const windowSize: Size = useWindowSize();
  const mobileL = +size.mobileL.slice(0, -2);

  const heroContents = {
    type: 2,
    title: <h1>Service Charge</h1>,
    text: (
      <p>
        The service charge allows a landlord to recover the day to day costs of
        maintenance and facilities used in common within a building.
      </p>
    ),
    img: bgs.serviceChargeHeroBg,
    mobileimg: bgs.serviceChargeHeroBgMobile,
    button: true,
  };

  const firstIntroContents = {
    title: "",
    text: (
      <p>
        Most commercial tenants occupying multi-let buildings will pay a service
        charge. At times there may be issues with how services are being
        provided by the landlord or the charges appear may appear to be too
        high. This advice provides an overview of the costs recoverable under
        the services charge.
        <br />
        <br />
        The obligation to pay service charge allows the landlord to recover the
        cost of a variety of services in connection with the operation and
        maintenance of its property.
        <br />
        <br />
        It is important that landlords are held to account for each item
        included in the service charge as there are limits to what is lawfully
        recoverable.
      </p>
    ),
    img: "",
    leftTitle: "An Introduction To Service Charges",
    bgBlock: imgs.charterBlock,
    link: "/enquiry",
  };

  const secondIntroContents = {
    title: "Before Lease Negotiations",
    text: (
      <p>
        Understanding the service charges attributed to a property will help
        ease negotiations. Prior to negotiating ‘heads of terms’ for a{" "}
        <span>new lease</span>, a prospective tenant should obtain advice from a
        property professional.
        <br />
        <br />
        Obtaining copies of previous years’ service charge accounts from the
        landlord may reveal significant items of expenditure as well as what
        charges to expect throughout the life of the lease. An experienced
        surveyor will be able to advise as to how the current level of service
        charge compares with similar buildings.
        <br />
        <br />
        It may even be possible to negotiate a reduced or ‘capped’ service
        charge in certain circumstances. A cap may be time-limited and apply to
        the service charge as a whole or to specific items within the service
        charge.
      </p>
    ),
    img: propertyImgs.beforeLeaseNegotiations,
    link: "/enquiry",
  };

  const expandingContents = {
    title: "Points To Consider",
    subTitle: "",
    qa: [
      {
        question: "What expenditure can the landlord recover?",
        answer: (
          <p>
            The lease will normally state precisely what services are
            recoverable and the lease provisions must be strictly adhered to. In
            most cases, the landlord must perform its obligations under the
            service charge provisions of the lease before a tenant’s liability
            to pay arises.
            <br />
            <br />
            Landlords are usually entitled to recover their costs in relation
            to: the day to day operation of the building, including utilities
            for common areas (if not separately metered) repairs and planned
            maintenance.
            <br />
            <br />
            However, they are only permitted to recover proper and actual costs.
            The overriding principle is that tenants should only pay for those
            services from which they derive benefit. A ground floor tenant
            should not have to pay for the recurring maintenance of a passenger
            lift serving the upper floors, for example.
            <br />
            <br />
            Tenants with a relatively short lease should not be expected to fund
            large capital expenditure by the landlord. Landlords who wait for
            ‘service charge caps’ to expire before charging back expensive works
            may find it difficult to justify recovery in full.
          </p>
        ),
      },
      {
        question: "Value for money",
        answer: (
          <p>
            Service charges in commercial property are to be run on an
            appropriate value for money basis. In practice, this does not
            necessarily mean achieving the lowest price. However, a tenant
            should only contribute to costs which are ‘fair and reasonable’.
          </p>
        ),
      },
      {
        question: "Improvements to the property",
        answer: (
          <p>
            As a general rule, landlords cannot recover the cost of improvements
            and additions. In this way, carpeted lobby areas refurbished with
            fine Italian marble would always be considered improvements.
            However, there may be justification in replacing an ageing HVAC
            system with something more modern that is less reliant on ongoing
            expensive maintenance.
          </p>
        ),
      },
      {
        question: "Withholding payment",
        answer: (
          <p>
            It may seem entirely justifiable to withhold payment if the services
            provided by the landlord fall below expectations. However, there is
            an underlying ‘reasonableness test’ as to what payments can be
            lawfully withheld. Before withholding any payment due under the
            terms of a lease, a tenant should seek professional advice.
          </p>
        ),
      },
    ],
  };

  const disputesContents = {
    title: "",
    text: (
      <p>
        It is not uncommon for <span>disputes</span> to arise in respect of
        service charges. Often these disputes relate to whether the tenant has a
        specific obligation to pay for certain services or how the service
        charge has been apportioned.
        <br />
        <br />
        The starting point in a service charge dispute will always be the lease.
        Since 1st April 2019, the lease should be read with reference to the{" "}
        <span>RICS Professional Statement on Service Charges</span> in
        Commercial Property.{" "}
        <span>The Royal Institute of Chartered Surveyors</span> (which regulates
        its members and member firms) introduced mandatory requirements for
        service charge administration. In practice, this means that managing
        agents may be required to provide justification for their actions and
        can be subject to disciplinary and legal action from the regulator.
        <br />
        <br />
        It is important that tenants seek expert advice from a{" "}
        <span>legal or surveying professional</span> at the earliest
        opportunity.
        <br />
        <br />
        Most disputes will be resolved without the need for further action.
        However, sometimes it is necessary to seek additional remedies.
        <br />
        <br />
        The RICS Statement encourages the use of alternative dispute resolution
        (ADR). Most new leases will provide for ADR referrals though, even if it
        does not contain an ADR provision, issuing court proceedings without
        first attempting ADR can be a costly mistake. Equally, a landlord must
        comply with any relevant protocol with regard to recovering debt should
        it attempt to force the tenant to pay the service charge in dispute.
      </p>
    ),
    img: "",
    leftTitle: "Service Charge Disputes",
    link: "/property/service-charge",
  };

  const offerContents = [offerData.ownYourProperty, offerData.arrears];

  const divStyle =
    windowSize.width! > mobileL ? { marginBottom: "-120px" } : {};

  return (
    <>
      <ReusableHero contents={heroContents} />
      <div style={divStyle}>
        <ReusableIntro contents={firstIntroContents} />
      </div>
      <ReusableIntro contents={secondIntroContents} />
      <ReusableExpanding contents={expandingContents} />
      <div style={{ marginTop: "-120px" }}>
        <ReusableIntro contents={disputesContents} />
      </div>
      {windowSize.width! > mobileL ? (
        <>
          <NeedAdvice />
          <Downloadable full={false} />
        </>
      ) : (
        <>
          <Downloadable full={false} />
          <NeedAdvice />
        </>
      )}

      <Offer
        contents={offerContents}
        title="Similar Services"
      />
      <EnquiryForm />
    </>
  );
};

export default ServiceCharge;
