import { ChangeEvent, useEffect, useState } from "react";
import {
  Container,
  InputsCtn,
  MultiInputsWrapper,
  InputWrapper,
  Input,
  Bottom,
} from "./styles";
import ReusableButton from "../../../../component/Reusable/ReusableButton";
import register from "../../../../utils/register";
import { Notify } from "notiflix";
import { phoneValidation } from "../../../../utils/phone-validation";
import { useNavigate } from "react-router-dom";
import icons from "../../../../assets/icons";
import { emailValidation } from "../../../../utils/email-validation";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import useWindowSize, { Size } from "../../../../utils/useWindowSize";
import size from "../../../../theme/devices/sizes";
import { updateLogin } from "../../../../redux/features/loginSlice";
import profile from "../../../../utils/profile";

const SignUp = () => {
  const dispatch = useAppDispatch();
  const windowSize: Size = useWindowSize();
  const mobileL = +size.mobileL.slice(0, -2);
  const navigate = useNavigate();
  const [accountDetails, setAccountDetails] = useState<{
    [key: string]: string;
  }>({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    password: "",
  });
  const [show, setShow] = useState({
    firstname: false,
    lastname: false,
    email: false,
    phone: false,
    password: false,
  });
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const loginState = useAppSelector((state) => state.login);

  useEffect(() => {
    const token = localStorage.getItem("token");

    const checkToken = async () => {
      const res = await profile(token);
      if (res.success) {
        dispatch(updateLogin(true));
        navigate("/login");
      } else {
        localStorage.removeItem("token");
        dispatch(updateLogin(false));
      }
    };

    if (token) {
      checkToken();
    } else {
      dispatch(updateLogin(false));
    }
  }, [loginState]);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setAccountDetails((prev) => ({ ...prev, [name]: value }));
    if (accountDetails[name].length > 0) {
      setShow((prev) => ({ ...prev, [name]: true }));
    }
  };

  const handleFocus = (name: string) => {
    setShow((prev) => ({ ...prev, [name]: true }));
  };

  const handleBlur = (name: string) => {
    if (accountDetails[name].length === 0) {
      setShow((prev) => ({ ...prev, [name]: false }));
    }
  };

  const navigateWithScroll = (path: any, targetDivId: any) => {
    navigate(path);

    setTimeout(() => {
      const offset = windowSize.width! <= mobileL ? 96 : 0;
      scrollToDiv(targetDivId, -offset);
    }, 100);
  };

  const scrollToDiv = (targetDivId: any, yOffset: number = 0) => {
    const targetDiv = document.getElementById(targetDivId);

    if (targetDiv) {
      const rect = targetDiv.getBoundingClientRect();
      const topOffset = rect.top + window.scrollY;

      window.scrollTo({
        top: topOffset + yOffset,
        behavior: "smooth",
      });
    }
  };

  const handleSubmit = async () => {
    try {
      if (!accountDetails.firstname) {
        return Notify.failure("Please provide your first name.");
      }
      if (!accountDetails.lastname) {
        return Notify.failure("Please provide your last name.");
      }
      if (!accountDetails.email) {
        return Notify.failure("Please provide your email address.");
      }
      const emailRes = await emailValidation(accountDetails.email);
      if (!emailRes) {
        return Notify.failure("Please provide a valid email address.");
      }
      if (!accountDetails.phone) {
        return Notify.failure("Please provide your phone number.");
      }
      const phoneRes = await phoneValidation(parseInt(accountDetails.phone));
      if (!phoneRes) {
        return Notify.failure("Please provide a valid phone number.");
      }

      const res = await register(accountDetails);

      if (res.success) {
        localStorage.setItem("token", res.token);
        dispatch(updateLogin(true));
      } else {
        if (res === "Phone Existed") {
          return Notify.failure(
            "This phone number has already been registered.",
          );
        } else if (res === "must NOT have fewer than 8 characters") {
          return Notify.failure("Password must be at least 8 characters.");
        } else if (res === "Email Existed") {
          return Notify.failure("This email has already been registered.");
        } else {
          return Notify.failure(
            "Something went wrong. Please try again later.",
          );
        }
      }
    } catch (err) {
      console.log(err);
      return Notify.failure("Something went wrong. Please try again later.");
    }
  };

  return (
    <Container>
      {loginState ? (
        <>
          <h1>Thanks for signing up!</h1>
          <p>
            Your account has been created. You can now access all our guides.
          </p>
          <ReusableButton
            $primary={true}
            name="View Guides"
            onClick={() =>
              navigateWithScroll("/advice-support", "downloadable")
            }
          />
        </>
      ) : (
        <>
          <h1>Create Account</h1>
          <InputsCtn>
            <MultiInputsWrapper>
              <InputWrapper $show={show.firstname}>
                <p>First name</p>
                <Input
                  name="firstname"
                  value={accountDetails.firstname}
                  onFocus={() => handleFocus("firstname")}
                  onBlur={() => handleBlur("firstname")}
                  onChange={handleChange}
                  $show={show.firstname}
                  placeholder="First name"
                />
              </InputWrapper>
              <InputWrapper $show={show.lastname}>
                <p>Surname</p>
                <Input
                  name="lastname"
                  value={accountDetails.lastname}
                  onFocus={() => handleFocus("lastname")}
                  onBlur={() => handleBlur("lastname")}
                  onChange={handleChange}
                  $show={show.lastname}
                  placeholder="Surname"
                />
              </InputWrapper>
            </MultiInputsWrapper>
            <InputWrapper $show={show.email}>
              <p>Email address</p>
              <Input
                name="email"
                value={accountDetails.email}
                onFocus={() => handleFocus("email")}
                onBlur={() => handleBlur("email")}
                onChange={handleChange}
                $show={show.email}
                placeholder="Email address"
              />
            </InputWrapper>
            <InputWrapper $show={show.phone}>
              <p>Contact number</p>
              <Input
                name="phone"
                value={accountDetails.phone}
                onFocus={() => handleFocus("phone")}
                onBlur={() => handleBlur("phone")}
                onChange={handleChange}
                $show={show.phone}
                placeholder="Contact number"
              />
            </InputWrapper>
            <InputWrapper $show={show.password}>
              <p>Create a password</p>
              <Input
                name="password"
                value={accountDetails.password}
                onFocus={() => handleFocus("password")}
                onBlur={() => handleBlur("password")}
                type={showPassword ? "text" : "password"}
                onChange={handleChange}
                $show={show.password}
                placeholder="Create a password"
              />
              <img
                id="visible"
                src={icons.visible}
                alt="visible"
                onClick={() => setShowPassword(!showPassword)}
              />
            </InputWrapper>
          </InputsCtn>
          <ReusableButton
            $primary={true}
            name="Join Now"
            onClick={handleSubmit}
          />
          <Bottom>
            <p>Already have an account? </p>
            <a href="/login">
              <strong> Sign In</strong>
            </a>
          </Bottom>
        </>
      )}
    </Container>
  );
};

export default SignUp;
