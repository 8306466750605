import { useEffect, useState } from "react";
import icons from "../../../assets/icons";
import { colors } from "../../../theme/colors";
import {
  Button,
  Card,
  CardsCtn,
  Container,
  InputWrapper,
  Section,
  Titles,
} from "./styles";
import { ReactComponent as Svg1 } from "../../../assets/icons/communityGroup1.svg";
import { ReactComponent as Svg2 } from "../../../assets/icons/communityGroup2.svg";
import { ReactComponent as Svg3 } from "../../../assets/icons/communityGroup3.svg";
import subscribe from "../../../utils/subscribe";
import { emailValidation } from "../../../utils/email-validation";
import { Notify } from "notiflix";
import { phoneValidation } from "../../../utils/phone-validation";

const CommunityGroups = () => {
  const [details, setDetails] = useState<{ [key: string]: string }>({
    email: "",
    whatsapp: "",
  });
  const [show, setShow] = useState<{ [key: string]: boolean }>({
    email: false,
    whatsapp: false,
    facebook: false,
  });
  const [submitted, setSubmitted] = useState<{ [key: string]: boolean }>({
    email: false,
    whatsapp: false,
    facebook: false,
  });

  const handleClick = (name: string) => {
    setShow((prev) => ({ ...prev, [name]: !show[name] }));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (name === "whatsapp") {
      const trimWhatsapp = value.replace(/\D/g, "");
      setDetails((prev) => ({
        ...prev,
        whatsapp: trimWhatsapp,
      }));
    } else {
      let trimmedEmail = value.trim();
      setDetails((prev) => ({ ...prev, email: trimmedEmail }));
    }
  };

  const handleSubmit = async (name: string) => {
    try {
      if (name === "email") {
        if (!details.email) {
          return Notify.failure("Please provide your email address.");
        }

        const emailRes = await emailValidation(details.email);
        if (!emailRes) {
          return Notify.failure("Please provide a valid email address.");
        }

        const res: any = await subscribe("boardcast", { email: details.email });

        if (res.success) {
          setSubmitted((prev) => ({ ...prev, email: true }));
        }
      } else {
        if (!details.whatsapp) {
          return Notify.failure("Please provide your phone number.");
        }
        const phoneRes = await phoneValidation(parseInt(details.whatsapp));

        if (!phoneRes) {
          return Notify.failure("Please provide a valid phone number.");
        }
        const res: any = await subscribe("whatsapp", {
          phone: details.whatsapp,
        });

        if (res.success) {
          setSubmitted((prev) => ({ ...prev, whatsapp: true }));
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Section>
      <Container>
        <Titles>
          <h1>Community Groups</h1>
          <p>
            In order to properly co-ordinate our messaging and plans to as many
            business owners as possible, we cordially invite you to join our
            various community groups, so you can get a proper feel for the hard
            work and collective goals the CTA looks to bring for its members.
          </p>
        </Titles>
        <CardsCtn>
          <Card
            $background={colors.greenLight2}
            color={colors.primary}
            $show={show.email}
            $submitted={submitted.email}
          >
            <img
              src={icons.circleTickGreen}
              alt="tick"
            />
            <Svg1 />
            <h1>
              Be Part of Our <span>Broadcast List</span>
            </h1>
            <Button
              color={colors.primary}
              $show={show.email}
              onClick={() => handleClick("email")}
            >
              Join Now
            </Button>
            <InputWrapper
              $show={show.email}
              color={colors.primary}
              $submitted={submitted.email}
            >
              <input
                name="email"
                value={details.email}
                onChange={handleChange}
                placeholder="Enter your email address"
              />
              <Button
                color={colors.primary}
                onClick={() => handleSubmit("email")}
              >
                Submit
              </Button>
            </InputWrapper>
            <p>Success! You are now part of our broadcast list!</p>
          </Card>
          <Card
            color={"#60D669"}
            $show={show.whatsapp}
            $submitted={submitted.whatsapp}
          >
            <img
              src={icons.circleTickGreen}
              alt="tick"
            />
            <Svg2 />
            <h1>
              Don’t Wait, Join Our <span>WhatsApp</span>
            </h1>
            <Button
              color="#60D669"
              $show={show.whatsapp}
              onClick={() => handleClick("whatsapp")}
            >
              Join Now
            </Button>
            <InputWrapper
              $show={show.whatsapp}
              color="#60D669"
              $submitted={submitted.whatsapp}
            >
              <input
                name="whatsapp"
                value={details.whatsapp}
                onChange={handleChange}
                inputMode="numeric"
                placeholder="Enter your mobile number"
              />
              <Button
                color="#60D669"
                onClick={() => handleSubmit("whatsapp")}
              >
                Submit
              </Button>
            </InputWrapper>
            <p>Success! You are now part of our WhatsApp group!</p>
          </Card>
          <Card color={"#1877F2"}>
            <img
              src={icons.communityGroup3}
              alt="icon"
            />
            <Svg3 />
            <h1>
              Follow Us On <span>Facebook</span>
            </h1>
            <a
              href="https://www.facebook.com/TheCTAuk"
              target="_blank"
            >
              <Button color="#1877F2">Join Now</Button>
            </a>
          </Card>
        </CardsCtn>
      </Container>
    </Section>
  );
};

export default CommunityGroups;
