import icons from "../../../assets/icons";
import { Container, Point, Section } from "./styles";

const ThreePoints = () => {
  const contents = [
    {
      img: icons.threePoints1,
      title: "Proudly Representing You",
      text: "We’re committed to being your primary platform in voicing your rental concerns.",
    },
    {
      img: icons.threePoints2,
      title: "Advocates For Change",
      text: "The CTA strives to drive industry change with your interests at the forefront.",
    },
    {
      img: icons.threePoints3,
      title: "Comprised Of Experts",
      text: "Our specialist team are trusted experts in commercial legislation and business matters.",
    },
  ];
  return (
    <Section>
      <Container>
        {contents.map((content, i) => {
          return (
            <Point key={i}>
              <img
                src={content.img}
                alt="icon"
              />
              <h3>{content.title}</h3>
              <p>{content.text}</p>
            </Point>
          );
        })}
      </Container>
    </Section>
  );
};

export default ThreePoints;
