import styled from "styled-components";
import { colors } from "../../theme/colors";
import bgs from "../../assets/bgs";
import size from "../../theme/devices/sizes";
import device from "../../theme/devices";

export const Main = styled.main`
    background: #002635;
    padding-top: 112px;
`;

export const Container = styled.div`
    background-image: url(${bgs.accountBg});
    background-size: cover;
    background-repeat: no-repeat;
    max-width: ${size.laptopL};
    margin: 0 auto;
    padding: 23px 160px 82px;

    @media ${device.laptopL} {
        padding: 23px 80px 82px;
    }

    @media ${device.tabletL} {
        flex-direction: column;
        padding: 0;
    }
`;

export const Wrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    border: none;
    border-bottom: 8px solid ${colors.primary};
    border-radius: 4px;

    @media ${device.tabletL} {
        flex-direction: column-reverse;
    }
`;
