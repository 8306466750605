import { propertyImgs } from "../../assets/imgs/property";

export const offerData = {
  leaseRestructuring: {
    img: propertyImgs.propertyOffer1,
    title: "Lease Restructuring",
    text: "Our guide provides an overview of the main points to consider when taking a new lease or sub-lease and assesses the timescales involved in the process. The perceived importance of each of these elements will vary from tenant to tenant, but they are all essential considerations.",
    link: "/property/lease-restructuring",
  },

  leaseRegearing: {
    img: propertyImgs.propertyOffer2,
    title: "Lease Re-Gearing",
    text: "Re-gearing your lease can be a fantastic option for commercial tenants and landlords alike, as both parties can benefit from new lease terms in the middle of the contracted period. ",
    link: "/property/lease-regearing",
  },

  leaseRenewals: {
    img: propertyImgs.propertyOffer3,
    title: "Lease Renewals",
    text: "If a business intends to remain in occupation beyond lease expiry then it will usually require a new lease. In this section, we will explore some of the key considerations for tenants who are approaching the expiry of their lease.",
    link: "/property/lease-renewals",
  },

  surrenders: {
    img: propertyImgs.propertyOffer4,
    title: "Surrenders",
    text: "If you want to bring the tenancy to an end before the actual expiry date in the lease, then you may have to negotiate a deal with the landlord to allow you to walk away. This is called a surrender.",
    link: "/property/surrenders",
  },

  assignments: {
    img: propertyImgs.propertyOffer5,
    title: "Assignments",
    text: "When one commercial tenant surrenders their lease, an assignment will see a new incoming commercial tenant assume control of the leasehold.",
    link: "/property/assignments",
  },

  serviceCharge: {
    img: propertyImgs.propertyOffer6,
    title: "Service Charge",
    text: "Service charges are used by landlords to recover the costs of running and maintaining a building. This section explores what costs can be recovered, lawfully, under the service charge.",
    link: "/property/service-charge",
  },

  rates: {
    img: propertyImgs.propertyOffer7,
    title: "Rates",
    text: "What are business rates? How are they calculated and can they be challenged?",
    link: "/property/rates",
  },

  strategicLeaseManagement: {
    img: propertyImgs.propertyOffer8,
    title: "Strategic Lease Management",
    text: "For commercial tenants with multiple leaseholds, they can avoid the complexities and logistical difficulties that come with us by employing better strategic lease management. ",
    link: "/property/strategic-lease-management",
  },

  ownYourProperty: {
    img: propertyImgs.propertyOffer9,
    title: "Own Your Property",
    text: "By constantly renting, commercial tenants across the UK may be left with either no retirement plan or end up being evicted at the end of their lease. By owning your own property, this can be easily avoided.",
    link: "/property/own-your-property",
  },

  acquisitions: {
    img: propertyImgs.propertyOffer10,
    title: "Acquisitions",
    text: "Business owners in the UK may want to acquire the premises they’re currently leasing, to allow for a more secure and assured future.",
    link: "/property/acquisitions",
  },

  arrears: {
    img: propertyImgs.propertyOffer11,
    title: "Arrears",
    text: "If you’re struggling to make rental payments, or owe money to your landlord, this is considered as being in ‘arrears’. It’s vitally important that if this is ever the case, you should do all you can to pay off these arrears as soon as possible.",
    link: "/property/arrears",
  },

  dilapidation: {
    img: propertyImgs.propertyOffer12,
    title: "Dilapidation",
    text: "Tenants may need to dispose of their interest in a property for a variety of reasons. However, exiting a lease is not a straightforward process and requires careful consideration. This page considers how tenants can dispose of their leased workplace.",
    link: "/property/dilapidations",
  },

  capexSupport: {
    img: propertyImgs.propertyOffer13,
    title: "Capex Support",
    text: "Capex Support (capital expenditure support) is a type of funding used to maintain or upgrade an asset. This could be in the form of plants, buildings, technology or equipment within or surrounding a commercial property.",
    link: "/property/capex-support",
  },

  leaseNegotiations: {
    img: propertyImgs.propertyOffer1,
    title: "Lease Negotiations",
    text: "Our guide provides an overview of the main points to consider when taking a new lease or sub-lease and assesses the timescales involved in the process. The perceived importance of each of these elements will vary from tenant to tenant, but they are all essential considerations.",
    link: "/",
  },
};
