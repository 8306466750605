import styled from "styled-components";
import size from "../../../theme/devices/sizes";
import { colors } from "../../../theme/colors";
import device from "../../../theme/devices";

export const Section = styled.section`
  max-width: ${size.laptopL};
  margin: 0 auto;
`;

export const Container = styled.div`
  padding: 0px 120px 120px;
  display: flex;
  gap: 80px;

  @media ${device.laptop} {
    flex-direction: column;
  }

  @media ${device.tabletL} {
    padding: 120px 56px;
  }

  @media ${device.mobileL} {
    padding: 0px 24px 88px;
    gap: 48px;
  }
`;

export const Left = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 436px;
  gap: 16px;

  > h1 {
    font-size: 40px;
    line-height: 56px;
  }

  > p {
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 16px;
  }

  @media ${device.laptop} {
    max-width: 100%;
  }

  @media ${device.mobileL} {
    > h1 {
      font-size: 32px;
      line-height: 48px;
    }

    > p {
      font-size: 14px;
      line-height: 26px;
    }
  }
`;

export const Right = styled.div`
  display: flex;
  flex-direction: column;
`;

interface QuestionProps {
  selected: boolean;
}

export const Wrapper = styled.div<QuestionProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 666px;
  max-width: 100%;
  padding-bottom: ${({ selected }) => selected && "24px"};
  border-top: 1px solid ${colors.border};
  max-height: ${({ selected }) => (selected ? "500px" : "60px")};
  transition: 0.3s all linear;

  &:nth-child(4) {
    border-bottom: 1px solid ${colors.border};
  }

  > img {
    position: absolute;
  }

  @media ${device.laptop} {
    width: 100%;
  }

  @media (max-width: 411px) {
    &:nth-child(4) {
      max-height: ${({ selected }) => (selected ? "1000px" : "90px")};
    }
  }
`;

export const Question = styled.div<QuestionProps>`
  display: flex;
  justify-content: space-between;
  ${({ selected }) => selected && "background: #e7ecee;"}
  padding: 16px 8px;
  cursor: pointer;

  > p {
    font-size: 16px;
    font-weight: 700;
    line-height: 28px;
  }

  @media ${device.mobileL} {
    > p {
      font-size: 14px;
    }
  }
`;

export const Answer = styled.div<QuestionProps>`
  font-size: 16px;
  line-height: 28px;
  overflow: hidden;

  @media ${device.mobileL} {
    font-size: 12px;
    line-height: 22px;
  }
`;
