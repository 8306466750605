import { useState } from "react";
import ReusableButton from "../../Reusable/ReusableButton";
import {
  Answer,
  Container,
  Left,
  Question,
  Right,
  Section,
  Wrapper,
} from "./styles";
import icons from "../../../assets/icons";
import { useNavigate } from "react-router-dom";

const FAQ = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState<{ [key: string]: boolean }>({
    "1": true,
    "2": false,
    "3": false,
    "4": false,
  });

  const contents = [
    {
      question: "What is the CTA?",
      answer:
        "The CTA is an independent membership organisation representing and empowering over 900,000 tenants UK-wide. The mission is to get fairer deals, more rights and better protection for commercial tenants.",
    },
    {
      question: "How do I join the CTA?",
      answer:
        "If you would like to join the CTA, we offer a free opportunity for UK business owners to be a part of our esteemed organisation. Simply press the ‘Join For Free’ button at any point on our website and a member of our team will be in touch with you soon to discuss your enquiry.",
    },
    {
      question: "Can the CTA help me and my business?",
      answer:
        "Regardless of what industry you work within, or the size and nature of your business, you could easily benefit from the privileges that come with being a member of the Commercial Tenants Association. With some of our membership plans being completely free, we hope to see you become part of our helpful, esteemed organisation. ",
    },
    {
      question: "What benefits does a CTA membership bring me?",
      answer:
        "Members of the CTA can enjoy numerous benefits and special privileges. These include, but are not limited to, unlimited helpful advice from our expert team, discounts on helpful H&S and compliance courses, as well as personal invitations to our member-only events and meetings. ",
    },
  ];

  const handleClick = (i: number) => {
    setShow({
      "1": false,
      "2": false,
      "3": false,
      "4": false,
    });

    const number = (i + 1).toString();
    setShow((prev) => ({ ...prev, [number]: !show[number] }));
  };

  return (
    <Section>
      <Container>
        <Left>
          <h1>FAQs</h1>
          <p>
            For more information about us and the services we offer to
            businesses, please find our Frequently Asked Questions here. <br />
            <br />
            If you can’t find the answer that you’re looking for, then please
            get in touch with us today and a member of our team will be more
            than happy to help you.
          </p>
          <a href="/enquiry">
            <ReusableButton
              name="Enquire Now"
              onClick={() => {}}
            />
          </a>
        </Left>
        <Right>
          {contents.map((content, i) => {
            return (
              <Wrapper
                key={i}
                selected={show[(i + 1).toString()]}
              >
                <Question
                  onClick={() => handleClick(i)}
                  selected={show[(i + 1).toString()]}
                >
                  <p> {content.question}</p>
                  {show[(i + 1).toString()] ? (
                    <img
                      src={icons.minus}
                      alt="minus"
                    />
                  ) : (
                    <img
                      src={icons.plus}
                      alt="plus"
                    />
                  )}
                </Question>
                <Answer selected={show[(i + 1).toString()]}>
                  {content.answer}
                </Answer>
              </Wrapper>
            );
          })}
        </Right>
      </Container>
    </Section>
  );
};

export default FAQ;
