import axios from "axios";

const getArticles = async (topic: string, page: string, keyword: string) => {
  try {
    const categoryId =
      topic === "Insights" ? "3" : topic === "Hot Topics" ? "2" : "1";

    const res = await axios({
      method: "get",
      url: `${process.env.REACT_APP_API}/article/list?page=${page}&categoryId=${categoryId}&keyword=${keyword}`,
    });

    return res?.data;
  } catch (err) {
    console.log("Err in axios get article", err);
    throw "Error in axios get article";
  }
};

export default getArticles;
