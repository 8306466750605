import disposingOfLeaseholdProperty from "./disposingOfLeaseholdProperty.pdf";
import leaseNegotiationsGuide from "./leaseNegotiationsGuide.pdf";
import leaseRenewalTimeline from "./leaseRenewalTimeline.pdf";
import leaseRenewals from "./leaseRenewals.pdf";
import newLeaseTimeline from "./newLeaseTimeline.pdf";
import newLicenseTimeline from "./newLicenseTimeline.pdf";
import rentReviewDisputes from "./rentReviewDisputes.pdf";
import serviceCharges from "./serviceCharges.pdf";

const downloadable = {
  rentReviewDisputes,
  leaseNegotiationsGuide,
  leaseRenewalTimeline,
  leaseRenewals,
  newLeaseTimeline,
  newLicenseTimeline,
  disposingOfLeaseholdProperty,
  serviceCharges,
};

export default downloadable;
