import React, { useState } from "react";
import {
  Answer,
  Bottom,
  Column,
  Container,
  Question,
  Section,
  Titles,
  Top,
  Wrapper,
} from "./styles";
import ReusableButton from "../../../Reusable/ReusableButton";
import icons from "../../../../assets/icons";
import useWindowSize, { Size } from "../../../../utils/useWindowSize";
import size from "../../../../theme/devices/sizes";

interface ReusableExpandingProps {
  contents: any;
}

const ReusableExpanding: React.FC<ReusableExpandingProps> = ({ contents }) => {
  const windowSize: Size = useWindowSize();
  const tabletL = +size.tabletL.slice(0, -2);
  const [show, setShow] = useState<{ [key: string]: boolean }>({
    "0": true,
    "1": false,
    "2": false,
    "3": false,
    "4": false,
    "5": false,
    "6": false,
    "7": false,
  });

  const handleClick = (i: number) => {
    setShow({
      "0": false,
      "1": false,
      "2": false,
      "3": false,
      "4": false,
      "5": false,
      "6": false,
      "7": false,
    });
    const number = i.toString();
    setShow((prev) => ({ ...prev, [number]: !show[number] }));
  };

  return (
    <Section>
      <Container>
        <Top>
          <Titles>
            <h1>{contents.title}</h1>
            <p>{contents.subTitle}</p>
          </Titles>
          {windowSize.width! > tabletL && (
            <a href="/enquiry">
              <ReusableButton
                name="Enquire now"
                onClick={() => {}}
              />
            </a>
          )}
        </Top>
        <Bottom>
          <Column>
            {contents.qa.map((qa: any, i: number) => {
              return (
                <React.Fragment key={i}>
                  {i % 2 === 0 && (
                    <Wrapper selected={show[i.toString()]}>
                      <Question
                        onClick={() => handleClick(i)}
                        selected={show[i.toString()]}
                      >
                        <p>{qa.question}</p>
                        {show[i.toString()] ? (
                          <img
                            src={icons.minusGrey}
                            alt="minus"
                          />
                        ) : (
                          <img
                            src={icons.plus}
                            alt="plus"
                          />
                        )}
                      </Question>
                      <Answer>{qa.answer}</Answer>
                    </Wrapper>
                  )}
                </React.Fragment>
              );
            })}
          </Column>
          <Column>
            {contents.qa.map((qa: any, i: number) => {
              return (
                <React.Fragment key={i}>
                  {i % 2 !== 0 && (
                    <Wrapper selected={show[i.toString()]}>
                      <Question
                        onClick={() => handleClick(i)}
                        selected={show[i.toString()]}
                      >
                        <p>{qa.question}</p>
                        {show[i.toString()] ? (
                          <img
                            src={icons.minusGrey}
                            alt="minus"
                          />
                        ) : (
                          <img
                            src={icons.plus}
                            alt="plus"
                          />
                        )}
                      </Question>
                      <Answer>{qa.answer}</Answer>
                    </Wrapper>
                  )}
                </React.Fragment>
              );
            })}
          </Column>
        </Bottom>
        {windowSize.width! <= tabletL && (
          <a href="/enquiry">
            <ReusableButton
              name="Enquire now"
              onClick={() => {}}
            />
          </a>
        )}
      </Container>
    </Section>
  );
};

export default ReusableExpanding;
