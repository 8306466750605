import imgs from "../../assets/imgs";
import Charter from "../../component/AboutPage/Charter";
import EnquiryForm from "../../component/Reusable/EnquiryForm";
import Help from "../../component/Reusable/Help";
import Review from "../../component/Reusable/Review";
import Values from "../../component/Reusable/Values";
import ReusableHero from "../../component/Reusable/ReusableHero";
import { Main } from "./styles";
import bgs from "../../assets/bgs";

const AboutPage = () => {
  const heroContents = {
    type: 1,
    title: (
      <h1>
        We’re Empowering Commercial Tenants To {" "}
        <span>Correct The Imbalance.</span>
      </h1>
    ),
    text: (
      <p>
        We champion the interests of over{" "}
        <strong>900,000 lease and license holders across the UK,</strong>{" "}
        creating a unified voice to drive permanent change in the industry.
      </p>
    ),
    img: imgs.aboutHero,
    mobileimg: bgs.aboutHeroBgMobile,
    button: false,
  };

  return (
    <Main>
      <ReusableHero contents={heroContents} />
      <Values aboutPage={true} />
      <Charter />
      <Review />
      <Help />
      <EnquiryForm />
    </Main>
  );
};

export default AboutPage;
