import { Main, Container, Wrapper } from "./styles";
import { useLocation } from "react-router-dom";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp/SignUp";
import { useEffect } from "react";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import Left from "./pages/Left";

const AccountPage = () => {
  const location = useLocation();
  const pathname = location.pathname.split("/")[1];

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <Main>
      <Container>
        <Wrapper>
          <Left />
          {pathname === "login" ? (
            <Login />
          ) : pathname === "forgot-password" ? (
            <ForgotPassword />
          ) : pathname === "reset-password" ? (
            <ResetPassword />
          ) : (
            <SignUp />
          )}
        </Wrapper>
      </Container>
    </Main>
  );
};

export default AccountPage;
