import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface StepState {
  step: number;
}

const initialState: StepState = {
  step: 1,
};

const enquiryStepSlice = createSlice({
  name: "enquiryStep",
  initialState,
  reducers: {
    updateEnquiryStep: (state, action: PayloadAction<number>) => {
      state.step = action.payload;
    },
    resetEnquiryStep: (state) => {
      state.step = initialState.step;
    },
  },
});

export const { updateEnquiryStep, resetEnquiryStep } = enquiryStepSlice.actions;
export default enquiryStepSlice.reducer;
