import { propertyImgs } from "../../../assets/imgs/property";
import EnquiryForm from "../../../component/Reusable/EnquiryForm";
import Offer from "../../../component/Reusable/Offer";
import NeedAdvice from "../../../component/Reusable/NeedAdvice";
import ReusableIntro from "../../../component/Reusable/ReusableIntro";
import ReusableHero from "../../../component/Reusable/ReusableHero";
import { offerData } from "../offerData";
import bgs from "../../../assets/bgs";

const Assignments = () => {
  const heroContents = {
    type: 2,
    title: <h1>Assignments</h1>,
    text: (
      <p>
        When one commercial tenant surrenders their lease, an assignment will
        see a new incoming commercial tenant assume control of the leasehold.
      </p>
    ),
    img: bgs.assignmentsHeroBg,
    mobileimg: bgs.assignmentsHeroBgMobile,
    button: true,
  };

  const introContents = {
    title: "What You Need To Know About Assignments",
    text: (
      <p>
        The advantages of assuming a commercial lease can include a
        lower-than-market rent level, as well as the opportunity to renegotiate
        certain rental terms adjudged to be ‘unfair’ or not in the best interest
        of any party involved.
        <br />
        <br />
        However, these assignments are still subject to similar agent, legal,
        and due-diligence fees, and can be a longer, more complex process to
        finalise than your standard rental agreement.
      </p>
    ),
    img: propertyImgs.assignmentsIntro,
    link: "/enquiry",
  };

  const offerContents = [
    offerData.surrenders,
    offerData.strategicLeaseManagement,
  ];

  return (
    <>
      <ReusableHero contents={heroContents} />
      <ReusableIntro contents={introContents} />
      <NeedAdvice />
      <Offer
        contents={offerContents}
        title="Similar Services"
      />
      <EnquiryForm />
    </>
  );
};

export default Assignments;
