import { corporateImgs } from "../../../assets/imgs/corporate";
import EnquiryForm from "../../../component/Reusable/EnquiryForm";
import Offer from "../../../component/Reusable/Offer";
import NeedAdvice from "../../../component/Reusable/NeedAdvice";
import ReusableIntro from "../../../component/Reusable/ReusableIntro";
import ReusableHero from "../../../component/Reusable/ReusableHero";
import { offerData } from "../offerData";
import bgs from "../../../assets/bgs";

const MergersAcquisition = () => {
  const heroContents = {
    type: 2,
    title: <h1>Mergers & Acquisition</h1>,
    text: (
      <p>
        Whether you’re acquiring or selling a business, we work closely with the
        sale and purchase of businesses to provide clear advice on the transfer
        of commercial property.
      </p>
    ),
    img: bgs.mergersAcquisitionHeroBg,
    mobileimg: bgs.mergersAcquisitionHeroBgMobile,
    button: true,
  };

  const introContents = {
    title: "Years of experience",
    text: (
      <p>
        We apply our focus on ownership, tax, and due diligence structuring
        which are all vital in the success of a property transaction, ensuring
        you have everything from a legal and safety perspective, covered.
      </p>
    ),
    img: corporateImgs.mergersAcquisitionIntro,
    link: "/enquiry",
  };

  const offerContents = [offerData.litigation, offerData.accountants];

  return (
    <>
      <ReusableHero contents={heroContents} />
      <ReusableIntro contents={introContents} />
      <NeedAdvice />
      <Offer
        contents={offerContents}
        title="Similar Services"
      />
      <EnquiryForm />
    </>
  );
};

export default MergersAcquisition;
