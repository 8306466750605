import styled from "styled-components";
import size from "../../../theme/devices/sizes";
import { colors } from "../../../theme/colors";
import device from "../../../theme/devices";

export const Section = styled.section`
  max-width: ${size.laptopL};
  margin: 0 auto;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 120px;

  @media ${device.tabletL} {
    padding: 88px 48px;
    align-items: center;
  }

  @media ${device.mobileL} {
    padding: 88px 24px;
    align-items: center;
    gap: 32px;
  }

  @media ${device.mobileML} {
    align-items: flex-start;
  }
`;

export const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  h1 {
    font-size: 40px;
    line-height: 56px;

    @media ${device.mobileL} {
      font-size: 32px;
      line-height: 48px;
    }
  }
`;

export const Main = styled.div`
  width: 100%;
`;

export const MainLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
  width: 579px;

  > h2 {
    font-size: 20px;
    line-height: 32px;
  }

  > p {
    font-size: 14px;
    line-height: 24px;
  }

  @media ${device.mobileL} {
    width: 327px;

    > h2 {
      font-size: 20px;
    }

    > p {
      font-size: 12px;
    }
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid ${colors.border};
  padding-top: 16px;

  p {
    font-size: 14px;
    line-height: 24px;
  }

  @media ${device.mobileL} {
    p {
      font-size: 12px;
    }
  }
`;

export const MainRight = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 40px;

  @media ${device.tabletL} {
    justify-content: center;
  }

  @media ${device.mobileL} {
    gap: 32px;
  }
`;

export const Article = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
  max-width: 269px;

  > img {
    width: 269px;
    height: 130px;
  }

  > h3 {
    font-size: 16px;
    line-height: 24px;
  }

  @media ${device.mobileL} {
    width: 327px;

    > h3 {
      font-size: 14px;
      line-height: 26px;
    }
  }
`;

export const ArticleTop = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
