import { corporateImgs } from "../../../assets/imgs/corporate";
import EnquiryForm from "../../../component/Reusable/EnquiryForm";

import Offer from "../../../component/Reusable/Offer";
import NeedAdvice from "../../../component/Reusable/NeedAdvice";
import ReusableIntro from "../../../component/Reusable/ReusableIntro";
import ReusableHero from "../../../component/Reusable/ReusableHero";
import { offerData } from "../offerData";
import bgs from "../../../assets/bgs";

const Accountants = () => {
  const heroContents = {
    type: 2,
    title: <h1>Accountants</h1>,
    text: (
      <p>
        Accountants in the commercial property sector can provide a wealth of
        assistance to you.
      </p>
    ),
    img: bgs.accountantsHeroBg,
    mobileimg: bgs.accountantsHeroBgMobile,
    button: true,
  };

  const introContents = {
    title: "Know the numbers of your business",
    text: (
      <p>
        If you're new to the market space or an experienced businessperson,
        achieving the returns from your property does require astute management,
        in-depth knowledge and access to the correct funding for each asset.
        <br />
        <br /> In the commercial and residential property sectors, our team have
        a wealth of knowledge to help advise and support your cause, assisting a
        diverse range of businesspeople wanting a commercial property.
      </p>
    ),
    img: corporateImgs.accountantsIntro,
    link: "/enquiry",
  };

  const offerContents = [offerData.insurance, offerData.legal];

  return (
    <>
      <ReusableHero contents={heroContents} />
      <ReusableIntro contents={introContents} />
      <NeedAdvice />
      <Offer
        contents={offerContents}
        title="Similar Services"
      />
      <EnquiryForm />
    </>
  );
};

export default Accountants;
