import { corporateImgs } from "../../../assets/imgs/corporate";
import EnquiryForm from "../../../component/Reusable/EnquiryForm";
import Offer from "../../../component/Reusable/Offer";
import NeedAdvice from "../../../component/Reusable/NeedAdvice";
import ReusableIntro from "../../../component/Reusable/ReusableIntro";
import ReusableHero from "../../../component/Reusable/ReusableHero";
import { offerData } from "../offerData";
import bgs from "../../../assets/bgs";

const Litigation = () => {
  const heroContents = {
    type: 2,
    title: <h1>Litigation</h1>,
    text: (
      <p>
        If you believe your commercial landlord has violated the terms of your
        agreement, then you may seek to pursue litigation to ensure the
        situation is adequately resolved.
      </p>
    ),
    img: bgs.litigationHeroBg,
    mobileimg: bgs.litigationHeroBgMobile,
    button: true,
  };

  const introContents = {
    title: "Why You May Opt For This",
    text: (
      <p>
        Your commercial tenancy agreement will clearly lay out the terms by
        which both you and your landlord must abide by, including rental
        payments, repair situations, and the landlord respecting you and your
        business’s privacy on the premises. 
        <br />
        <br />
        If your landlord violates clauses of your contract, you may opt for
        litigation or other forms of action in order to maintain your dignity
        and receive some form of compensation as a result of these violations.
      </p>
    ),
    img: corporateImgs.litigationIntro,
    link: "/enquiry",
  };

  const offerContents = [offerData.mergersAcquisitions, offerData.mediation];

  return (
    <>
      <ReusableHero contents={heroContents} />
      <ReusableIntro contents={introContents} />
      <NeedAdvice />
      <Offer
        contents={offerContents}
        title="Similar Services"
      />
      <EnquiryForm />
    </>
  );
};

export default Litigation;
