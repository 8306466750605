import axios from "axios";

const subscribe = async (type: string, data: any) => {
  try {
    const res = await axios.post(
      process.env.REACT_APP_API + `/subscribe/${type}`,
      data,
    );
    return res.data;
  } catch (err) {
    console.log("Subscribe Error: ", err);
    return err;
  }
};

export default subscribe;
