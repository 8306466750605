import { corporateImgs } from "../../assets/imgs/corporate";

export const offerData = {
  insolvencyAdvice: {
    img: corporateImgs.corporateOffer1,
    title: "Insolvency Advice",
    text: "We deal in commercial/corporate insolvency – if businesses cannot meet their financial obligations, we’re here to help find a solution.",
    link: "/corporate/insolvency-advice",
  },

  insurance: {
    img: corporateImgs.corporateOffer2,
    title: "Insurance",
    text: "What are the tenant’s insuring responsibilities and what are the landlord’s during a tenancy?",
    link: "/corporate/insurance",
  },

  litigation: {
    img: corporateImgs.corporateOffer3,
    title: "Litigation",
    text: "If you believe your commercial landlord has violated the terms of your agreement, then you may seek to pursue litigation to ensure the situation is adequately resolved. ",
    link: "/corporate/litigation",
  },

  financeInvestments: {
    img: corporateImgs.corporateOffer4,
    title: "Finance & Investments",
    text: "Commercial property can be a really important asset which allows you to spread and diversify risk in your investment portfolio.",
    link: "/corporate/finance-investments",
  },

  mergersAcquisitions: {
    img: corporateImgs.corporateOffer5,
    title: "Mergers & Acquisitions",
    text: "Whether you’re acquiring or selling a business, we work closely with the sale and purchase of businesses to provide clear advice on the transfer of commercial property. ",
    link: "/corporate/mergers-acquisition",
  },

  accountants: {
    img: corporateImgs.corporateOffer6,
    title: "Accountants",
    text: "Accountants in the commercial property sector can provide a wealth of assistance to you. ",
    link: "/corporate/accountants",
  },

  legal: {
    img: corporateImgs.corporateOffer7,
    title: "Legal",
    text: "We can find you a range of legal services that are designed to support your business, including litigation, property-related matters and commercial issues.",
    link: "/corporate/legal",
  },
  mediation: {
    img: corporateImgs.corporateOffer8,
    title: "Mediation",
    text: "Commercial mediation is a form of dispute resolution in which a neutral third party (the mediator) assists parties to agree to work towards a settlement of their dispute.",
    link: "/corporate/mediation",
  },
  businessRates: {
    img: corporateImgs.corporateOffer9,
    title: "Business Rates",
    text: "What are business rates? How are they calculated and can they be challenged?",
    link: "/property/rates",
  },
  disputeResolution: {
    img: corporateImgs.corporateOffer10,
    title: "Dispute Resolution",
    text: "There are various areas of dispute between commercial landlords and tenants. This section is designed to help identify and prevent these disputes from arising or escalating.",
    link: "/",
  },
  tenantCompliance: {
    img: corporateImgs.corporateOffer10,
    title: "Tenant Compliance",
    text: "The various obligations of commercial tenants are explained within this section.",
    link: "/",
  },
};
