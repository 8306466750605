import EnquiryForm from "../../../component/Reusable/EnquiryForm";
import Offer from "../../../component/Reusable/Offer";
import NeedAdvice from "../../../component/Reusable/NeedAdvice";
import ReusableHero from "../../../component/Reusable/ReusableHero";
import { offerData } from "../offerData";
import InsuranceIntro from "../../../component/CorporatePage/InsuranceIntro";
import bgs from "../../../assets/bgs";

const Insurance = () => {
  const heroContents = {
    type: 2,
    title: <h1>Insurance</h1>,
    text: (
      <p>
        Details of insurance will be clearly stated within the lease and will
        determine who is responsible for insuring what within a commercial
        property. Generally, this is a combination of landlord and tenant
        working in tandem to cover the property for which both have an interest.
      </p>
    ),
    img: bgs.insuranceHeroBg,
    mobileimg: bgs.insuranceHeroBgMobile,
    button: true,
  };

  const offerContents = [offerData.insolvencyAdvice, offerData.accountants];

  return (
    <>
      <ReusableHero contents={heroContents} />
      <InsuranceIntro />
      <NeedAdvice />
      <Offer
        contents={offerContents}
        title="Similar Services"
      />
      <EnquiryForm />
    </>
  );
};

export default Insurance;
