import { useState } from "react";
import {
  Checkbox,
  CheckboxWrapper,
  ContactWrapper,
  InputField,
  InputWrapper,
  Remark,
  StepMain,
  Title,
} from "../styles";
import ReusableButton from "../../ReusableButton";
import { useAppDispatch } from "../../../../redux/hooks";
import { updateEnquiryForm } from "../../../../redux/features/enquiryFormSlice";
import { updateEnquiryStep } from "../../../../redux/features/enquiryStepSlice";
import { useNavigate } from "react-router-dom";
import icons from "../../../../assets/icons";
import submitEnquiryForm from "../../../../utils/submitEnquiry";
import store from "../../../../redux/store";
import { phoneValidation } from "../../../../utils/phone-validation";
import { Notify } from "notiflix";
import { emailValidation } from "../../../../utils/email-validation";

interface Step7Props {
  setSubmitted: any;
}

const Step7: React.FC<Step7Props> = ({ setSubmitted }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [details, setDetails] = useState<{
    email: string;
    phone: string;
    isSubscribe: boolean;
  }>({
    email: "",
    phone: "",
    isSubscribe: false,
  });
  const [show, setShow] = useState<{
    email: boolean;
    phone: boolean;
  }>({
    email: false,
    phone: false,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (name === "email") {
      let trimmedEmail = value.trim();
      setDetails((prev) => ({ ...prev, email: trimmedEmail }));
    } else {
      const trimPhone = value.replace(/\D/g, "");
      setDetails((prev) => ({
        ...prev,
        phone: trimPhone,
      }));
    }
  };

  const handleSubmit = async () => {
    try {
      if (details.email.length === 0) {
        return Notify.failure("Please provide your email address.");
      }
      const emailRes = await emailValidation(details.email);
      if (!emailRes) {
        return Notify.failure("Please provide a valid email address.");
      }
      if (details.phone.length === 0) {
        return Notify.failure("Please provide your phone number.");
      }
      const phoneRes = await phoneValidation(parseInt(details.phone));
      if (!phoneRes) {
        return Notify.failure("Please provide a valid phone number.");
      }

      dispatch(
        updateEnquiryForm({
          email: details.email,
          phone: details.phone,
          isSubscribe: details.isSubscribe,
        }),
      );

      const formData = store.getState().enquiryForm;

      console.log(formData);

      const res = await submitEnquiryForm(formData);

      if (res.success) {
        navigate("/enquiry/thankyou");
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <StepMain>
      <Title>How can we contact you?</Title>
      <ContactWrapper>
        <InputWrapper>
          {details.email.length > 0 || show.email ? <p>Email address</p> : null}
          <InputField
            name="email"
            value={details.email}
            onChange={handleChange}
            onFocus={() => setShow((prev) => ({ ...prev, email: true }))}
            onBlur={() => setShow((prev) => ({ ...prev, email: false }))}
            $show={details.email.length > 0}
            placeholder="Email address"
          />
        </InputWrapper>
        <InputWrapper $show={show.phone}>
          {details.phone.length > 0 || show.phone ? <p>Phone number</p> : null}
          <InputField
            name="phone"
            value={details.phone}
            onChange={handleChange}
            onFocus={() => setShow((prev) => ({ ...prev, phone: true }))}
            onBlur={() => setShow((prev) => ({ ...prev, phone: false }))}
            $show={details.phone.length > 0}
            placeholder="Phone number"
            inputMode="numeric"
          />
        </InputWrapper>
      </ContactWrapper>
      <CheckboxWrapper>
        <Checkbox
          checked={details.isSubscribe}
          onClick={() =>
            setDetails((prev) => ({
              ...prev,
              isSubscribe: !details.isSubscribe,
            }))
          }
        >
          <img
            src={icons.tick}
            alt="tick"
          />
        </Checkbox>
        <p>
          Keep up-to-date with the latest events and industry news, courtesy of
          the CTA
        </p>
      </CheckboxWrapper>
      <ReusableButton
        $primary={true}
        name="Submit"
        onClick={handleSubmit}
      />

      <Remark>
        <img
          src={icons.info}
          alt="i-icon"
        />
        <p>
          A member of our team will use this information to contact you
          regarding your enquiry.
        </p>
      </Remark>
    </StepMain>
  );
};

export default Step7;
