import { useState } from "react";
import {
  Bottom,
  Container,
  Top,
  Input,
  InputWrapper,
  InputsCtn,
} from "./styles";
import icons from "../../../../assets/icons";
import ReusableButton from "../../../../component/Reusable/ReusableButton";
import forgotPassword from "../../../../utils/forgotPassword";
import { Notify } from "notiflix";
import { emailValidation } from "../../../../utils/email-validation";

const ForgotPassword = () => {
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const handleChange = (e: any) => {
    const { value } = e.target;
    setEmail(value);
  };

  const handleFocus = () => {
    setShow(true);
  };

  const handleBlur = () => {
    if (email.length === 0) {
      setShow(false);
    }
  };

  const handleReset = async () => {
    try {
      if (!email) {
        Notify.failure("Please provide your email.");
      }
      const emailRes = await emailValidation(email);
      if (!emailRes) {
        return Notify.failure("Please provide a valid email address.");
      }

      const res = await forgotPassword({ email: email });

      if (res.success) {
        setSubmitted(true);
        Notify.success("We have sent you an email.");
      } else {
        setError(true);
        if (res === "User does not exist") {
          setErrorMsg("*Sorry, this email does not match with any account.");
        }
      }
    } catch (err: any) {
      console.log(err);
    }
  };

  return (
    <Container>
      <Top>
        {submitted ? (
          <>
            <h1>Password Reset Sent</h1>
            <p>
              We have sent an email to <strong>{email}</strong> with a link to
              reset your password. It may take a couple of minutes to come
              through.
            </p>
            <p>Not received an email yet?</p>
            <ReusableButton
              $primary={true}
              name="Resend Email"
              width="230px"
              onClick={handleReset}
            />
          </>
        ) : (
          <>
            <h1>Forgotten Password</h1>
            <p>To reset your password, enter the email you used to sign up.</p>
            <InputsCtn>
              <InputWrapper
                $show={show}
                $error={error}
              >
                <p>Email address</p>
                <img
                  src={icons.userIconBlue}
                  alt="user-icon"
                />
                <Input
                  name="email"
                  value={email}
                  onFocus={() => handleFocus()}
                  onBlur={() => handleBlur()}
                  $show={email.length > 0}
                  $error={error}
                  placeholder="Email address"
                  onChange={handleChange}
                />
                {error && <span id="error">{errorMsg}</span>}
              </InputWrapper>
            </InputsCtn>
            <ReusableButton
              $primary={true}
              name="Reset My Password"
              width="230px"
              onClick={handleReset}
            />
          </>
        )}
      </Top>
      <Bottom>
        <a href="/login">
          <strong>Back To Login</strong>
        </a>
      </Bottom>
    </Container>
  );
};

export default ForgotPassword;
