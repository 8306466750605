import styled from "styled-components";
import { colors } from "../../../theme/colors";
import size from "../../../theme/devices/sizes";
import device from "../../../theme/devices";

export const Section = styled.section`
  background: ${colors.greenLight2};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${size.laptopL};
  margin: 0 auto;
  padding: 80px 120px 84px;
  color: ${colors.white};
  gap: 56px;

  @media ${device.tabletL} {
    padding: 80px 56px 84px;
    align-items: center;
  }

  @media ${device.mobileL} {
    padding: 64px 24px;
    align-items: flex-start;
    gap: 32px;
  }
`;

export const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const Titles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  > h1 {
    font-size: 40px;
    line-height: 56px;
  }

  p {
    font-size: 16px;
    line-height: 28px;
  }

  @media ${device.mobileL} {
    gap: 16px;

    > h1 {
      font-size: 32px;
      line-height: 48px;
    }

    p {
      font-size: 14px;
      line-height: 26px;
    }
  }
`;

export const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
`;

export const ItemsCtn = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 48px;

  @media ${device.tabletL} {
    flex-direction: column;
    align-items: center;
  }
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 559px;

  > img {
    width: 56px;
  }

  > h3 {
    font-size: 24px;
    line-height: 32px;
  }

  > p {
    font-size: 16px;
    line-height: 28px;
  }

  @media ${device.mobileL} {
    > img {
      width: 48px;
    }
    > h3 {
      font-size: 20px;
    }

    > p {
      font-size: 14px;
    }
  }
`;

export const LastItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: rgba(35, 215, 229, 0.1);
  padding: 32px;
  border-bottom: 8px solid ${colors.primary};

  > img {
    width: 32px;
  }

  > h3 {
    font-size: 24px;
    line-height: 32px;
  }

  > p {
    font-size: 16px;
    line-height: 28px;
  }

  @media ${device.mobileL} {
    padding: 24px 16px;

    > h3 {
      font-size: 20px;
    }

    > p {
      font-size: 14px;
      line-height: 26px;
    }
  }
`;
