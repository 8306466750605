import styled from "styled-components";
import { colors } from "../../theme/colors";
import device from "../../theme/devices";
import bgs from "../../assets/bgs";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: 1440px;
  margin: 0 auto;

  padding: 115px 120px 120px;
  color: ${colors.greenDark};

  @media ${device.laptopL} {
    padding: 115px 56px 120px;
  }

  @media ${device.tabletL} {
    padding: 115px 56px 120px;
  }

  @media ${device.mobileL} {
    padding: 115px 24px 60px;
  }
`;
export const Header = styled.div`
  display: flex;
  flex-direction: column;
  /* max-width: 824px; */

  @media ${device.laptopL} {
    max-width: 100%;
  }
`;

export const Main = styled.div`
  display: flex;
  justify-content: center;

  position: relative;
  gap: 76px;
`;

export const Title = styled.div`
  > p {
    font-size: 14px;
    line-height: 24px;
  }

  > h1 {
    font-size: 40px;
    line-height: 56px;
  }

  @media ${device.mobileL} {
    > p {
      font-size: 12px;
      line-height: 24px;
    }

    > h1 {
      font-size: 32px;
      line-height: 48px;
    }
  }
`;

export const TitleFooter = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 24px;
  border-top: 1px solid ${colors.border};

  > p {
    font-size: 14px;
    line-height: 24px;
  }

  @media ${device.mobileL} {
    > p {
      font-size: 12px;
      line-height: 24px;
    }
  }
`;

export const Share = styled.div`
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  align-items: center;
  /* margin-bottom: 56px; */

  > p {
    font-size: 14px;
    line-height: 24px;
    margin-right: 8px;
  }

  a {
    width: 32px;
    height: 32px;
    &:hover {
      cursor: pointer;
    }
  }

  @media ${device.mobileL} {
    justify-content: flex-start;
    > p {
      font-size: 12px;
      line-height: 24px;
    }
  }
`;

export const Article = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: 100%;
  /* max-width: 824px; */
  /* width: 60%; */

  > img {
    max-width: 824px;
  }

  > h2 {
    font-size: 24px;
    line-height: 40px;
  }

  > p {
    font-size: 16px;
    line-height: 28px;

    > strong {
      font-size: 24px;
    }

    > b {
      font-size: 24px;
    }
  }

  .caption {
    > p {
      font-size: 24px;
      font-weight: 700;

      a {
        color: #f6a100;
      }
    }
    a {
      color: #f6a100;
    }
  }

  .content {
    > p {
      font-size: 16px;
      line-height: 28px;

      b {
        line-height: 40px;

        a {
          color: #f6a100;
        }
      }

      a {
        color: #f6a100;
      }
    }
  }

  @media (max-width: 1000px) {
    max-width: 100%;
    width: 100%;

    .content {
      > p {
        font-size: 14px;
        line-height: 26px;

        > strong {
          font-size: 20px;
          line-height: 40px;
        }

        > b {
          font-size: 20px;
          line-height: 40px;
          color: #20566b;

          > a {
            color: #f6a100;
          }
        }
        > a {
          color: #f6a100;
        }
      }
    }
  }
`;

export const Right = styled.div`
  position: sticky;
  top: 0;
  background-image: url(${bgs.newsArticleHelp});
  background-size: cover;
  background-repeat: no-repeat;
  width: 369px;
  height: 318px;
  display: flex;
  flex-direction: column;
  padding: 24px;
  color: ${colors.white};
  gap: 16px;
  min-width: 369px;

  > h2 {
    font-size: 24px;
    line-height: 32px;
  }

  > p {
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 16px;
  }

  @media (max-width: 1000px) {
    display: none;
  }
`;

export const OtherArticlesSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 56px;

  > h2 {
    font-size: 40px;
    line-height: 56px;
  }

  @media ${device.mobileL} {
    gap: 24px;

    > h2 {
      font-size: 32px;
      line-height: 48px;
    }
  }
`;

export const OtherArticlesCtn = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 40px;

  @media ${device.laptop} {
    flex-direction: column;
  }
`;

export const OtherArticle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 579px;
  cursor: pointer;

  @media ${device.laptopL} {
    max-width: 100%;
  }
`;

export const ArticleTop = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  > img {
    max-height: 364px;
  }

  > h3 {
    font-size: 20px;
    line-height: 32px;
  }

  > p {
    font-size: 14px;
    line-height: 24px;
    font-weight: 500;
  }

  > span {
    > p {
      font-size: 14px;
      line-height: 24px;
      font-weight: 500;
    }
  }

  @media ${device.mobileL} {
    > p {
      font-size: 12px;
      line-height: 24px;
    }
  }
`;

export const ArticleFooter = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  line-height: 24px;
  color: ${colors.greenLight2};
  padding-top: 16px;
  border-top: 1px solid ${colors.border};

  > p {
    font-size: 14px;
    line-height: 24px;
  }

  @media ${device.mobileL} {
    > p {
      font-size: 12px;
      line-height: 24px;
      font-weight: 500;
    }
  }
`;

export const LoadingScreen = styled.div`
  min-width: 100vw;
  min-height: 100vh;
  background: #002635;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    color: white;
  }
`;
