import { useState } from "react";
import ReusableButton from "../../ReusableButton";
import { InputField, InputWrapper, StepMain, Title } from "../styles";
import { useAppDispatch } from "../../../../redux/hooks";
import { updateEnquiryForm } from "../../../../redux/features/enquiryFormSlice";
import { updateEnquiryStep } from "../../../../redux/features/enquiryStepSlice";
import icons from "../../../../assets/icons";

const Step4 = () => {
  const dispatch = useAppDispatch();
  const [city, setCity] = useState<string>("");
  const [show, setShow] = useState<boolean>(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setCity(value);
  };

  const handleClick = () => {
    dispatch(updateEnquiryForm({ city: city }));
    dispatch(updateEnquiryStep(5));
  };

  return (
    <StepMain>
      <Title>Where are you located?</Title>
      <InputWrapper $show={show}>
        {city.length > 0 || show ? <p>Town / City</p> : null}
        <img
          src={icons.location}
          alt="location"
        />
        <InputField
          name="city"
          value={city}
          onChange={handleChange}
          onFocus={() => setShow(true)}
          onBlur={() => setShow(false)}
          $show={city.length > 0}
          placeholder="Town / City"
        />
      </InputWrapper>
      <ReusableButton
        $primary={true}
        name="Continue"
        onClick={handleClick}
      />
    </StepMain>
  );
};

export default Step4;
