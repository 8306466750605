import Articles from "../../component/Lobbying/Articles";
import Benefits from "../../component/LandingPage/Benefits";
import EnquiryForm from "../../component/Reusable/EnquiryForm";
import Help from "../../component/Reusable/Help";
import Review from "../../component/Reusable/Review";
import Service from "../../component/Reusable/Service";
import Values from "../../component/Reusable/Values";
import { Main } from "./styles";
import LandingHero from "../../component/LandingPage/LandingHero";
import { useRef } from "react";

const LandingPage = () => {
  const scrollToValues = useRef<HTMLDivElement | null>(null);

  const handleValuesScroll = () => {
    if (scrollToValues.current) {
      scrollToValues.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Main>
      <LandingHero handleValuesScroll={handleValuesScroll} />
      <Values scrollRef={scrollToValues} />
      <Service />
      <Benefits />
      <Review />
      <Help />
      <Articles />
      <EnquiryForm />
    </Main>
  );
};

export default LandingPage;
