import styled from "styled-components";
import { colors } from "../../../theme/colors";
import size from "../../../theme/devices/sizes";
import device from "../../../theme/devices";

export const Section = styled.section``;

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 120px 56px;
  gap: 120px;
  max-width: ${size.laptopL};
  margin: 0 auto;

  @media ${device.mobileL} {
    gap: 88px;
    padding: 88px 0px;
  }
`;

export const BackgroundBlock = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  width: 475px;
  z-index: -1;

  @media ${device.mobileL} {
    top: 135px;
    width: 248px;
    height: 248px;
  }
`;

export const Top = styled.div`
  display: flex;
  gap: 80px;

  > img {
    order: 2;
    max-width: 559px;
    margin: 0 auto;
  }

  @media ${device.laptop} {
    flex-direction: column;

    > img {
      order: 1;
      width: 100%;
    }
  }

  @media ${device.mobileL} {
    padding: 0px 24px;
    gap: 32px;

    > img {
      max-width: 327px;
    }
  }
`;

export const TopParagraph = styled.div`
  display: flex;
  flex-direction: column;
  order: 1;
  gap: 8px;
  max-width: 559px;
  margin: 0 auto;
  color: ${colors.greenDark};

  > h1 {
    font-size: 40px;
    line-height: 56px;
  }

  > p {
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 24px;
  }

  @media ${device.laptop} {
    order: 2;
  }

  @media ${device.mobileL} {
    max-width: 100%;
    > h1 {
      font-size: 32px;
      line-height: 48px;
    }

    p {
      font-size: 14px;
      line-height: 26px;
    }
  }
`;

export const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 80px 65px;
  background: #e7ecee;

  @media ${device.mobileL} {
    padding: 48px 24px;
    gap: 32px;
  }
`;

export const CardsCtn = styled.div`
  display: flex;
  gap: 40px;

  @media ${device.tabletL} {
    flex-direction: column;
    gap: 32px;
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 300px;
  max-width: 579px;
  padding: 40px;
  background: ${colors.white};
  box-shadow: 0px 10px 30px 0px rgba(7, 53, 71, 0.1);

  > h1 {
    font-size: 40px;
    line-height: 56px;

    > span {
      font-family: "owners";
      color: ${colors.primary};
    }
  }

  p {
    font-size: 16px;
    line-height: 28px;
  }

  @media ${device.tabletL} {
    max-width: 100%;
  }

  @media ${device.mobileL} {
    padding: 24px 16px;

    > h1 {
      font-size: 32px;
      line-height: 48px;
    }

    > p {
      font-size: 14px;
      line-height: 26px;
    }
  }
`;

export const LastCard = styled.div`
  display: flex;
  flex-direction: column;
  background: ${colors.greenLight2};
  padding: 40px;
  color: ${colors.white};
  border-bottom: 8px solid ${colors.primary};

  > h1 {
    font-size: 40px;
    line-height: 56px;

    > span {
      font-family: "owners";
      color: ${colors.primary};
    }
  }

  p {
    font-size: 16px;
    line-height: 28px;
  }

  @media ${device.mobileL} {
    padding: 24px 16px;

    > h1 {
      font-size: 32px;
      line-height: 48px;
    }

    > p {
      font-size: 14px;
      line-height: 26px;
    }
  }
`;
