import service1 from "./service1.png";
import service2 from "./service2.png";
import service3 from "./service3.png";
import articles1 from "./articles1.png";
import articles2 from "./articles2.png";
import articles3 from "./articles3.png";
import articles4 from "./articles4.png";
import articles5 from "./articles5.png";
import aboutHero from "./aboutHero.png";
import charter from "./charter.png";
import charterBlock from "./charterBlock.png";
import review1 from "./review1.png";
import review2 from "./review2.png";
import review3 from "./review3.png";
import review1S from "./review1S.png";
import review2S from "./review2S.png";
import review3S from "./review3S.png";
import reviewMobile1 from "./reviewMobile1.png";
import reviewMobile1S from "./reviewMobile1S.png";
import reviewMobile2 from "./reviewMobile2.png";
import reviewMobile2S from "./reviewMobile2S.png";
import reviewMobile3 from "./reviewMobile3.png";
import reviewMobile3S from "./reviewMobile3S.png";
import lobbyingHero from "./lobbyingHero.png";
import whyImg from "./whyImg.png";
import adviceOfferImg from "./adviceOfferImg.png";
import needAdviceImg from "./needAdviceImg.png";
import accountLeft from "./accountLeft.png";
import bg from "./bg.png";

interface Image {
    [key: string]: string;
}

const imgs: Image = {
    bg,
    service1,
    service2,
    service3,
    articles1,
    articles2,
    articles3,
    articles4,
    articles5,
    aboutHero,
    charter,
    charterBlock,
    review1,
    review1S,
    review2,
    review2S,
    review3,
    review3S,
    reviewMobile1,
    reviewMobile1S,
    reviewMobile2,
    reviewMobile2S,
    reviewMobile3,
    reviewMobile3S,
    lobbyingHero,
    whyImg,
    adviceOfferImg,
    needAdviceImg,
    accountLeft,
};

export default imgs;
