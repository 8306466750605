import commercialServicesHero from "./commercialServicesHero.png";
import corporateOffer1 from "./corporateOffer1.png";
import corporateOffer2 from "./corporateOffer2.png";
import corporateOffer3 from "./corporateOffer3.png";
import corporateOffer4 from "./corporateOffer4.png";
import corporateOffer5 from "./corporateOffer5.png";
import corporateOffer6 from "./corporateOffer6.png";
import corporateOffer7 from "./corporateOffer7.png";
import corporateOffer8 from "./corporateOffer8.png";
import insolvencyAdviceIntro from "./insolvencyAdviceIntro.png";
import insuranceIntro from "./insuranceIntro.png";
import litigationIntro from "./litigationIntro.png";
import financeInvestmentsIntro from "./financeInvestmentsIntro.png";
import mergersAcquisitionIntro from "./mergersAcquisitionIntro.png";
import accountantsIntro from "./accountantsIntro.png";
import legalIntro from "./legalIntro.png";
import mediationIntro from "./mediationIntro.png";
import corporateOffer9 from "./corporateOffer9.png";
import corporateOffer10 from "./corporateOffer10.png";

export const corporateImgs = {
  commercialServicesHero,
  corporateOffer1,
  corporateOffer2,
  corporateOffer3,
  corporateOffer4,
  corporateOffer5,
  corporateOffer6,
  corporateOffer7,
  corporateOffer8,
  insolvencyAdviceIntro,
  insuranceIntro,
  litigationIntro,
  financeInvestmentsIntro,
  mergersAcquisitionIntro,
  accountantsIntro,
  legalIntro,
  mediationIntro,
  corporateOffer9,
  corporateOffer10,
};
