import axios from "axios";

const submitEnquiry = async (data: any) => {
  try {
    const res = await axios.post(
      process.env.REACT_APP_API + "/enquire/create",
      data,
    );
    console.log("Submit Enquiry Response: ", res);
    return res.data;
  } catch (err) {
    console.log("Submit Enquiry Form err: ", err);
  }
};

export default submitEnquiry;
