import { corporateImgs } from "../../../assets/imgs/corporate";
import EnquiryForm from "../../../component/Reusable/EnquiryForm";

import Offer from "../../../component/Reusable/Offer";
import NeedAdvice from "../../../component/Reusable/NeedAdvice";
import ReusableIntro from "../../../component/Reusable/ReusableIntro";
import ReusableHero from "../../../component/Reusable/ReusableHero";
import { offerData } from "../offerData";
import bgs from "../../../assets/bgs";

const FinanceInvestments = () => {
  const heroContents = {
    type: 2,
    title: <h1>Finance & Investments</h1>,
    text: (
      <p>
        Commercial property can be a really important asset which allows you to
        spread and diversify risk in your investment portfolio.
      </p>
    ),
    img: bgs.financeInvestmentsHeroBg,
    mobileimg: bgs.financeInvestmentsHeroBgMobile,
    button: true,
  };

  const introContents = {
    title: "About Finance & Investments",
    text: (
      <p>
        Generally speaking, property values move independently of other assets
        such as bonds & gilts, cash and equities, meaning they are not really
        affected by the ongoings throughout the stock markets.
        <br />
        <br />
        There’s a few different ways that you can get exposure to property
        investment: through direct investment, which is mainly buying all or a
        share in a property; direct commercial property funds (brick-and-mortar
        funds) which is a more common way to invest; or indirect property funds.
        We can help you further with your options by getting in touch with us.
      </p>
    ),
    img: corporateImgs.financeInvestmentsIntro,
    link: "/enquiry",
  };

  const offerContents = [offerData.tenantCompliance, offerData.businessRates];

  return (
    <>
      <ReusableHero contents={heroContents} />
      <ReusableIntro contents={introContents} />
      <NeedAdvice />
      <Offer
        contents={offerContents}
        title="Similar Services"
      />
      <EnquiryForm />
    </>
  );
};

export default FinanceInvestments;
