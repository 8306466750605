import { nonCorporateImgs } from "../../assets/imgs/nonCorporate";

export const offerData = {
  hrConsultants: {
    img: nonCorporateImgs.nonCorporateOffer1,
    title: "HR Consultants",
    text: "HR is an essential function that doesn’t just deal with recruitment talent for your business, but also to help succeed and grow your company.",
    link: "/non-corporate/hr-consultants",
  },

  contractors: {
    img: nonCorporateImgs.nonCorporateOffer2,
    title: "Contractors",
    text: "If you have permission to extend or renovate your existing commercial property, we can help with the expert contractors you need with the experience to ensure things run smoothly.",
    link: "/non-corporate/contractors",
  },

  shopfitters: {
    img: nonCorporateImgs.nonCorporateOffer3,
    title: "Shopfitters",
    text: "A great commercial fit-out can give a new lease of life to your building, increase occupier satisfaction, attract great quality tenants, extend occupant retention, and boost the owner’s bottom line. ",
    link: "/non-corporate/shopfitters",
  },

  utilities: {
    img: nonCorporateImgs.nonCorporateOffer4,
    title: "Utilities",
    text: "You want to get the lowest energy and water prices for your business and try to avoid common pitfalls.",
    link: "/non-corporate/utilities",
  },
  businessRates: {
    img: nonCorporateImgs.nonCorporateOffer5,
    title: "Business Rates",
    text: "What are business rates? How are they calculated and can they be challenged?",
    link: "/property/rates",
  },
  disputeResolution: {
    img: nonCorporateImgs.nonCorporateOffer6,
    title: "Dispute Resolution",
    text: "There are various areas of dispute between commercial landlords and tenants. This section is designed to help identify and prevent these disputes from arising or escalating.",
    link: "/",
  },
};
