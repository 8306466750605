import styled from "styled-components";
import device from "../../theme/devices";
import { colors } from "../../theme/colors";

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
  background: ${colors.greenDark};
  min-height: 80vh;

  > h1 {
    font-size: 56px;
    color: ${colors.white};
  }

  @media ${device.mobileL} {
    > h1 {
      font-size: 32px;
    }
  }
`;
