import { useEffect, useState } from "react";
import "./App.css";
import AppRouter from "./router";
import Notiflix from "notiflix";
import profile from "./utils/profile";

declare global {
  interface Window {
    predictiveAddress: any;
    data8: any;
  }
}

function App() {
  const { pathname } = window.location;

  useEffect(() => {
    Notiflix.Loading.init({
      svgColor: "rgb(28, 206, 220)",
    });
  }, []);

  useEffect(() => {
    if (pathname.split("/")[1] !== "donation") {
      sessionStorage.removeItem("amount");
      sessionStorage.removeItem("billingInfo");
    }
  }, []);

  return <AppRouter />;
}

export default App;
