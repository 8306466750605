import axios from "axios";

const getArticle = async (alias: string) => {
  const res = await axios({
    method: "get",
    url: `${process.env.REACT_APP_API}/article/get/alias/${alias}`,
  }).catch((err: any) => {
    console.log("Err in axios get article", err);
    throw "Error in axios get article";
  });

  return res?.data;
};

export default getArticle;
