import styled from "styled-components";
import {colors} from "../../../theme/colors";
import device from "../../../theme/devices";
import size from "../../../theme/devices/sizes";
import bgs from "../../../assets/bgs";

export const Section = styled.section`
  background: ${colors.greenDark};
`;

export const Container = styled.div`
  display: flex;
  max-width: ${size.laptopL};
  margin: 0 auto;
  padding-left: 56px;
  justify-content: space-between;

  @media ${device.tabletL} {
    flex-direction: column;
    padding-left: 0px;
    align-items: center;
  }
`;

export const Left = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 52px;
  padding-top: 24px;
  padding-right: 40px;

  @media ${device.tabletL} {
    gap: 32px;
    padding: 35px 24px 32px;
    padding-top: 112px;
  }
`;

export const LeftTop = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Logo = styled.img`
  /* width: 437px; */
  width: 585px;

  @media ${device.laptop} {
    width: 437px;
  }

  /* @media ${device.mobileL} {
    width: 241px;
  } */
`;

export const Nav = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 24px;
`;

export const UserIcon = styled.img`
  padding-right: 32px;
`;

export const Headers = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  color: ${colors.white};
  margin-bottom: 47px;
  max-width: 644px;

  > h1 {
    font-size: 64px;
    font-weight: 500;
    line-height: 80px;

    > span {
      color: ${colors.primary};
    }
  }

  > span {
    font-size: 64px;
    font-weight: 500;
    line-height: 80px;
    margin-top: -32px;
    color: ${colors.primary};
  }

  > p {
    font-size: 16px;
    line-height: 32px;
  }

  @media ${device.mobileL} {
    gap: 16px;
    margin-bottom: 0px;

    > h1 {
      font-size: 40px;
      line-height: 56px;
    }

    > p {
      font-size: 14px;
      line-height: 26px;
    }
  }
`;

export const Arrow = styled.img`
  width: 64px;
  cursor: pointer;
`;

export const Right = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;

  > a {
    width: 50%;
  }

  @media ${device.mobileL} {
    flex-direction: column;
  }
`;

interface BoxProps {
  $num: number;
}

export const BoxWrapper = styled.div<BoxProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 50%;
  padding: 124px 32px 32px;
  cursor: pointer;

  ${({$num}) =>
    $num === 0
      ? `background: ${colors.primary};`
      : $num === 1
      ? " background: #D9E3E7;"
      : $num === 2
      ? `background-image: url(${bgs.heroThirdBg});     background-repeat: no-repeat;
      background-size: cover;
      background-position: top;
      color: ${colors.white};`
      : `background: ${colors.white};`}

  > h1 {
    font-size: 32px;
  }

  > p {
    font-size: 14px;
    line-height: 24px;
  }

  > .background {
    position: absolute;
    right: 0px;
    top: 24px;
  }

  &:hover {
    background: ${({$num}) => $num !== 2 && `${colors.greenLight2}`};

    color: ${colors.white};

    > .background {
      path {
        fill: #fff;
      }
    }

    > .icon {
      > svg {
        path {
          fill: #fff;
        }
        rect {
          width: 200px;
        }
      }
    }
  }

  &:hover::before {
    ${({$num}) =>
      $num === 2 &&
      `content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, rgba(0, 221, 238, 0.50) 0%, rgba(0, 0, 0, 0.60) 100%);`}
  }

  @media ${device.mobileL} {
    width: 100%;
    padding: 71px 24px 24px;

    > .background {
      width: 92px;
      height: 140px;
    }
  }
`;

interface BoxImgProps {
  hoverImg?: boolean;
}

export const BoxImg = styled.img<BoxImgProps>`
  ${({hoverImg}) => hoverImg && "display:none;"}
  width: 40px;
`;

export const BackgroundIcon = styled.img`
  position: absolute;
  right: 0px;
  top: 24px;

  @media ${device.mobileL} {
    width: 92px;
    height: 140px;
  }
`;

export const IconDiv = styled.div`
  width: 40px;
  height: 40px;
  background: rgba(255, 255, 255, 0.2);
`;
