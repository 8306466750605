import styled from "styled-components";
import { colors } from "../../../theme/colors";
import device from "../../../theme/devices";

export const Container = styled.div`
  display: flex;
  gap: 40px;

  @media ${device.laptop} {
    flex-direction: column;
  }
`;

interface CtnProps {
  $flip?: boolean;
}

export const LargeNewsCtn = styled.div<CtnProps>`
  flex: 1;
  order: ${({ $flip }) => ($flip ? "2" : "1")};

  @media ${device.laptopL} {
    order: 1;
  }
`;

export const SmallNewsCtn = styled.div<CtnProps>`
  display: flex;
  flex: 1;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 40px;
  order: ${({ $flip }) => ($flip ? "1" : "2")};

  > div {
    max-width: 269px;
  }

  @media ${device.laptopL} {
    > div {
      max-width: 46%;
    }
  }

  @media ${device.mobileL} {
    flex-direction: column;

    > div {
      max-width: 100%;
    }
  }
`;

interface ArticleProps {
  size?: string;
  order?: string;
}

export const Article = styled.div<ArticleProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  border-radius: 4px;
  max-height: ${({ size }) => (size === "small" ? "274px" : "588px")};

  @media ${device.laptopL} {
    order: ${({ order }) => order};
  }
`;

export const ArticleTop = styled.div<ArticleProps>`
  display: flex;
  flex-direction: column;
  gap: 16px;

  > h3 {
    font-size: ${({ size }) => (size === "large" ? "20px" : "16px")};
    line-height: ${({ size }) => (size === "large" ? "32px" : "24px")};
    cursor: pointer;
  }

  > span {
    > p {
      font-size: 14px;
      line-height: 24px;
      cursor: pointer;
    }
  }

  @media ${device.mobileL} {
    > h3 {
      font-size: 14px;
      line-height: 32px;
    }

    > span {
      > p {
        font-size: 12px;
      }
    }
  }
`;

export const ArticleFooter = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  line-height: 24px;
  color: ${colors.greenLight2};
  padding-top: 16px;
  border-top: 1px solid ${colors.border};

  > p {
    font-size: 14px;
    line-height: 24px;
  }

  @media ${device.mobileL} {
    > p {
      font-size: 12px;
      line-height: 24px;
      font-weight: 500;
    }
  }
`;

export const ImgBackground = styled.div<ArticleProps>`
  position: relative;
  width: 100%;
  height: 100%;
  max-height: ${({ size }) => (size === "small" ? "130px" : "364px")};
  cursor: pointer;

  > img {
    max-height: ${({ size }) => (size === "small" ? "130px" : "364px")};
    width: 100%;
    height: 100%;
  }

  img {
    object-fit: cover;
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    z-index: 9;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    transition: opacity 0.2s ease-in-out;
  }

  &::before {
    background: linear-gradient(
      90deg,
      rgba(2, 0, 36, 1) 0%,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(0, 0, 0, 0.2399553571428571) 100%
    );
    opacity: 1;
  }

  &::after {
    background: linear-gradient(
      90deg,
      rgba(2, 0, 36, 1) 0%,
      rgba(0, 252, 252, 0.7105435924369747) 0%,
      rgba(0, 0, 0, 0.2399553571428571) 100%
    );
    opacity: 0;
  }

  &:hover::before {
    opacity: 0;
  }

  &:hover::after {
    opacity: 1;
  }

  @media ${device.mobileL} {
    max-height: ${({ size }) => (size === "small" ? "158px" : "205.5px")};

    > img {
      max-height: ${({ size }) => (size === "small" ? "158px" : "205.5px")};
      width: 100%;
      height: 100%;
    }
  }
`;

export const ImgWrapper = styled.div`
  position: absolute;
  width: calc(100% - 24px);
  height: calc(100% - 24px);
  cursor: pointer;

  z-index: 10;
  top: 12px;
  left: 12px;
  overflow: hidden;

  img {
    width: calc(100% + 24px);
    height: calc(100% + 24px);
    object-fit: cover;
    overflow: hidden;
    position: absolute;
    left: -10px;
    top: -10px;
  }
`;
