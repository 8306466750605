import axios from "axios";

const register = async (data: any) => {
  try {
    const res = await axios.post(process.env.REACT_APP_API + "/register", data);

    return res.data;
  } catch (err: any) {
    console.log("Register Error: ", err);
    return err.response.data.errors[0].message;
  }
};

export default register;
