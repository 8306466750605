import { useNavigate } from "react-router-dom";
import imgs from "../../../assets/imgs";
import ReusableButton from "../../Reusable/ReusableButton";
import {
  BackgroundBlock,
  Bottom,
  Card,
  CardsCtn,
  Container,
  LastCard,
  Section,
  Top,
  TopParagraph,
} from "./styles";

const Charter = () => {
  const navigate = useNavigate();

  return (
    <Section>
      <Container>
        <BackgroundBlock
          src={imgs.charterBlock}
          alt="bg-block"
        />
        <Top>
          <TopParagraph>
            <h1>Our Charter</h1>
            <p>
              The CTA is an independent organisation whose fundamental purpose
              is to champion consumer rights and protection in the commercial
              property market.
              <br /> <br />
              Our core values of establishing a <strong>community,</strong>{" "}
              making a <strong>difference,</strong> maintaining{" "}
              <strong>integrity,</strong> pursuing <strong>fairness,</strong>{" "}
              and delivering outcomes using{" "}
              <strong>bold, innovative ways of thinking,</strong> are part of
              everything we do and are the standards that we set for ourselves
              and those that work closely with us.
              <br /> <br /> We champion the interests of over 900,000 lease and
              license holders across the UK, and have made it our duty to become
              their voice and to stand alongside them in an industry that has
              historically been dominated by landlords.
            </p>
            <a href="/sign-up">
              <ReusableButton
                $primary={true}
                name="Join For Free"
                onClick={() => navigate("/sign-up")}
              />
            </a>
          </TopParagraph>
          <img
            src={imgs.charter}
            alt="people"
          />
        </Top>
        <Bottom>
          <CardsCtn>
            <Card>
              <h1>
                Our <span>Charter</span>
              </h1>
              <p>
                Our independent organisation is wholly committed to working
                towards the greater benefit of all our members, utilising
                industry-standard techniques to ensure our shared aims are met.
              </p>
            </Card>
            <Card>
              <h1>
                Our <span>Partners</span>{" "}
              </h1>
              <p>
                Ssupport our mission by providing advice, support and services
                to our members in a manner that is congruent with our values and
                the fair practices we campaign for.
              </p>
            </Card>
          </CardsCtn>
          <CardsCtn>
            <Card>
              <h1>
                Our <span>People</span>{" "}
              </h1>
              <p>
                We’re committed to our cause and are present to serve our
                members with enthusiasm, integrity and the highest levels of
                customer service.
              </p>
            </Card>
            <Card>
              <h1>
                Our <span>Vision</span>
              </h1>
              <p>
                We strive for the commercial property market to be fair and
                equitable for all and for tenants to be treated as valued
                customers.
              </p>
            </Card>
          </CardsCtn>
          <LastCard>
            <h1>
              Our <span>Mission</span>
            </h1>
            <p>
              Built on the fundamentals of fairness, innovation and maintaining
              a united community of businesses, all work conducted by the CTA
              aims to bring about greater change that benefits everyone involved
              within the commercial property sector.
            </p>
          </LastCard>
        </Bottom>
      </Container>
    </Section>
  );
};

export default Charter;
