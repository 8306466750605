const size = {
  mobileS: "323px",
  mobileM: "378px",
  mobileML: "478px",
  mobileL: "630px",
  tablet: "770px",
  tabletL: "995px",
  laptop: "1203px",
  laptopL: "1439px",
  desktop: "2560px",
};

export default size;
