import { ChangeEvent, useEffect, useState } from "react";
import icons from "../../assets/icons";
import ReusableButton from "../../component/Reusable/ReusableButton";
import {
  AmountInputWrapper,
  Circle,
  Option,
  OptionsCtn,
  OptionWrapper,
  TickCircle,
  Title,
} from "./styles";
import { useAppDispatch } from "../../redux/hooks";
import { updateDonationStep } from "../../redux/features/donationStepSlice";
import { updateDonationForm } from "../../redux/features/donationFormSlice";
import { Notify } from "notiflix";

const Step1 = () => {
  const dispatch = useAppDispatch();
  const [amount, setAmount] = useState<string>("");
  const [selected, setSelected] = useState<{ [key: string]: boolean }>({
    "£10": false,
    "£25": false,
    "£50": false,
  });
  const [customAmount, setCustomAmount] = useState<string>("");
  const options = ["£10", "£25", "£50"];
  const [show, setShow] = useState(false);
  const { pathname } = window.location;

  useEffect(() => {
    if (pathname.split("/")[2] !== "failure") {
      const billingInfo = sessionStorage.getItem("billingInfo");

      if (billingInfo) {
        sessionStorage.removeItem("billingInfo");
      }
    }
  }, []);

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const handleSelect = (amount: string) => {
    setCustomAmount("");
    setSelected({ [amount]: true });
    setAmount(amount);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setCustomAmount(value.replace(/\D/g, ""));
    setSelected({
      "£10": false,
      "£25": false,
      "£50": false,
    });
  };

  const handleFocus = () => {
    setShow(true);
  };

  const handleBlur = () => {
    if (customAmount.length > 0) {
      console.log("here");
      setShow(true);
    } else {
      setShow(false);
    }
  };

  const handleSubmit = () => {
    if (customAmount.length > 0) {
      const number = +customAmount.replace(/\D/g, "");

      if (number <= 0) {
        return Notify.failure("Please make sure the amount is correct.");
      }

      dispatch(updateDonationForm({ amount: number }));
      dispatch(updateDonationStep(2));
    } else {
      if (!amount) {
        return Notify.failure("Please select or enter the amount.");
      }
      const number = +amount.replace(/\D/g, "");
      dispatch(updateDonationForm({ amount: number }));
      sessionStorage.setItem("amount", JSON.stringify(number));
      dispatch(updateDonationStep(2));
    }
  };

  return (
    <>
      <Title>Your Donation</Title>
      <OptionsCtn>
        {options.map((option: string, i: number) => {
          return (
            <OptionWrapper
              key={i}
              $selected={selected[option]}
            >
              <Option onClick={() => handleSelect(option)}>
                <p>{option}</p>
              </Option>
              {selected[option] ? (
                <TickCircle
                  src={icons.circleTick}
                  alt="tick"
                />
              ) : (
                <Circle
                  src={icons.circleBlue}
                  alt="circle"
                />
              )}
            </OptionWrapper>
          );
        })}
      </OptionsCtn>
      <AmountInputWrapper $show={show}>
        {show || customAmount.length > 0 ? <p>Donation amount</p> : null}
        <img
          src={icons.donationPound}
          alt="pound"
        />
        <input
          name="customAmount"
          value={customAmount}
          placeholder="Donation amount"
          onChange={handleChange}
          inputMode="numeric"
          onBlur={handleBlur}
          onFocus={handleFocus}
        />
      </AmountInputWrapper>
      <ReusableButton
        $primary={true}
        width="100%"
        $mwidth="100%"
        name="Make Donation"
        onClick={() => handleSubmit()}
      />
    </>
  );
};

export default Step1;
