import { propertyImgs } from "../../../assets/imgs/property";
import Downloadable from "../../../component/Reusable/Downloadable";
import EnquiryForm from "../../../component/Reusable/EnquiryForm";
import Offer from "../../../component/Reusable/Offer";
import NeedAdvice from "../../../component/Reusable/NeedAdvice";
import ReusableIntro from "../../../component/Reusable/ReusableIntro";
import ReusableHero from "../../../component/Reusable/ReusableHero";
import { offerData } from "../offerData";
import bgs from "../../../assets/bgs";

const Surrenders = () => {
  const heroContents = {
    type: 2,
    title: <h1>Surrenders</h1>,
    text: (
      <p>
        If you want to bring the tenancy to an end before the actual expiry date
        in the lease, then you may have to negotiate a deal with the landlord to
        allow you to walk away. This is called a surrender.
      </p>
    ),
    img: bgs.surrendersHeroBg,
    mobileimg: bgs.surrendersHeroBgMobile,
    button: true,
  };
  const introContents = {
    title: "Why You Might Surrender Your Lease",
    text: (
      <p>
        You and your business may be going through a period of financial
        uncertainty, and the money you’re paying towards your commercial tenancy
        could be particularly draining.
        <br />
        <br />
        Surrenders offer tenants a clean break and is the preferred method. In
        most cases tenants will only be able to dispose of the entire property
        by way of a surrender, assignment or sublease. Although, some leases
        permit subletting in part.
      </p>
    ),
    img: propertyImgs.surrenderIntro,
    link: "/enquiry",
  };

  const offerContents = [
    offerData.assignments,
    offerData.strategicLeaseManagement,
  ];

  return (
    <>
      <ReusableHero contents={heroContents} />
      <ReusableIntro contents={introContents} />
      <Downloadable full={false} />
      <NeedAdvice />
      <Offer
        contents={offerContents}
        title="Similar Services"
      />
      <EnquiryForm />
    </>
  );
};

export default Surrenders;
