import { useNavigate } from "react-router-dom";
import imgs from "../../../assets/imgs";
import ReusableButton from "../../Reusable/ReusableButton";
import { BackgroundBlock, Container, Paragraph, Section } from "./styles";

const Why = () => {
  const navigate = useNavigate();

  return (
    <Section>
      <BackgroundBlock
        src={imgs.charterBlock}
        alt="person"
      />
      <Container>
        <img
          src={imgs.whyImg}
          alt="writing"
        />
        <Paragraph>
          <h1>Why You Should Entrust Us To Lobby On Your Behalf</h1>
          <p>
            Using industry standard, tried-and-tested techniques, the CTA has
            garnered an overwhelmingly positive reputation around the UK for our
            lobbying efforts.
            <br />
            <br /> It’s always our aim to ensure that both our members and the
            decision makers are treated fairly and respectfully during these
            lobbying procedures, as without this, we mitigate the risk of
            returning from negotiations and large-scale lobbying efforts without
            a result.
            <br />
            <br /> But we never lose sight of our ultimate aim: effectively
            addressing the concerns of the UK’s commercial tenants, and making
            sure your voice is heard by anyone and everyone who can bring about
            the changes we feel are necessary for the growth and benefit of
            every Commercial Tenant, regardless of their lease status or what
            industry they work within. 
          </p>
          <a href="/enquiry">
            <ReusableButton
              name="Enquire Now"
              onClick={() => {}}
            />
          </a>
        </Paragraph>
      </Container>
    </Section>
  );
};

export default Why;
