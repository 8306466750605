import propertyAcquisitionsIntro from "./propertyAcquisitionsIntro.png";
import propertyAssignmentsIntro from "./propertyAssignmentsIntro.png";
import propertyCapexIntro from "./propertyCapexIntro.png";
import propertyLeaseRenewalsIntro from "./propertyLeaseRenewalsIntro.png";
import propertyLeaseRestructuringIntro from "./propertyLeaseRestructuringIntro.png";
import propertyMainHero from "./propertyMainHero.png";
import propertyOwnPropertyIntro from "./propertyOwnPropertyIntro.png";
import propertyStrategicLeaseManagementIntro from "./propertyStrategicLeaseManagementIntro.png";
import propertySurrenderIntro from "./propertySurrenderIntro.png";
import propertyPointsImg from "./propertyPointsImg.png";
import timing from "./timing.png";
import surrenderIntro from "./surrenderIntro.png";
import assignmentsIntro from "./assignmentsIntro.png";
import beforeLeaseNegotiations from "./beforeLeaseNegotiations.png";
import ratesPoints from "./ratesPoints.png";
import strategicLeaseManagementIntro from "./strategicLeaseManagementIntro.png";
import ownYourPropertyIntro from "./ownYourPropertyIntro.png";
import acquisitionsIntro from "./AcquisitionsIntro.png";
import capExSupportIntro from "./capExSupportIntro.png";
import propertyOffer1 from "./propertyOffer1.png";
import propertyOffer2 from "./propertyOffer2.png";
import propertyOffer3 from "./propertyOffer3.png";
import propertyOffer4 from "./propertyOffer4.png";
import propertyOffer5 from "./propertyOffer5.png";
import propertyOffer6 from "./propertyOffer6.png";
import propertyOffer7 from "./propertyOffer7.png";
import propertyOffer8 from "./propertyOffer8.png";
import propertyOffer9 from "./propertyOffer9.png";
import propertyOffer10 from "./propertyOffer10.png";
import propertyOffer11 from "./propertyOffer11.png";
import propertyOffer12 from "./propertyOffer12.png";
import propertyOffer13 from "./propertyOffer13.png";

export const propertyImgs = {
  propertyAcquisitionsIntro,
  propertyAssignmentsIntro,
  propertyCapexIntro,
  propertyLeaseRenewalsIntro,
  propertyLeaseRestructuringIntro,
  propertyMainHero,
  propertyOwnPropertyIntro,
  propertyStrategicLeaseManagementIntro,
  propertySurrenderIntro,
  propertyPointsImg,
  timing,
  surrenderIntro,
  assignmentsIntro,
  beforeLeaseNegotiations,
  ratesPoints,
  strategicLeaseManagementIntro,
  ownYourPropertyIntro,
  acquisitionsIntro,
  capExSupportIntro,
  propertyOffer1,
  propertyOffer2,
  propertyOffer3,
  propertyOffer4,
  propertyOffer5,
  propertyOffer6,
  propertyOffer7,
  propertyOffer8,
  propertyOffer9,
  propertyOffer10,
  propertyOffer11,
  propertyOffer12,
  propertyOffer13,
};
