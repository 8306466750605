import styled from "styled-components";
import { colors } from "../../../../theme/colors";
import device from "../../../../theme/devices";

export const Container = styled.div`
  flex: 1;
  background: ${colors.white};
  padding: 142px 40px 41px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 32px;
  max-width: 58%;

  p {
    color: ${colors.greenLight};
  }

  @media ${device.tabletL} {
    max-width: 100%;
  }

  @media ${device.mobileL} {
    padding: 40px 24px 32px;
    gap: 48px;

    > h1 {
      font-size: 24px;
      line-height: 32px;
    }
  }
`;

export const Top = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;

  > h1 {
    font-size: 32px;
    line-height: 48px;
  }

  > p {
    text-align: center;
  }

  @media ${device.mobileL} {
    gap: 48px;

    > h1 {
      font-size: 24px;
      line-height: 32px;
    }

    > p {
      font-size: 14px;
    }
  }
`;

export const InputsCtn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

interface InputProps {
  $show: boolean;
  $error?: boolean;
}

export const InputWrapper = styled.div<InputProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 479px;

  > img {
    position: absolute;
    top: 45%;
    left: 14px;
    transform: translateY(-35%);
  }

  > #visible {
    left: unset;
    right: 14px;
    cursor: pointer;
  }

  > p {
    ${({ $show }) => !$show && "display: none"};
    position: absolute;
    background: ${colors.white};
    top: -8px;
    left: 14px;
    z-index: 2;
    font-size: 12px;
    color: ${colors.primary};
    padding: 0px 5px;
  }

  > span {
    font-size: 14px;
    align-self: flex-end;
    color: #eb3c3c;
  }

  @media ${device.mobileL} {
    width: 100%;

    > span {
      font-size: 12px;
    }
  }
`;

export const Input = styled.input<InputProps>`
  width: 100%;
  height: 50px;
  border: none;
  border: 2px solid
    ${({ $show, $error }) =>
      $show ? `${colors.primary}` : $error ? "#EB3C3C" : `${colors.border}`};
  padding: 14px 14px 14px 40px;
  border-radius: 4px;
  font-size: 16px;
  background: ${colors.white};

  &:focus {
    outline: none;
    border: 2px solid ${colors.primary};
    &::placeholder {
      opacity: 0;
    }
  }

  @media ${device.mobileL} {
    font-size: 14px;
  }
`;

export const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media ${device.mobileL} {
    > a {
      font-size: 14px;
    }
  }
`;
