import { useState } from "react";
import { useAppDispatch } from "../../../../redux/hooks";
import size from "../../../../theme/devices/sizes";
import useWindowSize, { Size } from "../../../../utils/useWindowSize";
import {
  Circle,
  OptionWrapper,
  OptionsCtn,
  StepMain,
  TickCircle,
  Title,
  Unsure,
} from "../styles";
import { updateEnquiryForm } from "../../../../redux/features/enquiryFormSlice";
import { updateEnquiryStep } from "../../../../redux/features/enquiryStepSlice";
import icons from "../../../../assets/icons";

const Step2 = () => {
  const windowSize: Size = useWindowSize();
  const laptopL = +size.laptopL.slice(0, -2);
  const dispatch = useAppDispatch();
  const [rent, setRent] = useState("");
  const [showTick, setShowTick] = useState<{ [key: string]: boolean }>({
    "Below £10,000": false,
    "£10,001 - £20,000": false,
    "£20,001 - £50,000": false,
    "Above £50,000": false,
    Unsure: false,
  });
  const options = [
    "Below £10,000",
    "£10,001 - £20,000",
    "£20,001 - £50,000",
    "Above £50,000",
  ];

  const handleClick = (option: string) => {
    setRent(option);
    setShowTick((prev) => ({ ...prev, [option]: true }));
    dispatch(updateEnquiryForm({ annual_rent: option }));
    setTimeout(() => {
      dispatch(updateEnquiryStep(3));
    }, 500);
  };

  return (
    <StepMain>
      <Title>How much is your annual rent?</Title>
      <OptionsCtn>
        {options.map((option, i) => {
          return (
            <OptionWrapper
              key={i}
              $selected={rent === option}
              onClick={() => handleClick(option)}
            >
              <p>{option}</p>
              {showTick[option] ? (
                <TickCircle
                  src={icons.circleTick}
                  alt="tick"
                />
              ) : (
                <Circle
                  src={icons.circleBlue}
                  alt="circle"
                />
              )}
            </OptionWrapper>
          );
        })}
        {windowSize.width! > laptopL ? (
          <Unsure
            $selected={rent === "Unsure"}
            onClick={() => handleClick("Unsure")}
          >
            <p>Unsure</p>
            {showTick.Unsure ? (
              <TickCircle
                src={icons.circleTick}
                alt="tick"
              />
            ) : (
              <Circle
                src={icons.circleBlue}
                alt="circle"
              />
            )}
          </Unsure>
        ) : (
          <OptionWrapper
            $selected={rent === "Unsure"}
            onClick={() => handleClick("Unsure")}
          >
            <p>Unsure</p>
            {showTick.Unsure ? (
              <TickCircle
                src={icons.circleTick}
                alt="tick"
              />
            ) : (
              <Circle
                src={icons.circleBlue}
                alt="circle"
              />
            )}
          </OptionWrapper>
        )}
      </OptionsCtn>
    </StepMain>
  );
};

export default Step2;
