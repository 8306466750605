import styled from "styled-components";
import { colors } from "../../../../theme/colors";
import device from "../../../../theme/devices";

export const Container = styled.div<{ img: string }>`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    background: rgba(32, 86, 107, 0.5);
    backdrop-filter: blur(5px);
    padding: 0 40px;
    max-width: 42%;
    min-height: 572px;
    max-height: 572px;
    color: ${colors.white};
    overflow: hidden;
    background-image: url(${({ img }) => img});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    /* > img {
        width: 60px;
        height: 24px;
    } */

    @media ${device.tabletL} {
        max-width: 100%;
        padding-top: 18px;
        background-position: top;
        p {
            max-width: 370px;
        }
    }

    @media ${device.mobileL} {
        padding: 0 24px;
        background-size: cover;

        > p {
            font-size: 14px;
            margin-bottom: 16px;
            width: 100%;
        }
    }
`;

export const Top = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    padding-right: 40px;

    > h1 {
        font-size: 40px;
        line-height: 56px;
    }

    > p {
        font-size: 16px;
        line-height: 32px;
    }
`;

export const Bottom = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    overflow: hidden;

    @media ${device.mobileL} {
        gap: 16px;
    }
`;

export const GradientOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        to right,
        rgba(7, 53, 71, 0),
        rgba(7, 53, 71, 0.8)
    );
    pointer-events: none;
    z-index: 4;
`;

export const CardsCtn = styled.div`
    display: flex;
    gap: 16px;
    min-height: 147px;

    overflow: hidden;

    @media ${device.laptopL} {
        justify-content: space-evenly;
    }
`;

export const Card = styled.div`
    position: relative;
    flex: 1;
    background: ${colors.greenLight2};
    border: 1px solid ${colors.primary};
    border-radius: 4px;
    padding: 16px;
    min-width: 138px;
    min-height: 147px;
    margin: 0 auto;
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    text-align: center;

    > #content {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
        object-fit: cover;
        width: 138px;
        height: 147px;
    }

    > #lock {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        width: 34px;
        height: 44px;
    }

    @media ${device.mobileL} {
        padding: 24px 16px;
        max-width: 100%;

        button {
            width: 80%;
        }
    }
`;

export const ImgWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;

    > #content {
        object-fit: cover;
    }

    > #lock {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 34px;
        height: 44px;
    }
`;
