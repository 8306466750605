import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface StepState {
  step: number;
}

const initialState: StepState = {
  step: 1,
};

const donationStepSlice = createSlice({
  name: "donationStep",
  initialState,
  reducers: {
    updateDonationStep: (state, action: PayloadAction<number>) => {
      state.step = action.payload;
    },
    resetDonationStep: (state) => {
      state.step = initialState.step;
    },
  },
});

export const { updateDonationStep, resetDonationStep } =
  donationStepSlice.actions;
export default donationStepSlice.reducer;
