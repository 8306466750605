import ReusableButton from "../../Reusable/ReusableButton";
import { colors } from "../../../theme/colors";
import size from "../../../theme/devices/sizes";
import useWindowSize, { Size } from "../../../utils/useWindowSize";
import {
  Bottom,
  Container,
  Item,
  LastItem,
  Section,
  Titles,
  Top,
  ItemsCtn,
} from "./styles";
import icons from "../../../assets/icons";

const Identifying = () => {
  const windowSize: Size = useWindowSize();
  const tabletL = +size.tabletL.slice(0, -2);
  const contents = [
    {
      img: icons.identifying1,
      title: "Budget",
      text: (
        <p>
          Establishing what can be spent on occupancy costs and researching just
          how much money goes into a relocation can be vital, as it provides a
          financial range within which to negotiate.
          <br />
          <br /> The following elements can greatly affect the amount of rent
          charged, but having a budget cap in mind for your occupancy costs will
          help to narrow down the options ahead of negotiations.
        </p>
      ),
    },
    {
      img: icons.identifying2,
      title: "Location",
      text: (
        <p>
          Location can greatly influence rental levels. For example, good access
          to main roads for distribution, areas with high footfall for retail
          and hospitality or proximity to public transport links for offices
          will all raise asking rent levels at a property.
          <br />
          <br />
          It is important to have realistic expectations when approaching
          negotiations if you are looking for property in a prime location.
        </p>
      ),
    },
    {
      img: icons.identifying3,
      title: "Size",
      text: (
        <p>
          It is important to think about how you use your current space, whether
          this could be improved, and how that might impact how much space you
          require from your next property.
        </p>
      ),
    },
    {
      img: icons.identifying4,
      title: "Amenities",
      text: (
        <p>
          Businesses should consider what amenities they require from their
          space – natural light, large street-frontage, security, 24-hour
          access, car parking, bike racks etc. and highlight their importance
          with their tenant representative.
        </p>
      ),
    },
  ];

  return (
    <Section>
      <Container>
        <Top>
          <Titles>
            <h1>Identifying The Property</h1>
            <p>Essential considerations when choosing a property:</p>
          </Titles>
          {windowSize.width! > tabletL && (
            <a href="/enquiry">
              <ReusableButton
                name="Enquire Now"
                onClick={() => {}}
                color={colors.white}
              />
            </a>
          )}
        </Top>
        <Bottom>
          <ItemsCtn>
            {contents.map((content, i) => {
              return (
                <Item key={i}>
                  <img
                    src={content.img}
                    alt="icon"
                  />
                  <h3>{content.title}</h3>
                  {content.text}
                </Item>
              );
            })}
          </ItemsCtn>
          <LastItem>
            <img
              src={icons.identifying5}
              alt="icon"
            />
            <h3>CTA Top Tip!</h3>
            <p>
              Landlords often offer small discounts for taking larger space. It
              is important to bear this in mind during negotiations.
            </p>
          </LastItem>
          {windowSize.width! <= tabletL && (
            <a href="/enquiry">
              <ReusableButton
                name="Enquire Now"
                onClick={() => {}}
                color={colors.white}
              />
            </a>
          )}
        </Bottom>
      </Container>
    </Section>
  );
};

export default Identifying;
