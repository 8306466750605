import { ReactElement } from "react";
import ReusableButton from "../../Reusable/ReusableButton";
import { colors } from "../../../theme/colors";
import size from "../../../theme/devices/sizes";
import useWindowSize, { Size } from "../../../utils/useWindowSize";
import { Container, Item, Left, Right, Section } from "./styles";
import imgs from "../../../assets/imgs";

const Impact = () => {
  const windowSize: Size = useWindowSize();
  const mobileL: number = +size.mobileL.slice(0, -2);
  const laptop: number = +size.laptop.slice(0, -2);

  const contents = {
    title: "The Impact Of Re-Gearing Your Lease",
    text: (
      <p>
        Our team are specialists in identifying good terms for re-gearing, and
        ensuring both tenants and landlords can come to an effective compromise
        during these negotiations. In your current situation, however positive
        or negative it may be, it’s always worth looking at the terms of your
        lease and whether it’s best suited to you. 
        <br />
        <br />
        To re-gear your lease is to negotiate the terms of your lease whilst
        your contract is still ongoing: this can include:
      </p>
    ),
    items: [
      <h2>
        Hoping to alter your <span>payment instalments.</span>
      </h2>,
      <h2>
        Removing a <span>break clause</span> from your contract.
      </h2>,
      <h2>
        Getting the <span>property refurbished</span> in exchange for a longer
        tenancy.
      </h2>,
    ],
  };

  return (
    <Section>
      <img
        src={imgs.charterBlock}
        alt="block"
      />
      <Container>
        <Left>
          <h1>{contents.title}</h1>
          {contents.text}

          {windowSize.width! > laptop && (
            <a href="/enquiry">
              <ReusableButton
                name="Enquire Now"
                onClick={() => {}}
              />
            </a>
          )}
        </Left>
        <Right>
          {contents.items.map((el: React.ReactNode, i: number) => {
            return (
              <Item
                key={i}
                $background={colors.white}
              >
                {el}
              </Item>
            );
          })}
          {windowSize.width! <= laptop && (
            <a href="/enquiry">
              <ReusableButton
                name="Enquire Now"
                onClick={() => {}}
              />
            </a>
          )}
        </Right>
      </Container>
    </Section>
  );
};

export default Impact;
