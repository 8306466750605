import styled from "styled-components";
import size from "../../../theme/devices/sizes";
import device from "../../../theme/devices";

export const Section = styled.section`
  position: relative;
  max-width: ${size.laptopL};
  margin: 0 auto;

  > img {
    position: absolute;
    z-index: -1;
  }

  @media ${device.tabletL} {
    > img {
      display: none;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  padding: 99px 120px 120px;

  @media ${device.laptop} {
    flex-direction: column;
  }

  @media ${device.tabletL} {
    padding: 88px 56px;
  }

  @media ${device.mobileL} {
    padding: 88px 24px;
  }
`;
