import { useEffect, useState } from "react";
import { useAppSelector } from "../../../redux/hooks";
import Step1 from "./Steps/Step1";
import Step2 from "./Steps/Step2";
import {
  CircleCtn,
  CircleWrapper,
  Container,
  Left,
  Section,
  StepWrapper,
  Thankyou,
} from "./styles";
import Step3 from "./Steps/Step3";
import Step4 from "./Steps/Step4";
import Step5 from "./Steps/Step5";
import Step6 from "./Steps/Step6";
import Step7 from "./Steps/Step7";
import ReusableButton from "../ReusableButton";
import { colors } from "../../../theme/colors";
import { useNavigate } from "react-router-dom";
import icons from "../../../assets/icons";
import useWindowSize, { Size } from "../../../utils/useWindowSize";

const Form = () => {
  const windowSize: Size = useWindowSize();
  const { step } = useAppSelector((state) => state.enquiryStep);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const { pathname } = window.location;
  const [thankyou, setThankyou] = useState(false);
  const [changeTexts, setChangeTexts] = useState(false);
  const form = useAppSelector((state) => state.enquiryForm);

  const Steps = [
    <Step1 />,
    <Step2 />,
    <Step3 />,
    <Step4 />,
    <Step5 />,
    <Step6 />,
    <Step7 setSubmitted={setSubmitted} />,
  ];

  useEffect(() => {
    pathname.split("/")[2] === "thankyou"
      ? setThankyou(true)
      : setThankyou(false);
  }, [pathname]);

  useEffect(() => {
    if (thankyou && windowSize.height! < 850 && windowSize.width! < 995) {
      window.scroll({
        top: 280,
        left: 0,
        behavior: "smooth",
      });
    } else {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  }, [thankyou]);

  useEffect(() => {
    if (thankyou) {
      setTimeout(() => {
        setChangeTexts(true);
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }, 1000);
    }
  }, [thankyou]);

  return (
    <Section>
      <Container>
        {thankyou ? (
          <>
            <Thankyou>
              {changeTexts ? (
                <h1 className="thankyou">We’ve Received Your Enquiry!</h1>
              ) : (
                <h1>Are you seeking support from The CTA?</h1>
              )}
              {changeTexts ? (
                <p className="thankyou">
                  We’ve now received your enquiry. A member of the CTA team will
                  be in touch shortly to discuss your responses and what options
                  are available to you.
                  <br />
                  <br />
                  In the meantime, why don’t you sign up for a free membership
                  and learn about all the privileges being a member of the CTA
                  can bring you and your business.
                </p>
              ) : (
                <p>
                  Simply fill out our simple short form and a member of our team
                  will get back to you as swiftly as we can.
                </p>
              )}
              {changeTexts && (
                <a href="/">
                  <ReusableButton
                    $primary={false}
                    name="Return To Home"
                    color={colors.white}
                    onClick={() => {}}
                  />
                </a>
              )}
            </Thankyou>
            <CircleCtn>
              <CircleWrapper>
                <img
                  src={icons.circleBlue}
                  alt="circle"
                  className="circle"
                />
                <img
                  src={icons.circleTick}
                  alt="circle"
                  className="tick"
                />
              </CircleWrapper>
            </CircleCtn>
          </>
        ) : (
          <>
            <Left $submitted={submitted}>
              <h1>Are you seeking support from The CTA?</h1>
              <p>
                Simply fill out our simple short form and a member of our team
                will get back to you as swiftly as we can.
              </p>
            </Left>
            <StepWrapper>{Steps[step - 1]}</StepWrapper>
          </>
        )}
      </Container>
    </Section>
  );
};

export default Form;
