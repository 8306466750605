import { propertyImgs } from "../../../assets/imgs/property";
import EnquiryForm from "../../../component/Reusable/EnquiryForm";
import Offer from "../../../component/Reusable/Offer";
import NeedAdvice from "../../../component/Reusable/NeedAdvice";
import ReusableIntro from "../../../component/Reusable/ReusableIntro";
import ReusableHero from "../../../component/Reusable/ReusableHero";
import { offerData } from "../offerData";
import bgs from "../../../assets/bgs";

const OwnYourProperty = () => {
  const heroContents = {
    type: 2,
    title: <h1>Own Your Property</h1>,
    text: (
      <p>
        By constantly renting, commercial tenants across the UK may be left with
        either no retirement plan or end up being evicted at the end of their
        lease. By owning your own property, this can be easily avoided.
      </p>
    ),
    img: bgs.ownYourPropertyHeroBg,
    mobileimg: bgs.ownYourPropertyHeroBgMobile,
    button: true,
  };
  const introContents = {
    title: "How to own your own property",
    text: (
      <p>
        Commercial properties can be very expensive to buy completely outright:
        if you’re fortunate to be in this situation, it’s no different than
        buying a house, as you go through a process of making offers, conducting
        checks, and signing contracts. 
        <br />
        <br />
        But some aspiring property owners will need to secure funds in order to
        initially get on the commercial property ladder: this can usually be
        achieved by partnering with a business dedicated to helping commercial
        tenants, or by taking out a commercial mortgage with a lender. 
      </p>
    ),
    img: propertyImgs.ownYourPropertyIntro,
    link: "/enquiry",
  };

  const offerContents = [offerData.serviceCharge, offerData.rates];

  return (
    <>
      <ReusableHero contents={heroContents} />
      <ReusableIntro contents={introContents} />
      <NeedAdvice />
      <Offer
        contents={offerContents}
        title="Similar Services"
      />
      <EnquiryForm />
    </>
  );
};

export default OwnYourProperty;
