import { Button } from "./styles";

interface ReusableButtonProps {
  $primary?: boolean;
  name: string;
  value?: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  width?: string;
  height?: string;
  $mwidth?: string;
  color?: string;
  fontSize?: string;
  hover?: string;
}

const ReusableButton: React.FC<ReusableButtonProps> = ({
  $primary,
  name,
  value,
  onClick,
  width,
  height,
  $mwidth,
  color,
  fontSize,
  hover,
}) => {
  return (
    <Button
      $primary={$primary}
      value={value}
      onClick={(e: any) => onClick(e)}
      width={width}
      height={height}
      $mwidth={$mwidth}
      color={color}
      fontSize={fontSize}
      hover={hover}
    >
      {name}
    </Button>
  );
};

export default ReusableButton;
