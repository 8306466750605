import icons from "../../../assets/icons";
import ReusableButton from "../ReusableButton";

import {
  Buttons,
  Card,
  CardsCtn,
  Container,
  EnquireBtn,
  Section,
  Title,
} from "./styles";

interface OfferProps {
  title: string;
  contents: { img: string; title: string; text: string; link: string }[];
}

const Offer: React.FC<OfferProps> = ({ title, contents }) => {
  return (
    <Section>
      <Container>
        <Title>{title}</Title>
        <CardsCtn>
          {contents.map((content, i) => {
            return (
              <Card key={i}>
                <img
                  src={content.img}
                  alt="people-working"
                />
                <h2>{content.title}</h2>
                <p>{content.text}</p>
                <Buttons>
                  <a href={content.link}>
                    <ReusableButton
                      name="View More"
                      onClick={() => {}}
                      $mwidth="150px"
                    />
                  </a>
                  <a href="/enquiry">
                    <EnquireBtn>
                      <p>Enquire Now</p>
                      <img
                        src={icons.navArrowRight}
                        alt="arrow"
                      />
                    </EnquireBtn>
                  </a>
                </Buttons>
              </Card>
            );
          })}
        </CardsCtn>
      </Container>
    </Section>
  );
};

export default Offer;
