import accountBg from "./accountBg.png";
import adviceHeroBg from "./adviceHeroBg.png";
import benefitsBg from "./benefitsBg.png";
import communityHeroBg from "./communityHeroBg.png";
import communityHeroBgMobile from "./communityHeroBgMobile.png";
import donationPageBg from "./donationPageBg.png";
import enquireFormBg from "./enquireFormBg.jpeg";
import helpBg from "./helpBg.png";
import helpBgMobile from "./helpBgMobile.png";
import heroThirdBg from "./heroThirdBg.png";
import newsArticleHelp from "./newsArticleHelp.png";
import propertyNeedAdviceBg from "./propertyNeedAdviceBg.png";
import propertyNeedAdviceBgMobile from "./propertyNeedAdviceBgMobile.png";
import specificAdviceHeroBg from "./specificAdviceHeroBg.png";
import valueBg from "./valueBg.png";
import changeBlock from "./changeBlock.png";
import changeBlockMobile from "./changeBlockMobile.png";
import leaseRestructuringHeroBg from "./leaseRestructuringHeroBg.png";
import leaseRegearingHeroBg from "./leaseRegearingHeroBg.png";
import leaseRenewalsHeroBg from "./leaseRenewalsHeroBg.png";
import surrendersHeroBg from "./surrendersHeroBg.png";
import assignmentsHeroBg from "./assignmentsHeroBg.png";
import serviceChargeHeroBg from "./serviceChargeHeroBg.png";
import ratesHeroBg from "./ratesHeroBg.png";
import strategicLeaseManagementHeroBg from "./strategicLeaseManagementHeroBg.png";
import ownYourPropertyHeroBg from "./ownYourPropertyHeroBg.png";
import acquisitionsHeroBg from "./acquisitionsHeroBg.png";
import arrearsHeroBg from "./arrearsHeroBg.png";
import dilapidationsHeroBg from "./dilapidationsHeroBg.png";
import capExSupportHeroBg from "./capExSupportHeroBg.png";
import insolvencyAdviceHeroBg from "./insolvencyAdviceHeroBg.png";
import insuranceHeroBg from "./insuranceHeroBg.png";
import litigationHeroBg from "./litigationHeroBg.png";
import financeInvestmentsHeroBg from "./financeInvestmentsHeroBg.png";
import mergersAcquisitionHeroBg from "./mergersAcquisitionHeroBg.png";
import accountantsHeroBg from "./accountantsHeroBg.png";
import legalHeroBg from "./legalHeroBg.png";
import mediationHeroBg from "./mediationHeroBg.png";
import hrConsultantsHeroBg from "./hrConsultantsHeroBg.png";
import contractorsHeroBg from "./contractorsHeroBg.png";
import shopfittersHeroBg from "./shopfittersHeroBg.png";
import utilitiesHeroBg from "./utilitiesHeroBg.png";
import aboutHeroBgMobile from "./aboutHeroBgMobile.png";
import lobbyingHeroBgMobile from "./lobbyingHeroBgMobile.png";
import adviceHeroBgMobile from "./adviceHeroBgMobile.png";
import specificAdviceHeroBgMobile from "./specificAdviceHeroBgMobile.png";
import propertyServicesHeroBgMobile from "./propertyServicesHeroBgMobile.png";
import leaseRegearingHeroBgMobile from "./leaseRegearingHeroBgMobile.png";

import leaseRestructuringHeroBgMobile from "./leaseRestructuringHeroBgMobile.png";
import leaseRenewalsHeroBgMobile from "./leaseRenewalsHeroBgMobile.png";
import surrendersHeroBgMobile from "./surrendersHeroBgMobile.png";
import assignmentsHeroBgMobile from "./assignmentsHeroBgMobile.png";
import serviceChargeHeroBgMobile from "./serviceChargeHeroBgMobile.png";
import ratesHeroBgMobile from "./ratesHeroBgMobile.png";
import strategicLeaseManagementHeroBgMobile from "./strategicLeaseManagementHeroBgMobile.png";
import ownYourPropertyHeroBgMobile from "./ownYourPropertyHeroBgMobile.png";
import acquisitionsHeroBgMobile from "./acquisitionsHeroBgMobile.png";
import arrearsHeroBgMobile from "./arrearsHeroBgMobile.png";
import dilapidationsHeroBgMobile from "./dilapidationsHeroBgMobile.png";
import capExSupportHeroBgMobile from "./capExSupportHeroBgMobile.png";
import corporateServicesHeroBgMobile from "./corporateServicesHeroBgMobile.png";
import insolvencyAdviceHeroBgMobile from "./insolvencyAdviceHeroBgMobile.png";
import litigationHeroBgMobile from "./litigationHeroBgMobile.png";
import financeInvestmentsHeroBgMobile from "./financeInvestmentsHeroBgMobile.png";
import mergersAcquisitionHeroBgMobile from "./mergersAcquisitionHeroBgMobile.png";
import accountantsHeroBgMobile from "./accountantsHeroBgMobile.png";
import legalHeroBgMobile from "./legalHeroBgMobile.png";
import mediationHeroBgMobile from "./mediationHeroBgMobile.png";
import nonCorporateServicesHeroBgMobile from "./nonCorporateServicesHeroBgMobile.png";
import hrConsultantsHeroBgMobile from "./hrConsultantsHeroBgMobile.png";
import contractorsHeroBgMobile from "./contractorsHeroBgMobile.png";
import shopfittersHeroBgMobile from "./shopfittersHeroBgMobile.png";
import utilitiesHeroBgMobile from "./utilitiesHeroBgMobile.png";
import insuranceHeroBgMobile from "./insuranceHeroBgMobile.png";
import benefitsBgMobile from "./benefitsBgMobile.png";
import valuesBgMobile from "./valuesBgMobile.png";
import donationPageBgMobile from "./donationPageBgMobile.png";

interface Bgs {
  [key: string]: string;
}

const bgs: Bgs = {
  accountBg,
  adviceHeroBg,
  benefitsBg,
  communityHeroBg,
  communityHeroBgMobile,
  donationPageBg,
  enquireFormBg,
  helpBg,
  helpBgMobile,
  heroThirdBg,
  newsArticleHelp,
  propertyNeedAdviceBg,
  propertyNeedAdviceBgMobile,
  specificAdviceHeroBg,
  valueBg,
  changeBlock,
  changeBlockMobile,
  leaseRestructuringHeroBg,
  leaseRegearingHeroBg,
  leaseRenewalsHeroBg,
  surrendersHeroBg,
  assignmentsHeroBg,
  serviceChargeHeroBg,
  ratesHeroBg,
  strategicLeaseManagementHeroBg,
  ownYourPropertyHeroBg,
  acquisitionsHeroBg,
  arrearsHeroBg,
  dilapidationsHeroBg,
  capExSupportHeroBg,
  insolvencyAdviceHeroBg,
  insuranceHeroBg,
  litigationHeroBg,
  financeInvestmentsHeroBg,
  mergersAcquisitionHeroBg,
  accountantsHeroBg,
  legalHeroBg,
  mediationHeroBg,
  hrConsultantsHeroBg,
  contractorsHeroBg,
  shopfittersHeroBg,
  utilitiesHeroBg,
  aboutHeroBgMobile,
  lobbyingHeroBgMobile,
  adviceHeroBgMobile,
  specificAdviceHeroBgMobile,
  propertyServicesHeroBgMobile,
  leaseRegearingHeroBgMobile,
  leaseRestructuringHeroBgMobile,
  leaseRenewalsHeroBgMobile,
  surrendersHeroBgMobile,
  assignmentsHeroBgMobile,
  serviceChargeHeroBgMobile,
  ratesHeroBgMobile,
  strategicLeaseManagementHeroBgMobile,
  ownYourPropertyHeroBgMobile,
  acquisitionsHeroBgMobile,
  arrearsHeroBgMobile,
  dilapidationsHeroBgMobile,
  capExSupportHeroBgMobile,
  corporateServicesHeroBgMobile,
  insolvencyAdviceHeroBgMobile,
  litigationHeroBgMobile,
  financeInvestmentsHeroBgMobile,
  mergersAcquisitionHeroBgMobile,
  accountantsHeroBgMobile,
  legalHeroBgMobile,
  mediationHeroBgMobile,
  nonCorporateServicesHeroBgMobile,
  hrConsultantsHeroBgMobile,
  contractorsHeroBgMobile,
  shopfittersHeroBgMobile,
  utilitiesHeroBgMobile,
  insuranceHeroBgMobile,
  benefitsBgMobile,
  valuesBgMobile,
  donationPageBgMobile,
};

export default bgs;
