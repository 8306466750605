import styled from "styled-components";
import size from "../../../theme/devices/sizes";
import device from "../../../theme/devices";

export const Section = styled.section`
  display: flex;
  justify-content: space-between;
  max-width: ${size.laptopL};
  margin: 0 auto;
  padding: 0px 120px 120px;
  gap: 40px;

  > div {
    flex: 1;
  }

  .first {
    height: 100%;
    > div {
      > a {
        @media ${device.laptop} {
          display: none;
        }
      }
    }
  }

  @media ${device.laptop} {
    flex-direction: column;
  }

  @media ${device.tabletL} {
    padding: 0px 56px 120px;
  }

  @media ${device.mobileL} {
    gap: 16px;
    padding: 0px 24px 88px;

    > a {
      margin-top: 16px;
    }
  }
`;
