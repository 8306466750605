import { useNavigate } from "react-router-dom";
import { corporateImgs } from "../../../assets/imgs/corporate";
import ReusableButton from "../../Reusable/ReusableButton";
import ReusableIntro from "../../Reusable/ReusableIntro";
import { Container, ParagraphsWrapper, Paragraph } from "./styles";

const InsuranceIntro = () => {
  const navigate = useNavigate();

  const introContents = {
    title: "Insurance tips for leasehold property",
    text: (
      <p>
        When leasing a property, the tenant will usually be responsible for the
        payment of the insurance premium, or an appropriate portion, to cover
        damage to the premises and any loss of rent the landlord may suffer if
        the premises become unusable. This effectively means that if the
        premises is damaged and a tenant is unable to utilise it for business,
        the tenant will not have to pay rent until the damage is repaired.
      </p>
    ),
    img: corporateImgs.insuranceIntro,
    link: "/enquiry",
  };

  return (
    <Container>
      <ReusableIntro contents={introContents} />
      <ParagraphsWrapper>
        <Paragraph>
          <p>
            The lease should state who is responsible for arranging buildings
            insurance and paying the premium.
            <br />
            <br />
            With most leases, the landlord will arrange and pay for buildings
            insurance but will then pass on the costs (or an appropriate
            proportion in a shared premises) either as part of the service
            charge or more commonly, as a separate itemised charge. Tenants can
            ask to see a copy of the cover to ensure that it is adequate and in
            line with what is being charged.
          </p>
        </Paragraph>
        <Paragraph>
          <p>
            Other insurances, such as contents and employers’ liability
            insurance may also be required. Even where a landlord arranges
            buildings insurance, the tenant may want to ensure that their own
            insurance includes cover for any losses from business interruption
            caused by problems with the building.
          </p>
          <a href="/corporate/insurance">
            <ReusableButton
              name="Enquire Now"
              onClick={() => {}}
            />
          </a>
        </Paragraph>
      </ParagraphsWrapper>
    </Container>
  );
};

export default InsuranceIntro;
