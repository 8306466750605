import styled from "styled-components";
import { colors } from "../../../theme/colors";
import size from "../../../theme/devices/sizes";
import device from "../../../theme/devices";

export const Section = styled.div`
  padding: 96px 56px 120px;
  max-width: ${size.laptopL};
  margin: 0 auto;

  @media ${device.mobileL} {
    padding: 0;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 56px;

  margin: 0 auto;
  background: #e7ecee;
  padding: 80px 66px;

  @media ${device.mobileL} {
    padding: 80px 24px;
  }
`;

export const Titles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 824px;

  > h1 {
    font-size: 40px;
    line-height: 56px;
  }

  > p {
    color: ${colors.greenLight};
    font-size: 16px;
    font-weight: 300;
    line-height: 28px;
  }
`;

export const CardsCtn = styled.div`
  display: flex;
  gap: 39px;
  flex-wrap: wrap;
`;

interface CardProps {
  $background?: string;
  color: string;
  $show?: boolean;
  $submitted?: boolean;
}

interface InputProps {
  $show?: boolean;
  color?: string;
}

export const Card = styled.div<CardProps>`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  background: ${({ $background }) => $background || `${colors.white}`};
  ${({ $submitted }) => $submitted && "background: #23E55A;"}
  border-radius: 4px;
  box-shadow: 0px 10px 30px 0px rgba(7, 53, 71, 0.1);
  padding: ${({ $show }) => ($show ? "52px 32px 28px" : "108px 32px 28px")};
  min-width: 372px;
  max-widt: 372px;
  height: 394px;
  transition: 0.3s ease-out;

  > img {
    display: ${({ $submitted }) => ($submitted ? "block" : "none")};
    width: 80px;
    height: 80px;
  }

  > svg {
    position: absolute;
    opacity: 0.2;
    top: 10%;
    right: 0;
    pointer-events: none;

    path {
      fill: ${({ $submitted }) => $submitted && "#fff"};
    }
  }

  > h1 {
    font-size: 40px;
    color: ${({ $submitted, color }) =>
      $submitted
        ? "#fff"
        : color === `${colors.primary}`
        ? `${colors.white}`
        : `${colors.greenDark}`};

    > span {
      color: ${({ $submitted, color }) => ($submitted ? "#fff" : color)};
    }
    transition: 0.3s ease-out;
  }

  > p {
    display: ${({ $submitted }) => ($submitted ? "block" : "none")};
    font-size: 16px;
    line-height: 32px;
    color: #fff;
  }

  @media ${device.mobileL} {
    padding: 50px 16px 35px;
    min-width: 100%;
    height: 336px;

    > h1 {
      font-size: 32px;
    }
  }
`;

export const InputWrapper = styled.div<CardProps>`
  display: ${({ $submitted }) => ($submitted ? "none" : "flex")};
  flex-direction: column;
  gap: 50px;
  width: 308px;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 100%;

  opacity: ${({ $show }) => ($show ? "1" : "0")};

  ${({ $show }) => $show && "transform: translate(-50%,-118%)"};
  transition: 0.3s ease-out;

  > input {
    width: 100%;
    z-index: 2;
    height: 50px;
    padding: 14px;
    border-radius: 4px;
    background: ${({ color }) =>
      color === `${colors.primary}`
        ? `${colors.greenLight2}`
        : `${colors.white}`};
    outline: none;
    border: none;
    border: 2px solid
      ${({ color }) =>
        color === `${colors.primary}` ? `${colors.greenLight}` : "#D3DADD"};
    color: ${({ color }) =>
      color === `${colors.primary}`
        ? `${colors.white}`
        : `${colors.greenDark}`};
    font-size: 16px;

    &::placeholder {
      color: ${({ color }) =>
        color === `${colors.primary}`
          ? `${colors.white}`
          : `${colors.greenDark}`};
    }
  }

  @media ${device.mobileL} {
    width: 295px;
  }
`;

export const Button = styled.button<CardProps>`
  width: 100%;
  background: ${({ color }) => color};
  border: none;
  height: 50px;
  font-size: 16px;
  font-weight: 700;
  color: ${({ color }) =>
    color === `${colors.primary}` ? `${colors.greenDark}` : `${colors.white}`};
  z-index: 2;
  cursor: pointer;
  border-radius: 4px;
  ${({ $show }) =>
    $show
      ? "transform: translateY(-100%);display: none;transition: 0.2s linear;"
      : ""}
`;
