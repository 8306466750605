import styled from "styled-components";
import { colors } from "../../../theme/colors";
import device from "../../../theme/devices";
import size from "../../../theme/devices/sizes";

interface SectionProps {
  id?: string;
}

export const Section = styled.section<SectionProps>`
  background: ${colors.greenDark};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${size.laptopL};
  margin: 0 auto 120px;
  padding: 80px 120px;
  gap: 56px;

  @media ${device.tabletL} {
    padding: 80px 56px;
    margin: 0 auto;
  }

  @media ${device.mobileL} {
    padding: 64px 24px;
  }
`;

export const Top = styled.div`
  display: flex;
  color: ${colors.white};
  justify-content: space-between;
  align-items: flex-end;

  @media ${device.tabletL} {
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
  }
`;

export const Titles = styled.div`
  display: flex;
  flex-direction: column;

  > h1 {
    font-size: 40px;
    line-height: 56px;
  }

  p {
    font-size: 16px;
    line-height: 28px;
  }

  @media ${device.mobileL} {
    > h1 {
      font-size: 32px;
      line-height: 48px;
    }

    > p {
      font-size: 14px;
      line-height: 26px;
    }
  }
`;

interface CardsCtnProps {
  $full: boolean;
}

export const CardsCtn = styled.div<CardsCtnProps>`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(287px, 1fr));
  grid-template-rows: ${({ $full }) =>
    $full ? "repeat(2, 1fr)" : "repeat(1, 1fr)"};
  gap: 16px;
`;

export const CardsWrapper = styled.div``;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 24px;
  background: ${colors.greenLight2};
  color: ${colors.white};
  border: 1px solid ${colors.primary};
  border-radius: 4px;
  padding: 24px;
  min-width: 287px;
  max-width: 100%;
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  text-align: center;

  @media ${device.mobileL} {
    padding: 24px 16px;
    max-width: 100%;

    button {
      width: 80%;
    }
  }
`;

export const ImgWrapper = styled.div`
  position: relative;

  > img {
    object-fit: cover;
    width: 138px;
    height: 147px;
  }

  > #lock {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 34px;
    height: 44px;
  }
`;

export const ButtonWrapper = styled.div`
  position: relative;
  width: 100%;

  > img {
    position: absolute;
    top: 50%;
    left: 25%;
    transform: translateY(-50%);
  }

  @media ${device.mobileL} {
    > img {
      left: 32%;
    }
  }
`;
