import ReusableButton from "../../Reusable/ReusableButton";
import size from "../../../theme/devices/sizes";
import useWindowSize, { Size } from "../../../utils/useWindowSize";
import Paragraph from "../../Reusable/Paragraph";
import { Section } from "./styles";

const Act1954 = () => {
  const windowSize: Size = useWindowSize();
  const laptop = +size.laptop.slice(0, -2);

  const firstParagraph = {
    title: "Is The Lease Inside The Landlord & Tenant Act 1954?",
    text: "",
    button: true,
  };
  const secondParagraph = {
    title: "",
    text: (
      <p>
        It is important to understand the implications of the terms of the lease
        and a tenant should always consider enlisting the help of a professional
        adviser.
        <br />
        <br />
        Generally, if the lease is inside the{" "}
        <span>Landlord and Tenant Act 1954</span>, there is a right to renew the
        lease of the premises. If notice is not provided to the landlord of the
        tenant’s intention to vacate, it will be assumed that occupation of the
        space will continue. Naturally, it is more appropriate to begin the
        negotiation for a new lease well ahead of lease expiry and seek
        preferential terms through a tenant representative. However, the
        landlord may terminate the lease by giving notice, which might be as
        little as six months.
        <br />
        <br />
        Alternatively, if the lease is outside the LtA 1954, there is no right
        to renew the lease, but there can still be an opportunity to open a
        discussion with the landlord. Unless there are underlying motives that
        the tenant is unaware of, such as development/refurbishment plans for
        the property, landlords will generally seek to retain the incumbent
        tenant. The landlord will want to minimise the risk of a vacancy void,
        where no tenant is leasing the property and where no income is being
        generated by the property.
      </p>
    ),
    button: false,
  };
  return (
    <Section>
      <div className="first">
        <Paragraph contents={firstParagraph} />
      </div>
      <Paragraph contents={secondParagraph} />

      {windowSize.width! <= laptop && (
        <a href="/enquiry">
          <ReusableButton
            name="Enquire Now"
            onClick={() => {}}
          />
        </a>
      )}
    </Section>
  );
};

export default Act1954;
