import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type StepState = boolean;

const initialState: StepState = false;

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    updateLogin: (state, action: PayloadAction<boolean>) => {
      return action.payload;
    },
    resetLogin: (state) => {
      return (state = initialState);
    },
  },
});

export const { updateLogin, resetLogin } = loginSlice.actions;
export default loginSlice.reducer;
