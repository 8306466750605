import EnquiryForm from "../../component/Reusable/EnquiryForm";
import { Main } from "./styes";

const EnquiryFormPage = () => {
  return (
    <Main>
      <EnquiryForm />
    </Main>
  );
};

export default EnquiryFormPage;
