import { ChangeEvent, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  InputField,
  InputWrapper,
  MultiInputsWrapper,
  Title,
  TopAmountWrapper,
  InputsCtn,
  ErrorMsg,
  PopPup,
} from "./styles";
import icons from "../../assets/icons";
import ReusableButton from "../../component/Reusable/ReusableButton";
import {
  resetDonationForm,
  updateDonationForm,
} from "../../redux/features/donationFormSlice";
import store from "../../redux/store";
import { Loading, Notify } from "notiflix";
import submitDonation from "../../utils/submitDonation";
import { useNavigate } from "react-router-dom";

interface Step3Props {
  fail: boolean;
}

const Step3: React.FC<Step3Props> = ({ fail }) => {
  const navigate = useNavigate();
  const form = useAppSelector((state) => state.donationForm);
  const dispatch = useAppDispatch();
  const [details, setDetails] = useState<{ [key: string]: string }>({
    name: "",
    card_number: "",
    card_exp_month: "",
    card_exp_year: "",
    card_cvc: "",
  });
  const [show, setShow] = useState({
    name: false,
    card_number: false,
    card_exp_month: false,
    card_exp_year: false,
    card_cvc: false,
  });
  const [showPopup, setShowPopup] = useState({
    card_number: false,
    card_cvc: false,
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (name === "card_number") {
      const formattedValue = value
        .replace(/\D/g, "")
        .slice(0, 16)
        .replace(/(\d{4})(?=\d)/g, "$1-");

      setDetails((prev) => ({ ...prev, card_number: formattedValue }));
    } else if (name === "card_exp_month") {
      const formattedValue = value.replace(/\D/g, "").slice(0, 2);

      setDetails((prev) => ({ ...prev, card_exp_month: formattedValue }));
    } else if (name === "card_exp_year") {
      const formattedValue = value
        .replace(/\D/g, "")
        .slice(0, 4)
        .replace(/(\d{4})(?=\d)/g, "$1 ");

      setDetails((prev) => ({ ...prev, card_exp_year: formattedValue }));
    } else if (name === "card_cvc") {
      const formattedValue = value.replace(/\D/g, "").slice(0, 3);

      setDetails((prev) => ({ ...prev, card_cvc: formattedValue }));
    } else {
      const formattedValue = value.replace(/[^a-zA-Z\s]+/g, "");

      setDetails((prev) => ({ ...prev, name: formattedValue }));
      if (name.length > 0) {
        setShow((prev) => ({ ...prev, name: true }));
      }
    }
  };

  const handleFocus = (name: string) => {
    setShow((prev) => ({ ...prev, [name]: true }));
  };

  const handleBlur = (name: string) => {
    if (details[name].length === 0) {
      setShow((prev) => ({ ...prev, [name]: false }));
    }
  };

  const handleSubmit = async () => {
    try {
      if (details.name.length === 0) {
        return Notify.failure("Please provide your name");
      }
      if (details.card_number.length === 0) {
        return Notify.failure("Please provide your card number");
      }
      if (details.card_exp_month.length === 0) {
        return Notify.failure("Please provide your card's expiry month");
      }
      if (details.card_exp_year.length === 0) {
        return Notify.failure("Please provide your card's expiry year");
      }
      if (details.card_exp_year.length < 4) {
        return Notify.failure("Please provide your card's full expiry year");
      }
      if (details.card_cvc.length === 0) {
        return Notify.failure("Please provide your card's CVV");
      }

      Loading.standard();

      dispatch(updateDonationForm(details));

      const formData = store.getState().donationForm;

      const res = await submitDonation(formData);

      if (res.success) {
        dispatch(resetDonationForm());
        if (!res.next_action) {
          navigate("/donation/thankyou");
        } else {
          window.location.href = res.next_action.redirect_to_url.url;
        }
      } else {
        Loading.remove();
        Notify.failure(res);
      }
    } catch (err) {
      Loading.remove();
      console.log(err);
    }
  };

  return (
    <>
      <TopAmountWrapper>
        <div>
          <p>Your Donation</p>
          <p>
            <span>£{form.amount}</span>
          </p>
        </div>
        <div>
          <p>Billing Information</p>
          <p>
            <span>Complete</span>
          </p>
        </div>
      </TopAmountWrapper>
      <Title>Card Details</Title>
      <InputsCtn>
        <InputWrapper $show={show.name}>
          {details.name.length > 0 || show.name ? (
            <p>Cardholder's name</p>
          ) : null}
          <InputField
            name="name"
            value={details.name}
            placeholder="Cardholder's name"
            $show={details.name.length > 0}
            onFocus={() => handleFocus("name")}
            onBlur={() => handleBlur("name")}
            onChange={handleChange}
          />
        </InputWrapper>
        <InputWrapper $show={show.card_number}>
          {details.card_number.length > 0 || show.card_number ? (
            <p>Card number</p>
          ) : null}
          <InputField
            name="card_number"
            value={details.card_number}
            placeholder="Card number"
            $show={details.card_number.length > 0}
            onFocus={() => handleFocus("card_number")}
            onBlur={() => handleBlur("card_number")}
            onChange={handleChange}
          />
          <img
            src={icons.card}
            alt="card"
            style={{ left: "unset", right: "14px" }}
            onClick={() =>
              setShowPopup((prev) => ({
                ...prev,
                card_number: !showPopup.card_number,
              }))
            }
          />
          {showPopup.card_number && (
            <PopPup>
              <p>This is the 16 digits number on the front of your card</p>
            </PopPup>
          )}
        </InputWrapper>
        <MultiInputsWrapper>
          <InputWrapper $show={show.card_exp_month}>
            {details.card_exp_month.length > 0 || show.card_exp_month ? (
              <p>Expiry Month</p>
            ) : null}
            <InputField
              name="card_exp_month"
              value={details.card_exp_month}
              placeholder="Expiry Month"
              $show={details.card_exp_month.length > 0}
              onFocus={() => handleFocus("card_exp_month")}
              onBlur={() => handleBlur("card_exp_month")}
              onChange={handleChange}
              inputMode="numeric"
            />
          </InputWrapper>
          <InputWrapper $show={show.card_exp_year}>
            {details.card_exp_year.length > 0 || show.card_exp_year ? (
              <p>Expiry Year</p>
            ) : null}
            <InputField
              name="card_exp_year"
              value={details.card_exp_year}
              placeholder="Expiry Year"
              $show={details.card_exp_year.length > 0}
              onFocus={() => handleFocus("card_exp_year")}
              onBlur={() => handleBlur("card_exp_year")}
              onChange={handleChange}
              inputMode="numeric"
            />
          </InputWrapper>
          <InputWrapper $show={show.card_cvc}>
            {details.card_cvc.length > 0 || show.card_cvc ? <p>CVV</p> : null}
            <InputField
              name="card_cvc"
              value={details.card_cvc}
              placeholder="CVV"
              $show={details.card_cvc.length > 0}
              onFocus={() => handleFocus("card_cvc")}
              onBlur={() => handleBlur("card_cvc")}
              onChange={handleChange}
              inputMode="numeric"
            />
            <img
              src={icons.infoBlue}
              alt="i-icon"
              style={{ left: "unset", right: "14px" }}
              onClick={() =>
                setShowPopup((prev) => ({
                  ...prev,
                  card_cvc: !showPopup.card_cvc,
                }))
              }
            />
            {showPopup.card_cvc && (
              <PopPup>
                <p>This is the number on the back of your card</p>
              </PopPup>
            )}
          </InputWrapper>
        </MultiInputsWrapper>
      </InputsCtn>
      <ReusableButton
        name="Send Donation"
        onClick={handleSubmit}
      />
      {fail && (
        <ErrorMsg>
          *Sorry, we were unable to process your payment. Please check all
          payment details are correct and try again.
        </ErrorMsg>
      )}
    </>
  );
};

export default Step3;
