import axios from "axios";

const profile = async (data: any) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: data,
    };

    const res = await axios({
      method: "get",
      url: process.env.REACT_APP_API + "/profile",
      headers,
    });

    return res.data;
  } catch (err: any) {
    console.log("Profile Error: ", err);
    return err.response.data;
  }
};

export default profile;
