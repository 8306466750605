import size from "../../../theme/devices/sizes";
import useWindowSize, { Size } from "../../../utils/useWindowSize";
import Paragraph from "../../Reusable/Paragraph";
import ReusableButton from "../../Reusable/ReusableButton";
import { Container, Section } from "./styles";

const Challenging = () => {
  const windowSize: Size = useWindowSize();
  const laptop = +size.laptop.slice(0, -2);

  const firstParagraph = {
    title: "Challenging The Rates Paid",
    text: "",
    img: "",
    button: true,
  };
  const secondParagraph = {
    title: "",
    text: (
      <p>
        There are often grounds to challenge the level of rates that a business
        must pay. This may be by claiming relief or challenging the VO’s
        estimate of rateable value.
        <br />
        <br />
        From 1 April 2017, the VO introduced a new system of challenging
        business rates, known as the Check, Challenge, Appeal process. It
        requires the ratepayer to register online, even if they wish to appoint
        an agent to deal with matters for them. If you a tenant does decide to
        appoint an agent, they will need their unique reference code.
        <br />
        <br />
        Before tenants can ‘Challenge’ the rates they pay, the ratepayer or its
        agent must complete the ‘Check’ process.
        <br />
        <br />
        Challenging the rates liability successfully requires the ratepayer to
        prove the VO is wrong in its assessment of the rateable value.
        Unsurprisingly, this requires a good deal of evidence and supporting
        information. Tenants should be aware that they may find themselves
        liable for a £500 penalty for any incorrect information supplied at this
        stage.
      </p>
    ),
    img: "",
  };
  return (
    <Section>
      <Container>
        <div className="first">
          <Paragraph contents={firstParagraph} />
        </div>
        <div className="second">
          <Paragraph contents={secondParagraph} />
        </div>
        {windowSize.width! <= laptop && (
          <a href="/enquiry">
            <ReusableButton
              name="Enquire Now"
              onClick={() => {}}
            />
          </a>
        )}
      </Container>
    </Section>
  );
};

export default Challenging;
