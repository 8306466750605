import axios from "axios";

const login = async (data: any) => {
  try {
    const res = await axios.post(process.env.REACT_APP_API + "/login", data);

    return res.data;
  } catch (err: any) {
    console.log("Login Error: ", err);
    return err.response.data;
  }
};

export default login;
