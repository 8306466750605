import imgs from "../../../assets/imgs";
import ReusableButton from "../../Reusable/ReusableButton";
import size from "../../../theme/devices/sizes";
import useWindowSize, { Size } from "../../../utils/useWindowSize";
import { Container, Item, ItemsCtn, Section, Titles, Top } from "./styles";
import { useNavigate } from "react-router-dom";

const CostConsiderations = () => {
  const navigate = useNavigate();
  const windowSize: Size = useWindowSize();
  const tabletL = +size.tabletL.slice(0, -2);
  const contents = [
    {
      title: "Agent’s Fees",
      text: (
        <p>
          This may be comparably smaller than if the tenant was taking a new
          lease elsewhere, as the property search element is removed from the
          equation.
        </p>
      ),
    },
    {
      title: "Solicitor’s Fees",
      text: (
        <p>
          As with a new lease, there are legal fees incurred during the lease
          renewal process. However, it is worth assessing, with the tenant
          representative and solicitor, whether any of the original lease terms
          and clauses will be changing. If they are largely staying the same, it
          could lower the overall conveyancing fees compared to a{" "}
          <span>new negotiation.</span>
        </p>
      ),
    },
    {
      title: "Refurbishment / Reconfiguration Works",
      text: (
        <p>
          Even though a business does not have to install, and pay for, a new
          fit-out within the space, it could still seek to address the fact that
          it may have been in the property for over 5 years. Indeed, a
          refurbishment or replacement of furniture may be useful to refresh the
          space for your employees.
        </p>
      ),
    },
  ];
  return (
    <Section>
      {windowSize.width! > tabletL && (
        <img
          src={imgs.charterBlock}
          alt="block"
        />
      )}
      <Container>
        <Top>
          <Titles>
            <h1>Cost Considerations</h1>
            <p>
              There are a few areas to consider when it comes to the costs
              involved in a lease renewal:
            </p>
          </Titles>
          {windowSize.width! > tabletL && (
            <ReusableButton
              name="Enquire Now"
              onClick={() => navigate("/enquiry")}
            />
          )}
        </Top>
        <ItemsCtn>
          {contents.map((content, i) => {
            return (
              <Item key={i}>
                <h2>{content.title}</h2>
                {content.text}
              </Item>
            );
          })}
        </ItemsCtn>
        {windowSize.width! <= tabletL && (
          <ReusableButton
            name="Enquire Now"
            onClick={() => navigate("/enquiry")}
          />
        )}
      </Container>
    </Section>
  );
};

export default CostConsiderations;
