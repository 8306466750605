import styled from "styled-components";
import size from "../../../theme/devices/sizes";
import { colors } from "../../../theme/colors";
import device from "../../../theme/devices";

export const Section = styled.section`
  background: #063446;
  margin-bottom: 120px;

  @media ${device.mobileL} {
    margin-bottom: 0px;
  }
`;

export const Container = styled.div`
  display: flex;
  gap: 39.5px;
  max-width: ${size.laptopL};
  margin: 0 auto;
  padding: 88px 120px 99px;

  @media ${device.tabletL} {
    flex-direction: column;
    padding: 88px 56px 99px;
    gap: 20px;
  }

  @media ${device.mobileL} {
    padding: 64px 24px;
  }
`;

export const Point = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  color: ${colors.white};

  > img {
    width: 56px;
  }

  > h3 {
    font-size: 24px;
    line-height: 32px;
  }

  > p {
    font-size: 16px;
    line-height: 28px;
  }

  @media ${device.mobileL} {
    > img {
      width: 48px;
    }

    > h3 {
      font-size: 20px;
    }

    > p {
      font-size: 14px;
      line-height: 26px;
    }
  }
`;
