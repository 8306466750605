import account1 from "./account1.svg";
import account2 from "./account2.svg";
import account3 from "./account3.svg";
import accountLogo from "./accountLogo.svg";
import apostrophes from "./apostrophes.svg";
import benefits1 from "./benefits1.svg";
import benefits2 from "./benefits2.svg";
import benefits3 from "./benefits3.svg";
import circleBlue from "./circleBlue.svg";
import circleTick from "./circleTick.svg";
import communityGroup1 from "./communityGroup1.svg";
import communityGroup2 from "./communityGroup2.svg";
import communityGroup3 from "./communityGroup3.svg";
import cross from "./cross.svg";
import ctaLogo from "./ctaLogo.svg";
import emailColor from "./emailColor.svg";
import emailIcon from "./emailIcon.svg";
import enquireFrom1 from "./enquireFrom1.svg";
import enquireFrom2 from "./enquireFrom2.svg";
import enquireFrom3 from "./enquireFrom3.svg";
import facebookColor from "./facebookColor.svg";
import facebookIcon from "./facebookIcon.svg";
import hamburger from "./hamburger.svg";
import hands from "./hands.svg";
import handsL from "./handsL.svg";
import heroArrow from "./heroArrow.svg";
import identifying1 from "./identifying1.svg";
import identifying2 from "./identifying2.svg";
import identifying3 from "./identifying3.svg";
import identifying4 from "./identifying4.svg";
import identifying5 from "./identifying5.svg";
import info from "./info.svg";
import instagramIcon from "./instagramIcon.svg";
import linkedInColor from "./linkedInColor.svg";
import linkedInIcon from "./linkedInIcon.svg";
import location from "./location.svg";
import lock from "./lock.svg";
import lockBlue from "./lockBlue.svg";
import logo from "./logo.svg";
import logoNav from "./logoNav.svg";
import mail from "./mail.svg";
import messages from "./messages.svg";
import minus from "./minus.svg";
import minusGrey from "./minusGrey.svg";
import navArrow from "./navArrow.svg";
import navArrowRight from "./navArrowRight.svg";
import opopLogo from "./opopLogo.svg";
import peoples from "./peoples.svg";
import peoplesL from "./peoplesL.svg";
import plus from "./plus.svg";
import search from "./search.svg";
import shield from "./shield.svg";
import shieldL from "./shieldL.svg";
import threePoints1 from "./threePoints1.svg";
import threePoints2 from "./threePoints2.svg";
import threePoints3 from "./threePoints3.svg";
import tick from "./tick.svg";
import triangleRight from "./triangleRight.svg";
import twitterColor from "./twitterColor.svg";
import twitterIcon from "./twitterIcon.svg";
import userIcon from "./userIcon.svg";
import userIconBlue from "./userIconBlue.svg";
import values1 from "./value1.svg";
import values2 from "./value2.svg";
import values3 from "./value3.svg";
import values4 from "./value4.svg";
import values5 from "./value5.svg";
import whatsappColor from "./whatsappColor.svg";
import downloadable1 from "./downloadable1.png";
import downloadable2 from "./downloadable2.png";
import downloadable3 from "./downloadable3.png";
import downloadable4 from "./downloadable4.png";
import downloadable5 from "./downloadable5.png";
import downloadable6 from "./downloadable6.png";
import downloadable7 from "./downloadable7.png";
import downloadable8 from "./downloadable8.png";
import enquireForm1 from "./enquireFrom1.svg";
import enquireForm2 from "./enquireFrom2.svg";
import enquireForm3 from "./enquireFrom3.svg";
import arrowLeft from "./arrowLeft.svg";
import arrowRight from "./arrowRight.svg";
import donationPound from "./donationPound.svg";
import card from "./card.svg";
import infoBlue from "./infoBlue.svg";
import circleTickGreen from "./circleTickGreen.svg";
import visible from "./visible.svg";

interface Icons {
  [key: string]: string;
}

const icons: Icons = {
  account1,
  account2,
  account3,
  accountLogo,
  apostrophes,
  benefits1,
  benefits2,
  benefits3,
  circleBlue,
  circleTick,
  communityGroup1,
  communityGroup2,
  communityGroup3,
  cross,
  ctaLogo,
  emailColor,
  emailIcon,
  enquireFrom1,
  enquireFrom2,
  enquireFrom3,
  facebookColor,
  facebookIcon,
  hamburger,
  hands,
  handsL,
  heroArrow,
  identifying1,
  identifying2,
  identifying3,
  identifying4,
  identifying5,
  info,
  instagramIcon,
  linkedInColor,
  linkedInIcon,
  location,
  lock,
  lockBlue,
  logo,
  logoNav,
  mail,
  messages,
  minus,
  minusGrey,
  navArrow,
  navArrowRight,
  opopLogo,
  peoples,
  peoplesL,
  plus,
  search,
  shield,
  shieldL,
  threePoints1,
  threePoints2,
  threePoints3,
  tick,
  triangleRight,
  twitterColor,
  twitterIcon,
  userIcon,
  userIconBlue,
  values1,
  values2,
  values3,
  values4,
  values5,
  whatsappColor,
  downloadable1,
  downloadable2,
  downloadable3,
  downloadable4,
  downloadable5,
  downloadable6,
  downloadable7,
  downloadable8,
  enquireForm1,
  enquireForm2,
  enquireForm3,
  arrowLeft,
  arrowRight,
  donationPound,
  card,
  infoBlue,
  circleTickGreen,
  visible,
};

export default icons;
