import { nonCorporateImgs } from "../../../assets/imgs/nonCorporate";
import EnquiryForm from "../../../component/Reusable/EnquiryForm";
import Offer from "../../../component/Reusable/Offer";
import NeedAdvice from "../../../component/Reusable/NeedAdvice";
import ReusableIntro from "../../../component/Reusable/ReusableIntro";
import ReusableHero from "../../../component/Reusable/ReusableHero";
import { offerData } from "../offerData";
import bgs from "../../../assets/bgs";

const Contractors = () => {
  const heroContents = {
    type: 2,
    title: <h1>Contractors</h1>,
    text: (
      <p>
        If you have permission to extend or renovate your existing commercial
        property, we can help with the expert contractors you need with the
        experience to ensure things run smoothly.
      </p>
    ),
    img: bgs.contractorsHeroBg,
    mobileimg: bgs.contractorsHeroBgMobile,
    button: true,
  };

  const introContents = {
    title: "Need the best contractor for the job?",
    text: (
      <p>
        Whether it’s the commercial design and build of large scale retail,
        industrial or factory premises, or new housing developments and bespoke
        renovations, our design and build contractors have experience in a
        diverse range of building projects.
        <br />
        <br />
        We understand the importance of attention to detail, precision and
        knowledge. Combining design, commercial awareness and safety, our
        management team work hard to deliver your vision, on time and on budget.
      </p>
    ),
    img: nonCorporateImgs.contractorsIntro,
    link: "/enquiry",
  };

  const offerContents = [offerData.utilities, offerData.shopfitters];

  return (
    <>
      <ReusableHero contents={heroContents} />
      <ReusableIntro contents={introContents} />
      <NeedAdvice />
      <Offer
        contents={offerContents}
        title="Similar Services"
      />
      <EnquiryForm />
    </>
  );
};

export default Contractors;
