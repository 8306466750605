import styled from "styled-components";
import size from "../../../theme/devices/sizes";
import { colors } from "../../../theme/colors";
import device from "../../../theme/devices";

export const Section = styled.section``;

export const Container = styled.div`
  display: flex;
  gap: 80px;
  max-width: ${size.laptopL};
  margin: 0 auto;
  padding: 120px;

  @media ${device.tabletL} {
    flex-direction: column;
    padding: 120px 56px;
  }

  @media ${device.mobileL} {
    gap: 16px;
    padding: 88px 24px;
  }
`;

export const Left = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
`;

export const Title = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;

  > h1 {
    font-size: 40px;
    line-height: 56px;
  }

  @media ${device.mobileL} {
    > h1 {
      font-size: 32px;
      line-height: 48px;
    }
  }
`;

export const Right = styled.div`
  flex: 1;
  > p {
    font-size: 16px;
    line-height: 28px;
  }

  @media ${device.mobileL} {
    > p {
      font-size: 14px;
      line-height: 26px;
    }
  }
`;

export const Tip = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 32px;
  background: rgba(35, 215, 229, 0.1);
  border-bottom: 8px solid ${colors.primary};

  > p {
    font-size: 16px;
    line-height: 28px;
  }

  @media ${device.mobileL} {
    margin-bottom: 16px;
    > p {
      font-size: 14px;
      line-height: 26px;
    }
  }
`;

export const TipTitle = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;

  > h2 {
    font-size: 26.21px;
    line-height: 35px;
  }

  @media ${device.mobileL} {
    > img {
      width: 28px;
    }

    > h2 {
      font-size: 20px;
    }
  }
`;
