import { corporateImgs } from "../../../assets/imgs/corporate";
import EnquiryForm from "../../../component/Reusable/EnquiryForm";
import Offer from "../../../component/Reusable/Offer";
import NeedAdvice from "../../../component/Reusable/NeedAdvice";
import ReusableIntro from "../../../component/Reusable/ReusableIntro";
import ReusableHero from "../../../component/Reusable/ReusableHero";
import { offerData } from "../offerData";
import bgs from "../../../assets/bgs";

const InsolvencyAdvice = () => {
  const heroContents = {
    type: 2,
    title: <h1>Insolvency Advice</h1>,
    text: (
      <p>
        We deal in commercial/corporate insolvency – if businesses cannot meet
        their financial obligations, we’re here to help find a solution.
      </p>
    ),
    img: bgs.insolvencyAdviceHeroBg,
    mobileimg: bgs.insolvencyAdviceHeroBgMobile,
    button: true,
  };

  const introContents = {
    title: "How Can CTA Help?",
    text: (
      <p>
        When your company cannot pay debts due to insufficient cash flow, the
        aim of insolvency proceedings is to allow a transparent and fair process
        which ultimately helps a company’s creditors get their money back. It’s
        also done to allow the business to become profitable again.
        <br />
        <br />A bonus with commercial insolvency is that it’s classed as a
        financial state and not a legal status. It also means that a company
        that’s insolvent doesn’t need to cease trading, rather can seek a
        solution via an insolvency procedure.
      </p>
    ),
    img: corporateImgs.insolvencyAdviceIntro,
    link: "/enquiry",
  };

  const offerContents = [offerData.insurance, offerData.litigation];

  return (
    <>
      <ReusableHero contents={heroContents} />
      <ReusableIntro contents={introContents} />
      <NeedAdvice />
      <Offer
        contents={offerContents}
        title="Similar Services"
      />
      <EnquiryForm />
    </>
  );
};

export default InsolvencyAdvice;
